<div class="or-mx-48-px or-d-flex or-justify-content-center">
  <div
    class="or-h-52-px or-w-200-px button-border-left or-d-flex or-justify-content-center or-align-items-center or-head-md text-no-wrap"
    (click)="goToFuture()"
    [ngClass]="
      isActiveFuture
        ? 'or-bg-primary or-color-white'
        : 'or-color-secondary or-bg-white'
    "
  >
    Przyszłe gierki
  </div>

  <div
    class="or-h-52-px or-w-200-px button-border-right or-d-flex or-justify-content-center or-align-items-center or-head-md text-no-wrap"
    (click)="goToPast()"
    [ngClass]="
      isActivePast
        ? 'or-bg-primary or-color-white'
        : 'or-color-secondary or-bg-white'
    "
  >
    Dawne gierki
  </div>
</div>
