import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const MARK_AS_PAID_COMMAND: InjectionToken<MarkAsPaidCommandPort> =
  new InjectionToken<MarkAsPaidCommandPort>("MARK_AS_PAID_COMMAND");

export interface MarkAsPaidCommandPort {
  markAsPaid(
    gameId: string,
    playerId: string,
    isPaid: boolean
  ): Observable<void>;
}
