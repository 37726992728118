import { LocationDTO } from "../location.dto";
import { GamePlayersDTO, PlayerApplicationsDTO } from "./game.dto";
import { HostDetailsDTO } from "./host-details.dto";
import { GameStatusEnum } from "../../enums";
import { VisibilityEnum } from "../../../../../hosted-games/src/lib/application/enums";
import { ChannelDataDTO } from "../channel-data.dto";

export interface GameDetailsModel {
  readonly gameName: string;
  readonly gameId: string;
  readonly formattedDate: string;
  readonly gameDateStart: string;
  readonly location: LocationDTO;
  readonly totalSlots: number;
  readonly occupiedSlots: number;
  readonly level: string;
  readonly priceAmount: number;
  readonly paymentType: string[];
  readonly description: string;
  readonly gameContactPhone: string;
  readonly duration?: number;
  readonly gameStatus?: GameStatusEnum;
  readonly players?: GamePlayersDTO[];
  readonly playersApplications?: PlayerApplicationsDTO[];
  readonly gamePlayersLeft?: GamePlayersDTO[];
  readonly gameInvitationId?: string;
  readonly host?: HostDetailsDTO;
  readonly link?: string;
  readonly isPaid?: boolean;
  readonly visibility: VisibilityEnum;
  readonly isRecurring: boolean;
  readonly channel: ChannelDataDTO;
  readonly isHost: boolean;
}
