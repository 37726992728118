import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { UserStatsDTO } from "../interfaces";

export const USER_STATS_QUERY: InjectionToken<UserStatsQueryPort> =
  new InjectionToken<UserStatsQueryPort>("USER_STATS_QUERY");

export interface UserStatsQueryPort {
  getStats(): Observable<UserStatsDTO>;
}
