import { Provider } from "@angular/core";
import { CreateGameCommandHandler } from "./create-game.command-handler";
import {
  ADD_PLAYER_COMMAND,
  APPROVE_APPLICATIONS_COMMAND,
  CREATE_GAME_COMMAND,
  GET_HOSTED_FUTURE_GAMES_QUERY,
  GET_HOSTED_GAME_QUERY,
  GET_HOSTED_PAST_GAMES_QUERY,
  MARK_AS_PAID_COMMAND,
  REJECT_APPLICATIONS_COMMAND,
  REMOVE_GAME_COMMAND,
  REMOVE_PLAYER_COMMAND,
  UPDATE_GAME_COMMAND,
} from "../ports";
import { GetHostedFutureGamesQueryHandler } from "./get-hosted-future-games.query-handler";
import { GetHostedPastGamesQueryHandler } from "./get-hosted-past-games.query-handler";
import { GetHostedGameQueryHandler } from "./get-hosted-game.query-handler";
import { ApproveApplicationsCommandHandler } from "./approve-applications.command-handler";
import { RemoveGameCommandHandler } from "./remove-game.command-handler";
import { RemovePlayerCommandHandler } from "./remove-player.command-handler";
import { RejectApplicationsCommandHandler } from "./reject-applications.command-handler";
import { MarkAsPaidCommandHandler } from "./mark-as-paid.command-handler";
import { AddPlayerCommandHandler } from "./add-player.command-handler";
import { UpdateGameCommandHandler } from "./update-game.command-handler";

export const provideCreateGameCommand = (): Provider => ({
  provide: CREATE_GAME_COMMAND,
  useClass: CreateGameCommandHandler,
});

export const provideHostedFutureGamesQuery = (): Provider => ({
  provide: GET_HOSTED_FUTURE_GAMES_QUERY,
  useClass: GetHostedFutureGamesQueryHandler,
});

export const provideHostedPastGamesQuery = (): Provider => ({
  provide: GET_HOSTED_PAST_GAMES_QUERY,
  useClass: GetHostedPastGamesQueryHandler,
});

export const provideHostedGameQuery = (): Provider => ({
  provide: GET_HOSTED_GAME_QUERY,
  useClass: GetHostedGameQueryHandler,
});

export const provideApproveApplicationsCommand = (): Provider => ({
  provide: APPROVE_APPLICATIONS_COMMAND,
  useClass: ApproveApplicationsCommandHandler,
});

export const provideRejectApplicationsCommand = (): Provider => ({
  provide: REJECT_APPLICATIONS_COMMAND,
  useClass: RejectApplicationsCommandHandler,
});

export const provideRemoveGamesCommand = (): Provider => ({
  provide: REMOVE_GAME_COMMAND,
  useClass: RemoveGameCommandHandler,
});

export const provideRemovePlayerCommand = (): Provider => ({
  provide: REMOVE_PLAYER_COMMAND,
  useClass: RemovePlayerCommandHandler,
});

export const provideMarkAsPaidCommand = (): Provider => ({
  provide: MARK_AS_PAID_COMMAND,
  useClass: MarkAsPaidCommandHandler,
});

export const provideAddPlayerCommand = (): Provider => ({
  provide: ADD_PLAYER_COMMAND,
  useClass: AddPlayerCommandHandler,
});

export const provideUpdateGameCommand = (): Provider => ({
  provide: UPDATE_GAME_COMMAND,
  useClass: UpdateGameCommandHandler,
});
