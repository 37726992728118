import { inject } from "@angular/core";
import {
  ADVANCED_INVITATIONS_CONTEXT,
  AdvancedInvitationsContextPort,
} from "../../infrastructure/storage";
import { AdvancedInvitationsQueryPort } from "../ports";
import { map, Observable } from "rxjs";
import { AdvancedInvitationsDTO, AdvancedInvitationsUser } from "../interfaces";

export class AdvancedInvitationsQueryHandler
  implements AdvancedInvitationsQueryPort
{
  private readonly advancedInvitationsContextPort: AdvancedInvitationsContextPort =
    inject(ADVANCED_INVITATIONS_CONTEXT);

  getChatUsers(): Observable<AdvancedInvitationsUser[]> {
    return this.advancedInvitationsContextPort.state$.pipe(
      map((data: AdvancedInvitationsDTO) => data.chatUsers)
    );
  }

  getPastUsers(): Observable<AdvancedInvitationsUser[]> {
    return this.advancedInvitationsContextPort.state$.pipe(
      map((data: AdvancedInvitationsDTO) => data.pastGames)
    );
  }
}
