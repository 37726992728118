import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NgxSpinnerComponent } from "ngx-spinner";

@Component({
  selector: "or-loading",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxSpinnerComponent],
  template: `<ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="large"
    color="#5a31f4"
    type="ball-scale-multiple"
  >
    <div class="or-head-xl or-color-secondary-3 text-no-wrap">
      {{ message }}
    </div>
  </ngx-spinner>`,
})
export class OrLoadingComponent {
  @Input() message: string = "";
}
