import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { AddToChannelCommandPort } from "../ports";
import {
  CHAT_CHANEL_DTO,
  ChatDtoPort,
} from "../../infrastructure/http-service";

export class AddToChannelCommandHandler implements AddToChannelCommandPort {
  private readonly chatDtoPort: ChatDtoPort = inject(CHAT_CHANEL_DTO);

  add(channelId: string): Observable<{ accepted: boolean }> {
    return this.chatDtoPort.addToChannel(channelId);
  }
}
