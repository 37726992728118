import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { UsersListDetailsDTO } from "../../models";

export const GET_ADMIN_USERS_LIST_QUERY: InjectionToken<GetAdminUsersListQueryPort> =
  new InjectionToken<GetAdminUsersListQueryPort>("GET_ADMIN_USERS_LIST_QUERY");

export interface GetAdminUsersListQueryPort {
  getList(): Observable<UsersListDetailsDTO[]>;
}
