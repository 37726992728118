import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { FiltersValues } from "../models";

export const SAVE_FILTERS_COMMAND: InjectionToken<SaveFiltersCommandPort> =
  new InjectionToken<SaveFiltersCommandPort>("SAVE_FILTERS_COMMAND");

export interface SaveFiltersCommandPort {
  saveFilters(data: FiltersValues): Observable<void>;
  defaultValue(): Observable<void>;
}
