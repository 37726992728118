import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  MODAL_TOKEN,
  ModalProvider,
  NAVIGATION_TOKEN,
  NavigationProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { ActivatedRoute, Params } from "@angular/router";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { OrAvatarComponent, TitileNavbarComponent } from "@ui-components";
import { Observable, shareReplay, switchMap, take, tap } from "rxjs";
import {
  provideChannelDetailsQuery,
  provideUpdateChannelCommand,
} from "../../application/handlers";
import {
  CHANNEL_DETAILS_QUERY,
  ChannelDetailsQueryPort,
  UPDATE_CHANNEL_COMMAND,
  UpdateChannelCommandPort,
} from "../../application/ports";
import { ChannelDetailsDTO } from "../../application/interfaces";

@Component({
  selector: "lib-edit-channel",
  templateUrl: "./edit-channel.component.html",
  styleUrls: ["./edit-channel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TitileNavbarComponent,
    NgIf,
    OrAvatarComponent,
    ReactiveFormsModule,
    NgClass,
  ],
  providers: [provideChannelDetailsQuery(), provideUpdateChannelCommand()],
})
export class EditChannelComponent implements OnInit {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly navigationProvider: NavigationProvider =
    inject(NAVIGATION_TOKEN);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly channelDetailsQueryPort: ChannelDetailsQueryPort = inject(
    CHANNEL_DETAILS_QUERY
  );
  private readonly updateChannelCommandPort: UpdateChannelCommandPort = inject(
    UPDATE_CHANNEL_COMMAND
  );

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly channelDataForm: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.maxLength(25)]),
    id: new FormControl("", [Validators.required]),
  });

  readonly channelDetails$: Observable<ChannelDetailsDTO> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.activatedRoute.params),
      take(1),
      switchMap((params: Params) =>
        this.channelDetailsQueryPort.details(params["id"])
      ),
      take(1),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1)
    );

  ngOnInit() {
    this.channelDetails$
      .pipe(
        take(1),
        tap((data: ChannelDetailsDTO) => {
          this.channelDataForm.patchValue({
            name: data.channelName,
            id: data.channelId,
          });
        })
      )
      .subscribe();
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.channelDataForm.get(fieldName);
    return control ? control.touched && control.invalid : false;
  }

  get isNameOverAvailableLength() {
    return this.channelDataForm.get("name")?.value?.length > 25;
  }

  backToChannel(): void {
    this.navigationProvider.back();
  }

  saveData(): void {
    const id: string = this.channelDataForm?.value?.id;

    const payload: {
      name: string;
    } = this.channelDataForm?.value;

    this.updateChannelCommandPort
      .update({ id: id, payload })
      .pipe(take(1))
      .subscribe(() => this.backToChannel());
  }
}
