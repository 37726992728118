import { GameDetailsQueryPort } from "../ports";
import { map, Observable, take } from "rxjs";
import { inject } from "@angular/core";
import { DateFormatPipe } from "@ui-components";
import { GAMES_DTO, GamesDTOPort } from "../../infrastructure/http-service";
import { mapToGameQueryUtil } from "./utils/map-to-game-query.util";
import { GameDetailsModel } from "@core";
import { PlayerGameDTO } from "../interfaces";

export class GameDetailsQueryHandler implements GameDetailsQueryPort {
  private gameDTOPort: GamesDTOPort = inject(GAMES_DTO);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  getGameDetails(gameId: string): Observable<GameDetailsModel> {
    return this.gameDTOPort.getOneGame(gameId).pipe(
      take(1),
      map((game: PlayerGameDTO) =>
        mapToGameQueryUtil(game, this.dateFormatPipe)
      )
    );
  }
}
