import { environment } from "src/environment/environment";
import { PlatformProvider } from "@core";

export const getNewVersionNative = (platformProvider: PlatformProvider) => {
  if (platformProvider.isAndroid && environment.environment === "production") {
    window.open(environment.androidApkUrl, "_blank");
  }

  if (platformProvider.isiOS && environment.environment === "production") {
    window.open(environment.iOSApkUrl, "_blank");
  }

  if (platformProvider.isAndroid && environment.environment === "stage") {
    window.open(environment.androidApkUrl, "_blank");
  }
};
