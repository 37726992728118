import { NotificationType } from "../enums";

export interface PushNotificationData {
  gameDate: string;
  gameName: string;
  gameAddress: string;
  gameId: string;
  notificationId: string;
  gameHours: string;
  gameWeekday: string;
  notificationType: NotificationType;
}
