<div
  (click)="openDetailsModal()"
  class="border-radius-8 or-bg-white or-cursor-pointer or-mx-16-px or-pb-12-px or-pt-4-px or-px-12-px z-index-1 card-box-shadow"
  *ngIf="game"
>
  <div class="or-mt-8-px or-d-flex or-justify-content-between">
    <div class="or-head-lg">{{ game.name }}</div>
    <div
      *ngIf="game.currentUserParticipationStatus === 'Approved'"
      class="border-radius-4 or-align-content-center or-bg-shade or-p-4-px or-text-sm text-no-wrap"
    >
      Grasz w tej gierce
    </div>
  </div>

  <div class="or-d-flex or-align-items-center or-mt-8-px">
    <img src="assets/icons/clock-icon.svg" class="or-mr-8-px" />

    <div class="or-text-md">
      {{ game.gameDateStart | dateFormat : game.gameDateEnd }}
    </div>
  </div>

  <div class="or-d-flex or-align-items-center or-mt-4-px">
    <img src="assets/icons/map-pin-icon.svg" class="or-mr-8-px" />

    <div class="or-text-md">
      {{ game.location.address }}, {{ game.location.city }}
    </div>
  </div>

  <div class="or-d-flex or-mt-4-px or-justify-content-between">
    <div class="or-d-flex">
      <div class="or-d-flex or-align-items-center or-mr-20-px">
        <img src="assets/icons/price-icon.svg" class="or-mr-8-px" />

        <div *ngIf="+game.priceAmount" class="or-text-md">
          {{ +game.priceAmount }} zł
        </div>
        <div *ngIf="!+game.priceAmount" class="or-text-md">Darmowa</div>
      </div>

      <div class="or-d-flex or-align-items-center">
        <img src="assets/icons/member-icon.svg" class="or-mr-8-px" />

        <div class="or-text-md">
          {{ game.occupiedSlots }}/{{ game.totalSlots }}
          <span *ngIf="(isAdmin$ | async) && game.playerApplications?.length"
            >({{ game.playerApplications?.length }})</span
          >
        </div>
      </div>
    </div>
    <div>
      <div
        *ngIf="game.visibility === 'PRIVATE'"
        class="border-radius-4 or-align-content-center or-bg-shade or-p-4-px or-text-sm text-no-wrap negative-margin-top-24-px"
      >
        Ukryta na mapie
      </div>
    </div>
  </div>
</div>
