<div class="or-p-24-px">
  <div class="or-head-xxl or-mb-20-px">Witaj w Orlikfy!</div>

  <div class="or-text-lg or-mb-20-px or-color-secondary-2">
    Nie organizujesz i nie grasz w żadnych gierkach.
  </div>

  <div class="or-text-lg or-mb-20-px or-color-secondary-2">
    Stwórz nową gierkę albo odwiedź mapę, by znaleźć gierkę dla siebie!
  </div>

  <div class="or-text-lg or-mb-32-px or-color-secondary-2">
    Gdy dodasz lub zorganizujesz gierkę, pojawi się ona na tym ekranie.
  </div>

  <div class="or-head-lg or-mb-20-px">Co warto zrobić na początek?</div>

  <div class="or-d-flex or-flex-col">
    <div
      (click)="goToGameCreator()"
      class="border-shade border-radius-4 or-p-16-px or-bg-white or-mb-12-px"
    >
      <div class="or-d-flex or-align-items-start">
        <img src="assets/icons/field-vertical.svg" class="or-mr-12-px" />
        <div>
          <div class="or-head-sm or-mb-8-px">Stwórz gierkę</div>
          <div class="or-text-md or-color-secondary-2">
            Dodaj swoją gierkę do aplikacji i znajdź chętnych do grania razem z
            Tobą!
          </div>
        </div>
      </div>
    </div>

    <div
      (click)="goToMap()"
      class="border-shade border-radius-4 or-p-16-px or-bg-white"
    >
      <div class="or-d-flex or-align-items-start">
        <img
          src="assets/icons/bottom-navbar/bottom-find-games.svg"
          class="or-mr-12-px"
        />
        <div>
          <div class="or-head-sm or-mb-8-px">Sprawdź mapkę z gierkami</div>
          <div class="or-text-md or-color-secondary-2">
            Znajdziesz tam wszystkie gierki do których możesz dołączyć
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
