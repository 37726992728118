import {
  ADVANCED_INVITATIONS_CONTEXT,
  AdvancedInvitationsContextPort,
} from "../../infrastructure/storage";
import { inject } from "@angular/core";
import { SwitchAdvancedInvitationsCommandPort } from "../ports";
import { map, Observable, switchMap, take, tap } from "rxjs";
import { AdvancedInvitationsDTO, AdvancedInvitationsUser } from "../interfaces";
import { ActivatedRoute, Params } from "@angular/router";
import {
  ADVANCED_INVITATIONS_DTO,
  AdvancedInvitationsDtoPort,
} from "../../infrastructure/http-service";

export class SwitchAdvancedInvitationsCommandHandler
  implements SwitchAdvancedInvitationsCommandPort
{
  private readonly advancedInvitationsContextPort: AdvancedInvitationsContextPort =
    inject(ADVANCED_INVITATIONS_CONTEXT);
  private readonly advancedInvitationsDtoPort: AdvancedInvitationsDtoPort =
    inject(ADVANCED_INVITATIONS_DTO);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  switchAutoInvite(
    userId: string,
    value: boolean,
    gameId?: string
  ): Observable<void> {
    return this.activatedRoute.params.pipe(
      take(1),
      tap(() => this.updateUserProperty(userId, value, "autoInvite")),
      switchMap((params: Params) =>
        this.advancedInvitationsDtoPort.switchAutoInvite(
          gameId ?? params["id"],
          userId,
          value
        )
      )
    );
  }

  switchAutoApproval(
    userId: string,
    value: boolean,
    gameId?: string
  ): Observable<void> {
    return this.activatedRoute.params.pipe(
      take(1),
      tap(() => this.updateUserProperty(userId, value, "autoApproval")),
      switchMap((params: Params) =>
        this.advancedInvitationsDtoPort.switchAutoApproval(
          gameId ?? params["id"],
          userId,
          value
        )
      )
    );
  }

  private updateUserProperty(
    userId: string,
    value: boolean,
    property: keyof AdvancedInvitationsUser
  ): void {
    this.advancedInvitationsContextPort.state$
      .pipe(
        take(1),
        map((data: AdvancedInvitationsDTO) => {
          const updateProperty = (
            users: AdvancedInvitationsUser[]
          ): AdvancedInvitationsUser[] =>
            users.map((user: AdvancedInvitationsUser) =>
              user.userId === userId ? { ...user, [property]: value } : user
            );

          return {
            chatUsers: updateProperty(data.chatUsers),
            pastGames: updateProperty(data.pastGames),
          };
        }),
        switchMap((updatedData: AdvancedInvitationsDTO) =>
          this.advancedInvitationsContextPort.patchContext(updatedData)
        ),
        map(() => void 0)
      )
      .subscribe();
  }
}
