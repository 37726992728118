<form [formGroup]="searchForm">
  <input
    class="input-style or-w-100-pc"
    formControlName="search"
    placeholder="Imię, nazwisko, e-mail, wersja..."
  />
</form>

<div
  *ngIf="!showFilters"
  (click)="changeVisibility()"
  class="or-color-primary or-text-lg or-mt-12-px"
>
  Pokaż filtry
</div>
<div
  *ngIf="showFilters"
  (click)="changeVisibility()"
  class="or-color-red or-text-lg or-mt-12-px"
>
  Zamknij filtry
</div>

<div *ngIf="showFilters" class="or-mt-12-px">
  <div *ngFor="let item of platformType$ | async">
    <ion-checkbox
      labelPlacement="end"
      [checked]="item.checked"
      (ionChange)="select(item.type)"
      ><span class="or-head-sm">{{
        item.type.toUpperCase()
      }}</span></ion-checkbox
    >
  </div>
</div>
