import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Observable, take } from "rxjs";
import { UserStatsDTO } from "../../application/interfaces";
import { USER_STATS_QUERY, UserStatsQueryPort } from "../../application/ports";
import { provideUserStatsService } from "../../infrastructure/http-service";
import { provideUserStatsQuery } from "../../application/handlers";
import { AsyncPipe, NgIf } from "@angular/common";

@Component({
  selector: "lib-user-stats",
  templateUrl: "./user-stats.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideUserStatsService(), provideUserStatsQuery()],
  imports: [NgIf, AsyncPipe],
})
export class UserStatsComponent {
  private readonly userStatsQueryPort: UserStatsQueryPort =
    inject(USER_STATS_QUERY);

  readonly userStats$: Observable<UserStatsDTO> = this.userStatsQueryPort
    .getStats()
    .pipe(take(1));
}
