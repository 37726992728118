import {
  GameLevel,
  GameStatusEnum,
  PaymentType,
  PlayerPosition,
  PreferredPosition,
} from "../../enums";
import { LocationDTO } from "../location.dto";
import { VisibilityEnum } from "../../../../../hosted-games/src/lib/application/enums";
import { ChannelDataDTO } from "../channel-data.dto";
import { PenaltyDTO } from "../../../../../fair-play/src/lib/application/interfaces/penalty.dto";

export interface GameDTO {
  readonly gameId: string;
  readonly name: string;
  readonly description: string;
  readonly gameDateStart: string;
  readonly gameDateEnd: string;
  readonly occupiedSlots: number;
  readonly totalSlots: number;
  readonly freeSlots: number;
  readonly priceAmount: string;
  readonly priceCurrency: string;
  readonly paymentTypes: PaymentType[];
  readonly level: GameLevel;
  readonly location: LocationDTO;
  readonly playerApplications: PlayerApplicationsDTO[];
  readonly gamePlayers: GamePlayersDTO[];
  readonly gamePlayersLeft: GamePlayersDTO[];
  readonly gameContactPhone: string;
  readonly visibility: VisibilityEnum;
  readonly isPaid: boolean;
  readonly isRecurring: boolean;
  readonly channel: ChannelDataDTO;
  readonly currentUserParticipationStatus: GameStatusEnum;
  readonly isHost: boolean;
}

export interface GamePlayersDTO {
  readonly gamePlayerId: string;
  readonly userId: string;
  readonly userDisplayName: string;
  readonly isPaid: true;
  readonly isAutoApproved: boolean;
  readonly isAutoInvited: boolean;
  readonly userThumbnail: string;
  readonly position: PlayerPosition;
  readonly isHost: boolean;
  readonly penalty: PenaltyDTO;
  readonly playerPreferences: PlayerPreferencesDTO;
}

export interface PlayerApplicationsDTO {
  readonly gamePlayerApplicationId: string;
  readonly userId: string;
  readonly userDisplayName: string;
  readonly userThumbnail: string;
  readonly position: PlayerPosition;
  readonly penalty: PenaltyDTO;
}

export interface PlayerPreferencesDTO {
  readonly preferredPosition: PreferredPosition;
  readonly preferredLevel: GameLevel;
}
