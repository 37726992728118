import { NotificationType } from "../enums";
import { AppRoutes } from "src/app/app-routes.enum";
import { HostedGamesRoutes } from "@hosted-games";
import { GamesRoutes } from "@games";
import { Router } from "@angular/router";
import { NotificationsRoutes } from "../../notifications-routes.enum";
import { ChannelTypeEnum } from "../../../../../messages/src/lib/application/enums";

export const notificationRedirectUtil = (data: {
  type: NotificationType;
  gameId: string;
  router: Router;
  customId?: string;
  channelType?: ChannelTypeEnum;
  customRedirectUrl?: string;
}): Promise<boolean> => {
  const hostedGames: NotificationType[] = [
    NotificationType.HostedGameNewApplication,
    NotificationType.HostedGamePlayerLeft,
    NotificationType.HostedGameReminder,
  ];
  const playerGames: NotificationType[] = [
    NotificationType.GameApplicationApproved,
    NotificationType.GameReminder,
    NotificationType.GameCanceled,
  ];

  const sharedGames: NotificationType[] = [
    NotificationType.NewGameByAlert,
    NotificationType.GameInvitationRecived,
  ];

  if (hostedGames.includes(data.type)) {
    return data.router.navigate([
      `${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${data.gameId}`,
    ]);
  }

  if (playerGames.includes(data.type)) {
    return data.router.navigate([
      `${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${data.gameId}`,
    ]);
  }

  if (sharedGames.includes(data.type)) {
    return data.router.navigate([
      `${AppRoutes.GAMES}/${GamesRoutes.SHARED}/${data.gameId}`,
    ]);
  }

  if (
    data.type === NotificationType.GameFinished ||
    data.type === NotificationType.HostedGameFinished
  ) {
    return data.router.navigate([
      `${AppRoutes.GAMES}/${GamesRoutes.GAME_FINISHED}/${data.gameId}`,
    ]);
  }

  if (data.type === NotificationType.ChatNewMessage) {
    return data.router.navigate([AppRoutes.MESSAGES], {
      queryParams: { type: data.channelType },
    });
  }

  if (
    data.type === NotificationType.CustomNotification &&
    !data.customRedirectUrl
  ) {
    return data.router.navigate([
      `${AppRoutes.NOTIFICATIONS}/${NotificationsRoutes.CUSTOM_NOTIFICATION}/${data.customId}`,
    ]);
  }

  if (
    data.type === NotificationType.CustomNotification &&
    data.customRedirectUrl
  ) {
    return data.router.navigate([`${data.customRedirectUrl}`]);
  }

  return data.router.navigate([AppRoutes.NOTIFICATIONS]);
};
