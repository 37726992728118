import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { NgClass } from "@angular/common";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { provideAddPlayerCommand } from "../../application/handlers";
import {
  ADD_PLAYER_COMMAND,
  AddPlayerCommandPort,
} from "../../application/ports";
import { BehaviorSubject, switchMap, take } from "rxjs";
import { CustomPlayerModel } from "../../application/interfaces";
import { MODAL_TOKEN, ModalProvider } from "@core";
import { provideHostedGamesService } from "../../infrastructure/http-service";
import { IonicModule } from "@ionic/angular";

@Component({
  selector: "lib-add-custom-player",
  templateUrl: "./add-custom-player.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, IonicModule],
  providers: [provideAddPlayerCommand(), provideHostedGamesService()],
})
export class AddCustomPlayerComponent implements OnInit {
  @Input() gameId!: string;

  private readonly addPlayerCommandPort: AddPlayerCommandPort =
    inject(ADD_PLAYER_COMMAND);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  private readonly addPlayerSubject: BehaviorSubject<CustomPlayerModel> =
    new BehaviorSubject<CustomPlayerModel>({} as CustomPlayerModel);

  ngOnInit() {
    this.addPLayerForm.valueChanges.subscribe((form) =>
      this.addPlayerSubject.next({ name: form.name, surname: form.surname })
    );
  }

  readonly addPLayerForm: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    surname: new FormControl("", [Validators.required]),
  });

  addPlayer(): void {
    this.addPlayerSubject
      .asObservable()
      .pipe(
        take(1),
        switchMap((value: CustomPlayerModel) => {
          return this.addPlayerCommandPort.addPlayer(this.gameId, value);
        }),
        take(1)
      )
      .subscribe(() => this.closeModal());
  }

  closeModal(): void {
    this.modalProvider.dismissAllStoredModals();
  }
}
