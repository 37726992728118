import { NotificationType } from "../enums";

export interface NotificationViewModel {
  title: string;
  description: string;
  formattedDate: string | null;
  gameName: string;
  address: string;
  avatar: string;
  isRead: boolean;
  id: string;
  type: NotificationType;
  createdAt: string;
  gameId: string;
  displayName: string;
  customId: string;
  customRedirectUrl: string;
  teamId: string;
  teamName: string;
  receiverUserId: string;
}
