import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { NotificationsRoutes } from "@notifications";
import { AsyncPipe, NgIf } from "@angular/common";
import { Observable } from "rxjs";
import { FeatureFlag, FeatureFlagHandler } from "@feature-flags";
import { TeamsRoutes } from "@teams";

@Component({
  selector: "lib-dashboard-navbar",
  templateUrl: "./dashboard-navbar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe],
})
export class DashboardNavbarComponent {
  @Input({ required: true }) canCreateTeam: boolean = false;

  private readonly router: Router = inject(Router);
  private readonly featureFlagHandler: FeatureFlagHandler =
    inject(FeatureFlagHandler);

  readonly isTeamEnabled$: Observable<boolean> =
    this.featureFlagHandler.isEnabled$(FeatureFlag.TEAMS);

  goToHosted(): void {
    this.router.navigateByUrl(AppRoutes.HOST);
  }

  goToGames(): void {
    this.router.navigateByUrl(AppRoutes.GAMES);
  }

  goToAlerts(): void {
    this.router.navigateByUrl(
      `${AppRoutes.NOTIFICATIONS}/${NotificationsRoutes.ALERTS}`
    );
  }

  createTeam(): void {
    this.router.navigateByUrl(`${AppRoutes.TEAMS}/${TeamsRoutes.CREATE}`);
  }
}
