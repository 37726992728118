import { inject, InjectionToken } from "@angular/core";
import { NavigationService } from "../services";

export const NAVIGATION_TOKEN: InjectionToken<NavigationProvider> =
  new InjectionToken<NavigationProvider>("NAVIGATION_TOKEN", {
    factory: (): NavigationProvider => {
      const navigationService: NavigationService = inject(NavigationService);

      return {
        init() {
          navigationService.init();
        },
        back() {
          navigationService.back();
        },
        hasHistory: navigationService.hasHistory,
        previousPage() {
          return navigationService.previousPage();
        },
      };
    },
  });

export interface NavigationProvider {
  init(): void;
  back(): void;
  hasHistory: boolean;
  previousPage(): string;
}
