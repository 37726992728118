import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { FiltersValues } from "../../../application/models";

export const FILTERS_CONTEXT_PORT: InjectionToken<FiltersContextPort> =
  new InjectionToken<FiltersContextPort>("FILTERS_CONTEXT_PORT");

export interface FiltersContextPort {
  filters$: Observable<FiltersValues>;
  setFilters(data: FiltersValues): Observable<void>;
  reset(): Observable<void>;
}
