<lib-title-navbar title="Polityka prywatnosci"></lib-title-navbar>

<div class="or-text-align-center or-px-12-px">
  <div class="or-head-30 or-mb-20-px">
    Polityka Prywatności Aplikacji "Orlikfy"
  </div>
  <p>
    Niniejsza Polityka Prywatności określa zasady przetwarzania danych osobowych
    użytkowników aplikacji "Orlikfy" (dalej: "Aplikacja").
  </p>

  <div class="or-mb-8-px"></div>

  <div class="or-text-md or-mb-20-px">
    <div class="or-head-xl">1. Administrator Danych</div>
    <p>
      Administratorem danych osobowych jest Dominik Króliczek Yeah Bunny Soft,
      adres do doręczeń ul. Mielęckiego 10 lok. 503, 40-013 Katowice, NIP:
      5512604761 (dalej: "Administrator").
    </p>
  </div>

  <div class="or-text-md or-mb-20-px">
    <div class="or-head-xl">2. Cel Przetwarzania Danych</div>
    <p>Dane osobowe użytkowników przetwarzane są w celu:</p>
    <ul>
      <li>Rejestracji i zarządzania kontami użytkowników.</li>
      <li>
        Organizacji i udziału w amatorskich meczach piłki nożnej lub innych
        wydarzeniach sportowych.
      </li>
      <li>Komunikacji z użytkownikami.</li>
      <li>Realizacji obowiązków prawnych ciążących na Administratorze.</li>
      <li>Poprawy jakości usług świadczonych przez Aplikację.</li>
    </ul>
  </div>

  <div class="or-text-md or-mb-20-px">
    <div class="or-head-xl">3. Rodzaj Przetwarzanych Danych</div>
    <p>Przetwarzamy następujące dane osobowe użytkowników:</p>
    <ul>
      <li>Imię i nazwisko.</li>
      <li>Adres e-mail.</li>
      <li>Numer telefonu.</li>
      <li>
        Informacje o uczestnictwie w meczach (np. preferencje, statystyki).
      </li>
      <li>Lokalizacja po wyrażeniu przez użytkownika zgody.</li>
    </ul>
  </div>

  <div class="or-text-md or-mb-20-px">
    <div class="or-head-xl">4. Podstawa Prawna Przetwarzania Danych</div>
    <p>Dane osobowe przetwarzane są na podstawie:</p>
    <ul>
      <li>
        Zgody użytkownika (art. 6 ust. 1 lit. a RODO) wyrażonej podczas
        dobrowolnej rejestracji.
      </li>
      <li>Realizacji obowiązków prawnych (art. 6 ust. 1 lit. c RODO).</li>
    </ul>
  </div>

  <div class="or-text-md or-mb-20-px">
    <div class="or-head-xl">5. Przekazywanie Danych</div>
    <p>Dane osobowe mogą być przekazywane:</p>
    <ul>
      <li>
        Podmiotom wspierającym Administratora w prowadzeniu Aplikacji (np.
        dostawcom usług IT).
      </li>
      <li>
        Organom publicznym, jeżeli wynika to z obowiązujących przepisów prawa.
      </li>
    </ul>
  </div>

  <div class="or-text-md or-mb-20-px">
    <div class="or-head-xl">6. Okres Przechowywania Danych</div>
    <p>Dane osobowe będą przechowywane przez okres:</p>
    <ul>
      <li>Niezbędny do realizacji celów przetwarzania określonych w pkt 2.</li>
      <li>
        Do momentu wycofania zgody przez użytkownika poprzez usunięcie konta w
        aplikacji.
      </li>
    </ul>
  </div>

  <div class="or-text-md or-mb-20-px">
    <div class="or-head-xl">7. Prawa Użytkowników</div>
    <p>Użytkownik ma prawo do:</p>
    <ul>
      <li>Dostępu do swoich danych osobowych.</li>
      <li>Sprostowania swoich danych.</li>
      <li>Usunięcia danych (prawo do bycia zapomnianym).</li>
      <li>Ograniczenia przetwarzania.</li>
      <li>Przenoszenia danych.</li>
      <li>Sprzeciwu wobec przetwarzania danych.</li>
      <li>Wycofania zgody na przetwarzanie danych w dowolnym momencie.</li>
    </ul>
    <p>
      W celu skorzystania z powyższych praw, należy skontaktować się z
      Administratorem pod adresem e-mail wskazanym w punkcie 8.
    </p>
  </div>

  <div class="or-text-md or-mb-20-px">
    <div class="or-head-xl">8. Kontakt</div>
    <p>
      W przypadku pytań dotyczących niniejszej Polityki Prywatności, prosimy o
      kontakt pod adresem e-mail:
      <a href="mailto:kontakt.orlikfy@gmail.com">kontakt.orlikfy&#64;gmail.com</a>
    </p>
  </div>

  <div class="or-text-md or-mb-20-px">
    <div class="or-head-xl">9. Zmiany Polityki Prywatności</div>
    <p>
      Administrator zastrzega sobie prawo do wprowadzania zmian w Polityce
      Prywatności. Zmiany będą publikowane w Aplikacji i wchodzą w życie z dniem
      ich publikacji.
    </p>

    <p>Data ostatniej aktualizacji: 03.06.2024</p>
  </div>
</div>
