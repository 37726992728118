<lib-title-navbar title="Gierki pod tym adresem"></lib-title-navbar>

<div *ngIf="locationDetails$ | async as games" class="or-h-100-pc">
  <div class="or-d-flex or-align-items-center or-mt-16-px or-mx-16-px">
    <img src="assets/icons/map-pin-icon.svg" class="or-mr-8-px" />
    <div>
      <div class="or-head-sm or-color-primary-2">Adres</div>
      <div class="or-head-md">{{ games.name }} ({{ games.address }})</div>
    </div>
  </div>

  <div class="or-d-flex or-justify-content-center">
    <div class="custom-divider-location-details or-my-16-px"></div>
  </div>

  <div
    [ngClass]="
      isiOS
        ? 'full-height-scrollable-container-ios'
        : 'full-height-scrollable-container'
    "
  >
    <ng-container *ngIf="games.todayGames.length">
      <div class="or-text-lg or-ml-16-px">Dzisiejsze</div>
    </ng-container>

    <ng-container *ngFor="let game of games.todayGames">
      <ng-container
        *ngTemplateOutlet="gameTemplate; context: { $implicit: game }"
      ></ng-container>
    </ng-container>

    <ng-container *ngIf="games.todayGames.length">
      <div class="or-d-flex or-justify-content-center">
        <div class="custom-divider-location-details or-mb-16-px"></div></div
    ></ng-container>

    <ng-container *ngIf="games.futureGames.length">
      <div class="or-text-lg or-ml-16-px">Pozostałe</div>
    </ng-container>

    <ng-container *ngFor="let game of games.futureGames">
      <ng-container
        *ngTemplateOutlet="gameTemplate; context: { $implicit: game }"
      ></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #gameTemplate let-game>
  <div
    class="border-radius-4 or-bg-white or-cursor-pointer or-mx-16-px or-p-12-px z-index-1 or-my-12-px"
  >
    <div class="or-d-flex or-justify-content-between or-align-items-center">
      <div class="or-head-lg">{{ game.name }}</div>
      <button
        (click)="openDetails(game.gameId)"
        class="button-primary-style or-text-md or-p-8-px text-no-wrap"
      >
        Pokaż szczegóły
      </button>
    </div>

    <div class="or-d-flex or-align-items-between or-mt-8-px">
      <img src="assets/icons/clock-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Data</div>
        <div class="or-text-md or-mt-2-px">
          {{ game.gameDate | dateFormat : game.gameDateEnd }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<lib-bottom-navbar></lib-bottom-navbar>
