import { NearbyGamesDtoPort } from "./nearby-games.dto-port";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig, GameDTO, NerabyGamesDTO } from "@core";

export class NearbyGamesService implements NearbyGamesDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getMarkers(params: string): Observable<{ locations: NerabyGamesDTO[] }> {
    return this.httpClient.get<{ locations: NerabyGamesDTO[] }>(
      `${this.env.apiUrl}/nearby-games/locations?${params}`
    );
  }

  getGamesList(params: string): Observable<{ games: GameDTO[] }> {
    return this.httpClient.get<{ games: GameDTO[] }>(
      `${this.env.apiUrl}/nearby-games/list?${params}`
    );
  }
}
