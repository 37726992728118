import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Output,
} from "@angular/core";
import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import {
  GameCardComponent,
  GameDetailsComponent,
  RedirectTypeEnum,
} from "@ui-components";
import {
  combineLatest,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from "rxjs";
import { GameDetailsModel, MODAL_TOKEN, ModalProvider } from "@core";
import {
  GET_HOSTED_FUTURE_GAMES_QUERY,
  GetHostedFutureGamesQueryPort,
} from "../../../../../hosted-games/src/lib/application/ports";
import {
  HostedFutureGamesListComponent,
  provideHostedFutureGamesQuery,
  provideHostedGamesService,
} from "@hosted-games";
import {
  GET_FUTURE_GAMES_QUERY,
  GetFutureGamesQueryPort,
  provideFutureGamesQuery,
  provideGamesService,
} from "@games";
import { IonicModule } from "@ionic/angular";
import {
  CREATE_SINGLE_CHAT_COMMAND,
  CreateSingleChatCommandPort,
  provideChatService,
  provideCreateSingleChatCommand,
} from "@messages";

@Component({
  selector: "lib-dashboard-games-list",
  templateUrl: "./dashboard-games-list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    GameCardComponent,
    HostedFutureGamesListComponent,
    NgForOf,
    NgIf,
    IonicModule,
    NgClass,
    GameDetailsComponent,
  ],
  providers: [
    provideHostedFutureGamesQuery(),
    provideHostedGamesService(),
    provideGamesService(),
    provideFutureGamesQuery(),
    provideCreateSingleChatCommand(),
    provideChatService(),
  ],
})
export class DashboardGamesListComponent implements AfterViewInit {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getHostedFutureGamesQueryPort: GetHostedFutureGamesQueryPort =
    inject(GET_HOSTED_FUTURE_GAMES_QUERY);
  private readonly getFutureGamesQueryPort: GetFutureGamesQueryPort = inject(
    GET_FUTURE_GAMES_QUERY
  );
  private readonly createSingleChatCommandPort: CreateSingleChatCommandPort =
    inject(CREATE_SINGLE_CHAT_COMMAND);

  @Output() isGameAvailable: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public gameRedirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;
  public isGamesExpanded: boolean = true;

  readonly hostedGames$: Observable<GameDetailsModel[]> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.getHostedFutureGamesQueryPort.getGames()),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1)
    );

  readonly playerApprovedGames$: Observable<GameDetailsModel[]> =
    this.modalProvider.showLoading$().pipe(
      switchMap(() => this.getFutureGamesQueryPort.getApproved()),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1)
    );

  readonly playerWaitingGames$: Observable<GameDetailsModel[]> =
    this.modalProvider.showLoading$().pipe(
      switchMap(() => this.getFutureGamesQueryPort.getWaiting()),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1)
    );

  ngAfterViewInit(): void {
    this.sendIsAvailableEvent();
  }

  changeGamesVisibility(): void {
    this.isGamesExpanded = !this.isGamesExpanded;
  }

  sendMessage(userId: string): void {
    this.createSingleChatCommandPort.create(userId).subscribe();
  }

  private sendIsAvailableEvent(): void {
    combineLatest([
      this.hostedGames$,
      this.playerApprovedGames$,
      this.playerWaitingGames$,
    ])
      .pipe(
        take(1),
        tap(
          ([hosted, approved, waiting]: [
            GameDetailsModel[],
            GameDetailsModel[],
            GameDetailsModel[]
          ]) => {
            const result: boolean =
              !!hosted?.length || !!approved?.length || !!waiting?.length;

            this.isGameAvailable.emit(result);
          }
        )
      )
      .subscribe();
  }
}
