import { NotificationType } from "../enums";

export interface NotificationViewModel {
  title: string;
  gameName: string;
  address: string;
  date: string;
  hours: string;
  avatar: string;
  isRead: boolean;
  id: string;
  type: NotificationType;
  createdAt: string;
  gameId: string;
  displayName: string;
  customId: string;
  customRedirectUrl: string;
}
