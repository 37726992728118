import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { IsAuthedService } from "@core";
import { inject } from "@angular/core";
import { map, take } from "rxjs";

export const chatInvitationsResolver: ResolveFn<void> = (
  activatedRoute: ActivatedRouteSnapshot
) => {
  const isAuthed: IsAuthedService = inject(IsAuthedService);
  const channelId: string = activatedRoute.params["id"];
  const itemKey: string = "invitations-channel-id";

  isAuthed.isAuthed$
    .pipe(
      take(1),
      map((isAuthed: boolean) => {
        if (isAuthed) return sessionStorage.removeItem(itemKey);

        return sessionStorage.setItem(itemKey, channelId);
      })
    )
    .subscribe();
};
