import { ModalProvider, PresentModalComponent } from "@core";

export const showInstructionModal = (modalProvider: ModalProvider) => {
  modalProvider.showModal$({
    component: PresentModalComponent,
    componentProps: {
      header: "Czym jest chat grupowy?",
      message: chatInstruction,
      btnTxt: "Zamknij",
    },
    cssClass: "present-modal",
  });
};

const chatInstruction: string = `Chat grupowy, to miejsce w którym możesz pisać ze swoją ekipą w sprawie gierek.<br> <br>
Jeden chat grupowy może być przypisany do wielu gierek, co oznacza że Wasza paczka może zgadywać się w jednym miejscu i być zapraszana na różne gierki. Wszystko zależy od Ciebie! `;
