import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { Observable, switchMap, take } from "rxjs";
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgTemplateOutlet,
} from "@angular/common";
import {
  provideAdvancedInvitationsQuery,
  provideSwitchAdvancedInvitationsCommand,
} from "../../application/handlers";
import {
  ADVANCED_INVITATIONS_QUERY,
  AdvancedInvitationsQueryPort,
  SWITCH_ADVANCED_INVITATIONS_COMMAND,
  SwitchAdvancedInvitationsCommandPort,
} from "../../application/ports";
import { IonicModule } from "@ionic/angular";
import { OrAvatarComponent } from "@ui-components";
import { AdvancedInvitationsUser } from "../../application/interfaces";
import { PLATFORM_TOKEN, PlatformProvider } from "@core";

@Component({
  selector: "lib-advanced-invitations",
  templateUrl: "./advanced-invitations.component.html",
  styleUrls: ["./advanced-invitations.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    IonicModule,
    NgForOf,
    NgIf,
    OrAvatarComponent,
    NgClass,
  ],
  providers: [
    provideAdvancedInvitationsQuery(),
    provideSwitchAdvancedInvitationsCommand(),
  ],
})
export class AdvancedInvitationsComponent {
  @Input({ required: true }) invitationsActive$!: Observable<boolean>;

  private readonly advancedInvitationsQueryPort: AdvancedInvitationsQueryPort =
    inject(ADVANCED_INVITATIONS_QUERY);
  private readonly switchAdvancedInvitationsCommandPort: SwitchAdvancedInvitationsCommandPort =
    inject(SWITCH_ADVANCED_INVITATIONS_COMMAND);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  readonly chatUsers$: Observable<AdvancedInvitationsUser[]> =
    this.advancedInvitationsQueryPort.getChatUsers();
  readonly pastUsers$: Observable<AdvancedInvitationsUser[]> =
    this.advancedInvitationsQueryPort.getPastUsers();

  readonly isiOS: boolean = this.platformProvider.isiOS;

  selectPlayer(userId: string, event: CustomEvent): void {
    const value: boolean = event.detail.checked;

    this.invitationsActive$
      .pipe(
        take(1),
        switchMap((isInvitationsActive: boolean) => {
          if (isInvitationsActive)
            return this.switchAdvancedInvitationsCommandPort.switchAutoInvite(
              userId,
              value
            );
          return this.switchAdvancedInvitationsCommandPort.switchAutoApproval(
            userId,
            value
          );
        })
      )
      .subscribe();
  }
}
