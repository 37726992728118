import { LOAD_FEATURE_FLAGS_COMMAND } from "../ports/command";
import { LoadFeatureFlagsCommandHandler } from "./load-feature-flags.command-handler";
import { FEATURE_FLAG_TOKEN } from "../tokens";
import { FeatureFlagTokenHandler } from "./feature-flag.token-handler";
import { Provider } from "@angular/core";

export const provideLoadFeatureFlagsCommand = (): Provider => ({
  provide: LOAD_FEATURE_FLAGS_COMMAND,
  useClass: LoadFeatureFlagsCommandHandler,
});

export const provideFeatureFlagsToken = (): Provider => ({
  provide: FEATURE_FLAG_TOKEN,
  useClass: FeatureFlagTokenHandler,
});
