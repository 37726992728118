import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import { Feature, View } from "ol";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Geometry, Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import { Fill, Icon, Style, Text } from "ol/style";
import { inject, Injectable } from "@angular/core";
import { MapMarkerOptions } from "./map-marker.options";
import { BehaviorSubject, Observable, take, tap } from "rxjs";
import {
  CoordinatesDTO,
  GEOLOCATION_TOKEN,
  GeolocationProvider,
  MODAL_TOKEN,
  ModalProvider,
} from "@core";
import { GameDetailsModalComponent } from "projects/games/src/lib/components/game-details-modal/game-details-modal.component";
import { environment } from "src/environment/environment";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { GamesRoutes } from "projects/games/src/lib/games-routes.enum";

@Injectable({ providedIn: "root" })
export class MapInitializer {
  private readonly modalToken: ModalProvider = inject(MODAL_TOKEN);
  private readonly geolocationProvider: GeolocationProvider =
    inject(GEOLOCATION_TOKEN);
  private readonly router: Router = inject(Router);

  private mapboxToken = environment.mapboxToken;
  private mapboxUsername = "dmt1337";
  private mapboxStyleId = "clu0kvh7x003a01p6azybdjra";

  private defaultMarkerIcon: string = "assets/icons/map/map-marker.svg";
  private userMarkerIcon: string = "assets/icons/map-marker-user.svg";
  private defaultLocation: CoordinatesDTO = {
    longitude: 19.94496073912186,
    latitude: 50.06467232923231,
  };

  private map: any;
  private vectorSource: VectorSource<Feature<Geometry>> = new VectorSource();
  private vectorLayer: VectorLayer<VectorSource<Feature<Geometry>>> =
    new VectorLayer({
      source: this.vectorSource,
    });

  private isMapLoadedSubject: BehaviorSubject<{ isLoaded: boolean }> =
    new BehaviorSubject<{ isLoaded: boolean }>({ isLoaded: false });

  readonly isMapLoaded$: Observable<{ isLoaded: boolean }> =
    this.isMapLoadedSubject.asObservable();

  initMap(opts: {
    mapContainerName: string;
    zoom?: number;
    startCoordinates?: CoordinatesDTO;
  }): void {
    this.geolocationProvider
      .getCoordinates()
      .pipe(
        take(1),
        tap((userLocation: CoordinatesDTO | null) => {
          this.setupMap(
            opts.mapContainerName,
            opts.startCoordinates ?? userLocation ?? this.defaultLocation,
            opts.zoom
          );
        }),
        tap(() => {
          this.map.on("singleclick", (event: any) => this.onMarkerClick(event));
          this.isMapLoadedSubject.next({ isLoaded: true });
        })
      )
      .subscribe();
  }

  addMarker<T>(opt: MapMarkerOptions<T>): void {
    const iconFeature = new Feature({
      geometry: new Point(
        fromLonLat([opt.coordinates.longitude, opt.coordinates.latitude])
      ),
      games: opt.games,
      info: opt.info,
      locationId: opt.locationId,
      isUserLocation: opt.isUserLocation,
    });

    iconFeature.setStyle(
      new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: opt.markerIcon ?? this.defaultMarkerIcon,
          scale: opt.isUserLocation ? 1.2 : 1,
        }),
        text: opt.showCounter
          ? new Text({
              text: opt.counterValue,
              scale: 1.2,
              offsetX: 8.5,
              offsetY: -23.5,
              fill: new Fill({ color: "#FFF" }),
              padding: [1, 1, 1, 4],
            })
          : undefined,
      })
    );

    this.vectorSource.addFeature(iconFeature);
  }

  setUserPosition(): void {
    this.geolocationProvider
      .getCoordinates()
      .pipe(
        take(1),
        tap((coordinates: CoordinatesDTO | null) => {
          if (coordinates) {
            this.addMarker({
              coordinates: coordinates,
              showCounter: false,
              markerIcon: this.userMarkerIcon,
              isUserLocation: true,
            });
            this.map
              ?.getView()
              ?.setCenter(
                fromLonLat([coordinates.longitude, coordinates.latitude])
              );
          }
        })
      )
      .subscribe();
  }

  clearMarkers(): void {
    this.vectorSource.clear();
  }

  private setupMap(
    mapContainerName: string,
    userLocation: CoordinatesDTO,
    zoom?: number
  ) {
    this.map = new Map({
      target: mapContainerName,
      layers: [
        new TileLayer({
          source: new XYZ({
            url: `https://api.mapbox.com/styles/v1/${this.mapboxUsername}/${this.mapboxStyleId}/tiles/256/{z}/{x}/{y}?access_token=${this.mapboxToken}`,
          }),
        }),
        this.vectorLayer,
      ],
      view: new View({
        center: fromLonLat([userLocation.longitude, userLocation.latitude]),
        zoom: zoom ?? 12.5,
      }),
      controls: [],
    });
  }

  private onMarkerClick(event: any): void {
    const marker = this.map.forEachFeatureAtPixel(event.pixel, (f: any) => f, {
      hitTolerance: 10,
    });

    if (marker) {
      const games = marker.get("games");
      const locationId: string = marker.get("locationId");
      const isUserLocation: boolean = marker.get("isUserLocation");

      if (!isUserLocation) {
        if (games?.length > 1) {
          this.router.navigateByUrl(
            `${AppRoutes.GAMES}/${GamesRoutes.LOCATION}/${locationId}`
          );
        } else {
          this.modalToken.showModal$({
            component: GameDetailsModalComponent,
            componentProps: { gameId: games[0].gameId },
            cssClass: "modal-auto",
            initialBreakpoint: 1,
            breakpoints: [0, 1],
          });
        }
      }
    }
  }
}
