import { Provider } from "@angular/core";
import {
  SEND_MESSAGE_COMMAND,
  UPDATE_USER_COMMAND,
  USER_STATS_QUERY,
} from "../ports";
import { UpdateUserCommandHandler } from "./update-user-command-handler";
import { UserStatsQueryHandler } from "./user-stats.query-handler";
import { SendMessageCommandHandler } from "./send-message.command-handler";

export const provideUpdateUserCommand = (): Provider => ({
  provide: UPDATE_USER_COMMAND,
  useClass: UpdateUserCommandHandler,
});

export const provideUserStatsQuery = (): Provider => ({
  provide: USER_STATS_QUERY,
  useClass: UserStatsQueryHandler,
});
export const provideSendMessageCommand = (): Provider => ({
  provide: SEND_MESSAGE_COMMAND,
  useClass: SendMessageCommandHandler,
});
