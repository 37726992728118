import { UpdateChannelCommandPort } from "../ports";
import { Observable } from "rxjs";
import { inject } from "@angular/core";
import {
  CHAT_CHANEL_DTO,
  ChatDtoPort,
} from "../../infrastructure/http-service";
import { UpdateChannelDTO } from "../interfaces";

export class UpdateChannelCommandHandler implements UpdateChannelCommandPort {
  private readonly chatDtoPort: ChatDtoPort = inject(CHAT_CHANEL_DTO);

  update(data: UpdateChannelDTO): Observable<void> {
    return this.chatDtoPort.updateChannel(data);
  }
}
