import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { CustomNotificationDTO } from "../../application/interfaces";

export const CUSTOM_NOTIFICATION_DTO: InjectionToken<CustomNotificationDtoPort> =
  new InjectionToken<CustomNotificationDtoPort>("CUSTOM_NOTIFICATION_DTO");

export interface CustomNotificationDtoPort {
  getData(id: string): Observable<CustomNotificationDTO>;
}
