<div class="or-d-flex overflow-x-auto or-pl-20-px or-pr-12-px">
  <div
    (click)="setFilter(filter)"
    *ngFor="let filter of filterValues$ | async; let i = index"
    class="or-head-md or-mr-8-px or-py-8-px or-px-12-px or-d-flex or-justify-content-center or-cursor-pointer text-no-wrap"
    [ngClass]="{ checked: filter.isChecked, unchecked: !filter.isChecked }"
  >
    {{ filter.text }}
  </div>
</div>
