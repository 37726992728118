import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from "@angular/core";
import { AsyncPipe, NgForOf, NgIf } from "@angular/common";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  PATCH_ADMIN_USERS_FILTERS_COMMAND,
  PatchAdminUsersFiltersCommandPort,
} from "../../../application/ports";
import { BehaviorSubject, map, Observable, switchMap, take, tap } from "rxjs";
import { IonicModule } from "@ionic/angular";
import { PlatformType } from "@core";

type PlatformFiltersModel = { type: PlatformType; checked: boolean };

@Component({
  selector: "lib-users-filters",
  templateUrl: "./users-filters.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, ReactiveFormsModule, IonicModule, NgForOf],
})
export class UsersFiltersComponent implements OnInit {
  public showFilters: boolean = false;

  startValues: PlatformFiltersModel[] = [
    { type: PlatformType.ANDROID, checked: false },
    { type: PlatformType.IOS, checked: false },
    { type: PlatformType.MOBILE_WEB, checked: false },
    { type: PlatformType.WEB, checked: false },
  ];

  private readonly platformTypeSubject: BehaviorSubject<
    PlatformFiltersModel[]
  > = new BehaviorSubject<PlatformFiltersModel[]>(this.startValues);

  private readonly patchAdminUsersFiltersCommandPort: PatchAdminUsersFiltersCommandPort =
    inject(PATCH_ADMIN_USERS_FILTERS_COMMAND);

  readonly searchForm: FormGroup = new FormGroup({
    search: new FormControl(),
  });

  platformType$: Observable<PlatformFiltersModel[]> =
    this.platformTypeSubject.asObservable();

  ngOnInit(): void {
    this.searchForm.valueChanges
      .pipe(
        switchMap((search) =>
          this.patchAdminUsersFiltersCommandPort.patch({
            search: search?.search,
          })
        )
      )
      .subscribe();
  }

  changeVisibility(): void {
    this.showFilters = !this.showFilters;
  }

  select(type: PlatformType): void {
    this.platformType$
      .pipe(
        take(1),
        map((data: PlatformFiltersModel[]) => {
          return data.map((item: PlatformFiltersModel) => {
            if (item.type === type) return { ...item, checked: !item.checked };

            return item;
          });
        }),
        tap((updatedData: PlatformFiltersModel[]) => {
          this.platformTypeSubject.next(updatedData);
        }),
        switchMap((updatedData: PlatformFiltersModel[]) => {
          const payload: PlatformType[] = updatedData
            .filter((data: PlatformFiltersModel) => data.checked)
            .map((item: PlatformFiltersModel) => item.type);
          return this.patchAdminUsersFiltersCommandPort.patch({
            platform: payload,
          });
        })
      )
      .subscribe();
  }
}
