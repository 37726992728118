import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class LocalRefreshService {
  private readonly localRefreshSubject: BehaviorSubject<void> =
    new BehaviorSubject<void>(void 0);

  readonly refresh$: Observable<void> = this.localRefreshSubject.asObservable();

  emit(): void {
    this.localRefreshSubject.next(void 0);
  }
}

export const provideLocalRefreshService = () => ({
  provide: LocalRefreshService,
  useClass: LocalRefreshService,
});
