import { FiltersValues } from "../models";

export const parseFiltersData = (obj: FiltersValues): string => {
  const parts: string[] = [];

  Object.keys(obj).forEach((key: string) => {
    const value = obj[key as keyof FiltersValues];

    if (
      value === undefined ||
      value === null ||
      (Array.isArray(value) && value.length === 0)
    ) {
      return;
    }

    if (
      key === "time" &&
      typeof value === "object" &&
      "min" in value &&
      "max" in value
    ) {
      parts.push(`hoursMin=${encodeURIComponent(value.min.toString())}`);
      parts.push(`hoursMax=${encodeURIComponent(value.max.toString())}`);
    } else if (Array.isArray(value)) {
      parts.push(
        `${key}=${value
          .map((item) => encodeURIComponent(item.toString()))
          .join(",")}`
      );
    } else if (typeof value === "string") {
      parts.push(`${key}=${encodeURIComponent(value.toString())}`);
    }
  });

  return parts.join("&");
};
