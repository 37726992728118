import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Output,
} from "@angular/core";
import { GameCardComponent } from "@ui-components";
import { Observable, of, switchMap, tap } from "rxjs";
import { MODAL_TOKEN, ModalProvider } from "@core";
import { AsyncPipe, NgForOf, NgIf } from "@angular/common";
import { mockTeamDetails } from "../../../../../teams/src/lib/application/data-mock";
import { TeamCardComponent, TeamDetailsDTO } from "@teams";

@Component({
  selector: "lib-dashboard-teams",
  templateUrl: "./dashboard-teams.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, NgForOf, GameCardComponent, TeamCardComponent],
  providers: [],
})
export class DashboardTeamsComponent {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  @Output() isTeamsAvailable: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public isTeamsExpanded: boolean = true;

  readonly teamDetailsQuery$: Observable<TeamDetailsDTO[]> = of([
    mockTeamDetails,
  ]);

  readonly teamDetails$: Observable<TeamDetailsDTO[]> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.teamDetailsQuery$),
      tap((result: TeamDetailsDTO[]) =>
        this.isTeamsAvailable.emit(!!result?.length)
      ),
      tap(() => this.modalProvider.dismissLoading$())
    );

  stopEventPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  changeTeamsVisibility() {
    this.isTeamsExpanded = !this.isTeamsExpanded;
  }
}
