<ng-container *ngIf="!(hideScreen$ | async); else notNativeView">
  <lib-title-navbar title="Udostępniona gierka"></lib-title-navbar>

  <div
    [ngClass]="
      isiOS
        ? 'full-height-scrollable-container-ios'
        : 'full-height-scrollable-container'
    "
  >
    <ng-container *ngIf="gameDetails$ | async as game">
      <div class="or-p-16-px or-mb-8-px">
        <lib-game-details
          [game]="game"
          [showHostInfo]="true"
          [showNavigateLink]="true"
          (onSendMessage)="sendMessage($event)"
        ></lib-game-details>

        <lib-join-buttons
          [game]="game"
          (applicationSent)="applicationSent($event)"
        ></lib-join-buttons>
      </div>
    </ng-container>

    <div #map class="map-container-shared"></div>
  </div>
</ng-container>

<ng-template #notNativeView
  ><lib-mobile-landing
    [path]="redirectPath"
    [showButtons]="true"
    [showContinueWithWeb]="true"
  ></lib-mobile-landing>
</ng-template>
