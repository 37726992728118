import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { ChannelDetailsDTO } from "../interfaces";

export const CHANNEL_DETAILS_QUERY: InjectionToken<ChannelDetailsQueryPort> =
  new InjectionToken<ChannelDetailsQueryPort>("CHANNEL_DETAILS_QUERY");

export interface ChannelDetailsQueryPort {
  details(channelId: string): Observable<ChannelDetailsDTO>;
}
