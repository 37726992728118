import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { UnreadMessagesDTO } from "../../application/interfaces";

export const UNREAD_MESSAGES_DTO: InjectionToken<UnreadMessagesDtoPort> =
  new InjectionToken<UnreadMessagesDtoPort>("UNREAD_MESSAGES_DTO");

export interface UnreadMessagesDtoPort {
  unreadCount(userId: string): Observable<UnreadMessagesDTO[]>;
}
