import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { AlertsSettingsDTO } from "../../interfaces";

export const UPDATE_ALERTS_SETTINGS_COMMAND: InjectionToken<UpdateAlertsSettingsCommandPort> =
  new InjectionToken<UpdateAlertsSettingsCommandPort>(
    "UPDATE_ALERTS_SETTINGS_COMMAND"
  );

export interface UpdateAlertsSettingsCommandPort {
  updateAlertsSettings(settings: AlertsSettingsDTO): Observable<void>;
  switchAlerts(value: boolean): Observable<void>;
}
