import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { GameDetailsModel } from "@core";

export const GAME_DETAILS_QUERY: InjectionToken<GameDetailsQueryPort> =
  new InjectionToken<GameDetailsQueryPort>("GAME_DETAILS_QUERY");

export interface GameDetailsQueryPort {
  getGameDetails(gameId: string): Observable<GameDetailsModel>;
}
