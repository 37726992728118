import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import { AdvancedInvitationsDTO } from "../../../application/interfaces";
import { AdvancedInvitationsDtoPort } from "./advanced-invitations.dto-port";

export class AdvancedInvitationsService implements AdvancedInvitationsDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getUsers(gameId: string): Observable<AdvancedInvitationsDTO> {
    return this.httpClient.get<AdvancedInvitationsDTO>(
      `${this.env.apiUrl}/recurring-games/games/${gameId}/users`
    );
  }

  switchAutoInvite(
    gameId: string,
    userId: string,
    value: boolean
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${this.env.apiUrl}/recurring-games/games/${gameId}/users/${userId}/auto-invite`,
      { autoInvite: value }
    );
  }

  switchAutoApproval(
    gameId: string,
    userId: string,
    value: boolean
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${this.env.apiUrl}/recurring-games/games/${gameId}/users/${userId}/auto-approval`,
      { autoApproval: value }
    );
  }
}
