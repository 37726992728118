<img src="assets/launch-2.svg" class="full-screen-image" />

<div
  class="or-d-flex or-flex-col or-text-align-center or-align-items-center or-px-16-px"
>
  <img src="assets/icons/orlikfy-header.svg" class="or-size-200-px" />

  <div
    class="z-index-999 or-text-xl or-color-white or-mb-12-px margin-top--35-px"
  >
    Czas na orlikową rewolucję!
  </div>
  <div class="z-index-999 or-text-lg or-color-white">
    Orlikfy jest pierwszą na świecie aplikacją orlikową, która w kompleksowy
    sposób służy do organizowania, dołączania i obsługiwania gier orlikowych.
  </div>

  <div class="or-d-flex or-justify-content-evenly or-w-100-pc or-my-32-px">
    <img (click)="downloadAndroid()" src="assets/download-google-play.svg" />
    <img (click)="downloadiOS()" src="assets/download-app-store.svg" />
  </div>

  <button
    (click)="redirectToNativeApp()"
    class="button-primary-style or-mb-16-px or-p-12-px or-head-md or-cursor-pointer z-index-999 or-w-200-px"
  >
    <div class="or-d-flex or-flex-col">
      <span>Przejdź do aplikacji</span>
      <span class="or-color-white or-mt-4-px or-text-sm"
        >Dla osób z pobraną aplikacją</span
      >
    </div>
  </button>

  <img src="assets/not-mobile.svg" class="or-mt-32-px or-mb-32-px" />
</div>
