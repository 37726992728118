import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import { SaveLineupsDTO } from "../../application/interfaces";
import { Observable, of } from "rxjs";

export class LineupsService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  save(payload: SaveLineupsDTO): Observable<void> {
    console.log(payload);
    return of(void 0);
  }
}
