import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { AsyncPipe, NgIf } from "@angular/common";
import {
  CopyService,
  CopyValueToClipboardDirective,
  GameDetailsModel,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { ShareGameViewService } from "./share-game.view-service";

@Component({
  selector: "lib-share-game",
  templateUrl: "./share-game.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, CopyValueToClipboardDirective, AsyncPipe],
  providers: [ShareGameViewService, CopyService],
})
export class ShareGameComponent {
  @Input({ required: true }) game!: GameDetailsModel;

  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  readonly isNativeRun: boolean = this.platformProvider.isNativeRun;

  private readonly shareGameViewService: ShareGameViewService =
    inject(ShareGameViewService);

  gameLink(): void {
    this.shareGameViewService.copyGameLink(this.game?.gameId);
  }

  copySocialContent(): void {
    this.shareGameViewService.copySocialContent(this.game);
  }

  openAdvancedSharing(): void {
    this.shareGameViewService.advancedShareNative(this.game);
  }
}
