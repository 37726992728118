import { Provider } from "@angular/core";
import {
  ADMIN_USER_SUMMARY_DATA_QUERY,
  GET_ADMIN_USERS_LIST_QUERY,
  PATCH_ADMIN_USERS_FILTERS_COMMAND,
} from "../ports";
import { GetAdminUsersListQueryHandler } from "./get-admin-users-list.query-handler";
import { AdminUsersSummaryDataQueryHandler } from "./admin-users-summary-data.query-handler";
import { PatchAdminUsersFiltersCommandHandler } from "./patch-admin-users-filters.command-handler";

export const provideAdminUsersListQuery = (): Provider => ({
  provide: GET_ADMIN_USERS_LIST_QUERY,
  useClass: GetAdminUsersListQueryHandler,
});

export const provideAdminUsersSummaryDataQuery = (): Provider => ({
  provide: ADMIN_USER_SUMMARY_DATA_QUERY,
  useClass: AdminUsersSummaryDataQueryHandler,
});

export const providePatchAdminUsersFiltersCommand = (): Provider => ({
  provide: PATCH_ADMIN_USERS_FILTERS_COMMAND,
  useClass: PatchAdminUsersFiltersCommandHandler,
});
