import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { NotificationViewModel } from "../../interfaces";

export const NOTIFICATIONS_LIST_QUERY: InjectionToken<NotificationsListQueryPort> =
  new InjectionToken<NotificationsListQueryPort>("NOTIFICATIONS_LIST_QUERY");

export interface NotificationsListQueryPort {
  getNotificationsList(): Observable<NotificationViewModel[]>;
}
