import { Routes } from "@angular/router";
import { HostedGamesRoutes } from "./hosted-games.routes.enum";
import {
  BlockWebVersionComponent,
  CreateGameComponent,
  HostedFutureGamesComponent,
  HostedGameDetailsComponent,
  HostedPastGamesComponent,
  InvitePlayersComponent,
} from "./components";
import { blockWebVersionGuard, createGameGuard } from "./application/guards";

export const HostedGamesRouting: Routes = [
  {
    path: HostedGamesRoutes.ROOT,
    redirectTo: HostedGamesRoutes.FUTURE,
    pathMatch: "full",
  },
  {
    path: HostedGamesRoutes.FUTURE,
    component: HostedFutureGamesComponent,
    canActivate: [blockWebVersionGuard],
  },
  { path: HostedGamesRoutes.PAST, component: HostedPastGamesComponent },
  {
    path: `${HostedGamesRoutes.FUTURE}/:id`,
    component: HostedGameDetailsComponent,
    canActivate: [blockWebVersionGuard],
  },
  {
    path: `${HostedGamesRoutes.FUTURE}/:id/${HostedGamesRoutes.INVITE}`,
    component: InvitePlayersComponent,
    canActivate: [blockWebVersionGuard],
  },

  {
    path: HostedGamesRoutes.CREATE_GAME,
    component: CreateGameComponent,
    canActivate: [createGameGuard, blockWebVersionGuard],
  },
  {
    path: HostedGamesRoutes.BLOCK_WEB,
    component: BlockWebVersionComponent,
  },
];
