import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import {
  ActionModalComponent,
  CoordinatesDTO,
  GameDetailsModel,
  GameLevel,
  gameLevelMapper,
  MODAL_TOKEN,
  ModalProvider,
  NAVIGATION_TOKEN,
  NavigationProvider,
  PaymentType,
  paymentTypesMapper,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent,
  reversedGameLevelMapper,
  reversedPaymentTypesMapper,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import {
  combineLatest,
  combineLatestWith,
  EMPTY,
  filter,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from "rxjs";
import { provideHostedGamesService } from "../../infrastructure/http-service";
import {
  provideCreateGameCommand,
  provideUpdateGameCommand,
} from "../../application/handlers";
import {
  CREATE_GAME_COMMAND,
  CreateGameCommandPort,
  UPDATE_GAME_COMMAND,
  UpdateGameCommandPort,
} from "../../application/ports";
import { TitileNavbarComponent } from "@ui-components";
import { CreateGameViewService } from "../../application/view-services";
import { CreateGameSuccessModalComponent } from "./success-modal/create-game-success-modal.component";
import { ActivatedRoute, Params } from "@angular/router";
import { VisibilityEnum } from "../../application/enums";
import { Loader } from "@googlemaps/js-api-loader";
import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteModule,
} from "@angular-magic/ngx-gp-autocomplete";
import { environment } from "../../../../../../src/environment/environment";
import { fromZonedTime } from "date-fns-tz";
import { formatISO } from "date-fns";
import { CreateGameResponseViewModel } from "../../application/interfaces";
import {
  AVAILABLE_CHANNELS_QUERY,
  AvailableChannelsQueryPort,
  ChatChanelDTO,
  provideAvailableChannelsQuery,
  provideChatService,
  showInstructionModal,
} from "@messages";
import { GameTypeModalComponent } from "./game-type-modal/game-type-modal.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FEATURE_FLAG_TOKEN,
  FeatureFlag,
  FeatureFlagProvider,
} from "@feature-flags";

@Component({
  selector: "lib-create-game",
  templateUrl: "./create-game.component.html",
  styleUrls: ["./create-game.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    TitileNavbarComponent,
    NgxGpAutocompleteModule,
  ],
  providers: [
    provideCreateGameCommand(),
    provideHostedGamesService(),
    provideUpdateGameCommand(),
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.mapApiKey,
        libraries: ["places"],
      }),
    },
    provideChatService(),
    provideAvailableChannelsQuery(),
  ],
})
export class CreateGameComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("placesRef") placesRef!: NgxGpAutocompleteDirective;

  public preventToDoubleClick: boolean = false;

  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly createGameCommandPort: CreateGameCommandPort =
    inject(CREATE_GAME_COMMAND);
  private readonly updateGameCommandPort: UpdateGameCommandPort =
    inject(UPDATE_GAME_COMMAND);
  private readonly createGameViewService: CreateGameViewService = inject(
    CreateGameViewService
  );
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly navigationProvider: NavigationProvider =
    inject(NAVIGATION_TOKEN);
  private readonly availableChannelsQueryPort: AvailableChannelsQueryPort =
    inject(AVAILABLE_CHANNELS_QUERY);
  private readonly featureFlagProvider: FeatureFlagProvider =
    inject(FEATURE_FLAG_TOKEN);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  readonly previousGame$: Observable<GameDetailsModel | null> =
    this.createGameViewService.previousGame$;

  readonly isRecurring$: Observable<boolean> =
    this.createGameViewService.isRecurring$;

  readonly previousGame: GameDetailsModel | null =
    this.createGameViewService.previousGame;

  readonly previousVisibility$: Observable<boolean> = this.previousGame$.pipe(
    filter((v) => !!v),
    map(
      (data: GameDetailsModel | null) =>
        data?.visibility === VisibilityEnum.PRIVATE
    )
  );

  readonly isEditMode$: Observable<boolean> =
    this.activatedRoute.queryParams.pipe(
      map((params: Params) => params["mode"] === "edit")
    );

  readonly navbarTitle$: Observable<string> = combineLatest([
    this.previousGame$,
    this.isEditMode$,
  ]).pipe(
    map(([isPrevious, isEdit]: [GameDetailsModel | null, boolean]) =>
      !isPrevious || (isPrevious && !isEdit)
        ? "Dodaj nową gierkę"
        : "Edytuj gierkę"
    )
  );

  readonly okButtonTitile$: Observable<string> = combineLatest([
    this.previousGame$,
    this.isEditMode$,
  ]).pipe(
    map(([data, isEdit]: [GameDetailsModel | null, boolean]) =>
      data && isEdit ? "Edytuj gierkę" : "Dodaj nową gierkę!"
    )
  );

  readonly previousRecurring$: Observable<boolean> = this.previousGame$.pipe(
    combineLatestWith(this.isRecurring$),
    map(
      ([data, isRecurring]: [GameDetailsModel | null, boolean]) =>
        (data?.isRecurring || isRecurring) ?? false
    )
  );

  startDate$: Observable<string | null> = this.isEditMode$.pipe(
    take(1),
    map((isEditMode: boolean) => {
      if (isEditMode) {
        const startValue = this.createGameForm.get("startDate")?.value;
        const utcDate: Date = new Date(startValue);
        const timezone: string =
          Intl.DateTimeFormat().resolvedOptions().timeZone;

        return formatISO(fromZonedTime(utcDate, timezone));
      }

      return null;
    })
  );

  readonly availableChannels$: Observable<ChatChanelDTO[]> =
    this.availableChannelsQueryPort.availableChannels().pipe(
      map((channels: ChatChanelDTO[]) => {
        return [
          {
            channelId: "create-new",
            channelExternalId: null,
            channelName: "Dodaj nowy chat...",
            channelThumbnail: null,
          },
          ...channels,
        ];
      }),
      shareReplay(1)
    );

  readonly disableCreateChannel$: Observable<boolean> = this.featureFlagProvider
    .isEnabled$(FeatureFlag.DISABLE_CREATE_CHANNEL)
    .pipe(shareReplay(1));

  readonly isiOS: boolean = this.platformProvider.isiOS;

  public gameLevels: GameLevel[] = Object.values(GameLevel);
  public gameLevelMapper: Record<GameLevel, string> = gameLevelMapper;
  public paymentTypes: PaymentType[] = Object.values(PaymentType);
  public paymentTypesMapper: Record<PaymentType, string> = paymentTypesMapper;
  public isPaymentTypeVisible: boolean = false;

  public autoCompleteOptions = {
    componentRestrictions: { country: "PL" },
  };

  readonly createGameForm: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.maxLength(25)]),
    startDate: new FormControl("", [Validators.required]),
    duration: new FormControl(90, [Validators.required, Validators.min(30)]),
    address: new FormControl("", [Validators.required]),
    city: new FormControl(),
    coordinates: new FormControl("", [Validators.required]),
    totalSlots: new FormControl("", [
      Validators.required,
      Validators.pattern(/^[0-9]+$/),
      Validators.min(1),
      Validators.max(999),
    ]),
    level: new FormControl(GameLevel.BEGINNER, [Validators.required]),
    priceAmount: new FormControl("", [
      Validators.required,
      Validators.pattern(/^[0-9]+$/),
      Validators.min(0),
      Validators.max(999),
    ]),
    paymentTypes: new FormControl(
      [PaymentType.BLIK, PaymentType.TRANSFER, PaymentType.CASH],
      [Validators.required]
    ),
    createGamePlayerForHost: new FormControl(true, [Validators.required]),
    description: new FormControl("", [Validators.maxLength(500)]),
    visibility: new FormControl(false),
    phone: new FormControl("", [
      Validators.minLength(9),
      Validators.maxLength(9),
      Validators.pattern("^[0-9]*$"),
    ]),
    isRecurring: new FormControl(false),
    channelId: new FormControl("None", [Validators.pattern(/^(?!None$).*/)]),
  });

  ngOnInit(): void {
    this.prepopulateForm();
    this.prepopulatePhone();
    this.manageChannelValidator();
  }

  ngAfterViewInit(): void {
    this.showGameTypeModal();

    this.createGameForm
      .get("priceAmount")
      ?.valueChanges.pipe(tap(() => this.priceChanged()))
      .subscribe();
  }

  ngOnDestroy() {
    this.createGameViewService.resetPreviousGame();
  }

  get nameValueInvalid(): boolean {
    return this.createGameForm.get("name")?.value?.length > 25;
  }

  create() {
    if (this.preventToDoubleClick) return;

    this.markAsTouched();

    combineLatest([this.isEditMode$, this.previousGame$])
      .pipe(
        take(1),
        switchMap(([isEditMode, previousGame]) => {
          if (isEditMode) {
            return this.createGameForm.valid
              ? this.updateGameCommandPort
                  .updateGame(previousGame?.gameId as string, {
                    ...this.setFormValues(),
                    gameContactPhone: this.setFormValues().gameContactPhone
                      ? `+48${this.setFormValues().gameContactPhone}`
                      : null,
                    priceAmount: this.setFormValues().priceAmount.toString(),
                    channelId: this.setFormValues().channelId,
                  })
                  .pipe(
                    take(1),
                    tap((game: CreateGameResponseViewModel) =>
                      this.openSuccessModal(game, true)
                    ),
                    tap(() => (this.preventToDoubleClick = true))
                  )
              : EMPTY;
          } else {
            return this.createGameForm.valid
              ? this.createGameCommandPort
                  .createGame({
                    ...this.setFormValues(),
                    channelId: this.setFormValues().channelId,
                  })
                  .pipe(
                    take(1),
                    tap((game: CreateGameResponseViewModel) =>
                      this.openSuccessModal(game, false)
                    ),
                    tap(() => (this.preventToDoubleClick = true))
                  )
              : EMPTY;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  setGameType(): void {
    this.isRecurring$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((isRecurring: boolean) => {
          return this.createGameForm.patchValue({
            isRecurring: isRecurring,
          });
        })
      )
      .subscribe();
  }

  getCoordinates(): CoordinatesDTO {
    return {
      latitude:
        this.previousGame?.location?.coordinates?.latitude ??
        this.createGameForm.get("coordinates")?.value.latitude,
      longitude:
        this.previousGame?.location?.coordinates?.longitude ??
        this.createGameForm.get("coordinates")?.value.longitude,
    };
  }

  handleAddressChange(address: google.maps.places.PlaceResult): void {
    const city = (): string | undefined => {
      if (address.address_components) {
        const localityComponent = address.address_components.find((component) =>
          component.types.includes("locality")
        );
        if (localityComponent) {
          return localityComponent.long_name;
        }
      }
      return undefined;
    };

    this.createGameForm.patchValue({
      address: address.name,
      coordinates: {
        latitude: address?.geometry?.location?.lat(),
        longitude: address?.geometry?.location?.lng(),
      },
      city: city(),
    });
  }

  changeStartDate(event: any) {
    const selectedDate: Date = new Date(event.detail.value);

    const timeDiff: number = Math.abs(
      selectedDate.getTime() - new Date().getTime()
    );

    if (timeDiff < 3600000 || selectedDate.getTime() < new Date().getTime()) {
      this.modalProvider.showModal$({
        component: PresentModalComponent,
        componentProps: {
          header: "Ustaw poprawną datę",
          message:
            "Nie możesz dodać gierki, ktora rozpoczyna sie wcześniej niż za godzinę.",
          btnTxt: "Zamknij",
        },
        cssClass: "present-modal",
      });
    } else {
      this.createGameForm.patchValue({
        startDate: selectedDate.toISOString(),
      });
    }
  }

  createGamePlayerForHost(event: any) {
    this.createGameForm.patchValue({
      createGamePlayerForHost: event.detail.checked,
    });
  }

  markAsPrivateGame(event: any) {
    this.createGameForm.patchValue({
      visibility: event.detail.checked
        ? VisibilityEnum.PRIVATE
        : VisibilityEnum.PUBLIC,
    });
  }

  markAsIsRecurring(event: any) {
    this.createGameForm.patchValue({
      isRecurring: event.detail.checked,
    });
  }

  get getFormattedDate(): string {
    const isoDate = this.createGameForm.get("startDate")?.value;

    if (!this.createGameForm.get("startDate")?.value) return "";

    return `${new Date(isoDate).toLocaleDateString("pl-PL", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })} | ${new Date(isoDate).toLocaleTimeString("pl-PL", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }

  markAsTouched(): void {
    Object.keys(this.createGameForm.controls).forEach((field: string) => {
      const control = this.createGameForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.createGameForm.get(fieldName);
    return control ? control.touched && control.invalid : false;
  }

  isChatFieldInvalid(): boolean {
    const control = this.createGameForm.get("channelId");
    return control ? control.touched && control.value === "None" : false;
  }

  showInstructionModal(): void {
    showInstructionModal(this.modalProvider);
  }

  selectedChannelName(
    channelId: string | null
  ): Observable<string | null | undefined> {
    return this.availableChannels$.pipe(
      take(1),
      map((channels: ChatChanelDTO[]) => {
        if (channelId === null) return "";

        return channels.find(
          (channel: ChatChanelDTO) => channel.channelId === channelId
        )?.channelName;
      })
    );
  }

  private addMinutesToDate(dateString: string, minutes: number): string {
    return new Date(
      new Date(dateString).getTime() + minutes * 60000
    ).toISOString();
  }

  private prepopulateForm(): void {
    combineLatest([this.previousGame$, this.isEditMode$])
      .pipe(
        take(1),
        map(([previousGame, isEdit]: [GameDetailsModel | null, boolean]) => {
          if (previousGame) {
            const datePart = previousGame?.formattedDate
              ?.split(",")[1]
              .split("|")[0]
              .trim() as string;
            const timePart = previousGame?.formattedDate
              ?.split("|")[1]
              .split("-")[0]
              .trim() as string;
            const [day, month, year] = datePart?.split(".").map(Number);
            const [hours, minutes] = timePart?.split(":")?.map(Number);

            const previousStartDate = new Date(
              year,
              month - 1,
              day,
              hours,
              minutes
            );

            const adjustedStartDate = !isEdit
              ? new Date(previousStartDate.getTime() + 7 * 24 * 60 * 60 * 1000)
              : previousStartDate;

            this.createGameForm.patchValue({
              name: previousGame?.gameName,
              totalSlots: previousGame?.totalSlots,
              level: reversedGameLevelMapper[previousGame?.level],
              priceAmount: previousGame?.priceAmount?.toString(),
              paymentTypes: previousGame?.paymentType?.map(
                (type: string) => reversedPaymentTypesMapper[type]
              ),
              description: previousGame?.description,
              duration: previousGame?.duration,
              coordinates: previousGame?.location?.coordinates,
              address: previousGame?.location?.address,
              city: previousGame?.location?.name?.split(",")?.[1]?.trim(),
              isRecurring: previousGame?.isRecurring,
              startDate: adjustedStartDate?.toISOString(),
              visibility: previousGame?.visibility,
              channelId: previousGame?.channel?.channelId,
            });
          }
        }),
        tap(() => this.priceChanged())
      )
      .subscribe();
  }

  private prepopulatePhone() {
    this.userDataProvider.userData$
      .pipe(
        take(1),
        tap((userData: UserModel) =>
          this.createGameForm.patchValue({ phone: userData.phone })
        )
      )
      .subscribe();
  }

  private openSuccessModal(
    game: CreateGameResponseViewModel,
    isEdit: boolean
  ): void {
    this.modalProvider.showModal$({
      component: CreateGameSuccessModalComponent,
      componentProps: {
        game: game as unknown as GameDetailsModel,
        isEdit: isEdit,
      },
      cssClass: "present-modal",
      backdropDismiss: false,
    });
  }

  private setFormValues() {
    return {
      name: this.createGameForm.get("name")?.value,
      gameDateStart: this.createGameForm.get("startDate")?.value,
      gameDateEnd: this.addMinutesToDate(
        this.createGameForm.get("startDate")?.value,
        this.createGameForm.get("duration")?.value
      ),
      address: this.createGameForm.get("address")?.value,
      city: this.createGameForm.get("city")?.value,
      coordinates: this.getCoordinates(),
      totalSlots: +this.createGameForm.get("totalSlots")?.value,
      level: this.createGameForm.get("level")?.value,
      priceAmount: this.createGameForm.get("priceAmount")?.value,
      paymentTypes: this.createGameForm.get("paymentTypes")?.value,
      description: this.createGameForm.get("description")?.value,
      createGamePlayerForHost: this.createGameForm.get(
        "createGamePlayerForHost"
      )?.value,
      priceCurrency: "PLN",
      visibility: this.createGameForm.get("visibility")?.value || false,
      gameContactPhone: this.createGameForm.get("phone")?.value,
      isRecurring: this.createGameForm.get("isRecurring")?.value,
      channelId: this.createGameForm.get("channelId")?.value,
    };
  }

  private priceChanged(): void {
    this.createGameForm.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        take(1),
        tap((form) => {
          this.isPaymentTypeVisible = +form.priceAmount > 0;

          if (+form.priceAmount < 1 || form.priceAmount === "")
            return this.createGameForm?.get("paymentTypes")?.disable();

          return this.createGameForm?.get("paymentTypes")?.enable();
        })
      )
      .subscribe();
  }

  private showGameTypeModal(): void {
    combineLatest([this.isEditMode$, this.previousGame$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        take(1),
        filter(
          ([isEdit, previousGame]: [boolean, GameDetailsModel | null]) =>
            !isEdit && !previousGame
        ),
        switchMap(() =>
          this.modalProvider.showModal$({
            component: ActionModalComponent,
            cssClass: "present-modal",
            backdropDismiss: false,
            componentProps: {
              header: "Jaką gierkę chciałbyś stworzyć?",
              message:
                "Typ gierki będzisz mógł zmienić w panelu dodawania oraz eydcji gierki.",
              btnOk: "Przejdź dalej",
              btnCancel: "Anuluj",
              template: GameTypeModalComponent,
              action: () => this.setGameType(),
              cancelAction: () => this.navigationProvider.back(),
            },
          })
        )
      )
      .subscribe();
  }

  private manageChannelValidator(): void {
    this.disableCreateChannel$
      .pipe(
        tap((isEnabled: boolean) => {
          if (isEnabled) {
            const channelIdControl = this.createGameForm.get("channelId");

            channelIdControl?.clearValidators();
            channelIdControl?.updateValueAndValidity();
          }
        })
      )
      .subscribe();
  }
}
