import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { FutureGameDTO } from "../../application/interfaces";

export const FUTURE_GAMES_CONTEXT: InjectionToken<FutureGamesContextPort> =
  new InjectionToken<FutureGamesContextPort>("FUTURE_GAMES_CONTEXT");

export interface FutureGamesContextPort {
  games$: Observable<FutureGameDTO>;
  setContext(data: FutureGameDTO): Observable<void>;
}
