import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { ChannelDetailsQueryPort } from "../ports";
import {
  CHAT_CHANEL_DTO,
  ChatDtoPort,
} from "../../infrastructure/http-service";
import { ChannelDetailsDTO } from "../interfaces";

export class ChannelDetailsQueryHandler implements ChannelDetailsQueryPort {
  private readonly chatDtoPort: ChatDtoPort = inject(CHAT_CHANEL_DTO);

  details(channelId: string): Observable<ChannelDetailsDTO> {
    return this.chatDtoPort.channelDetails(channelId);
  }
}
