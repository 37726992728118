import { Provider } from "@angular/core";
import {
  GAME_DETAILS_QUERY,
  GET_FUTURE_GAMES_QUERY,
  GET_PAST_GAMES_QUERY,
  LOCATION_DETAILS_QUERY,
  RATE_APP_COMMAND,
  REMOVE_APPLICATION_COMMAND,
  SEND_APPLICATION_COMMAND,
} from "../ports";
import { GetFutureGamesQueryHandler } from "./get-future-games.query-handler";
import { GameDetailsQueryHandler } from "./game-details.query-handler";
import { SendApplicationCommandHandler } from "./send-application.command-handler";
import { GetPastGamesQueryHandler } from "./get-past-games.query-handler";
import { RemoveApplicationCommandHandler } from "./remove-application.command-handler";
import { GetLocationDetailsQueryHandler } from "./get-location-details.query-handler";
import { RateAppCommandHandler } from "./rate-app.command-handler";

export const provideFutureGamesQuery = (): Provider => ({
  provide: GET_FUTURE_GAMES_QUERY,
  useClass: GetFutureGamesQueryHandler,
});

export const providePastGamesQuery = (): Provider => ({
  provide: GET_PAST_GAMES_QUERY,
  useClass: GetPastGamesQueryHandler,
});

export const provideGameDetailsQuery = (): Provider => ({
  provide: GAME_DETAILS_QUERY,
  useClass: GameDetailsQueryHandler,
});

export const provideSendApplicationCommand = (): Provider => ({
  provide: SEND_APPLICATION_COMMAND,
  useClass: SendApplicationCommandHandler,
});

export const provideRemoveApplicationCommand = (): Provider => ({
  provide: REMOVE_APPLICATION_COMMAND,
  useClass: RemoveApplicationCommandHandler,
});

export const provideLocationDetailsQuery = (): Provider => ({
  provide: LOCATION_DETAILS_QUERY,
  useClass: GetLocationDetailsQueryHandler,
});

export const provideRateAppCommand = (): Provider => ({
  provide: RATE_APP_COMMAND,
  useClass: RateAppCommandHandler,
});
