import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const UPDATE_ALERTS_LOCATION_COMMAND: InjectionToken<UpdateAlertsLocationCommandPort> =
  new InjectionToken<UpdateAlertsLocationCommandPort>(
    "UPDATE_ALERTS_LOCATION_COMMAND"
  );

export interface UpdateAlertsLocationCommandPort {
  setLocation(): Observable<void>;
}
