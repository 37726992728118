import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Output,
} from "@angular/core";
import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import {
  GameCardComponent,
  GameDetailsComponent,
  RedirectTypeEnum,
} from "@ui-components";
import {
  combineLatest,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from "rxjs";
import {
  GameDetailsModel,
  LocalRefreshService,
  MODAL_TOKEN,
  ModalProvider,
} from "@core";
import {
  HostedFutureGamesListComponent,
  provideHostedGamesService,
} from "@hosted-games";
import { provideGamesService } from "@games";
import { IonicModule } from "@ionic/angular";
import { CreateSingleChatCommandHandler, provideChatService } from "@messages";
import { GetFutureGamesQueryHandler } from "../../../../../games/src/lib/application/handlers";
import { GetHostedFutureGamesQueryHandler } from "../../../../../hosted-games/src/lib/application/handlers";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "lib-dashboard-games-list",
  templateUrl: "./dashboard-games-list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    GameCardComponent,
    HostedFutureGamesListComponent,
    NgForOf,
    NgIf,
    IonicModule,
    NgClass,
    GameDetailsComponent,
  ],
  providers: [
    GetHostedFutureGamesQueryHandler,
    provideHostedGamesService(),
    provideGamesService(),
    GetFutureGamesQueryHandler,
    CreateSingleChatCommandHandler,
    provideChatService(),
    LocalRefreshService,
  ],
})
export class DashboardGamesListComponent implements AfterViewInit {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getHostedFutureGamesQueryHandler: GetHostedFutureGamesQueryHandler =
    inject(GetHostedFutureGamesQueryHandler);
  private readonly getFutureGamesQueryHandler: GetFutureGamesQueryHandler =
    inject(GetFutureGamesQueryHandler);
  private readonly createSingleChatCommandHandler: CreateSingleChatCommandHandler =
    inject(CreateSingleChatCommandHandler);
  private readonly localRefreshService: LocalRefreshService =
    inject(LocalRefreshService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @Output() isGameAvailable: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public gameRedirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;
  public isGamesExpanded: boolean = true;

  readonly hostedGames$: Observable<GameDetailsModel[]> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.getHostedFutureGamesQueryHandler.getGames()),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1),
      takeUntilDestroyed(this.destroyRef)
    );

  readonly playerApprovedGames$: Observable<GameDetailsModel[]> =
    this.localRefreshService.refresh$.pipe(
      switchMap(() => this.modalProvider.showLoading$()),
      switchMap(() => this.getFutureGamesQueryHandler.getApproved()),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1),
      takeUntilDestroyed(this.destroyRef)
    );

  readonly playerWaitingGames$: Observable<GameDetailsModel[]> =
    this.localRefreshService.refresh$.pipe(
      switchMap(() => this.modalProvider.showLoading$()),
      switchMap(() => this.getFutureGamesQueryHandler.getWaiting()),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1),
      takeUntilDestroyed(this.destroyRef)
    );

  ngAfterViewInit(): void {
    this.sendIsAvailableEvent();
  }

  changeGamesVisibility(): void {
    this.isGamesExpanded = !this.isGamesExpanded;
  }

  sendMessage(userId: string): void {
    this.createSingleChatCommandHandler.create(userId).subscribe();
  }

  refresh(): void {
    this.localRefreshService.emit();
  }

  private sendIsAvailableEvent(): void {
    combineLatest([
      this.hostedGames$,
      this.playerApprovedGames$,
      this.playerWaitingGames$,
    ])
      .pipe(
        take(1),
        tap(
          ([hosted, approved, waiting]: [
            GameDetailsModel[],
            GameDetailsModel[],
            GameDetailsModel[]
          ]) => {
            const result: boolean =
              !!hosted?.length || !!approved?.length || !!waiting?.length;

            this.isGameAvailable.emit(result);
          }
        )
      )
      .subscribe();
  }
}
