import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const AUTH_WITH_PROVIDER_COMMAND: InjectionToken<AuthWithProviderCommandPort> =
  new InjectionToken<AuthWithProviderCommandPort>("AUTH_WITH_PROVIDER_COMMAND");

export interface AuthWithProviderCommandPort {
  authWithGoogle(): Observable<void>;
  authWithApple(): Observable<void>;
}
