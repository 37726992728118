<div class="or-p-16-px or-pt-40-px or-bg-shade">
  <div class="or-head-xs or-color-secondary-2 or-mb-12-px">
    Szczegóły gracza
  </div>

  <div
    class="or-d-flex or-align-items-center or-mb-20-px or-justify-content-between"
  >
    <div class="or-d-flex or-align-items-center">
      <lib-or-avatar
        [avatarUrl]="member.userThumbnail"
        [initials]="member.userDisplayName"
      ></lib-or-avatar>

      <div class="or-head-sm or-truncate-at-line-1">
        {{ member.userDisplayName }}
      </div>
    </div>
  </div>

  <ng-container>
    <div
      (click)="switchAutoAccept()"
      class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
    >
      <img
        src="assets/icons/favourite-shade.svg"
        class="or-mr-8-px or-size-32-px"
      />
      <div>
        <div class="or-text-md">{{ autoApproveText.header }}</div>
        <div class="or-text-sm or-color-secondary-2">
          {{ autoApproveText.text }}
        </div>
      </div>
    </div>
  </ng-container>

  <div
    (click)="removePlayer()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-d-flex or-p-8-px"
  >
    <img src="assets/icons/trash-icon.svg" class="or-mr-8-px" />
    <div class="or-text-md or-color-red">Usuń gracza z grupki</div>
  </div>
</div>
