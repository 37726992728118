import { FutureGamesContextPort } from "./future-games.context-port";
import { BehaviorSubject, Observable, of } from "rxjs";
import { FutureGameDTO } from "../../application/interfaces";

export class FutureGamesStorage implements FutureGamesContextPort {
  private readonly gamesSubject: BehaviorSubject<FutureGameDTO> =
    new BehaviorSubject<FutureGameDTO>({} as FutureGameDTO);

  readonly games$: Observable<FutureGameDTO> = this.gamesSubject.asObservable();

  setContext(data: FutureGameDTO): Observable<void> {
    return of(this.gamesSubject.next(data));
  }
}
