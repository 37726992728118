import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { AdvancedInvitationsDTO } from "../../../application/interfaces";

export const ADVANCED_INVITATIONS_DTO: InjectionToken<AdvancedInvitationsDtoPort> =
  new InjectionToken<AdvancedInvitationsDtoPort>("ADVANCED_INVITATIONS_DTO");

export interface AdvancedInvitationsDtoPort {
  getUsers(gameId: string): Observable<AdvancedInvitationsDTO>;
  switchAutoInvite(
    gameId: string,
    userId: string,
    value: boolean
  ): Observable<void>;
  switchAutoApproval(
    gameId: string,
    userId: string,
    value: boolean
  ): Observable<void>;
}
