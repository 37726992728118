import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const UNREAD_MESSAGES_QUERY: InjectionToken<UnreadMessagesQueryPort> =
  new InjectionToken<UnreadMessagesQueryPort>("UNREAD_MESSAGES_QUERY");

export interface UnreadMessagesQueryPort {
  isUnreadMessage(): Observable<boolean>;
  isUnreadSingle(): Observable<boolean>;
  isUnreadMulti(): Observable<boolean>;
}
