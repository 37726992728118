import { NotificationType } from "../enums";

export interface NotificationResponseDTO {
  readonly notifications: NotificationBody[];
}

export interface NotificationBody {
  readonly notificationId: string;
  readonly sentAt: Date;
  readonly createdAt: Date;
  readonly payload: NotificationPayload;
  readonly isRead: boolean;
  readonly notificationType: NotificationType;
}

export interface NotificationPayload {
  readonly title: string;
  readonly body: string;
  readonly gameId: string;
  readonly gameDate: string;
  readonly gameName: string;
  readonly gameHours: string;
  readonly gameAddress: string;
  readonly notificationId: string;
  readonly applicantUserId: string;
  readonly applicantUserName?: string;
  readonly gamePlayerApplicationId: string;
  readonly displayName: string;
  readonly thumbnailUrl: string;
  readonly customId: string;
  readonly customRedirectUrl: string;
  readonly teamId: string;
  readonly teamName: string;
  readonly receiverUserId: string;
}
