import { CardReason, CardType } from "../enums";

export interface PenaltyDTO {
  userPenaltyId: string;
  gameId: string;
  expirationDate: Date;
  penaltyDate: Date;
  author: PenaltyAuthor;
  penaltyType: CardType;
  penaltyReason: CardReason;
}

export interface PenaltyAuthor {
  userId: string;
  displayName: string;
  thumbnail: string;
}
