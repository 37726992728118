<div class="or-p-16-px or-pt-40-px or-bg-shade">
  <div class="or-head-xs or-color-secondary-2 or-mb-12-px">
    Opcje udostępniania
  </div>

  <div
    (click)="gameLink()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
  >
    <img
      src="assets/icons/share-shade-2.svg"
      class="or-mr-8-px or-size-32-px"
    />
    <div class="or-text-md">Kopiuj link</div>
  </div>

  <div
    (click)="copySocialContent()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
  >
    <img src="assets/icons/copy-icon.svg" class="or-mr-8-px or-size-32-px" />
    <div>
      <div class="or-text-md">Kopiuj treść posta</div>
      <div class="or-text-sm or-color-secondary-2">
        Skopiuj link wraz z najważniejszymi informacjami
      </div>
    </div>
  </div>

  <div
    *ngIf="isNativeRun"
    (click)="openAdvancedSharing()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
  >
    <img
      src="assets/icons/advanced-share.svg"
      class="or-mr-8-px or-size-32-px"
    />
    <div class="or-text-md">Więcej opcji udostępniania...</div>
  </div>
</div>
