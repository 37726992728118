import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import {
  AsyncPipe,
  DatePipe,
  JsonPipe,
  NgForOf,
  NgIf,
  NgTemplateOutlet,
} from "@angular/common";
import { Observable } from "rxjs";
import { CopyService, CopyValueToClipboardDirective } from "@core";
import { UsersListDetailsDTO } from "../../../application/models";
import { provideAdminUsersListQuery } from "../../../application/handlers";
import {
  GET_ADMIN_USERS_LIST_QUERY,
  GetAdminUsersListQueryPort,
} from "../../../application/ports";

@Component({
  selector: "lib-users-list",
  templateUrl: "./users-list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    JsonPipe,
    NgIf,
    NgForOf,
    DatePipe,
    CopyValueToClipboardDirective,
    NgTemplateOutlet,
  ],
  providers: [CopyService, provideAdminUsersListQuery()],
})
export class UsersListComponent {
  private readonly getAdminUsersListQueryPort: GetAdminUsersListQueryPort =
    inject(GET_ADMIN_USERS_LIST_QUERY);

  readonly usersList$: Observable<UsersListDetailsDTO[]> =
    this.getAdminUsersListQueryPort.getList();
}
