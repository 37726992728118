import { Provider } from "@angular/core";
import {
  ALERTS_SETTINGS_QUERY,
  CUSTOM_NOTIFICATION_QUERY,
  MARK_AS_READ_NOTIFICATIONS_COMMAND,
  NOTIFICATIONS_LIST_QUERY,
  PUSH_PERMISSIONS_STATUS_COMMAND,
  UNREGISTER_TOKEN_COMMAND,
  UPDATE_ALERTS_LOCATION_COMMAND,
  UPDATE_ALERTS_SETTINGS_COMMAND,
} from "../ports";
import { NotificationsListQueryHandler } from "./notifications-list.query-handler";
import { MarkAsReadNotificationsCommandHandler } from "./mark-as-read-notifications.command-handler";
import { GetAlertsSettingsQueryHandler } from "./get-alerts-settings.query-handler";
import { UpdateAlertsSettingsCommandHandler } from "./update-alerts-settings.command-handler";
import { UnregisterTokenCommandHandler } from "./unregister-token.command-handler";
import { CustomNotificationQueryHandler } from "./custom-notification.query-handler";
import { UpdateAlertsLocationCommandHandler } from "./update-alerts-location.command-handler";
import { PushPermissionsStatusCommandHandler } from "./push-permissions-status.command-handler";

export const provideNotificationsListQuery = (): Provider => ({
  provide: NOTIFICATIONS_LIST_QUERY,
  useClass: NotificationsListQueryHandler,
});

export const provideMarkAsReadNotificationsCommand = (): Provider => ({
  provide: MARK_AS_READ_NOTIFICATIONS_COMMAND,
  useClass: MarkAsReadNotificationsCommandHandler,
});

export const provideGetAlertsSettingsQuery = (): Provider => ({
  provide: ALERTS_SETTINGS_QUERY,
  useClass: GetAlertsSettingsQueryHandler,
});

export const provideUpdateAlertsSettingsCommand = (): Provider => ({
  provide: UPDATE_ALERTS_SETTINGS_COMMAND,
  useClass: UpdateAlertsSettingsCommandHandler,
});

export const provideUpdateAlertsLocationCommand = (): Provider => ({
  provide: UPDATE_ALERTS_LOCATION_COMMAND,
  useClass: UpdateAlertsLocationCommandHandler,
});

export const provideUnregisterTokenCommand = (): Provider => ({
  provide: UNREGISTER_TOKEN_COMMAND,
  useClass: UnregisterTokenCommandHandler,
});

export const provideCustomNotificationQuery = (): Provider => ({
  provide: CUSTOM_NOTIFICATION_QUERY,
  useClass: CustomNotificationQueryHandler,
});
export const providePushNotificationsStatusCommand = (): Provider => ({
  provide: PUSH_PERMISSIONS_STATUS_COMMAND,
  useClass: PushPermissionsStatusCommandHandler,
});
