import { UserRoleEnum } from "../../enums";
import { ChatUserDataDTO } from "./user.dto";

export interface UserModel {
  readonly userId: string;
  readonly userAuthId: string;
  readonly email: string;
  readonly phone: string;
  readonly displayName: string;
  readonly name: string;
  readonly surname: string;
  readonly role: UserRoleEnum;
  readonly avatarUrl: string;
  readonly isHost: boolean;
  readonly isPaidHost: boolean;
  readonly chatSettings: ChatUserDataDTO;
}
