import { ErrorEnum } from "../enums/error.enum";
import { ErrorModel } from "../interfaces";

export const getErrorMessage = (
  errorCode: ErrorEnum,
  messageForClient: ErrorModel
): ErrorModel => {
  return (
    errorMessages[errorCode] || {
      title: messageForClient?.title ?? "Nieznany błąd",
      message: messageForClient?.message ?? "Wystąpił nieznany błąd",
    }
  );
};

const errorMessages: Record<ErrorEnum, ErrorModel> = {
  [ErrorEnum.HostApprovalAllSlotsAlreadyTaken]: {
    title: "Lista graczy jest już pełna",
    message: "Niestety, wszystkie miejsca w tej gierce są już zajęte",
  },
  [ErrorEnum.GameAlreadyPending]: {
    title: "Gra już się odbywa",
    message: "Niestety, nie możesz dołączyć do gierki która się odbywa",
  },
  [ErrorEnum.GameApplicationAlreadyPending]: {
    title: "Dołączenie do gierki",
    message: "Nie możesz dołączyć do gierki, w której już uczestniczysz",
  },
  [ErrorEnum.GameApplicationAlreadyApproved]: {
    title: "Dołączenie gracza do gierki",
    message: "Nie możesz dołączyć gracza do gierki, w której już uczestniczy",
  },
  [ErrorEnum.NotHandledTimezone]: {
    title: "Błąd",
    message: "Niewłaściwa strefa czasowa",
  },
  [ErrorEnum.GameEndDateBeforeStartDate]: {
    title: "Niewłaściwa data gry",
    message: "Nie możesz ustawić daty zakończenia gry przed początkiem gry",
  },
  [ErrorEnum.GameStartDateBeforeCurrentDatePlusOffset]: {
    title: "Niewłaściwa data gry",
    message: "Ustawiłeś niewłaściwą datę gry",
  },
  [ErrorEnum.CannotEditGameAfterItsFinished]: {
    title: "Edycja gry",
    message: "Nie możesz edytować gry, która już się zakończyła",
  },
  [ErrorEnum.CannotCancelGameIfSomePlayersAlreadyPaid]: {
    title: "Usunięcie gry",
    message: "Nie możesz usunąć gry kiedy ktoś już za nią zapłacił",
  },
  [ErrorEnum.UserDeleted]: {
    title: "Konto zostało usunięte",
    message: "Konto zostało usunięte w przeszłości",
  },
  [ErrorEnum.CannotApproveGamePlayerApplicationItsTooLate]: {
    title: "Akceptacja gracza",
    message: "Nie możesz zaakceptować gracza, jeśli gra się już rozpoczęła",
  },
  [ErrorEnum.CannotCreateRatingAlreadyExists]: {
    title: "Ocena aplikacji",
    message: "Ocena zostala już wyslana. Nie możesz jej zmienic",
  },
};
