import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { TitileNavbarComponent } from "@ui-components";
import { UsersListComponent } from "../users-list/users-list.component";
import { UsersSummaryComponent } from "../users-summary/users-summary.component";
import { UsersFiltersComponent } from "../users-filters/users-filters.component";
import { provideUsersListService } from "../../../infrastructure/http-services";
import { providePatchAdminUsersFiltersCommand } from "../../../application/handlers";
import {
  PATCH_ADMIN_USERS_FILTERS_COMMAND,
  PatchAdminUsersFiltersCommandPort,
} from "../../../application/ports";

@Component({
  selector: "lib-users-home",
  templateUrl: "./users-home.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    UsersListComponent,
    UsersSummaryComponent,
    UsersFiltersComponent,
  ],
  providers: [
    provideUsersListService(),
    providePatchAdminUsersFiltersCommand(),
  ],
})
export class UsersHomeComponent {
  private readonly patchAdminUsersFiltersCommandPort: PatchAdminUsersFiltersCommandPort =
    inject(PATCH_ADMIN_USERS_FILTERS_COMMAND);

  clearContext(): void {
    this.patchAdminUsersFiltersCommandPort.reset().subscribe();
  }
}
