import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Observable } from "rxjs";
import { UsersSummaryModel } from "../../../application/models";
import { AsyncPipe, JsonPipe, NgForOf, NgIf, SlicePipe } from "@angular/common";
import {
  ADMIN_USER_SUMMARY_DATA_QUERY,
  AdminUsersSummaryDataQueryPort,
} from "../../../application/ports";
import { provideAdminUsersSummaryDataQuery } from "../../../application/handlers";

@Component({
  selector: "lib-users-summary",
  templateUrl: "./users-summary.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, JsonPipe, NgIf, NgForOf, SlicePipe],
  providers: [provideAdminUsersSummaryDataQuery()],
})
export class UsersSummaryComponent {
  public showStats: boolean = false;

  private readonly adminUsersSummaryDataQueryPort: AdminUsersSummaryDataQueryPort =
    inject(ADMIN_USER_SUMMARY_DATA_QUERY);

  readonly usersSummary$: Observable<UsersSummaryModel> =
    this.adminUsersSummaryDataQueryPort.summaryData();

  versionEntries(
    version: Record<string, number | null>
  ): { key: string; value: number | null }[] {
    return this.adminUsersSummaryDataQueryPort.versionEntries(version);
  }

  changeVisibility(): void {
    this.showStats = !this.showStats;
  }
}
