<lib-title-navbar
  (actionOnBack)="clearContext()"
  title="Użytkownicy"
></lib-title-navbar>

<div class="or-mt-12-px or-mx-24-px">
  <div class="or-mb-12-px"><lib-users-filters></lib-users-filters></div>
  <div class="or-mb-32-px"><lib-users-summary></lib-users-summary></div>
  <div>
    <lib-users-list></lib-users-list>
  </div>
</div>
