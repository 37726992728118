import { TeamDetailsDTO } from "./interfaces/team-details.dto";

const member = {
  userId: "user-2",
  userDisplayName: "Player One",
  isAutoApproved: false,
  userThumbnail: "",
  phone: "123456780",
  isOwner: false,
};

const member2 = {
  userId: "user-2",
  userDisplayName: "Player Two",
  isAutoApproved: false,
  userThumbnail: "",
  phone: "123456780",
  isOwner: true,
};

const member3 = {
  userId: "user-2",
  userDisplayName: "Player Three",
  isAutoApproved: false,
  userThumbnail: "",
  phone: "123456780",
  isOwner: false,
};

const member4 = {
  userId: "user-2",
  userDisplayName: "Player Four",
  isAutoApproved: false,
  userThumbnail: "",
  phone: "123456780",
  isOwner: false,
};

const member5 = {
  userId: "user-2",
  userDisplayName: "Player Five",
  isAutoApproved: false,
  userThumbnail: "",
  phone: "123456780",
  isOwner: false,
};

export const mockTeamDetails: TeamDetailsDTO = {
  teamId: "123",
  name: "Patology Team Elo Elo",
  chatId: "chat-67890",
  isOwner: true,
  owner: member,
  members: [member, member2, member3, member4, member5],
};
