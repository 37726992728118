import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { UsersListDTO } from "../../../application/models";

export const USERS_LIST_DTO: InjectionToken<UsersListDtoPort> =
  new InjectionToken<UsersListDtoPort>("USERS_LIST_DTO");

export interface UsersListDtoPort {
  getAllUsers(): Observable<UsersListDTO>;
}
