import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { GameDetailsModel } from "@core";

export const GET_HOSTED_GAME_QUERY: InjectionToken<GetHostedGameQueryPort> =
  new InjectionToken<GetHostedGameQueryPort>("GET_HOSTED_GAME_QUERY");

export interface GetHostedGameQueryPort {
  getGame(gameId: string): Observable<GameDetailsModel>;
}
