<div class="or-align-items-center or-d-flex or-flex-col or-head-md">
  <div>
    Current version:
    <span class="or-color-red">{{ appVersion }} ({{ buildVersion }})</span>
  </div>

  <div>
    Newest version:
    <span class="or-color-red">{{ newestVersion$ | async }}</span>
  </div>

  <ng-container>
    <div>Available versions:</div>
    <div *ngFor="let v of availableVersions$ | async">
      <span>{{ v }}</span>
    </div>
  </ng-container>
</div>
