import { FiltersContextPort } from "./filters.context-port";
import { BehaviorSubject, Observable, of } from "rxjs";
import { FiltersValues } from "../../../application/models";
import { ShowFrom } from "@core";

export class FiltersStorage implements FiltersContextPort {
  private startValue: FiltersValues = {
    time: { min: 0, max: 24 },
    gameLevel: [],
    specificDaysType: ShowFrom.ALL,
    specificWeekdays: [],
    specificDateFrom: null,
    specificDateTo: null,
  };

  private filtersSubject: BehaviorSubject<FiltersValues> =
    new BehaviorSubject<FiltersValues>(this.startValue);

  filters$: Observable<FiltersValues> = this.filtersSubject.asObservable();

  setFilters(data: FiltersValues): Observable<void> {
    this.filtersSubject.next(data);
    return of(void 0);
  }

  reset(): Observable<void> {
    this.filtersSubject.next(this.startValue);
    return of(void 0);
  }
}
