<lib-title-navbar
  [showBackArrow]="false"
  title="Tworzenie nowej grupki"
></lib-title-navbar>

<div class="or-p-20-px or-mt-12-px">
  <div
    [ngClass]="
      isiOS
        ? 'full-height-scrollable-container-ios'
        : 'full-height-scrollable-container'
    "
  >
    <div class="or-d-flex or-flex-col or-align-items-center or-mb-40-px">
      <img src="assets/icons/teams-approved.svg" class="or-mb-16-px" />
      <div class="or-head-lg or-text-align-center">
        Gratulacje! Udało Ci się stworzyć nową grupkę! ⚽️
      </div>
      <div class="or-note-sm or-color-secondary-2 or-mt-12-px">
        Co warto teraz zrobić?
      </div>
    </div>

    <button
      (click)="copyLink()"
      class="button-secondary-style or-h-48-px or-w-100-pc or-head-md or-mb-8-px"
    >
      <div class="or-align-items-center or-d-flex or-justify-content-center">
        <img src="assets/icons/share-shade-icon.svg" class="or-mr-12-px" /><span
          >Skopiuj link do grupki</span
        >
      </div>
    </button>
    <div
      class="or-text-align-center or-note-sm or-color-secondary-2 or-mb-20-px"
    >
      Każda osoba z linkiem będzie miała możliwość dopisania się do Twojej
      ekipy.
    </div>

    <button
      (click)="invitePlayers()"
      class="button-secondary-style or-h-48-px or-w-100-pc or-head-md or-mb-8-px"
    >
      <div class="or-align-items-center or-d-flex or-justify-content-center">
        <img src="assets/icons/add-player-icon.svg" class="or-mr-12-px" />
        <span>Zaproś graczy</span>
      </div>
    </button>
    <div
      class="or-text-align-center or-note-sm or-color-secondary-2 or-mb-20-px"
    >
      Grałeś już z kimś w aplikacji? Zaproś go przez aplikacje!
    </div>
  </div>

  <button
    (click)="goToDetails()"
    class="button-primary-style or-h-48-px or-w-100-pc or-head-md"
  >
    <div class="or-align-items-center or-d-flex or-justify-content-center">
      <img src="assets/icons/ball-icon.svg" class="or-mr-12-px" /><span>
        Przejdź do widoku grupki
      </span>
    </div>
  </button>
</div>
