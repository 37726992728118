import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { PlayerPosition } from "@core";

@Injectable()
export class PlayerPositionViewService {
  private readonly playerPositionSubject: BehaviorSubject<PlayerPosition> =
    new BehaviorSubject<PlayerPosition>(PlayerPosition.FIELD);

  readonly playerPosition$: Observable<PlayerPosition> =
    this.playerPositionSubject.asObservable();

  setAsGoalKeeper(value: PlayerPosition): void {
    this.playerPositionSubject.next(value);
  }
}
