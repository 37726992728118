import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import {
  GET_PAST_GAMES_QUERY,
  GetPastGamesQueryPort,
} from "../../application/ports";
import { BehaviorSubject, Observable, switchMap, tap } from "rxjs";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import {
  BottomNavbarComponent,
  GameCardComponent,
  GamesMenuComponent,
  RedirectTypeEnum,
  TitileNavbarComponent,
} from "@ui-components";
import { providePastGamesQuery } from "../../application/handlers";
import { provideGamesService } from "../../infrastructure/http-service";
import { AppRoutes } from "src/app/app-routes.enum";
import { GamesRoutes } from "../../games-routes.enum";
import { Router } from "@angular/router";
import {
  GameDetailsModel,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";

@Component({
  selector: "lib-past-games",
  templateUrl: "./past-games.component.html",
  styleUrls: ["./past-games.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TitileNavbarComponent,
    BottomNavbarComponent,
    GamesMenuComponent,
    GameCardComponent,
  ],
  providers: [providePastGamesQuery(), provideGamesService()],
})
export class PastGamesComponent {
  public redirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;

  private readonly getPastGamesQueryPort: GetPastGamesQueryPort =
    inject(GET_PAST_GAMES_QUERY);
  private router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  private showPlaceholderSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isPageLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly games$: Observable<Map<string, GameDetailsModel[]>> =
    this.modalProvider.showLoading$().pipe(
      switchMap(() => this.getPastGamesQueryPort.getGames()),
      tap((games: Map<string, GameDetailsModel[]>) =>
        this.showPlaceholderSubject.next(games?.size === 0)
      ),
      tap(() => this.isPageLoaded$.next(true)),
      tap(() => this.modalProvider.dismissLoading$())
    );

  readonly showPlaceholder$: Observable<boolean> =
    this.showPlaceholderSubject.asObservable();

  readonly isiOS: boolean = this.platformProvider.isiOS;

  goToFutureGames(): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.FUTURE}`]);
  }

  goToPastGames(): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.PAST}`]);
  }
}
