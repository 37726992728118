<div
  *ngIf="
    !(isLocationEnabled$ | async) ||
    ((isLocationEnabled$ | async) && !(isPermissionGranted$ | async))
  "
  (click)="openPermissionSettings()"
  [ngClass]="{
    'location-banner or-d-flex or-justify-content-center border-radius-8 or-py-8-px or-w-100-pc':
      isPageLoaded$ | async
  }"
>
  <span *ngIf="!(isLocationEnabled$ | async) && (isPageLoaded$ | async)"
    >Kliknij aby włączyć lokalizację</span
  >
  <span
    *ngIf="
      (isLocationEnabled$ | async) &&
      !(isPermissionGranted$ | async) &&
      (isPageLoaded$ | async)
    "
    >Kliknij aby nadać uprawnienia lokalizacji</span
  >
</div>
