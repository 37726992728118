import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { AsyncPipe, NgForOf } from "@angular/common";
import { map, Observable } from "rxjs";
import { AppVersion, BuildVersion } from "@core";
import { VersionInfoService } from "../../infrastructure/http-services";

@Component({
  selector: "lib-version-info",
  templateUrl: "./version-info.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgForOf],
  providers: [VersionInfoService],
})
export class VersionInfoComponent {
  private readonly versionInfoService: VersionInfoService =
    inject(VersionInfoService);

  public appVersion: string = AppVersion;
  public buildVersion: string = BuildVersion;

  readonly newestVersion$: Observable<string> =
    this.versionInfoService.newestVersion();

  readonly availableVersions$: Observable<string[]> = this.versionInfoService
    .availableVersions()
    .pipe(
      map((versions: string[]) =>
        versions.sort((a, b) => this.compareVersions(b, a))
      )
    );

  private compareVersions(version1: string, version2: string): number {
    const v1 = version1.split(".").map(Number);
    const v2 = version2.split(".").map(Number);

    for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
      const num1 = v1[i] || 0;
      const num2 = v2[i] || 0;

      if (num1 > num2) return 1;
      if (num1 < num2) return -1;
    }
    return 0;
  }
}
