import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { ArrivalBarModel } from "../models";

export const GET_ARRIVAL_BAR_INFO_QUERY: InjectionToken<GetArrivalBarInfoQueryPort> =
  new InjectionToken<GetArrivalBarInfoQueryPort>("GET_ARRIVAL_BAR_INFO_QUERY");

export interface GetArrivalBarInfoQueryPort {
  getArrivalInfo(): Observable<ArrivalBarModel>;
}
