import { ChannelTypeEnum } from "../enums";

export interface ChannelDetailsDTO {
  readonly channelId: string;
  readonly channelExternalId: string;
  readonly channelName: string;
  readonly closestGame: GameChatDetailsDTO;
  readonly allGames: GameChatDetailsDTO[];
  readonly channelThumbnail: string;
  readonly canEdit: boolean;
  readonly channelType: ChannelTypeEnum;
  readonly isMuted: boolean;
}

export interface GameChatDetailsDTO {
  readonly gameId: string;
  readonly name: string;
  readonly gameDateStart: string;
  readonly gameDateEnd: string;
  readonly occupiedSlots: number;
  readonly totalSlots: number;
  readonly freeSlots: number;
}
