import { AngularFirestore } from "@angular/fire/compat/firestore";
import { map, Observable } from "rxjs";

export const firestoreGetDataUtil = <T>(
  firestore: AngularFirestore,
  collection: string,
  doc: string
): Observable<T> => {
  return firestore
    .collection<T>(collection)
    .doc(doc)
    .snapshotChanges()
    .pipe(map((data) => data?.payload?.data() as unknown as T));
};
