export enum HostedGamesRoutes {
  ROOT = "",
  FUTURE = "future",
  PAST = "past",
  ID = ":id",
  CREATE_GAME = "create-game",
  INVITE = "invite",
  ADVANCED_INVITATIONS = "advanced-invitations",
  BLOCK_WEB = "block-web",
}
