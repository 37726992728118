import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

export class TraceRequestInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const traceRequestId = req.clone({
      headers: req.headers.set("x-trace-request-id", crypto.randomUUID()),
    });

    return next.handle(traceRequestId);
  }
}
