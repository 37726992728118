import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  inject,
  Input,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { MODAL_TOKEN, ModalProvider } from "../../tokens";
import { NgTemplateOutlet } from "@angular/common";

@Component({
  templateUrl: "./action-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class ActionModalComponent implements AfterViewInit {
  @ViewChild("componentHost", { read: ViewContainerRef })
  componentHost!: ViewContainerRef;

  @Input() header!: string;
  @Input() message!: string;
  @Input() template!: Type<unknown>;
  @Input() btnOk!: string;
  @Input() btnCancel!: string;
  @Input() action!: Function;
  @Input() cancelAction!: Function;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly componentFactoryResolver: ComponentFactoryResolver = inject(
    ComponentFactoryResolver
  );

  ngAfterViewInit(): void {
    if (this.template instanceof Type) {
      this.createComponent(this.template);
    }
  }

  closeModal(): void {
    this.modalProvider.dismissAllStoredModals();
    this.cancelAction();
  }

  handle(): void {
    this.modalProvider.dismissAllStoredModals();
    this.action();
  }

  createComponent(component: Type<unknown>): void {
    this.componentHost.clear();
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef: ComponentRef<unknown> =
      this.componentHost.createComponent(componentFactory);
    componentRef.changeDetectorRef.detectChanges();
  }
}
