import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { GameDetailsModel } from "@core";

export const GET_PAST_GAMES_QUERY: InjectionToken<GetPastGamesQueryPort> =
  new InjectionToken<GetPastGamesQueryPort>("GET_PAST_GAMES_QUERY");

export interface GetPastGamesQueryPort {
  getGames(): Observable<Map<string, GameDetailsModel[]>>;
}
