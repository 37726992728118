import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { BehaviorSubject, map, Observable, switchMap, take, tap } from "rxjs";
import { CommonModule } from "@angular/common";
import {
  SAVE_FILTERS_COMMAND,
  SAVE_QUICK_FILTERS_COMMAND,
  SaveFiltersCommandPort,
  SaveQuickFiltersCommandPort,
} from "../../application/ports";
import {
  provideSaveFiltersCommand,
  provideSaveQuickFiltersCommand,
} from "../../application/handlers";
import { quickFiltersMapper } from "../../application/mappers";
import { QuickFilterOption } from "../../application/models";

type ExtendedQuickFilterOption = QuickFilterOption & { isChecked: boolean };

@Component({
  selector: "lib-quick-filters",
  templateUrl: "./quick-filters.component.html",
  styleUrls: ["./quick-filters.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  providers: [provideSaveQuickFiltersCommand(), provideSaveFiltersCommand()],
})
export class QuickFiltersComponent {
  private readonly saveQuickFiltersCommandPort: SaveQuickFiltersCommandPort =
    inject(SAVE_QUICK_FILTERS_COMMAND);
  private readonly saveFiltersCommandPort: SaveFiltersCommandPort =
    inject(SAVE_FILTERS_COMMAND);

  private quickFilterOptions: ExtendedQuickFilterOption[] = Object.values(
    quickFiltersMapper
  ).map((option: ExtendedQuickFilterOption) => ({
    ...option,
    isChecked: false,
  }));

  readonly filtersSubject: BehaviorSubject<ExtendedQuickFilterOption[]> =
    new BehaviorSubject<ExtendedQuickFilterOption[]>(this.quickFilterOptions);

  readonly filterValues$: Observable<ExtendedQuickFilterOption[]> =
    this.filtersSubject.asObservable();

  setFilter(data: ExtendedQuickFilterOption): void {
    this.filterValues$
      .pipe(
        take(1),
        map((filters: ExtendedQuickFilterOption[]) =>
          filters.map((filter: ExtendedQuickFilterOption) => {
            if (filter.id === data.id) {
              return { ...filter, isChecked: !filter.isChecked };
            }
            return { ...filter, isChecked: false };
          })
        ),
        tap((result: ExtendedQuickFilterOption[]) =>
          this.filtersSubject.next(result)
        ),
        switchMap(() =>
          data.isChecked
            ? this.saveFiltersCommandPort.defaultValue()
            : this.saveQuickFiltersCommandPort.saveQuickFilters(data.filters)
        )
      )
      .subscribe();
  }
}
