import {
  ADD_TO_CHANNEL_COMMAND,
  AVAILABLE_CHANNELS_QUERY,
  CHANNEL_DETAILS_QUERY,
  CHANNEL_MEMBERS_QUERY,
  COPY_CHANNEL_URL_COMMAND,
  CREATE_SINGLE_CHAT_COMMAND,
  MUTE_CHANNEL_COMMAND,
  REMOVE_MEMBERS_FROM_CHANNEL_COMMAND,
  UNREAD_MESSAGES_QUERY,
  UPDATE_CHANNEL_COMMAND,
} from "../ports";
import { AvailableChannelsQueryHandler } from "./available-channels.query-handler";
import { Provider } from "@angular/core";
import { ChannelDetailsQueryHandler } from "./channel-details.query-handler";
import { UpdateChannelCommandHandler } from "./update-channel.command-handler";
import { UnreadMessagesQueryHandler } from "./unread-messages.query-handler";
import { ChannelMembersQueryHandler } from "./channel-members.query-handler";
import { RemoveMembersFromChannelCommandHandler } from "./remove-members-from-channel.command-handler";
import { CopyChannelUrlCommandHandler } from "./copy-channel-url.command-handler";
import { AddToChannelCommandHandler } from "./add-to-channel.command-handler";
import { CreateSingleChatCommandHandler } from "./create-single-chat.command-handler";
import { MuteChannelCommandHandler } from "./mute-channel.command-handler";

export const provideAvailableChannelsQuery = (): Provider => ({
  provide: AVAILABLE_CHANNELS_QUERY,
  useClass: AvailableChannelsQueryHandler,
});

export const provideChannelDetailsQuery = (): Provider => ({
  provide: CHANNEL_DETAILS_QUERY,
  useClass: ChannelDetailsQueryHandler,
});

export const provideUpdateChannelCommand = (): Provider => ({
  provide: UPDATE_CHANNEL_COMMAND,
  useClass: UpdateChannelCommandHandler,
});

export const provideUnreadMessagesQuery = (): Provider => ({
  provide: UNREAD_MESSAGES_QUERY,
  useClass: UnreadMessagesQueryHandler,
});

export const provideChannelMembersQuery = (): Provider => ({
  provide: CHANNEL_MEMBERS_QUERY,
  useClass: ChannelMembersQueryHandler,
});

export const provideRemoveMembersFromChannelCommand = (): Provider => ({
  provide: REMOVE_MEMBERS_FROM_CHANNEL_COMMAND,
  useClass: RemoveMembersFromChannelCommandHandler,
});

export const provideCopyChannelUrlCommand = (): Provider => ({
  provide: COPY_CHANNEL_URL_COMMAND,
  useClass: CopyChannelUrlCommandHandler,
});

export const provideAddToChannelCommand = (): Provider => ({
  provide: ADD_TO_CHANNEL_COMMAND,
  useClass: AddToChannelCommandHandler,
});

export const provideCreateSingleChatCommand = (): Provider => ({
  provide: CREATE_SINGLE_CHAT_COMMAND,
  useClass: CreateSingleChatCommandHandler,
});

export const provideMuteChannelCommand = (): Provider => ({
  provide: MUTE_CHANNEL_COMMAND,
  useClass: MuteChannelCommandHandler,
});
