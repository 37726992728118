<lib-title-navbar
  [customBackUrl]="customBackUrl"
  title="Płatność za gierkę"
></lib-title-navbar>

<div
  [ngClass]="
    isiOS
      ? 'full-height-scrollable-container-ios'
      : 'full-height-scrollable-container'
  "
>
  <div class="or-head-xl or-mt-28-px or-mx-20-px or-text-align-center">
    Gierka została zakończona.
  </div>

  <div
    class="or-mb-28-px or-mt-12-px or-mx-20-px or-text-lg or-text-align-center"
  >
    {{ messageText$ | async }}
  </div>

  <ng-container *ngIf="game$ | async as game">
    <lib-game-card
      [game]="game"
      [showHostInfo]="true"
      [showPhoneNumber]="!(isHostedGame$ | async)"
    ></lib-game-card>

    <ng-container *ngIf="!(isHostedGame$ | async)">
      <div class="or-d-flex or-justify-content-center or-my-28-px">
        <div class="custom-divider"></div>
      </div>

      <div class="or-d-flex or-justify-content-between or-mx-20-px or-text-lg">
        <div>Płatność za gierkę</div>
        <div>{{ game.priceAmount }} zł</div>
      </div>
    </ng-container>

    <div class="or-d-flex or-justify-content-center or-my-28-px">
      <div class="custom-divider"></div>
    </div>

    <div>
      <lib-rating-app
        [gameId]="game.gameId"
        [isHostedGame]="!!(isHostedGame$ | async)"
      ></lib-rating-app>
    </div>
  </ng-container>
</div>
<lib-bottom-navbar></lib-bottom-navbar>
