import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { PlayerPositionViewService } from "../../application/view-services";
import { map, Observable, shareReplay } from "rxjs";
import { PlayerPosition } from "@core";

@Component({
  selector: "lib-choose-position",
  templateUrl: "./choose-position.component.html",
  styleUrls: ["./choose-position.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class ChoosePositionComponent {
  private playerPositionViewService: PlayerPositionViewService = inject(
    PlayerPositionViewService
  );

  public goalkeeperValue: PlayerPosition = PlayerPosition.GOALKEEPER;
  public fieldValue: PlayerPosition = PlayerPosition.FIELD;

  readonly playerPosition$: Observable<PlayerPosition> =
    this.playerPositionViewService.playerPosition$.pipe(shareReplay(1));

  readonly isGoalKeeper$: Observable<boolean> = this.playerPosition$.pipe(
    map((position: PlayerPosition) => position === PlayerPosition.GOALKEEPER)
  );

  readonly isFieldPlayer$: Observable<boolean> = this.playerPosition$.pipe(
    map((position: PlayerPosition) => position === PlayerPosition.FIELD)
  );

  choosePosition(event: CustomEvent) {
    this.playerPositionViewService.setAsGoalKeeper(event.detail.value);
  }
}
