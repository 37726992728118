import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";
import { GameCardComponent, RedirectTypeEnum } from "@ui-components";
import { IonicModule } from "@ionic/angular";
import { GameDetailsModel } from "@core";

@Component({
  selector: "lib-hosted-future-games-list",
  templateUrl: "./hosted-future-games-list.component.html",
  styleUrls: ["./hosted-future-games-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, GameCardComponent, NgClass, NgIf],
})
export class HostedFutureGamesListComponent {
  @Input({ required: true }) game!: GameDetailsModel;

  public redirectType: RedirectTypeEnum = RedirectTypeEnum.HOSTED;
}
