import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TitileNavbarComponent } from "@ui-components";
import { CredentialsDTO } from "../../application/models";
import { MODAL_TOKEN, ModalProvider, PresentModalComponent } from "@core";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { RouterLink } from "@angular/router";

@Component({
  selector: "auth-form",
  templateUrl: "./auth-form.component.html",
  styleUrls: ["./auth-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TitileNavbarComponent,
    ReactiveFormsModule,
    RouterLink,
  ],
})
export class AuthFormComponent {
  @Input() isRegister: boolean = false;
  @Output() actionEvent: EventEmitter<CredentialsDTO> =
    new EventEmitter<CredentialsDTO>();

  public showPassword: boolean = false;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly credentialsForm: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
    password2: new FormControl(
      "",
      this.isRegister ? [Validators.required] : []
    ),
  });

  onButtonClicked() {
    const credentials: CredentialsDTO = {
      email: this.credentialsForm.get("email")?.value,
      password: this.credentialsForm.get("password")?.value,
    };

    if (this.isRegister && !this.passwordMatchValidator()) {
      return this.passwordsNotMatched();
    }
    return this.actionEvent.emit(credentials);
  }

  passwordMatchValidator(): boolean {
    if (!this.isRegister) return true;
    const password = this.credentialsForm.get("password")?.value;
    const confirmPassword = this.credentialsForm.get("password2")?.value;
    return password === confirmPassword;
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  private passwordsNotMatched(): void {
    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: "Rejestracja",
        message: "Hasła nie pasują",
        btnTxt: "Zamknij",
      },
      cssClass: "present-modal",
    });
  }

  resetPasswordModal(): void {
    this.modalProvider.showModal$({
      component: ResetPasswordComponent,
      cssClass: "present-modal",
    });
  }
}
