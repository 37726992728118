import { inject } from "@angular/core";
import { map, Observable, switchMap } from "rxjs";
import {
  NEARBY_GAMES_DTO,
  NearbyGamesDtoPort,
} from "../../infrastructure/http-services";
import {
  FILTERS_CONTEXT_PORT,
  FiltersContextPort,
} from "../../infrastructure/storages";
import { FiltersValues } from "../models";
import { NerabyGamesDTO } from "@core";
import { parseFiltersData } from "../utils";

export class GetMapMarkersQueryHandler {
  private nearbyGamesDtoPort: NearbyGamesDtoPort = inject(NEARBY_GAMES_DTO);
  private filtersContextPort: FiltersContextPort = inject(FILTERS_CONTEXT_PORT);

  getMapMarkers(): Observable<{ locations: NerabyGamesDTO[] }> {
    return this.filtersContextPort.filters$.pipe(
      map((filters: FiltersValues) => parseFiltersData(filters)),
      switchMap((params: string) => this.nearbyGamesDtoPort.getMarkers(params))
    );
  }
}
