import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { FeatureFlagsRecord } from "../../application/models";

export const FEATURE_FLAGS_CONTEXT: InjectionToken<FeatureFlagsContextPort> =
  new InjectionToken<FeatureFlagsContextPort>("FEATURE_FLAGS_CONTEXT");

export interface FeatureFlagsContextPort {
  flags$: Observable<FeatureFlagsRecord>;
  setState(data: FeatureFlagsRecord): Observable<void>;
}
