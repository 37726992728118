import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { NgIf } from "@angular/common";
import {
  ButtonLabels,
  CopyService,
  CopyValueToClipboardDirective,
  MODAL_TOKEN,
  ModalProvider,
} from "@core";
import { showInstructionModal } from "../../application/utils";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { MessagesRoutes } from "../../messages-routes.enum";
import {
  provideCopyChannelUrlCommand,
  provideMuteChannelCommand,
} from "../../application/handlers";
import {
  COPY_CHANNEL_URL_COMMAND,
  CopyChannelUrlCommandPort,
  MUTE_CHANNEL_COMMAND,
  MuteChannelCommandPort,
} from "../../application/ports";
import { take, tap } from "rxjs";
import { ChannelDetailsDTO } from "../../application/interfaces";

@Component({
  selector: "lib-manage-channel",
  templateUrl: "./manage-channel.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, CopyValueToClipboardDirective],
  providers: [
    provideCopyChannelUrlCommand(),
    CopyService,
    provideMuteChannelCommand(),
  ],
})
export class ManageChannelComponent {
  @Input({ required: true }) channelDetails!: ChannelDetailsDTO;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly copyChannelUrlCommandPort: CopyChannelUrlCommandPort =
    inject(COPY_CHANNEL_URL_COMMAND);
  private readonly muteChannelCommandPort: MuteChannelCommandPort =
    inject(MUTE_CHANNEL_COMMAND);

  get muteChatLabel(): ButtonLabels {
    const isMuted: boolean = this.channelDetails?.isMuted;
    const header: string = isMuted ? "Włącz powiadomienia" : "Wycisz Chat";

    const icon: string = isMuted
      ? "assets/icons/notifications-grey.svg"
      : "assets/icons/notifications-muted.svg";
    return { header, icon };
  }

  goToEditChannel(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigateByUrl(
      `${AppRoutes.MESSAGES}/${this.channelDetails.channelId}/${MessagesRoutes.EDIT_CHANNEL}`
    );
  }

  goToMembers(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigateByUrl(
      `${AppRoutes.MESSAGES}/${this.channelDetails.channelId}/${MessagesRoutes.MANAGE_MEMBERS}`
    );
  }

  showInstructionModal(): void {
    this.modalProvider.dismissAllStoredModals();
    showInstructionModal(this.modalProvider);
  }

  copyLink(): void {
    this.copyChannelUrlCommandPort
      .copy(this.channelDetails.channelId)
      .pipe(take(1))
      .subscribe();
    this.modalProvider.dismissAllStoredModals();
  }

  muteChannel(): void {
    this.muteChannelCommandPort
      .mute(this.channelDetails.channelId, !this.channelDetails.isMuted)
      .pipe(
        take(1),
        tap(() => this.modalProvider.dismissAllStoredModals())
      )
      .subscribe();
  }
}
