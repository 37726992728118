import { GetArrivalBarInfoQueryPort } from "../ports";
import { map, Observable, take } from "rxjs";
import { ArrivalBarDto, ArrivalBarModel } from "../models";
import {
  ARRIVAL_BAR_DTO,
  ArrivalBarDtoPort,
} from "../../infrastructure/http-services";
import { inject } from "@angular/core";
import { DateFormatPipe } from "@ui-components";
import { ArrivalBarType } from "../enums";

export class GetArrivalBarInfoQueryHandler
  implements GetArrivalBarInfoQueryPort
{
  private readonly arrivalBarDtoPort: ArrivalBarDtoPort =
    inject(ARRIVAL_BAR_DTO);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  getArrivalInfo(): Observable<ArrivalBarModel> {
    return this.arrivalBarDtoPort.getArrivalInfo().pipe(
      take(1),
      map((data: ArrivalBarDto) => {
        return {
          gameId: data?.gameId,
          gameName: data?.gameName,
          date: this.dateFormatPipe.transform(
            data?.gameDate,
            data?.gameDateEnd
          ),
          pendingPlayerApplicationsCount: data?.pendingPlayerApplicationsCount,
          isHost: data?.type === ArrivalBarType.HostedGame,
        };
      })
    );
  }
}
