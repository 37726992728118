import { GameDetailsModel, gameLevelMapper } from "@core";
import { PostGameDto } from "../../../../admin-panel/src/lib/application/models/post-game.dto";
import { DateFormatPipe } from "../date-pipe/date-format.pipe";

export const shareGameContent = (url: string, game: GameDetailsModel): string =>
  `${url}\n\n📍 ${game?.location?.name}\n⏰ ${game?.formattedDate}\n🎯 ${game?.level}\n💲 ${game?.priceAmount} zł\n\nDołącz przez aplikację Orlikfy!\n`;

export const futureGamesPostContent = (
  games: PostGameDto[],
  dateFormatPipe: DateFormatPipe
) => {
  return games.map(
    (game: PostGameDto) =>
      `📍 ${game?.location?.name}\n⏰ ${dateFormatPipe.transform(
        game?.gameDateStart,
        game?.gameDateEnd
      )}\n🎯 ${gameLevelMapper[game.level]}\n💲 ${game?.priceAmount} zł \n ${
        game.link
      }\n\n`
  );
};

export const futureGamesHeader = () => {
  return `Sprawdź, gdzie możesz zagrać w tym tygodniu! 🔥⚽️\n\n`;
};
