import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import {
  BottomNavbarComponent,
  OrAvatarComponent,
  TitileNavbarComponent,
} from "@ui-components";
import { Router } from "@angular/router";
import { NgClass, NgForOf, NgIf, SlicePipe } from "@angular/common";
import { AppRoutes } from "src/app/app-routes.enum";
import { TeamsRoutes } from "../../teams-routes.enum";
import { TeamDetailsDTO } from "../../application/interfaces";

@Component({
  selector: "lib-team-card",
  templateUrl: "./team-card.component.html",
  styleUrls: ["./team-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    NgClass,
    NgForOf,
    OrAvatarComponent,
    SlicePipe,
    NgIf,
  ],
})
export class TeamCardComponent {
  @Input({ required: true }) teamDetails!: TeamDetailsDTO;
  private readonly router: Router = inject(Router);

  goToDetails(): void {
    this.router.navigateByUrl(
      `${AppRoutes.TEAMS}/${this.teamDetails.teamId}/${TeamsRoutes.TEAM_DETAILS}`
    );
  }

  goToChat(event: Event): void {
    event.stopImmediatePropagation();
  }

  setAutoApprove(event: Event): void {
    event.stopImmediatePropagation();
  }
}
