import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TitileNavbarComponent } from "@ui-components";
import { AdminGamesListComponent } from "../../components";
import { provideAdminGames } from "../../infrastructure/http-services";

@Component({
  templateUrl: "./games-admin.page.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, AdminGamesListComponent],
  providers: [provideAdminGames()],
})
export class GamesAdminPage {}
