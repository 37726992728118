import { inject, Injectable } from "@angular/core";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { Observable, of, take, tap } from "rxjs";
import { NOTIFICATIONS_DTO, NotificationsDTOPort } from "../../infrastructure";
import {
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { Router } from "@angular/router";
import { notificationRedirectUtil } from "../utils";
import { NotificationType } from "../enums";

@Injectable()
export class NotificationsInitializer {
  private readonly notificationDtoPort: NotificationsDTOPort =
    inject(NOTIFICATIONS_DTO);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private isListenerAdded: boolean = false;

  init(): Observable<void> {
    if (!this.platformProvider.isNativeRun) return of(void 0);
    this.requestPermissions();
    this.registerNotification();
    this.pushNotificationReceived();
    this.pushNotificationActionPerformed();

    PushNotifications.addListener("registrationError", (error: any) => {
      console.error("Error on registration: " + JSON.stringify(error));
    });

    return of(void 0);
  }

  private requestPermissions(): void {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        PushNotifications.register();
      }
    });
  }

  private registerNotification(): void {
    if (!this.isListenerAdded) {
      PushNotifications.addListener("registration", (token: Token) => {
        this.notificationDtoPort
          .registerToken(token.value)
          .pipe(
            take(1),
            tap(() => localStorage.setItem("FCM_Device_Token", token.value))
          )
          .subscribe(() => (this.isListenerAdded = true));
      });
    }
  }

  private pushNotificationReceived(): void {
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        if (
          notification?.data?.notificationType ===
          NotificationType.ChatNewMessage
        ) {
          return this.showToast("Otrzymałeś nową wiadomość");
        } else {
          return this.showToast("Otrzymałeś nowe powiadomienie");
        }
      }
    );
  }

  private pushNotificationActionPerformed(): void {
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        notificationRedirectUtil({
          type: notification.notification?.data?.notificationType,
          gameId: notification.notification?.data?.gameId,
          router: this.router,
          customId: notification.notification?.data?.customId,
          channelType: notification.notification?.data?.channelType,
          customRedirectUrl: notification.notification?.data?.customRedirectUrl,
          teamId: notification.notification?.data?.teamId,
          receiverUserId: notification.notification?.data?.receiverUserId,
        });
        PushNotifications.removeAllDeliveredNotifications();
      }
    );
  }

  private showToast(message: string): void {
    this.modalProvider
      .showToast$({
        message: message,
        position: "top",
        duration: 3000,
        cssClass: [
          "or-d-flex or-text-align-center or-text-lg or-color-white or-mt-12-px or-toast-style",
        ],
      })
      .pipe(take(1))
      .subscribe();
  }
}
