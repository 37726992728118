import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: "lib-or-avatar",
  templateUrl: "./or-avatar.component.html",
  styleUrls: ["./or-avatar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonicModule, NgClass, NgIf],
})
export class OrAvatarComponent {
  @Input() avatarUrl!: string | undefined | null;
  @Input() initials: string | undefined = "";
  @Input() isProfile!: boolean;
  @Input() isNotifications!: boolean;
  @Input() base64?: string;
  @Input() showWhiteBorder?: boolean = false;

  get getInitials(): string {
    const nameParts: string[] = this.initials ? this.initials?.split(" ") : [];
    const a: string = nameParts[0]?.charAt(0).toUpperCase() ?? "";
    const b: string =
      nameParts[1]?.charAt(0).toUpperCase() ??
      nameParts[0]?.charAt(1).toLowerCase() ??
      "";

    return `${a}${b}`;
  }
}
