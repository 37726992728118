import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { inject } from "@angular/core";
import {
  LOAD_ADVANCED_INVITATIONS_COMMAND,
  LoadAdvancedInvtitationsCommandPort,
} from "../ports";

export const loadAdvancedInvitationsResolver: ResolveFn<void> = (
  activatedRoute: ActivatedRouteSnapshot
) => {
  const loadAdvancedInvtitationsCommandPort: LoadAdvancedInvtitationsCommandPort =
    inject(LOAD_ADVANCED_INVITATIONS_COMMAND);

  return loadAdvancedInvtitationsCommandPort.load(activatedRoute.params["id"]);
};
