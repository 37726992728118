import { AlertsSettingsDTO } from "../../../application/interfaces";
import { NotificationsAlertsDTOPort } from "./notifications-alerts.dto-port";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { CoordinatesDTO, ENV_CONFIG, EnvConfig } from "@core";

export class NotificationsAlertsService implements NotificationsAlertsDTOPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getAlerts(): Observable<AlertsSettingsDTO> {
    return this.httpClient.get<AlertsSettingsDTO>(
      `${this.env.apiUrl}/nearby-games/alerts`
    );
  }

  updateAlerts(settings: AlertsSettingsDTO): Observable<void> {
    return this.httpClient.put<void>(`${this.env.apiUrl}/nearby-games/alerts`, {
      ...settings,
    });
  }

  switchAlerts(value: boolean): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.env.apiUrl}/nearby-games/alerts/enabled`,
      { isEnabled: value }
    );
  }

  setLocation(coordinates: CoordinatesDTO): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.env.apiUrl}/nearby-games/alerts/location`,
      { coordinates: coordinates }
    );
  }

  setPushStatus(value: boolean): Observable<void> {
    return this.httpClient.put<void>(
      `${this.env.apiUrl}/user-meta/push-notification-enabled`,
      { value: value }
    );
  }
}
