export interface ChatChanelResponseDTO {
  readonly channels: ChatChanelDTO[];
}

export interface ChatChanelDTO {
  readonly channelId: string | null;
  readonly channelExternalId: string | null;
  readonly channelName: string | null;
  readonly channelThumbnail: string | null;
}
