import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from "@angular/core";
import { BottomNavbarComponent, TitileNavbarComponent } from "@ui-components";
import { IonicModule } from "@ionic/angular";
import { ActivatedRoute, ParamMap, Router, RouterLink } from "@angular/router";
import { StreamChatModule } from "stream-chat-angular";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { BehaviorSubject, Observable, switchMap, take, tap } from "rxjs";
import {
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { ChatInitializer } from "../../application/initializers";
import { AppRoutes } from "src/app/app-routes.enum";
import { MessagesRoutes } from "../../messages-routes.enum";
import { ChannelTypeEnum } from "../../application/enums";
import {
  UNREAD_MESSAGES_QUERY,
  UnreadMessagesQueryPort,
} from "../../application/ports";

@Component({
  selector: "lib-chats-list",
  templateUrl: "./chats-list.component.html",
  styleUrls: ["./chats-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    IonicModule,
    RouterLink,
    StreamChatModule,
    NgIf,
    AsyncPipe,
    NgClass,
  ],
})
export class ChatsListComponent implements OnInit {
  private readonly chatInitializer: ChatInitializer = inject(ChatInitializer);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly activatedRouter: ActivatedRoute = inject(ActivatedRoute);
  private readonly unreadMessagesQueryPort: UnreadMessagesQueryPort = inject(
    UNREAD_MESSAGES_QUERY
  );

  readonly isMultiActive$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  readonly isChatLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly isChatAvailable$: Observable<boolean> =
    this.chatInitializer.isChatAvailable$;

  readonly isUnreadMulti$: Observable<boolean> =
    this.unreadMessagesQueryPort.isUnreadMulti();

  readonly isUnreadSingle$: Observable<boolean> =
    this.unreadMessagesQueryPort.isUnreadSingle();

  readonly isiOS: boolean = this.platformProvider.isiOS;

  ngOnInit(): void {
    this.modalProvider
      .showLoading$()
      .pipe(
        switchMap(() => this.setChannelType()),
        switchMap(() => this.isMultiActive$),
        switchMap((isMulti: boolean) =>
          this.chatInitializer.init(
            isMulti ? ChannelTypeEnum.MULTI : ChannelTypeEnum.SINGLE
          )
        ),
        tap(() => this.isChatLoaded$.next(true)),
        tap(() => this.modalProvider.dismissLoading$())
      )
      .subscribe();
  }

  openSingle(): void {
    this.isMultiActive$.next(false);
  }

  openMulti(): void {
    this.isMultiActive$.next(true);
  }

  openChannel(): void {
    const qParams = this.isMultiActive$.value
      ? ChannelTypeEnum.MULTI
      : ChannelTypeEnum.SINGLE;

    this.router.navigateByUrl(
      `${AppRoutes.MESSAGES}/${MessagesRoutes.CHAT}?type=${qParams}`
    );
  }

  private setChannelType(): Observable<ParamMap> {
    return this.activatedRouter.queryParamMap.pipe(
      take(1),
      tap((paramMap: ParamMap) => {
        const type = paramMap.get("type");
        this.isMultiActive$.next(type !== ChannelTypeEnum.SINGLE);
      })
    );
  }
}
