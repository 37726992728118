import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { UsersSummaryModel } from "../../models";

export const ADMIN_USER_SUMMARY_DATA_QUERY: InjectionToken<AdminUsersSummaryDataQueryPort> =
  new InjectionToken<AdminUsersSummaryDataQueryPort>(
    "ADMIN_USER_SUMMARY_DATA_QUERY"
  );

export interface AdminUsersSummaryDataQueryPort {
  summaryData(): Observable<UsersSummaryModel>;
  versionEntries(
    version: Record<string, number | null>
  ): { key: string; value: number | null }[];
}
