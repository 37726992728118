<ion-radio-group
  (ionChange)="choosePosition($event)"
  [value]="playerPosition$ | async"
>
  <div class="or-d-flex or-justify-content-center or-head-sm">
    <div
      class="or-align-items-center or-d-flex or-flex-col or-justify-content-evenly or--12-px"
      [ngClass]="
        (isFieldPlayer$ | async)
          ? 'is-active or-bg-focus or-color-primary'
          : 'is-no-active or-bg-white'
      "
    >
      <ion-radio [value]="fieldValue"></ion-radio>
      <div>W polu</div>
    </div>

    <div
      class="or-align-items-center or-d-flex or-flex-col or-justify-content-evenly or-ml-12-px"
      [ngClass]="
        (isGoalKeeper$ | async)
          ? 'is-active or-bg-focus or-color-primary'
          : 'is-no-active or-bg-white'
      "
    >
      <ion-radio [value]="goalkeeperValue"></ion-radio>
      <div>Bramkarz</div>
    </div>
  </div>
</ion-radio-group>
