import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { BottomNavbarComponent, TitileNavbarComponent } from "@ui-components";
import { PLATFORM_TOKEN, PlatformProvider } from "@core";
import { Router } from "@angular/router";
import { NgClass } from "@angular/common";

@Component({
  selector: "lib-team-invite-players",
  templateUrl: "./team-invite-players.component.html",
  styleUrls: ["./team-invite-players.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, BottomNavbarComponent, NgClass],
})
export class TeamInvitePlayersComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);

  readonly isiOS: boolean = this.platformProvider.isiOS;
}
