import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { GlobalRefreshService, PLATFORM_TOKEN, PlatformProvider } from "@core";
import {
  BehaviorSubject,
  combineLatest,
  delay,
  from,
  Observable,
  switchMap,
  tap,
} from "rxjs";
import { CommonModule } from "@angular/common";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";

@Component({
  selector: "lib-location-button",
  templateUrl: "./location-button.component.html",
  styleUrls: ["./location-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class LocationButtonComponent {
  private platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private globalRefreshService: GlobalRefreshService =
    inject(GlobalRefreshService);

  readonly isPageLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  readonly isPageLoaded$ = this.isPageLoadedSubject
    .asObservable()
    .pipe(delay(2000));

  readonly isPermissionGranted$: Observable<boolean> =
    this.globalRefreshService.refresh$.pipe(
      switchMap(() => from(this.platformProvider.isPermissionGranted())),
      tap(() => this.isPageLoadedSubject.next(true))
    );

  readonly isLocationEnabled$: Observable<boolean> =
    this.globalRefreshService.refresh$.pipe(
      switchMap(() => from(this.platformProvider.isLocationEnabled())),
      tap(() => this.isPageLoadedSubject.next(true))
    );

  openPermissionSettings(): void {
    combineLatest([
      from(this.platformProvider.isPermissionGranted()),
      from(this.platformProvider.isLocationEnabled()),
    ])
      .pipe(
        tap(([granted, enabled]: [boolean, boolean]) => {
          if (this.platformProvider.isAndroid) {
            if (!enabled) {
              this.platformProvider.requestPermissions();

              return NativeSettings.openAndroid({
                option: AndroidSettings.Location,
              });
            }

            if (enabled && !granted) {
              return this.platformProvider.requestPermissions();
            }
          }

          if (this.platformProvider.isiOS) {
            if (!enabled) {
              this.platformProvider.requestPermissions();

              return NativeSettings.openIOS({
                option: IOSSettings.LocationServices,
              });
            }

            if (enabled && !granted) {
              return this.platformProvider.requestPermissions();
            }
          }
        })
      )
      .subscribe();
  }
}
