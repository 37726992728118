import { Directive, HostListener, inject, Input } from "@angular/core";
import { ActionModalComponent } from "../modals";
import {
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "../tokens";
import { CoordinatesDTO } from "../interfaces";

@Directive({
  standalone: true,
  selector: "[navigate]",
})
export class NavigateDirective {
  @Input("navigate") coordinates!: CoordinatesDTO;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private platform: PlatformProvider = inject(PLATFORM_TOKEN);

  @HostListener("click", ["$event"])
  onClick(event: Event): void {
    event.stopImmediatePropagation();

    this.showMessage();
  }

  private showMessage(): void {
    this.modalProvider.showModal$({
      component: ActionModalComponent,
      componentProps: {
        header: "Nawiguj",
        message: "Czy chcesz przejść do nawigacji?",
        btnOk: "Tak, nawiguj",
        btnCancel: "Anuluj",
        action: () => this.openNavigate(),
      },
      cssClass: "present-modal",
    });
  }

  private openNavigate(): void {
    if (this.platform.isAndroid) {
      const url = `geo:${this.coordinates.latitude},${this.coordinates.longitude}`;
      window.open(url, "_blank");
    }
    if (this.platform.isiOS) {
      const url = `maps://maps.google.com/maps?daddr=${this.coordinates.latitude},${this.coordinates.longitude}&amp;ll=`;
      window.open(url, "_blank");
    } else {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${this.coordinates.latitude},${this.coordinates.longitude}`;
      window.open(url, "_blank");
    }
  }
}
