import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import {
  NAVIGATION_TOKEN,
  NavigationProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { NgClass, NgIf } from "@angular/common";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { take, tap } from "rxjs";
import { AppRoutes } from "src/app/app-routes.enum";

@Component({
  selector: "lib-chat-navbar",
  templateUrl: "./chat-navbar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonicModule, NgClass, NgIf],
})
export class ChatNavbarComponent {
  @Output() actionOnBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDotsClicked: EventEmitter<void> = new EventEmitter<void>();

  @Input({ required: true }) name: string = "";
  @Input({ required: true }) date: string = "";
  @Input({ required: true }) isMulti: boolean = false;

  private readonly navigationProvider: NavigationProvider =
    inject(NAVIGATION_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly activatedRouter: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);

  isIOs(): boolean {
    return this.platformProvider.isiOS;
  }

  goBack(): void {
    this.activatedRouter.queryParamMap
      .pipe(
        take(1),
        tap((paramMap: ParamMap) => {
          const type = paramMap.get("type");
          if (!type) return this.navigationProvider.back();

          return this.router.navigateByUrl(
            `${AppRoutes.MESSAGES}?type=${type}`
          );
        }),
        tap(() => this.actionOnBack.emit())
      )
      .subscribe();
  }

  dotsClicked(): void {
    this.onDotsClicked.emit();
  }
}
