import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { GamesListModel } from "../interfaces";

export const GAMES_LIST_QUERY: InjectionToken<GamesListQueryPort> =
  new InjectionToken<GamesListQueryPort>("GAMES_LIST_QUERY");

export interface GamesListQueryPort {
  getGames(): Observable<GamesListModel[]>;
}
