import { GetHostedPastGamesQueryPort } from "../ports";
import {
  HOSTED_GAMES_DTO,
  HostedGamesDTOPort,
} from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { DateFormatPipe } from "@ui-components";
import { map, Observable } from "rxjs";
import { mapToHostedGameQueryUtil } from "./utils/map-to-hosted-game-query.util";
import { HostedGameDTO } from "../interfaces";
import { GameDetailsModel, GameDTO } from "@core";

export class GetHostedPastGamesQueryHandler
  implements GetHostedPastGamesQueryPort
{
  private readonly hostedGamesDTOPort: HostedGamesDTOPort =
    inject(HOSTED_GAMES_DTO);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  getGames(): Observable<Map<string, GameDetailsModel[]>> {
    return this.hostedGamesDTOPort
      .getPastGames()
      .pipe(
        map((games: HostedGameDTO) =>
          this.groupAndMapGamesByMonth(games.hostedGames)
        )
      );
  }

  private groupAndMapGamesByMonth(
    games: GameDTO[]
  ): Map<string, GameDetailsModel[]> {
    return games.reduce((groupedGames, game) => {
      const gameDate: Date = new Date(game.gameDateStart);
      const monthYear: string = this.capitalizeFirstLetter(
        gameDate.toLocaleString("pl-PL", {
          month: "long",
          year: "numeric",
        })
      );

      const updatedGroup = (groupedGames.get(monthYear) || []).concat(
        mapToHostedGameQueryUtil(game, this.dateFormatPipe)
      );
      return new Map(groupedGames).set(monthYear, updatedGroup);
    }, new Map<string, GameDetailsModel[]>());
  }

  private capitalizeFirstLetter(string: string): string {
    return string?.charAt(0).toUpperCase() + string.slice(1);
  }
}
