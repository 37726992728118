import { ChatDtoPort } from "./chat.dto-port";
import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import { Observable } from "rxjs";
import {
  ChannelDetailsDTO,
  ChannelMembersResponseDTO,
  ChatChanelResponseDTO,
  CreateSingleChatResponseDTO,
  UpdateChannelDTO,
} from "../../application/interfaces";

export class ChatService implements ChatDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  availableChannels(): Observable<ChatChanelResponseDTO> {
    return this.httpClient.get<ChatChanelResponseDTO>(
      `${this.env.apiUrl}/chat/game/assignable-channels`
    );
  }

  channelDetails(channelId: string): Observable<ChannelDetailsDTO> {
    return this.httpClient.get<ChannelDetailsDTO>(
      `${this.env.apiUrl}/chat/channel/${channelId}`
    );
  }

  updateChannel(data: UpdateChannelDTO): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.env.apiUrl}/chat/channel/${data.id}`,
      data.payload
    );
  }

  removeUsersFromChannel(
    channelId: string,
    userIds: string[]
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.env.apiUrl}/chat/channel/${channelId}/user`,
      { body: { userIds } }
    );
  }

  channelMembers(channelId: string): Observable<ChannelMembersResponseDTO> {
    return this.httpClient.get<ChannelMembersResponseDTO>(
      `${this.env.apiUrl}/chat/channel/${channelId}/members`
    );
  }

  addToChannel(channelId: string): Observable<{ accepted: boolean }> {
    return this.httpClient.post<{ accepted: boolean }>(
      `${this.env.apiUrl}/chat/channel/${channelId}/invitation/accept`,
      null
    );
  }

  createSingleChat(userId: string): Observable<CreateSingleChatResponseDTO> {
    return this.httpClient.post<CreateSingleChatResponseDTO>(
      `${this.env.apiUrl}/chat/channel/single`,
      { userId }
    );
  }
  muteChannel(channelId: string, value: boolean): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.env.apiUrl}/chat/channel/${channelId}/muted`,
      { isMuted: value }
    );
  }
}
