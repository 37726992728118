import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const REMOVE_MEMBERS_FROM_CHANNEL_COMMAND: InjectionToken<RemoveMembersFromChannelCommandPort> =
  new InjectionToken<RemoveMembersFromChannelCommandPort>(
    "REMOVE_MEMBERS_FROM_CHANNEL_COMMAND"
  );

export interface RemoveMembersFromChannelCommandPort {
  remove(channelId: string, userIds: string[]): Observable<void>;
}
