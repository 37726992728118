import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import {
  InvitationsDTO,
  InvitePlayersRequestDTO,
} from "../../../application/interfaces";

export const INVITATIONS_DTO: InjectionToken<InvitationsDtoPort> =
  new InjectionToken<InvitationsDtoPort>("INVITATIONS_DTO");

export interface InvitationsDtoPort {
  getList(gameId: string): Observable<InvitationsDTO>;
  invite(gameId: string, payload: InvitePlayersRequestDTO): Observable<void>;
  decline(invitaionId: string): Observable<void>;
}
