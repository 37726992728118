<div
  class="or-px-16-px or-pt-28-px full-height-scrollable-container"
  *ngIf="filtersValues$ | async as filterValues"
>
  <div class="or-head-xxl or-mt-20-px">Filtrowanie</div>
  <div class="or-mt-32-px">
    <span class="or-head-md">Godzina</span>
    <div class="or-position-relative or-mt-24-px">
      <ion-range
        [dualKnobs]="true"
        [value]="{
          lower: filterValues.time.min,
          upper: filterValues.time.max
        }"
        [min]="0"
        [max]="24"
        (ionInput)="onTimeChange($event)"
        class="or-mx-20-px"
      >
      </ion-range>
      <div class="left-count or-text-md">{{ filterValues.time.min }}:00</div>
      <div class="right-count or-text-md">{{ filterValues.time.max }}:00</div>
    </div>
  </div>

  <div class="or-mt-12-px">
    <span class="or-head-md">Poziom gry</span>
    <div class="or-mt-8-px">
      <ion-chip
        *ngFor="let level of gameLevels"
        class="or-head-xs border-radius-20 or-p-8-px"
        [ngClass]="levelChips(level) ? 'chip-checked' : 'chip-unchecked'"
        (click)="setLevelChips(level)"
      >
        {{ gameLevelMapper[level] }}
      </ion-chip>
    </div>
  </div>
  <div class="or-mt-20-px">
    <span class="or-head-md">Pokazuj gry</span>
    <div class="or-mt-8-px">
      <ion-chip
        *ngFor="let from of availableShowFromValues"
        class="or-head-xs border-radius-20 or-p-8-px"
        [ngClass]="viewFrom(from) ? 'chip-checked' : 'chip-unchecked'"
        (click)="setViewFromChips(from)"
      >
        {{ viewFromValuesMapper[from] }}
      </ion-chip>
    </div>
  </div>
</div>

<div class="or-d-flex or-justify-content-center or-my-20-px">
  <button
    class="button-secondary-style or-h-48-px or-head-md or-mr-8-px or-px-16-px"
    (click)="resetFilters()"
  >
    Wyzeruj
  </button>
  <button
    class="button-primary-style or-h-48-px or-head-md or-px-16-px"
    (click)="saveSettings()"
  >
    Pokaż dostępne gierki
  </button>
</div>
