import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { UpdateChannelDTO } from "../interfaces";

export const UPDATE_CHANNEL_COMMAND: InjectionToken<UpdateChannelCommandPort> =
  new InjectionToken<UpdateChannelCommandPort>("UPDATE_CHANNEL_COMMAND");

export interface UpdateChannelCommandPort {
  update(data: UpdateChannelDTO): Observable<void>;
}
