import { HostedGamesDTOPort } from "./hosted-games.dto-port";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig, GameDTO } from "@core";
import {
  CreateGameDTO,
  CreateGameResponseDTO,
  CustomPlayerModel,
  HostedGameDTO,
} from "../../../application/interfaces";

export class HostedGamesService implements HostedGamesDTOPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  createGame(game: CreateGameDTO): Observable<CreateGameResponseDTO> {
    return this.httpClient.post<CreateGameResponseDTO>(
      `${this.env.apiUrl}/hosted-games`,
      game
    );
  }

  updateGame(
    gameId: string,
    game: Partial<CreateGameDTO>
  ): Observable<CreateGameResponseDTO> {
    return this.httpClient.patch<CreateGameResponseDTO>(
      `${this.env.apiUrl}/hosted-games/${gameId}`,
      game
    );
  }

  getOneGame(gameId: string): Observable<GameDTO> {
    return this.httpClient.get<GameDTO>(
      `${this.env.apiUrl}/hosted-games/${gameId}`
    );
  }

  getFutureGames(): Observable<HostedGameDTO> {
    return this.httpClient.get<HostedGameDTO>(
      `${this.env.apiUrl}/hosted-games/future`
    );
  }

  getPastGames(): Observable<HostedGameDTO> {
    return this.httpClient.get<HostedGameDTO>(
      `${this.env.apiUrl}/hosted-games/past`
    );
  }

  removeGame(gameId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.env.apiUrl}/hosted-games/${gameId}`
    );
  }

  removePlayer(gameId: string, playerId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.env.apiUrl}/hosted-games/${gameId}/players/${playerId}`
    );
  }

  markAsPaid(gameId: string, playerId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${this.env.apiUrl}/hosted-games/${gameId}/players/${playerId}/mark-as-paid`,
      null
    );
  }

  markAsNotPaid(gameId: string, playerId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${this.env.apiUrl}/hosted-games/${gameId}/players/${playerId}/mark-as-not-paid`,
      null
    );
  }

  addPlayer(gameId: string, player: CustomPlayerModel): Observable<void> {
    return this.httpClient.post<void>(
      `${this.env.apiUrl}/hosted-games/${gameId}/players`,
      { name: player.name, surname: player.surname }
    );
  }
}
