import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation,
} from "@angular/core";
import { AuthFormComponent } from "../../components";
import { CredentialsDTO } from "../../application/models";
import { LOGIN_COMMAND, LoginCommandPort } from "../../application/ports";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import {
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent,
} from "@core";
import { NgClass } from "@angular/common";
import { MessagesRoutes } from "../../../../../messages/src/lib/messages-routes.enum";

@Component({
  templateUrl: "./login.page.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [AuthFormComponent, NgClass],
  standalone: true,
})
export class LoginPage {
  private readonly loginCommandPort: LoginCommandPort = inject(LOGIN_COMMAND);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  login(event: CredentialsDTO) {
    this.loginCommandPort
      .login({
        email: event.email?.trim(),
        password: event.password,
      })
      .subscribe({
        next: () => {
          this.redirectAfterLogin();
        },
        error: () => this.errorLogin(),
      });
  }

  goBack(): void {
    this.router.navigate([`/${AppRoutes.AUTH}`]);
  }

  private errorLogin(): void {
    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: "Logowanie",
        message: "Błedne dane",
        btnTxt: "Zamknij",
      },
      cssClass: "present-modal",
    });
  }

  private redirectAfterLogin() {
    const sharedGameId: string | null = sessionStorage.getItem("shared-game");
    const channelId: string | null = sessionStorage.getItem(
      "invitations-channel-id"
    );

    if (sharedGameId) {
      return this.router.navigate([
        `/${AppRoutes.GAMES}/shared/${sharedGameId}`,
      ]);
    }

    if (channelId) {
      return this.router.navigate([
        `/${AppRoutes.MESSAGES}/${MessagesRoutes.CHANNEL_INVITATIONS}/${channelId}`,
      ]);
    }
    return this.router.navigate([`/${AppRoutes.ROOT}`]);
  }
}
