import { UserStatsDtoPort } from "./user-stats.dto-port";
import { inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ENV_CONFIG, EnvConfig } from "@core";
import { Observable } from "rxjs";
import { UserStatsDTO } from "../../../application/interfaces";

export class UserStatsService implements UserStatsDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getStats(): Observable<UserStatsDTO> {
    return this.httpClient.get<UserStatsDTO>(
      `${this.env.apiUrl}/users/me/stats`
    );
  }
}
