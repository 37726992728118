<ng-container *ngIf="arrivalBarInfo$ | async as arrival">
  <div
    *ngIf="showFullItem; else minimalItem"
    (click)="redirectToGame(arrival.gameId, arrival.isHost)"
    class="or-w-100-pc"
  >
    <ion-chip
      *ngIf="arrival.isHost"
      class="or-ml-16-px z-index-999 or-head-sm or-color-primary or-bg-focus arrival-custom-chip"
      >Ilość oczekujących graczy:
      {{ arrival.pendingPlayerApplicationsCount }}</ion-chip
    >
    <div
      *ngIf="arrival.gameId"
      class="border-radius-8 or-bg-black-2 or-cursor-pointer or-pb-16-px or-pt-12-px or-px-16-px"
    >
      <div
        class="or-d-flex or-justify-content-between"
        [class.or-mt-16-px]="arrival.isHost"
      >
        <div class="or-text-md or-color-secondary-4">
          Twoja najbliższa gierka:
        </div>
        <img
          src="assets/icons/back-arrow-icon.svg"
          (click)="changeVisibility()"
          [class.margin-top--16-px]="arrival.isHost"
          class="rotate-270 or-size-24-px"
        />
      </div>
      <div class="or-color-white or-head-lg or-mb-2-px or-mt-4-px">
        {{ arrival.gameName }}
      </div>
      <div class="or-color-white or-text-lg">{{ arrival.date }}</div>
    </div>
  </div>
</ng-container>

<ng-template #minimalItem>
  <div
    (click)="changeVisibility()"
    class="or-bg-black-2 or-p-8-px border-radius-8 or-text-align-center or-cursor-pointer"
  >
    <div class="or-text-md or-color-white">
      Zobacz szczegóły najbliższej gierki
    </div>
  </div>
</ng-template>
