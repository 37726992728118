import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { UpdateUserDTO } from "../../../application/interfaces";

export const USER_DTO: InjectionToken<UserDTOPort> =
  new InjectionToken<UserDTOPort>("USER_DTO");

export interface UserDTOPort {
  updateUser(userData: UpdateUserDTO): Observable<void>;
}
