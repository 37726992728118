import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import {
  GENERATE_POST_DTO,
  GeneratePostDtoPort,
  provideGeneratePostService,
} from "../../infrastructure/http-services";
import {
  BehaviorSubject,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
} from "rxjs";
import {
  DateFormatPipe,
  futureGamesHeader,
  futureGamesPostContent,
  TitileNavbarComponent,
} from "@ui-components";
import { AsyncPipe, NgForOf } from "@angular/common";
import { CopyService } from "@core";

@Component({
  selector: "lib-generate-post",
  templateUrl: "./generate-post.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgForOf, TitileNavbarComponent],
  providers: [provideGeneratePostService(), DateFormatPipe, CopyService],
})
export class GeneratePostComponent {
  private readonly generatePostDtoPort: GeneratePostDtoPort =
    inject(GENERATE_POST_DTO);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);
  private readonly copyService: CopyService = inject(CopyService);

  private readonly endDays$: BehaviorSubject<number> =
    new BehaviorSubject<number>(7);

  games$: Observable<string[]> = this.endDays$.pipe(
    switchMap((endDays: number) =>
      this.generatePostDtoPort.generateFutureGames(endDays)
    ),
    map((res) => futureGamesPostContent(res.games, this.dateFormatPipe)),
    shareReplay(1)
  );

  setDay(day: number): void {
    this.endDays$.next(day);
  }

  copy(): void {
    this.games$
      .pipe(
        take(1),
        map((data) => data.join("\n")),
        switchMap((data) =>
          this.copyService.copyToClipboard(`${futureGamesHeader()}${data}`)
        )
      )
      .subscribe();
  }
}
