import { UserStatsQueryPort } from "../ports";
import { Observable } from "rxjs";
import { UserStatsDTO } from "../interfaces";
import { inject } from "@angular/core";
import {
  USER_STATS_DTO,
  UserStatsDtoPort,
} from "../../infrastructure/http-service";

export class UserStatsQueryHandler implements UserStatsQueryPort {
  private readonly userStatsDTO: UserStatsDtoPort = inject(USER_STATS_DTO);

  getStats(): Observable<UserStatsDTO> {
    return this.userStatsDTO.getStats();
  }
}
