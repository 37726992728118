import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { TitileNavbarComponent } from "@ui-components";
import {
  CopyService,
  DeepLinkService,
  DynamicLinkType,
  MODAL_TOKEN,
  ModalProvider,
  SocialMetaTagInfo,
  socialMetaTagInfoMapper,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { Observable, shareReplay, switchMap, tap } from "rxjs";
import { environment } from "src/environment/environment";
import { AsyncPipe, NgIf } from "@angular/common";

@Component({
  selector: "lib-referral",
  templateUrl: "./referral.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, AsyncPipe, NgIf],
  providers: [CopyService],
})
export class ReferralComponent {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly deepLinkService: DeepLinkService = inject(DeepLinkService);
  private readonly copyService: CopyService = inject(CopyService);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  readonly referralUrl$: Observable<string> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.userDataProvider.userData$),
      switchMap((userData: UserModel) => {
        const url: string = `${environment.frontendUrl}?referrer=${userData.userId}`;
        const socialMetaTagInfo: SocialMetaTagInfo =
          socialMetaTagInfoMapper[DynamicLinkType.REFERRAL];

        return this.deepLinkService.generateLink(url, socialMetaTagInfo);
      }),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1)
    );

  copy(): void {
    this.referralUrl$
      .pipe(switchMap((url: string) => this.copyService.copyToClipboard(url)))
      .subscribe();
  }
}
