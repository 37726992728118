import { CoordinatesDTO, GameLevel, ShowFrom } from "@core";

export interface AlertsSettingsDTO {
  isEnabled: boolean;
  coordinates: CoordinatesDTO | null;
  maxDistanceMeters: number;
  price: { max: number; min: number; currency: string };
  time: { hoursMax: number; hoursMin: number };
  gameLevel: GameLevel[];
  specificDaysType: ShowFrom;
  specificDate?: string | null;
  specificWeekdays?: string[];
}
