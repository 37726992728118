import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { NerabyGamesDTO } from "@core";

export const LOCATION_DETAILS_DTO: InjectionToken<LocationDetailsDtoPort> =
  new InjectionToken<LocationDetailsDtoPort>("LOCATION_DETAILS_DTO");

export interface LocationDetailsDtoPort {
  getLocationDetails(id: string): Observable<NerabyGamesDTO>;
}
