<lib-title-navbar title="Usuń użytkowników"></lib-title-navbar>

<div
  class="or-px-12-px or-pt-20-px"
  [ngClass]="
    isiOS
      ? 'full-height-scrollable-container-ios'
      : 'full-height-scrollable-container'
  "
>
  <div *ngFor="let member of members$ | async; let i = index">
    <div
      class="or-bg-white or-d-flex or-justify-content-between or-p-8-px or-mb-4-px player-box border-radius-8"
      [ngClass]="
        member.isSelected
          ? 'border-primary or-bg-selected'
          : 'player-box or-bg-white'
      "
    >
      <div class="or-d-flex or-align-items-center or-w-100-pc">
        <ion-checkbox
          [checked]="member.isSelected"
          (ionChange)="selectMember(member.userId)"
          class="or-mr-8-px"
        ></ion-checkbox>
        <lib-or-avatar
          [avatarUrl]="member.thumbnail"
          [initials]="member.displayName"
        ></lib-or-avatar>

        <div class="or-head-sm or-d-flex or-align-items-center or-w-100-pc">
          {{ i + 1 }}.&nbsp;
          <span class="or-truncate-at-line-1">{{ member.displayName }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="or-mx-12-px or-mt-20-px or-mb-12-px">
  <button
    (click)="removeUsers()"
    class="button-primary-style or-h-48-px or-head-md or-w-100-pc"
    [class.disabled-button]="!(selectedMembers$ | async)?.length"
  >
    Usuń użytkowników
  </button>
</div>

<or-loading></or-loading>
