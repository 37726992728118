import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { RatingDTO } from "../../../application/interfaces";

export const RATING_DTO: InjectionToken<RatingDtoPort> =
  new InjectionToken<RatingDtoPort>("RATING_DTO");

export interface RatingDtoPort {
  rateApplication(rating: RatingDTO): Observable<void>;
}
