export interface TeamDetailsDTO {
  readonly teamId: string;
  readonly name: string;
  readonly chatId: string;
  readonly isOwner: boolean;
  readonly owner: TeamPlayer;
  readonly members: TeamPlayer[];
}

export interface TeamPlayer {
  readonly userId: string;
  readonly userDisplayName: string;
  readonly isAutoApproved: boolean;
  readonly userThumbnail: string;
  readonly phone: string;
  readonly isOwner: boolean;
}
