import { NotificationsListQueryPort } from "../ports";
import { map, Observable } from "rxjs";
import { inject } from "@angular/core";
import {
  NotificationBody,
  NotificationResponseDTO,
  NotificationViewModel,
} from "../interfaces";
import { NOTIFICATIONS_DTO, NotificationsDTOPort } from "../../infrastructure";
import { NotificationStatusMapper } from "../mappers";

export class NotificationsListQueryHandler
  implements NotificationsListQueryPort
{
  private readonly notificationsDtoPort: NotificationsDTOPort =
    inject(NOTIFICATIONS_DTO);

  getNotificationsList(): Observable<NotificationViewModel[]> {
    return this.notificationsDtoPort.notificationsList().pipe(
      map((notifications: NotificationResponseDTO) =>
        notifications.notifications.map((notification: NotificationBody) => {
          return {
            title: NotificationStatusMapper[notification.notificationType],
            gameName: notification.payload.gameName,
            address: notification.payload.gameAddress,
            date: notification.payload.gameDate,
            hours: notification.payload.gameHours,
            avatar: notification.payload.thumbnailUrl,
            isRead: notification.isRead,
            id: notification.notificationId,
            type: notification.notificationType,
            createdAt: this.formatCreatedAt(new Date(notification.createdAt)),
            gameId: notification.payload.gameId,
            displayName: notification.payload.displayName,
            customId: notification.payload.customId,
            customRedirectUrl: notification.payload.customRedirectUrl,
          };
        })
      )
    );
  }

  private formatCreatedAt(date: Date) {
    const now: Date = new Date();
    const diffMs: number = now.getTime() - date.getTime();
    const diffHours: number = Math.floor(diffMs / (1000 * 60 * 60));

    const daysBetween: number =
      (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);

    if (daysBetween < 1) {
      if (diffHours < 1) {
        return "Mniej niż godzinę temu";
      }
      return `${diffHours} godz. temu`;
    } else if (daysBetween === 1) {
      return "Wczoraj";
    } else if (daysBetween > 1 && daysBetween <= 7) {
      return `${Math.floor(daysBetween)} dni temu`;
    } else {
      return "7+ dni temu";
    }
  }
}
