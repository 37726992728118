import { Observable } from "rxjs";
import { inject } from "@angular/core";
import { RemoveMembersFromChannelCommandPort } from "../ports";
import {
  CHAT_CHANEL_DTO,
  ChatDtoPort,
} from "../../infrastructure/http-service";

export class RemoveMembersFromChannelCommandHandler
  implements RemoveMembersFromChannelCommandPort
{
  private readonly chatService: ChatDtoPort = inject(CHAT_CHANEL_DTO);

  remove(channelId: string, userIds: string[]): Observable<void> {
    return this.chatService.removeUsersFromChannel(channelId, userIds);
  }
}
