<div
  *ngIf="(teamDetailsQuery$ | async)?.length"
  (click)="changeTeamsVisibility()"
  class="or-d-flex or-justify-content-between or-align-items-center or-px-24-px or-mt-20-px or-mb-12-px"
>
  <div class="or-head-lg">Twoje ekipy</div>
  <img
    [src]="
      isTeamsExpanded
        ? 'assets/icons/chevron-up.svg'
        : 'assets/icons/chevron-down-primary.svg'
    "
  />
</div>
<div *ngIf="isTeamsExpanded">
  <div
    *ngFor="let team of teamDetails$ | async"
    class="border-radius-8 or-bg-white or-cursor-pointer or-mx-16-px or-pb-12-px or-mb-16-px or-pt-4-px or-px-12-px z-index-1 card-box-shadow"
  >
    <lib-team-card [teamDetails]="team"></lib-team-card>
  </div>
</div>
