import { SaveFiltersCommandPort } from "../ports";
import {
  FILTERS_CONTEXT_PORT,
  FiltersContextPort,
} from "../../infrastructure/storages";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { FiltersValues } from "../models";
import { ShowFrom } from "@core";

export class SaveFiltersCommandHandler implements SaveFiltersCommandPort {
  private filtersContextPort: FiltersContextPort = inject(FILTERS_CONTEXT_PORT);

  saveFilters(filters: FiltersValues): Observable<void> {
    const mappedFilters: FiltersValues =
      filters.specificDaysType === ShowFrom.TODAY ||
      filters.specificDaysType === ShowFrom.TOMORROW ||
      filters.specificDaysType === ShowFrom.ALL
        ? this.setPropertiesToNull(filters)
        : this.setWeekdays(filters);

    return this.filtersContextPort.setFilters(mappedFilters);
  }

  defaultValue(): Observable<void> {
    return this.filtersContextPort.reset();
  }

  private setPropertiesToNull(data: FiltersValues): FiltersValues {
    return {
      ...data,
      specificWeekdays: [],
      specificDateFrom: null,
      specificDateTo: null,
    };
  }

  private setWeekdays(data: FiltersValues): FiltersValues {
    const preventToEmptyWeekdays = (data: FiltersValues) => {
      if (
        data.specificDaysType === ShowFrom.WEEKDAYS &&
        !data.specificWeekdays?.length
      ) {
        return { ...data, specificDaysType: ShowFrom.ALL };
      }
      return data;
    };

    if (data.specificDaysType === ShowFrom.WEEKDAYS)
      return preventToEmptyWeekdays(data);
    return data;
  }
}
