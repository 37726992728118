import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { OrAvatarComponent, TitileNavbarComponent } from "@ui-components";
import { Router } from "@angular/router";
import {
  BehaviorSubject,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from "rxjs";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import {
  MARK_AS_READ_NOTIFICATIONS_COMMAND,
  MarkAsReadNotificationsCommandPort,
  NOTIFICATIONS_LIST_QUERY,
  NotificationsListQueryPort,
} from "../../application/ports";
import { NotificationViewModel } from "../../application/interfaces";
import {
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { notificationRedirectUtil } from "../../application/utils";

@Component({
  selector: "lib-notifications-list",
  templateUrl: "./notifications-list.component.html",
  styleUrls: ["./notifications-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    CommonModule,
    IonicModule,
    OrAvatarComponent,
  ],
})
export class NotificationsListComponent {
  private readonly notificationsListQueryPort: NotificationsListQueryPort =
    inject(NOTIFICATIONS_LIST_QUERY);
  private readonly markAsReadNotificationsCommandPort: MarkAsReadNotificationsCommandPort =
    inject(MARK_AS_READ_NOTIFICATIONS_COMMAND);
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly notifications$: Observable<NotificationViewModel[]> =
    this.modalProvider.showLoading$().pipe(
      switchMap(() => this.notificationsListQueryPort.getNotificationsList()),
      take(1),
      tap(() => this.isPageLoaded$.next(true)),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1)
    );

  readonly newNotifications$: Observable<NotificationViewModel[]> =
    this.notifications$.pipe(
      take(1),
      map((notifications: NotificationViewModel[]) =>
        notifications.filter(
          (notification: NotificationViewModel) => !notification.isRead
        )
      ),
      tap((notifications: NotificationViewModel[]) => {
        const notificationsIds: string[] = notifications.map(
          (notification) => notification.id
        );
        this.markAsReadNotificationsCommandPort
          .markAsRead(notificationsIds)
          .pipe(take(1))
          .subscribe();
      }),
      shareReplay(1)
    );

  readonly pastNotifications$: Observable<NotificationViewModel[]> =
    this.notifications$.pipe(
      map((notifications: NotificationViewModel[]) =>
        notifications.filter(
          (notification: NotificationViewModel) => notification.isRead
        )
      ),
      shareReplay(1)
    );

  readonly isiOS: boolean = this.platformProvider.isiOS;

  redirectToGame(notification: NotificationViewModel): void {
    notificationRedirectUtil({
      type: notification.type,
      gameId: notification.gameId,
      router: this.router,
      customId: notification.customId,
      customRedirectUrl: notification.customRedirectUrl,
    });
  }
}
