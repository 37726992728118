import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import {
  BottomNavbarComponent,
  GameDetailsComponent,
  OrAvatarComponent,
  TitileNavbarComponent,
} from "@ui-components";
import {
  combineLatest,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from "rxjs";
import {
  GAME_DETAILS_QUERY,
  GameDetailsQueryPort,
} from "../../application/ports";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Params } from "@angular/router";
import { JoinButtonsComponent } from "../join-buttons/join-buttons.component";
import {
  GameDetailsModel,
  GamePlayersDTO,
  LocalRefreshService,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  provideLocalRefreshService,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { ManageMyGameComponent } from "../manage-my-game/manage-my-game.component";
import {
  CREATE_SINGLE_CHAT_COMMAND,
  CreateSingleChatCommandPort,
  provideChatService,
  provideCreateSingleChatCommand,
} from "@messages";

@Component({
  selector: "lib-smy-game-details",
  templateUrl: "./my-game-details.component.html",
  styleUrls: ["./my-game-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    CommonModule,
    JoinButtonsComponent,
    GameDetailsComponent,
    OrAvatarComponent,
  ],
  providers: [
    provideLocalRefreshService(),
    provideCreateSingleChatCommand(),
    provideChatService(),
    provideChatService(),
  ],
})
export class MyGameDetailsComponent {
  private readonly gameDetailsQueryPort: GameDetailsQueryPort =
    inject(GAME_DETAILS_QUERY);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly localRefreshService: LocalRefreshService =
    inject(LocalRefreshService);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly createSingleChatCommandPort: CreateSingleChatCommandPort =
    inject(CREATE_SINGLE_CHAT_COMMAND);

  readonly gameDetails$: Observable<GameDetailsModel> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.localRefreshService.refresh$),
      switchMap(() =>
        this.activatedRoute.params.pipe(
          switchMap((params: Params) =>
            this.gameDetailsQueryPort.getGameDetails(params["id"])
          )
        )
      ),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1)
    );

  readonly showPhoneNumber$: Observable<boolean> = combineLatest([
    this.gameDetails$,
    this.userDataProvider.userData$,
  ]).pipe(
    map(
      ([game, userData]: [GameDetailsModel, UserModel]) =>
        !!game?.players?.some(
          (player: GamePlayersDTO) => player?.userId === userData?.userId
        )
    )
  );

  readonly isiOS: boolean = this.platformProvider.isiOS;

  manageGame(): void {
    this.gameDetails$
      .pipe(
        take(1),
        switchMap((game: GameDetailsModel) =>
          this.modalProvider.showModal$({
            component: ManageMyGameComponent,
            componentProps: {
              game: game,
            },
            cssClass: "modal-auto",
            initialBreakpoint: 1,
            breakpoints: [0, 1],
          })
        )
      )
      .subscribe(() => this.localRefreshService.emit());
  }

  sendMessage(userId: string): void {
    this.createSingleChatCommandPort.create(userId).subscribe();
  }
}
