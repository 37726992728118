import { AvailableChannelsQueryPort } from "../ports/available-channels.query-port";
import {
  CHAT_CHANEL_DTO,
  ChatDtoPort,
} from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { map, Observable } from "rxjs";
import { ChatChanelDTO, ChatChanelResponseDTO } from "../interfaces";

export class AvailableChannelsQueryHandler
  implements AvailableChannelsQueryPort
{
  private readonly chatDtoPort: ChatDtoPort = inject(CHAT_CHANEL_DTO);

  availableChannels(): Observable<ChatChanelDTO[]> {
    return this.chatDtoPort
      .availableChannels()
      .pipe(map((response: ChatChanelResponseDTO) => response.channels));
  }
}
