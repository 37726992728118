import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { EMPTY, Observable, switchMap, take } from "rxjs";
import { AppRoutes } from "src/app/app-routes.enum";
import {
  IsAuthedService,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent,
} from "@core";
import { MobileLandingComponent } from "@ui-components";
import { MessagesRoutes } from "../../messages-routes.enum";
import { AsyncPipe, NgIf } from "@angular/common";
import { provideAddToChannelCommand } from "../../application/handlers";
import {
  ADD_TO_CHANNEL_COMMAND,
  AddToChannelCommandPort,
} from "../../application/ports";

@Component({
  selector: "lib-channel-invitations",
  templateUrl: "./channel-invitations.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MobileLandingComponent, AsyncPipe, NgIf],
  providers: [provideAddToChannelCommand()],
})
export class ChannelInvitationsComponent implements OnInit {
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly isAuthed: IsAuthedService = inject(IsAuthedService);
  private readonly addToChannelCommandPort: AddToChannelCommandPort = inject(
    ADD_TO_CHANNEL_COMMAND
  );

  public redirectPath: string = `${AppRoutes.MESSAGES}/${MessagesRoutes.CHANNEL_INVITATIONS}`;

  get allowToRun() {
    return this.platformProvider.isNativeRun;
  }

  ngOnInit(): void {
    this.isAuthed.isAuthed$
      .pipe(
        take(1),
        switchMap((isAuthed: boolean) => {
          if (isAuthed && this.allowToRun) {
            this.router.navigateByUrl(AppRoutes.ROOT);

            return this.activatedRoute.params.pipe(
              take(1),
              switchMap((params: Params) => {
                const id: string = params["id"]?.split("?")?.[0];
                return this.addToChannelCommandPort.add(id);
              }),
              switchMap((res: { accepted: boolean }) =>
                this.showSuccessMessage(res.accepted)
              )
            );
          }

          if (!isAuthed && this.allowToRun)
            return this.router.navigateByUrl(AppRoutes.AUTH);

          return EMPTY;
        })
      )
      .subscribe();
  }

  private showSuccessMessage(accepted: boolean): Observable<void> {
    return this.modalProvider.showModal$({
      component: PresentModalComponent,
      cssClass: "present-modal",
      componentProps: {
        header: accepted ? "Zostałeś dodany do chatu gierki!" : "Błąd",
        message: accepted ? "" : "Nie zostałeś dodany do chatu.",
        btnTxt: "Rozumiem, dzięki!",
      },
    });
  }
}
