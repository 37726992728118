import { importProvidersFrom, NgModule } from "@angular/core";
import { MessagesRoutingModule } from "./messages-routing.module";
import { ChatInitializer } from "./application/initializers";
import { TranslateModule } from "@ngx-translate/core";
import {
  StreamAutocompleteTextareaModule,
  StreamI18nService,
} from "stream-chat-angular";
import { provideChatService } from "./infrastructure/http-service";

@NgModule({
  imports: [MessagesRoutingModule, StreamAutocompleteTextareaModule],
  providers: [
    importProvidersFrom(TranslateModule.forRoot({ defaultLanguage: "pl" })),
    ChatInitializer,
    StreamI18nService,
    provideChatService(),
  ],
})
export class MessagesModule {}
