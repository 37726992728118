<div class="or-mr-8-px">
  <ion-avatar
    *ngIf="avatarUrl && !base64"
    [ngClass]="
      isProfile
        ? 'custom-avatar-lg'
        : isNotifications
        ? 'custom-avatar-md'
        : 'custom-avatar-sm'
    "
  >
    <img
      [src]="avatarUrl"
      class="custom-avatar-img"
      [class.avatar-white-border]="showWhiteBorder"
    />
  </ion-avatar>

  <div
    *ngIf="!avatarUrl && initials && !base64"
    class="or-bg-focus or-color-primary"
    [class.avatar-white-border]="showWhiteBorder"
    [ngClass]="
      isProfile
        ? 'custom-avatar-lg or-head-xl'
        : isNotifications
        ? 'custom-avatar-md or-head-xl'
        : 'custom-avatar-sm or-head-md'
    "
  >
    {{ getInitials }}
  </div>

  <div
    *ngIf="base64 && !avatarUrl && !initials"
    [ngClass]="
      isProfile
        ? 'custom-avatar-lg'
        : isNotifications
        ? 'custom-avatar-md'
        : 'custom-avatar-sm'
    "
  >
    <img
      [src]="base64"
      class="custom-avatar-img"
      [class.avatar-white-border]="showWhiteBorder"
    />
  </div>
</div>
