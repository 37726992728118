import { TeamRole } from "../enums";
import { ChannelDataDTO } from "@core";

export interface TeamDTO {
  readonly teamId: string;
  readonly teamName: string;
  readonly thumbnail: string;
  readonly host: TeamHostDTO;
  readonly memberRole: TeamRole;
  readonly members: TeamMemberDTO[];
  readonly channel: ChannelDataDTO;
  readonly invitationToken: string;
}

export interface TeamMemberDTO {
  readonly userId: string;
  readonly displayName: string;
  readonly thumbnail: string;
  readonly isAutoApproved: boolean;
  readonly memberRole: TeamRole;
}

export interface TeamHostDTO {
  readonly hostId: string;
  readonly displayName: string;
  readonly thumbnail: string;
  readonly phone: string;
}
