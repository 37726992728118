import { inject } from "@angular/core";
import { GamesListQueryPort } from "../ports";
import { map, Observable, switchMap } from "rxjs";
import {
  NEARBY_GAMES_DTO,
  NearbyGamesDtoPort,
} from "../../infrastructure/http-services";
import {
  FILTERS_CONTEXT_PORT,
  FiltersContextPort,
} from "../../infrastructure/storages";
import { FiltersValues } from "../models";
import { GameDTO } from "@core";
import { parseFiltersData } from "../utils";
import { GamesListModel } from "../interfaces";
import { format, parseISO } from "date-fns";
import { pl } from "date-fns/locale";

export class GamesListQueryHandler implements GamesListQueryPort {
  private nearbyGamesDtoPort: NearbyGamesDtoPort = inject(NEARBY_GAMES_DTO);
  private filtersContextPort: FiltersContextPort = inject(FILTERS_CONTEXT_PORT);

  getGames(): Observable<GamesListModel[]> {
    return this.filtersContextPort.filters$.pipe(
      map((filters: FiltersValues) => parseFiltersData(filters)),
      switchMap((params: string) =>
        this.nearbyGamesDtoPort.getGamesList(params)
      ),
      map((games: { games: GameDTO[] }) => {
        const groupedGames: Record<string, GameDTO[]> = this.groupByDate(
          games.games
        );

        return Object.keys(groupedGames)
          .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
          .map((date) => ({
            date: this.formatDate(date),
            games: groupedGames[date],
          }));
      })
    );
  }

  private groupByDate(games: GameDTO[]): Record<string, GameDTO[]> {
    return games.reduce((acc, game) => {
      const date: string = game.gameDateStart.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(game);
      return acc;
    }, {} as Record<string, GameDTO[]>);
  }

  formatDate(dateString: string): string {
    const date: Date = parseISO(dateString);
    const formattedDate: string = format(date, "EEEE, dd.MM.yyyy", {
      locale: pl,
    });

    return this.capitalizeFirstLetter(formattedDate);
  }

  private capitalizeFirstLetter(date: string): string {
    return date?.charAt(0).toUpperCase() + date.slice(1);
  }
}
