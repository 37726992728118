import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import * as Sentry from "@sentry/angular-ivy";
import { environment } from "./environment/environment";

Sentry.init({
  dsn: "https://92bb45371de7e112a2a83cbf6bc2948d@o4507171930898433.ingest.de.sentry.io/4507172141989968",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  environment: environment.environment,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api\.staging\.orlikfy\.pl\/.*/,
    /^https:\/\/api\.prod\.orlikfy\.pl\/.*/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
