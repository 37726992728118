import { AngularFirestore } from "@angular/fire/compat/firestore";
import { inject } from "@angular/core";
import { map, Observable } from "rxjs";
import { firestoreGetDataUtil } from "@core";

export class VersionInfoService {
  private readonly firestore: AngularFirestore = inject(AngularFirestore);

  newestVersion(): Observable<string> {
    return firestoreGetDataUtil<{ version: string }>(
      this.firestore,
      "version",
      "current-version"
    ).pipe(map((data: { version: string }) => data.version));
  }

  availableVersions(): Observable<string[]> {
    return firestoreGetDataUtil<{ versions: string[] }>(
      this.firestore,
      "version",
      "available-versions"
    ).pipe(map((version: { versions: string[] }) => version.versions));
  }
}
