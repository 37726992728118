<lib-title-navbar title="Tworzenie nowej grupki"></lib-title-navbar>

<div class="or-p-20-px or-mt-12-px">
  <div
    [ngClass]="
      isiOS
        ? 'full-height-scrollable-container-ios'
        : 'full-height-scrollable-container'
    "
  >
    <div class="or-head-xl">W Orlikfy możesz prowadzić swoją grupkę.</div>
    <div class="or-color-secondary-2 or-my-16-px or-note-sm">
      Dzięki grupce, możesz:
    </div>

    <div class="or-d-flex or-align-items-start or-mb-20-px">
      <img src="assets/icons/teams-message.svg" class="or-mr-12-px" />
      <div>
        <div class="or-head-sm or-mb-4-px">Prowadzić Chat Grupki</div>
        <div class="or-color-secondary-2 or-note-sm">
          Przenieś swoją ekipę i prowadź rozmowy w aplikacji.
        </div>
      </div>
    </div>

    <div class="or-d-flex or-align-items-start or-mb-20-px">
      <img src="assets/icons/teams-invite.svg" class="or-mr-12-px" />
      <div>
        <div class="or-head-sm or-mb-4-px">
          Automatyczne zapraszanie ludzi z grupki
        </div>
        <div class="or-color-secondary-2 or-note-sm">
          Gracze z grupki będą automatycznie zapraszani do Twoich gierek.
        </div>
      </div>
    </div>

    <div class="or-d-flex or-align-items-start">
      <img src="assets/icons/teams-favourite.svg" class="or-mr-12-px" />
      <div>
        <div class="or-head-sm or-mb-4-px">Wybierz ulubionych graczy</div>
        <div class="or-color-secondary-2 or-note-sm">
          Ulubieni gracze zaznaczeni przez Ciebie nie będą potrzebować Twojej
          akceptacji. Sami dodadzą się do gierki - pierwszeństwo dla
          największych weteranów!
        </div>
      </div>
    </div>

    <div class="or-color-secondary-2 or-note-sm or-mt-36-px">
      Zamiast korzystać z Messengera, WhatsApp, Facebooka i Excela, miej
      wszystko w jednej aplikacji - skorzystaj z Orlikfy!
    </div>

    <div class="custom-divider or-my-32-px or-w-100-pc"></div>

    <form
      [formGroup]="createTeamForm"
      class="or-d-flex or-flex-col or-justify-content-center"
    >
      <div class="or-head-xs or-color-secondary-2 or-mb-4-px">Nazwa grupki</div>
      <input
        class="outline-none input-style or-mb-12-px"
        formControlName="name"
        [ngClass]="{ 'error-field': isFieldInvalid('name') }"
      />
      <div
        *ngIf="isNameOverAvailableLength"
        class="or-text-sm or-color-red or-mt-2-px"
      >
        Maksymalna długość to 15 znakówc
      </div>
    </form>

    <div
      class="or-text-sm or-text-align-center or-color-secondary-2 or-mt-16-px"
    >
      Nazwa grupki będzie również nazwą Waszego chatu. Znajdziesz go w zakładce
      “Wiadomości”
    </div>
  </div>

  <button
    (click)="createTeam()"
    class="button-primary-style or-h-48-px or-w-100-pc or-head-md"
    [ngClass]="{ 'disabled-button': !createTeamForm.valid }"
  >
    Stwórz swoją grupkę!
  </button>
</div>
