import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { GameDetailsModel } from "@core";

export const GET_FUTURE_GAMES_QUERY: InjectionToken<GetFutureGamesQueryPort> =
  new InjectionToken<GetFutureGamesQueryPort>("GET_FUTURE_GAMES_QUERY");

export interface GetFutureGamesQueryPort {
  getApproved(): Observable<GameDetailsModel[]>;
  getWaiting(): Observable<GameDetailsModel[]>;
  getInvitations(): Observable<GameDetailsModel[]>;
}
