import { UpdateUserCommandPort } from "../ports";
import { USER_DTO, UserDTOPort } from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { UpdateUserDTO } from "../interfaces";

export class UpdateUserCommandHandler implements UpdateUserCommandPort {
  private readonly userDTOPort: UserDTOPort = inject(USER_DTO);

  update(userData: UpdateUserDTO): Observable<void> {
    return this.userDTOPort.updateUser({
      ...userData,
      phone: userData.phone ? `+48${userData.phone}` : null,
    });
  }
}
