<div class="or-p-16-px or-pt-40-px or-bg-shade">
  <div class="or-head-xs or-color-secondary-2 or-mb-12-px">Opcje</div>
  <div
    [navigate]="game.location.coordinates"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
  >
    <img
      src="assets/icons/navigate-icon.svg"
      class="or-mr-8-px or-size-32-px"
    />
    <div class="or-text-md">Nawiguj do miejsca gierki</div>
  </div>

  <div
    *ngIf="lineupsAvailable$ | async"
    (click)="goToLineups()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
  >
    <img
      src="assets/icons/randomize-shade.svg"
      class="or-mr-8-px or-size-32-px"
    />
    <div class="or-text-md">Zobacz składy!</div>
  </div>

  <div
    (click)="leaveGameModal()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
  >
    <img src="assets/icons/trash-icon.svg" class="or-mr-8-px" />
    <div class="or-text-md or-color-red">Opuść gierkę</div>
  </div>
</div>
