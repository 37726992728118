<lib-title-navbar title="Panel Admina"></lib-title-navbar>
<div class="or-mt-28-px or-mx-28-px">
  <button
    (click)="goToDeveloperSettings()"
    class="button-primary-style or-h-48-px or-w-100-pc or-head-md or-mb-12-px"
  >
    Developer settings
  </button>

  <button
    (click)="goToUsers()"
    class="button-primary-style or-h-48-px or-w-100-pc or-head-md or-mb-12-px"
  >
    Użytkownicy
  </button>

  <button
    (click)="goToGames()"
    class="button-primary-style or-h-48-px or-w-100-pc or-head-md or-mb-12-px"
  >
    Gierki
  </button>

  <button
    (click)="goToGeneratePost()"
    class="button-primary-style or-h-48-px or-w-100-pc or-head-md or-mb-12-px"
  >
    Generuj posty
  </button>
</div>
