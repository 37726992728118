import { Routes } from "@angular/router";
import {
  FindGamesHomeComponent,
  GamesListComponent,
  MapComponent,
} from "./components";

export const FindGamesRouting: Routes = [
  {
    path: "",
    component: FindGamesHomeComponent,
    children: [
      {
        path: "",
        component: GamesListComponent,
      },
      {
        path: "map",
        component: MapComponent,
      },
    ],
  },
];
