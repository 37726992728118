<lib-home-navbar
  (onFiltersOpened)="openFilters()"
  (onAddGameOpened)="goToGameCreator()"
  [isNewNotifications]="!!(isNewNotifications$ | async)"
  [isFiltersApplied]="!!(isFiltersApplied$ | async)"
></lib-home-navbar>
<div
  class="header-bg-color or-d-flex or-justify-content-evenly margin-top--1-px"
>
  <div
    (click)="goToList()"
    class="or-head-xl or-py-12-px or-px-8-px"
    [ngClass]="
      !isMapActive
        ? 'or-color-white bottom-border-active'
        : 'or-color-secondary-2'
    "
  >
    Lista
  </div>
  <div
    (click)="goToMap()"
    class="or-head-xl or-py-12-px or-px-8-px"
    [ngClass]="
      isMapActive
        ? 'or-color-white bottom-border-active'
        : 'or-color-secondary-2'
    "
  >
    Mapa
  </div>
</div>

<router-outlet></router-outlet>

<div class="or-position-relative or-w-100-pc">
  <lib-bottom-navbar></lib-bottom-navbar>
</div>
