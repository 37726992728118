import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { AdvancedInvitationsDTO } from "../../../application/interfaces";

export const ADVANCED_INVITATIONS_CONTEXT: InjectionToken<AdvancedInvitationsContextPort> =
  new InjectionToken<AdvancedInvitationsContextPort>(
    "ADVANCED_INVITATIONS_CONTEXT"
  );

export interface AdvancedInvitationsContextPort {
  state$: Observable<AdvancedInvitationsDTO>;
  patchContext(state: Partial<AdvancedInvitationsDTO>): Observable<void>;
}
