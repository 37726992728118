import { firebaseStageCredentials } from "../secrets/firebase-stage";
import {
  CHAT_TOKEN_STAGE,
  MAP_API_KEY,
  MAPBOX_TOKEN,
} from "../secrets/api-keys";

export const environment = {
  environment: "stage",
  production: false,
  apiUrl: "https://api.staging.orlikfy.pl",
  frontendUrl: "https://app.staging.orlikfy.pl",
  customUrl: "orlikfy://app.staging.orlikfy.pl",
  domain: "app.staging.orlikfy.pl",
  androidApkUrl:
    "https://storage.googleapis.com/orlikfy-infra-stage.appspot.com/android-apk/app-release.apk",
  iOSApkUrl: "",
  firebase: firebaseStageCredentials,
  mapApiKey: MAP_API_KEY,
  mapboxToken: MAPBOX_TOKEN,
  chatToken: CHAT_TOKEN_STAGE,
  packageName: "com.orlikfy.stage",
  deepLinkUriPrefix: "orlikfy.page.link",
};
