import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { CustomPlayerModel } from "../../interfaces";

export const ADD_PLAYER_COMMAND: InjectionToken<AddPlayerCommandPort> =
  new InjectionToken<AddPlayerCommandPort>("ADD_PLAYER_COMMAND");

export interface AddPlayerCommandPort {
  addPlayer(gameId: string, player: CustomPlayerModel): Observable<void>;
}
