import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { RatingDTO } from "../../interfaces";

export const RATE_APP_COMMAND: InjectionToken<RateApplicationCommandPort> =
  new InjectionToken<RateApplicationCommandPort>("RATE_APP_COMMAND");

export interface RateApplicationCommandPort {
  rateApp(rating: RatingDTO): Observable<void>;
}
