import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { AlertsSettingsDTO } from "../../../application/interfaces";
import { CoordinatesDTO } from "@core";

export const NOTIFICATIONS_ALERTS_DTO: InjectionToken<NotificationsAlertsDTOPort> =
  new InjectionToken<NotificationsAlertsDTOPort>("NOTIFICATIONS_ALERTS_DTO");

export interface NotificationsAlertsDTOPort {
  getAlerts(): Observable<AlertsSettingsDTO>;
  updateAlerts(settings: AlertsSettingsDTO): Observable<void>;
  switchAlerts(value: boolean): Observable<void>;
  setLocation(coordinates: CoordinatesDTO | null): Observable<void>;
  setPushStatus(value: boolean): Observable<void>;
}
