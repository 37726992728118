<lib-title-navbar title="Wiadomości"></lib-title-navbar>

<div class="top-container"></div>

<div
  class="or-mx-48-px or-d-flex or-justify-content-center margin-top--24-px or-pb-8-px"
>
  <div
    class="or-h-52-px or-w-144-px or-max-w-144-px button-border-left or-d-flex or-justify-content-center or-align-items-center or-text-align-center or-head-sm"
    (click)="openMulti()"
    [ngClass]="
      (isMultiActive$ | async)
        ? 'or-bg-primary or-color-white'
        : 'or-color-secondary or-bg-white'
    "
  >
    <img
      *ngIf="isUnreadMulti$ | async"
      src="assets/icons/unread-icon.svg"
      class="or-position-absolute z-index-999 or-ml-112-px or-mb-24-px"
    />
    Grupowe
  </div>

  <div
    class="or-h-52-px or-w-144-px or-max-w-144-px button-border-right or-d-flex or-justify-content-center or-align-items-center or-text-align-center or-head-sm"
    (click)="openSingle()"
    [ngClass]="
      !(isMultiActive$ | async)
        ? 'or-bg-primary or-color-white'
        : 'or-color-secondary or-bg-white'
    "
  >
    <img
      *ngIf="isUnreadSingle$ | async"
      src="assets/icons/unread-icon.svg"
      class="or-position-absolute z-index-999 or-ml-112-px or-mb-24-px"
    />
    Pojedyncze
  </div>
</div>

<div
  *ngIf="isChatLoaded$ | async"
  [ngClass]="
    isiOS
      ? 'full-height-scrollable-container-ios'
      : 'full-height-scrollable-container'
  "
>
  <stream-channel-list
    (click)="openChannel()"
    *ngIf="isChatAvailable$ | async; else emptyChatList"
  ></stream-channel-list>
</div>
<lib-bottom-navbar></lib-bottom-navbar>

<ng-template #emptyChatList>
  <div
    class="or-justify-content-center or-d-flex or-flex-col or-h-100-pc or-px-32-px"
  >
    <div class="or-text-align-center or-head-lg">Twój chat jest pusty.</div>
    <div class="or-text-align-center or-text-lg">
      Dołącz do gierki lub stwórz swoją. Chat pojawi się automatycznie.
    </div>
  </div>
</ng-template>
