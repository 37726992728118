import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { NerabyGamesDTO } from "@core";

export const GET_MAP_MARKERS: InjectionToken<GetMapMarkersQueryPort> =
  new InjectionToken<GetMapMarkersQueryPort>("GET_MAP_MARKERS");

export interface GetMapMarkersQueryPort {
  getMapMarkers(): Observable<{ locations: NerabyGamesDTO[] }>;
}
