import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from "@angular/core";
import {
  BottomNavbarComponent,
  GamesMenuComponent,
  OrAvatarComponent,
  TitileNavbarComponent,
} from "@ui-components";
import {
  UPDATE_USER_COMMAND,
  UpdateUserCommandPort,
} from "../../application/ports";
import { BehaviorSubject, Observable, take, tap } from "rxjs";
import { CommonModule } from "@angular/common";
import { provideUserService } from "../../infrastructure/http-service";
import { provideUpdateUserCommand } from "../../application/handlers";
import { IonicModule } from "@ionic/angular";
import { Router } from "@angular/router";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { AppRoutes } from "src/app/app-routes.enum";
import {
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import {
  REMOVE_ACCOUNT_COMMAND,
  RemoveAccountCommandPort,
} from "../../../../../auth/src/lib/application/ports";

interface Base64imageModel {
  readonly isAvailable: boolean;
  readonly base64: string;
}

@Component({
  selector: "lib-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    GamesMenuComponent,
    TitileNavbarComponent,
    BottomNavbarComponent,
    IonicModule,
    ReactiveFormsModule,
    OrAvatarComponent,
  ],
  providers: [provideUserService(), provideUpdateUserCommand()],
})
export class EditProfileComponent implements OnInit {
  public isRemoveAccountMode: boolean = false;

  readonly fileExtensions: string = ".png, .jpg, .jpeg";

  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly updateUserCommandPort: UpdateUserCommandPort =
    inject(UPDATE_USER_COMMAND);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly removeAccountCommandPort: RemoveAccountCommandPort = inject(
    REMOVE_ACCOUNT_COMMAND
  );

  private base64imageSubject$: BehaviorSubject<Base64imageModel> =
    new BehaviorSubject<Base64imageModel>({ isAvailable: false, base64: "" });

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly profileForm: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.maxLength(15)]),
    lastName: new FormControl("", [Validators.maxLength(15)]),
    phone: new FormControl("", [
      Validators.minLength(9),
      Validators.maxLength(9),
      Validators.pattern("^[0-9]*$"),
    ]),
    avatar: new FormControl(),
  });

  readonly removeAccountForm: FormGroup = new FormGroup({
    password: new FormControl(),
  });

  readonly userData$: Observable<UserModel> = this.userDataProvider.userData$;

  readonly base64image$: Observable<Base64imageModel> =
    this.base64imageSubject$.asObservable();

  get isNameOverAvailableLength() {
    return this.profileForm.get("name")?.value?.length > 15;
  }

  get isLastNameOverAvailableLength() {
    return this.profileForm.get("lastName")?.value?.length > 15;
  }

  ngOnInit(): void {
    this.userData$
      .pipe(
        take(1),
        tap((userData: UserModel) =>
          this.profileForm.patchValue({
            name: userData?.name,
            lastName: userData?.surname,
            phone: userData?.phone,
          })
        )
      )
      .subscribe();
  }

  saveData(): void {
    if (this.isRemoveAccountMode) return;

    if (this.profileForm.valid) {
      this.updateUserCommandPort
        .update({
          name: this.profileForm.get("name")?.value,
          surname: this.profileForm.get("lastName")?.value,
          phone: this.profileForm.get("phone")?.value,
          thumbnail: this.profileForm.get("avatar")?.value,
        })
        .pipe(
          take(1),
          tap(() => this.userDataProvider.setUserData())
        )
        .subscribe({
          error: () => this.imageSizeError(),
          next: () => this.goToProfile(),
        });
    }
  }

  goToProfile(): void {
    this.router.navigate([AppRoutes.PROFILE]);
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.profileForm.get(fieldName);
    return control ? control.touched && control.invalid : false;
  }

  changeAvatar(event: Event): void {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;
    const file: File | null = inputElement.files ? inputElement.files[0] : null;

    if (!file) return;

    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const imageBase64: string = reader.result as string;

      const estimatedSize: number =
        (imageBase64.replace(/[\s=]/g, "").length * 3) / 4;

      if (estimatedSize < 2000000) {
        this.base64imageSubject$.next({
          isAvailable: true,
          base64: imageBase64,
        });
        this.profileForm.patchValue({ avatar: imageBase64 });
      } else {
        this.imageSizeError();
      }
    };
  }

  private imageSizeError() {
    this.profileForm.patchValue({ avatar: null });
    this.base64imageSubject$.next({ isAvailable: false, base64: "" });

    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: "Błąd",
        message: "Avatar jest zbyt duży. Maksymalny rozmiar to 2MB.",
        btnTxt: "Zamknij",
      },
      cssClass: "present-modal",
    });
  }

  removeAcoountMode(): void {
    this.isRemoveAccountMode = true;
  }

  deleteAccount(): void {
    this.removeAccountCommandPort
      .removeAccount(this.removeAccountForm.get("password")?.value)
      .pipe(take(1))
      .subscribe(() => this.router.navigate([AppRoutes.AUTH]));
  }
}
