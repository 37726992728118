import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { GameDetailsModel } from "@core";

export const GET_HOSTED_PAST_GAMES_QUERY: InjectionToken<GetHostedPastGamesQueryPort> =
  new InjectionToken<GetHostedPastGamesQueryPort>(
    "GET_HOSTED_PAST_GAMES_QUERY"
  );

export interface GetHostedPastGamesQueryPort {
  getGames(): Observable<Map<string, GameDetailsModel[]>>;
}
