import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { CreateGameDTO, CreateGameResponseViewModel } from "../../interfaces";

export const UPDATE_GAME_COMMAND: InjectionToken<UpdateGameCommandPort> =
  new InjectionToken<UpdateGameCommandPort>("UPDATE_GAME_COMMAND");

export interface UpdateGameCommandPort {
  updateGame(
    gameId: string,
    game: Partial<CreateGameDTO>
  ): Observable<CreateGameResponseViewModel>;
}
