import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from "@angular/core";
import {
  CopyService,
  CopyValueToClipboardDirective,
  GameDetailsModel,
  MODAL_TOKEN,
  ModalProvider,
  NavigateDirective,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { IonicModule } from "@ionic/angular";
import { OrAvatarComponent } from "../or-avatar/or-avatar.component";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { ShareGameDirective } from "../share-game";
import { map, Observable } from "rxjs";
import { VisibilityEnum } from "../../../../hosted-games/src/lib/application/enums";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { GamesRoutes } from "../../../../games/src/lib/games-routes.enum";

@Component({
  selector: "lib-game-details",
  templateUrl: "./game-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ShareGameDirective,
    IonicModule,
    OrAvatarComponent,
    CopyValueToClipboardDirective,
    NgIf,
    NavigateDirective,
    NgClass,
    AsyncPipe,
  ],
  providers: [CopyService],
})
export class GameDetailsComponent {
  @Input() game!: GameDetailsModel;
  @Input() showHostInfo: boolean = false;
  @Input() showPhoneNumber: boolean = true;
  @Input() showMessage: boolean = true;
  @Input() showNavigateLink: boolean = false;
  @Input() showGameTypeDetails: boolean = false;
  @Input() adminAccess: boolean = false;
  @Output() onSendMessage: EventEmitter<string> = new EventEmitter<string>();

  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly isMyGame$: Observable<boolean> =
    this.userDataProvider.userData$.pipe(
      map((userData: UserModel) => userData.userId === this.game.host?.hostId)
    );

  get gameType(): string {
    return this.game.isRecurring ? "Powtarzalna" : "Pojedyncza";
  }

  get gameVisibilityLabel(): string {
    return this.game.visibility === VisibilityEnum.PRIVATE
      ? "Ukryta"
      : "Widoczna";
  }

  sendMessage(userId: string | undefined, event: Event): void {
    event.stopImmediatePropagation();
    this.onSendMessage.emit(userId);
  }

  showAdminDetails(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigateByUrl(
      `${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${this.game.gameId}`
    );
  }
}
