import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import { Observable } from "rxjs";
import { AdminGamesDtoPort } from "./admin-games.dto-port";
import { AdminGamesDTO } from "../../../application/models";

export class AdminGamesService implements AdminGamesDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getAllGames(daysBefore: number): Observable<AdminGamesDTO> {
    return this.httpClient.get<AdminGamesDTO>(
      `${this.env.apiUrl}/admin/games?daysBefore=${daysBefore}`
    );
  }
}
