import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { NotificationsRoutes } from "@notifications";
import { AsyncPipe, NgIf } from "@angular/common";
import { Observable } from "rxjs";
import {
  FEATURE_FLAG_TOKEN,
  FeatureFlag,
  FeatureFlagProvider,
} from "@feature-flags";
import { TeamsRoutes } from "@teams";

@Component({
  selector: "lib-dashboard-navbar",
  templateUrl: "./dashboard-navbar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe],
})
export class DashboardNavbarComponent {
  private readonly router: Router = inject(Router);
  private readonly featureFlagProvider: FeatureFlagProvider =
    inject(FEATURE_FLAG_TOKEN);

  readonly isTeamEnabled$: Observable<boolean> =
    this.featureFlagProvider.isEnabled$(FeatureFlag.TEAMS);

  goToHosted(): void {
    this.router.navigateByUrl(AppRoutes.HOST);
  }

  goToGames(): void {
    this.router.navigateByUrl(AppRoutes.GAMES);
  }

  goToAlerts(): void {
    this.router.navigateByUrl(
      `${AppRoutes.NOTIFICATIONS}/${NotificationsRoutes.ALERTS}`
    );
  }

  createTeam(): void {
    this.router.navigateByUrl(`${AppRoutes.TEAMS}/${TeamsRoutes.CREATE}`);
  }
}
