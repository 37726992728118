import { DeclineInvitationCommandPort } from "../ports";
import {
  INVITATIONS_DTO,
  InvitationsDtoPort,
} from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { Observable } from "rxjs";

export class DeclineInvitationCommandHandler
  implements DeclineInvitationCommandPort
{
  private readonly invitationsDtoPort: InvitationsDtoPort =
    inject(INVITATIONS_DTO);

  decline(invitaionId: string): Observable<void> {
    return this.invitationsDtoPort.decline(invitaionId).pipe();
  }
}
