import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TitileNavbarComponent } from "@ui-components";
import { getNewVersionWebAndroid, getNewVersionWebiOS } from "@update";

@Component({
  selector: "lib-block-web-version",
  templateUrl: "./block-web-version.component.html",
  styleUrls: ["./block-web-version.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent],
})
export class BlockWebVersionComponent {
  downloadAndroid(): void {
    getNewVersionWebAndroid();
  }

  downloadiOS(): void {
    getNewVersionWebiOS();
  }
}
