import { AngularFirestore } from "@angular/fire/compat/firestore";
import { inject } from "@angular/core";
import { map, Observable } from "rxjs";
import { IntroductionEnum } from "../application/enums";
import { arrayUnion } from "@angular/fire/firestore";
import { IntroductionDtoPort } from "./introduction.dto-port";
import { firestoreGetDataUtil } from "@core";

export class IntroductionService implements IntroductionDtoPort {
  private readonly firestore: AngularFirestore = inject(AngularFirestore);

  isViewed(introId: IntroductionEnum, userId: string): Observable<boolean> {
    return firestoreGetDataUtil<{ viewed: string[] }>(
      this.firestore,
      "introduction",
      userId
    ).pipe(
      map((data: { viewed: string[] }) => data?.viewed?.includes(introId))
    );
  }

  markAsViewed(introId: IntroductionEnum, userId: string): Promise<void> {
    const userDocRef = this.firestore.collection("introduction").doc(userId);
    return userDocRef.set(
      {
        viewed: arrayUnion(introId),
      },
      { merge: true }
    );
  }
}
