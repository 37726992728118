import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const MARK_AS_READ_NOTIFICATIONS_COMMAND: InjectionToken<MarkAsReadNotificationsCommandPort> =
  new InjectionToken<MarkAsReadNotificationsCommandPort>(
    "MARK_AS_READ_NOTIFICATIONS_COMMAND"
  );

export interface MarkAsReadNotificationsCommandPort {
  markAsRead(ids: string[]): Observable<void>;
}
