export const chatTranslation = {
  "1 reply": "1 odpowiedź",
  "Attach files": "Załącz pliki",
  Cancel: "Anuluj",
  "Channel Missing": "Brak kanału",
  Close: "Zamknij",
  "Close emoji picker": "Zamknij wybierak emotikon",
  "Commands matching": "Pasujące komendy",
  "Connection failure, reconnecting now...":
    "Błąd połączenia, ponowne łączenie...",
  Delete: "Usuń",
  Delivered: "Dostarczono",
  "Edit Message": "Edytuj wiadomość",
  "Edit message request failed": "Nie udało się edytować wiadomości",
  "Emoji matching": "Pasujące emotikony",
  "Empty message...": "Pusta wiadomość...",
  "Error adding flag": "Błąd dodawania flagi",
  "Error connecting to chat, refresh the page to try again.":
    "Błąd połączenia z czatem, odśwież stronę, aby spróbować ponownie",
  "Error deleting message": "Błąd usuwania wiadomości",
  "Error loading reactions": "Błąd ładowania reakcji",
  "Error muting a user ...": "Błąd wyciszania użytkownika...",
  "Error pinning message": "Błąd przypinania wiadomości",
  "Error removing message pin": "Błąd usuwania przypięcia wiadomości",
  "Error unmuting a user ...": "Błąd wyłączania wyciszenia użytkownika...",
  "Error uploading file": 'Błąd przesyłania pliku "{{ name }}"',
  "Error uploading file, maximum file size exceeded":
    'Błąd przesyłania "{{ name }}", maksymalny rozmiar pliku {{ limit }} przekroczony',
  "Error uploading file, extension not supported":
    'Błąd przesyłania "{{ name }}", typ {{ ext }} nie jest obsługiwany',
  "Error deleting attachment": "Błąd usuwania załącznika",
  "Error · Unsent": "Wiadomość nie mogła zostać wysłana",
  "Error: {{ errorMessage }}": "Błąd: {{ errorMessage }}",
  Flag: "Zgłoś",
  "Message Failed": "Wiadomość nieudana",
  "Message Failed · Unauthorized": "Nieautoryzowany do wysyłania wiadomości",
  "Message Failed · Click to try again":
    "Wiadomość nie mogła zostać wysłana, kliknij, aby spróbować ponownie",
  "Message deleted": "Wiadomość usunięta",
  "Message has been successfully flagged":
    "Wiadomość została pomyślnie zgłoszona",
  "Message pinned": "Wiadomość przypięta",
  "Message unpinned": "Wiadomość odpięta",
  Mute: "Wycisz",
  New: "Nowe",
  "New Messages!": "Nowe wiadomości!",
  "No results found": "Nie znaleziono wyników",
  "Nothing yet...": "",
  "Only visible to you": "Widoczne tylko dla ciebie",
  "Open emoji picker": "Otwórz wybierak emotikon",
  "People matching": "Pasujący ludzie",
  "Pick your emoji": "Wybierz swoją emotikonę",
  Pin: "Przypnij",
  "Pinned by": "Przypięte przez",
  Reply: "Odpowiedz cytując",
  "Reply to Message": "Odpowiedz na wiadomość",
  Search: "Szukaj",
  "Searching...": "Wyszukiwanie...",
  Send: "Wyślij",
  "Send message request failed": "Nie udało się wysłać wiadomości",
  "Sending...": "Wysyłanie...",
  "Slow Mode ON": "Włączony tryb wolny",
  "Start of a new thread": "Początek nowego wątku",
  "This message was deleted...": "Ta wiadomość została usunięta...",
  Thread: "Odpowiedź w wątku",
  "Type your message": "Napisz wiadomość...",
  Unmute: "Wyłącz wyciszenie",
  Unpin: "Odepnij",
  "Wait until all attachments have uploaded":
    "Poczekaj, aż wszystkie załączniki zostaną przesłane",
  "You have no channels currently": "Obecnie nie masz żadnych kanałów",
  "You've reached the maximum number of files":
    "Osiągnąłeś maksymalną liczbę plików",
  live: "na żywo",
  "this content could not be displayed": "nie można wyświetlić tej zawartości",
  "{{ commaSeparatedUsers }} and {{ moreCount }} more":
    "{{ commaSeparatedUsers }} i {{ moreCount }} więcej",
  "{{ commaSeparatedUsers }}, and {{ lastUser }}":
    "{{ commaSeparatedUsers }}, i {{ lastUser }}",
  "{{ firstUser }} and {{ secondUser }}": "{{ firstUser }} i {{ secondUser }}",
  "{{ imageCount }} more": "{{ imageCount }} więcej",
  "{{ memberCount }} members": "{{ memberCount }} członków",
  "{{ replyCount }} replies": "{{ replyCount }} odpowiedzi",
  "{{ user }} has been muted": "{{ user }} został wyciszony",
  "{{ user }} has been unmuted": "{{ user }} został odciszony",
  "{{ watcherCount }} online": "{{ watcherCount }} online",
  "🏙 Attachment...": "🏙 Załącznik...",
  "Connection error": "Błąd połączenia",
  "Load more": "Załaduj więcej",
  failed: "nieudane",
  retry: "ponów",
  test: "sukces",
  "Sending links is not allowed in this conversation":
    "Wysyłanie linków nie jest dozwolone w tej rozmowie",
  "You can't send messages in this channel":
    "Nie możesz wysyłać wiadomości w tym kanale",
  "You can't send thread replies in this channel":
    "Nie możesz wysyłać odpowiedzi w wątkach w tym kanale",
  "Message not found": "Wiadomość nie znaleziona",
  "No chats here yet…": "Brak czatów...",
  "user is typing": "{{ user }} pisze",
  "users are typing": "{{ users }} piszą",
  "Error loading channels": "Błąd ładowania kanałów",
  "See original (automatically translated)":
    "Zobacz oryginał (automatycznie przetłumaczony)",
  "See translation": "Zobacz tłumaczenie",
  "Mark as unread": "Oznacz jako nieprzeczytane",
  "Error marking message as unread":
    "Błąd oznaczania wiadomości jako nieprzeczytanej",
  "Error, only the first {{count}} message can be marked as unread":
    "Błąd, tylko pierwsza {{count}} wiadomość może być oznaczona jako nieprzeczytana",
  "Unread messages": "Nieprzeczytane wiadomości",
  "{{count}} unread messages": "{{count}} nieprzeczytanych wiadomości",
  "{{count}} unread message": "{{count}} nieprzeczytana wiadomość",
  "This message did not meet our content guidelines":
    "Ta wiadomość nie spełnia naszych wytycznych dotyczących treści",
  "Send Anyway": "Wyślij mimo to",
  Edited: "Edytowane",
  "Error playing audio": "Błąd odtwarzania audio",
  "Copy text": "Skopiuj tekst",
};
