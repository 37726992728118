import { Directive, HostListener, inject, Input } from "@angular/core";
import { CopyService } from "../services";

@Directive({
  standalone: true,
  selector: "[copyToClipboard]",
})
export class CopyValueToClipboardDirective {
  @Input("copyToClipboard") copyToClipboard!: string | null | undefined;

  private readonly copyService: CopyService = inject(CopyService);

  @HostListener("click", ["$event"])
  onClick(event: Event): void {
    event.stopImmediatePropagation();

    this.copyService.copyToClipboard(this.copyToClipboard ?? "").subscribe();
  }
}
