import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { ArrivalBarDto } from "../../../application/models";

export const ARRIVAL_BAR_DTO: InjectionToken<ArrivalBarDtoPort> =
  new InjectionToken<ArrivalBarDtoPort>("ARRIVAL_BAR_DTO");

export interface ArrivalBarDtoPort {
  getArrivalInfo(): Observable<ArrivalBarDto>;
}
