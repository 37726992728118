import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  GET_MAP_MARKERS,
  GetMapMarkersQueryPort,
} from "../../application/ports";
import { MapInitializer } from "../../application/map-initializer";
import { BehaviorSubject, filter, Observable, tap } from "rxjs";
import {
  BottomNavbarComponent,
  HomeNavbarComponent,
  LaunchScreenComponent,
  LocationButtonComponent,
} from "@ui-components";
import { CommonModule } from "@angular/common";
import {
  GlobalRefreshService,
  IsAuthedService,
  MODAL_TOKEN,
  ModalProvider,
  NearbyGamesGame,
  NerabyGamesDTO,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { provideMapMarkerQuery } from "../../application/handlers";
import { provideNearbyGamesService } from "../../infrastructure/http-services";
import { CreateGameComponent } from "@hosted-games";
import { QuickFiltersComponent } from "../quick-filters/quick-filters.component";
import { ArrivalBarComponent } from "../arrival-bar/arrival-bar.component";
import { IonicModule } from "@ionic/angular";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [provideMapMarkerQuery(), provideNearbyGamesService()],
  imports: [
    LaunchScreenComponent,
    CommonModule,
    CreateGameComponent,
    HomeNavbarComponent,
    BottomNavbarComponent,
    QuickFiltersComponent,
    ArrivalBarComponent,
    LocationButtonComponent,
    IonicModule,
  ],
})
export class MapComponent implements AfterViewInit {
  @ViewChild("map") map: ElementRef = {} as ElementRef;

  private readonly mapInitializer: MapInitializer = inject(MapInitializer);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getMapMarkersQueryPort: GetMapMarkersQueryPort =
    inject(GET_MAP_MARKERS);
  private readonly globalRefreshService: GlobalRefreshService =
    inject(GlobalRefreshService);
  private platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly isAuthedService: IsAuthedService = inject(IsAuthedService);

  private markerIconCounter: string = "assets/icons/map/map-marker-counter.svg";
  private markerIconShade: string = "assets/icons/map/map-marker-shade.svg";
  private markerIconShadeCounter: string =
    "assets/icons/map/map-marker-shade-counter.svg";

  arrivalBarWidth$: BehaviorSubject<string> = new BehaviorSubject<string>(
    "0px"
  );

  readonly isMapLoaded$: Observable<{ isLoaded: boolean }> =
    this.mapInitializer.isMapLoaded$;

  readonly isAuthed$: Observable<boolean> = this.isAuthedService.isAuthed$;

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly isNativeRun: boolean = this.platformProvider.isNativeRun;

  ngAfterViewInit(): void {
    this.modalProvider
      .showLoading$()
      .pipe(
        tap(() =>
          this.mapInitializer.initMap({
            mapContainerName: this.map.nativeElement,
          })
        )
      )
      .subscribe();

    this.isMapLoaded$
      .pipe(
        filter((isLoaded) => isLoaded.isLoaded),
        tap(() => this.calculateArrivalBarWidth()),
        tap(() => this.modalProvider.dismissLoading$())
      )
      .subscribe();

    this.globalRefreshService.refresh$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.initMarkers())
      )
      .subscribe();

    if (this.platformProvider.isNativeRun) {
      this.platformProvider.requestPermissions();
    }
  }

  private initMarkers(): void {
    this.getMapMarkersQueryPort
      .getMapMarkers()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.mapInitializer.clearMarkers()),
        tap(() => this.mapInitializer.setUserPosition()),
        tap((markers: { locations: NerabyGamesDTO[] }) =>
          markers.locations.map((location: NerabyGamesDTO) =>
            this.mapInitializer.addMarker<NearbyGamesGame[]>({
              coordinates: location.coordinates,
              showCounter: true,
              counterValue:
                location.games.length > 1
                  ? location.games.length.toString()
                  : undefined,
              games: location.games,
              locationId: location.locationId,
              markerIcon: this.setMarkerIcon(location),
            })
          )
        )
      )
      .subscribe();
  }

  private setMarkerIcon(location: NerabyGamesDTO) {
    if (!location.hasRelationWithSomeGame) {
      if (location.games.length > 1) {
        return this.markerIconCounter;
      }
      return undefined;
    }

    if (location.games.length > 1) {
      return this.markerIconShadeCounter;
    }

    return this.markerIconShade;
  }

  private calculateArrivalBarWidth(): void {
    const observer: ResizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        const expecedWidth: number = entries[0].contentRect.width - 40;
        this.arrivalBarWidth$.next(`${expecedWidth}px`);
      }
    );
    observer.observe(this.map?.nativeElement);
  }
}
