<div class="or-p-16-px or-pt-40-px or-bg-shade">
  <div class="or-head-xs or-color-secondary-2 or-mb-12-px">
    Zarządzaj grupką
  </div>

  <div
    (click)="shareTeam()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
  >
    <img
      src="assets/icons/share-shade-2.svg"
      class="or-mr-8-px or-size-32-px"
    />

    <div>
      <div class="or-text-md">Wyślij link do grupki</div>
      <div class="or-text-sm or-color-secondary-2">
        Osoba z linkiem zostanie dodana do grupki
      </div>
    </div>
  </div>

  <div
    (click)="addMember()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
  >
    <img src="assets/add-button-shade.svg" class="or-mr-8-px or-size-32-px" />

    <div>
      <div class="or-text-md">Dodaj graczy do grupki</div>
      <div class="or-text-sm or-color-secondary-2">
        Dodaj graczy z poprzednich gierek
      </div>
    </div>
  </div>

  <div
    (click)="editTeam()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
  >
    <img
      src="assets/icons/edit-2-border.svg"
      class="or-mr-8-px or-size-32-px"
    />
    <div class="or-text-md">Edytuj grupkę</div>
  </div>

  <div
    (click)="removeTeam()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
  >
    <img src="assets/icons/trash-icon.svg" class="or-mr-8-px" />
    <div class="or-text-md or-color-red">Usuń grupkę</div>
  </div>
</div>
