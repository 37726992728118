import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const HOSTED_GAME_APPLICATIONS_DTO: InjectionToken<HostedGameApplicationsDTOPort> =
  new InjectionToken<HostedGameApplicationsDTOPort>(
    "HOSTED_GAME_APPLICATIONS_DTO"
  );

export interface HostedGameApplicationsDTOPort {
  approveApplications(
    gameId: string,
    applicationIds: string[]
  ): Observable<void>;

  rejectApplications(
    gameId: string,
    applicationIds: string[]
  ): Observable<void>;
}
