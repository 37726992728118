import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import {
  ChannelDetailsDTO,
  ChannelMembersResponseDTO,
  ChatChanelResponseDTO,
  CreateSingleChatResponseDTO,
  UpdateChannelDTO,
} from "../../application/interfaces";

export const CHAT_CHANEL_DTO: InjectionToken<ChatDtoPort> =
  new InjectionToken<ChatDtoPort>("CHAT_CHANEL_DTO");

export interface ChatDtoPort {
  availableChannels(): Observable<ChatChanelResponseDTO>;
  channelDetails(channelId: string): Observable<ChannelDetailsDTO>;
  updateChannel(data: UpdateChannelDTO): Observable<void>;
  removeUsersFromChannel(
    channelId: string,
    userIds: string[]
  ): Observable<void>;
  channelMembers(channelId: string): Observable<ChannelMembersResponseDTO>;
  addToChannel(channelId: string): Observable<{ accepted: boolean }>;
  createSingleChat(userId: string): Observable<CreateSingleChatResponseDTO>;
  muteChannel(channelId: string, value: boolean): Observable<void>;
}
