import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ShareGameDirective, TitileNavbarComponent } from "@ui-components";
import { NgClass } from "@angular/common";
import { PLATFORM_TOKEN, PlatformProvider } from "@core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { TeamsRoutes } from "../../teams-routes.enum";
import { map, Observable, switchMap, take } from "rxjs";

@Component({
  selector: "lib-create-team-finish",
  templateUrl: "./create-team-finish.component.html",
  styleUrls: ["./create-team-finish.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, NgClass, ShareGameDirective],
})
export class CreateTeamFinishComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  readonly isiOS: boolean = this.platformProvider.isiOS;
  readonly teamId$: Observable<string> = this.activatedRoute.params.pipe(
    map((params: Params) => params["id"])
  );

  goToDetails(): void {
    this.teamId$
      .pipe(
        take(1),
        switchMap((id: string) =>
          this.router.navigateByUrl(
            `${AppRoutes.TEAMS}/${id}/${TeamsRoutes.TEAM_DETAILS}`
          )
        )
      )
      .subscribe();
  }

  invitePlayers(): void {
    this.teamId$
      .pipe(
        take(1),
        switchMap((id: string) =>
          this.router.navigateByUrl(
            `${AppRoutes.TEAMS}/${id}/${TeamsRoutes.INVITE}`
          )
        )
      )
      .subscribe();
  }

  copyLink(): void {}
}
