import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import {
  FutureGameDTO,
  PastGameDTO,
  PlayerGameDTO,
} from "../../../application/interfaces";

export const GAMES_DTO: InjectionToken<GamesDTOPort> =
  new InjectionToken<GamesDTOPort>("GAMES_DTO");

export interface GamesDTOPort {
  getOneGame(id: string): Observable<PlayerGameDTO>;
  getFutureGames(): Observable<FutureGameDTO>;
  getPastGames(): Observable<PastGameDTO>;
}
