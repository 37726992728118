import { InjectionToken } from "@angular/core";
import { CredentialsDTO } from "../models";
import { Observable } from "rxjs";

export const LOGIN_COMMAND: InjectionToken<LoginCommandPort> =
  new InjectionToken<LoginCommandPort>("LOGIN_COMMAND");

export interface LoginCommandPort {
  login(credentials: CredentialsDTO): Observable<void>;
}
