<lib-title-navbar title="Organizacja gierki"></lib-title-navbar>

<div
  class="or-align-items-center or-d-flex or-flex-col blocked-version-container or-justify-content-center or-px-16-px"
>
  <div class="or-head-xl or-mb-12-px">Nie możesz zorganizować gierki</div>
  <div class="or-text-align-center or-text-lg or-mb-40-px">
    Aby korzystać z wszystkich funkcji aplikacji, musisz pobrać aplikację
    Orlikfy na swój telefon.
  </div>

  <div class="or-d-flex or-flex-col">
    <img (click)="downloadAndroid()" src="assets/download-google-play.svg" />
    <img
      (click)="downloadiOS()"
      src="assets/download-app-store.svg"
      class="or-mt-20-px"
    />
  </div>
</div>
