<div
  class="or-px-12-px"
  [ngClass]="
    isiOS
      ? 'full-height-scrollable-container-ios'
      : 'full-height-scrollable-container'
  "
>
  <ng-container *ngIf="invitations$ | async as invitations">
    <ng-container *ngIf="!invitations?.availableToInvite?.length">
      <ng-container
        *ngTemplateOutlet="
          invitationsUnavailable;
          context: {
            adjustCenter:
              !invitations?.availableToInvite?.length &&
              !invitations?.invited?.length
          }
        "
      ></ng-container>
    </ng-container>

    <div
      *ngIf="invitations?.availableToInvite?.length"
      class="or-d-flex or-justify-content-between or-mb-16-px or-mt-24-px or-head-sm or-align-items-center or-mx-8-px"
    >
      <div class="or-color-secondary-2">Lista dawnych graczy</div>
      <div class="or-d-flex or-justify-content-between or-align-items-center">
        <div>Zaznacz wszystkich</div>
        <ion-checkbox
          [checked]="false"
          (ionChange)="selectAll($event)"
          class="or-ml-8-px"
        ></ion-checkbox>
      </div>
    </div>

    <div *ngFor="let player of invitations.availableToInvite; let i = index">
      <div
        class="or-bg-white or-d-flex or-justify-content-between or-p-8-px or-mb-4-px player-box border-radius-8"
        [ngClass]="
          player.isSelected
            ? 'border-primary or-bg-selected'
            : 'player-box or-bg-white'
        "
      >
        <div class="or-d-flex or-align-items-center or-w-100-pc">
          <ion-checkbox
            [checked]="player.isSelected"
            (ionChange)="selectPlayer(player.userId)"
            class="or-mr-8-px"
          ></ion-checkbox>
          <lib-or-avatar
            [avatarUrl]="player.thumbnail"
            [initials]="player.displayName"
          ></lib-or-avatar>

          <div class="or-head-sm or-d-flex or-align-items-center">
            {{ i + 1 }}.&nbsp;
            <span class="or-truncate-at-line-1">{{ player.displayName }}</span>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="invitations.invited?.length">
      <div
        class="or-d-flex or-justify-content-between or-mb-16-px or-mt-32-px or-head-sm or-mx-8-px"
      >
        <div class="or-color-secondary-2">Już zaproszeni</div>
        <div></div>
      </div>

      <div *ngFor="let player of invitations.invited; let i = index">
        <div
          class="or-d-flex or-justify-content-between or-p-8-px or-mb-4-px player-box border-radius-8"
        >
          <div class="or-d-flex or-align-items-center">
            <ion-checkbox
              [checked]="true"
              class="or-mr-8-px"
              disabled
            ></ion-checkbox>
            <lib-or-avatar
              [avatarUrl]="player.thumbnail"
              [initials]="player.displayName"
            ></lib-or-avatar>

            <div class="or-head-sm or-d-flex or-align-items-center or-w-100-pc">
              {{ i + 1 }}.&nbsp;<span class="or-truncate-at-line-1">{{
                player.displayName
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="showInviteButton$ | async" class="or-mx-12-px or-mb-12-px">
  <button
    (click)="sendInvitations()"
    class="button-primary-style or-h-48-px or-head-md or-w-100-pc"
    [class.disabled-button]="!(selectedUsers$ | async)?.length"
  >
    Zaproś graczy
  </button>
</div>

<ng-template #invitationsUnavailable let-adjustCenter="adjustCenter">
  <div
    class="or-mt-32-px or-text-align-center or-text-md"
    [ngClass]="{ 'or-h-100-pc or-align-content-center': adjustCenter }"
  >
    <div>Tu możesz zaprosić graczy z poprzednich gier.</div>
    <div class="or-mt-12-px">Zaproś graczy do obecnej gry za pomocą linku.</div>

    <button
      *ngIf="gameDetails$ | async as game"
      class="button-primary-style or-h-48-px or-w-90-pc or-head-md or-mb-12-px or-mt-32-px"
      [shareGame]="game"
    >
      <div class="or-align-items-center or-d-flex or-justify-content-center">
        <img src="assets/icons/share-icon.svg" class="or-mr-12-px" />
        <span class="or-mr-12-px">Skopiuj link</span>
      </div>
    </button>
  </div></ng-template
>
