import { GamesDTOPort } from "./games.dto-port";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import {
  FutureGameDTO,
  PastGameDTO,
  PlayerGameDTO,
} from "../../../application/interfaces";

export class GamesService implements GamesDTOPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getOneGame(id: string): Observable<PlayerGameDTO> {
    return this.httpClient.get<PlayerGameDTO>(`${this.env.apiUrl}/games/${id}`);
  }

  getFutureGames(): Observable<FutureGameDTO> {
    return this.httpClient.get<FutureGameDTO>(
      `${this.env.apiUrl}/player-games/future`
    );
  }

  getPastGames(): Observable<PastGameDTO> {
    return this.httpClient.get<PastGameDTO>(
      `${this.env.apiUrl}/player-games/past`
    );
  }
}
