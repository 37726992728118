import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const REJECT_APPLICATIONS_COMMAND: InjectionToken<RejectApplicationsCommandPort> =
  new InjectionToken<RejectApplicationsCommandPort>(
    "REJECT_APPLICATIONS_COMMAND"
  );

export interface RejectApplicationsCommandPort {
  reject(gameId: string, payload: string[]): Observable<void>;
}
