import { CopyChannelUrlCommandPort } from "../ports";
import {
  CopyService,
  DeepLinkService,
  DynamicLinkType,
  socialMetaTagInfoMapper,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { inject } from "@angular/core";
import { Observable, switchMap, take } from "rxjs";
import { AppRoutes } from "src/app/app-routes.enum";
import { MessagesRoutes } from "../../messages-routes.enum";
import { environment } from "src/environment/environment";

export class CopyChannelUrlCommandHandler implements CopyChannelUrlCommandPort {
  private readonly deepLinkService: DeepLinkService = inject(DeepLinkService);
  private readonly copyService: CopyService = inject(CopyService);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  copy(channelId: string): Observable<void> {
    const socialMetaTagInfo = socialMetaTagInfoMapper[DynamicLinkType.CHANNEL];

    return this.userDataProvider.userData$.pipe(
      switchMap((userData: UserModel) => {
        const url = `${environment.frontendUrl}/${AppRoutes.MESSAGES}/${MessagesRoutes.CHANNEL_INVITATIONS}/${channelId}?referrer=${userData.userId}`;

        return this.deepLinkService.generateLink(url, socialMetaTagInfo);
      }),
      take(1),
      switchMap((generatedUrl: string) =>
        this.copyService.copyToClipboard(this.copyText(generatedUrl))
      )
    );
  }

  copyText(generatedUrl: string) {
    return `Zapraszam do dołączenia do chatu naszej drużyny! \n${generatedUrl}`;
  }
}
