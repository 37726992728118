import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import {
  BehaviorSubject,
  filter,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from "rxjs";
import {
  GAME_DETAILS_QUERY,
  GameDetailsQueryPort,
} from "../../application/ports";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { GameDetailsComponent } from "@ui-components";
import { JoinButtonsComponent } from "../join-buttons/join-buttons.component";
import { provideGameDetailsQuery } from "../../application/handlers";
import { provideGamesService } from "../../infrastructure/http-service";
import { GameDetailsModel, MODAL_TOKEN, ModalProvider } from "@core";
import {
  CREATE_SINGLE_CHAT_COMMAND,
  CreateSingleChatCommandPort,
  provideChatService,
  provideCreateSingleChatCommand,
} from "@messages";

@Component({
  templateUrl: "./game-details-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    GameDetailsComponent,
    JoinButtonsComponent,
  ],
  providers: [
    provideGameDetailsQuery(),
    provideGamesService(),
    provideCreateSingleChatCommand(),
    provideChatService(),
  ],
})
export class GameDetailsModalComponent implements OnInit {
  @Input() gameId!: string;
  @Input() adminAccess: boolean = false;

  private readonly gameDetailsQueryPort: GameDetailsQueryPort =
    inject(GAME_DETAILS_QUERY);
  private readonly createSingleChatCommandPort: CreateSingleChatCommandPort =
    inject(CREATE_SINGLE_CHAT_COMMAND);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  private readonly gameIdSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>("");

  readonly gameDetails$: Observable<GameDetailsModel> = this.gameIdSubject
    .asObservable()
    .pipe(
      filter((gameId: string) => !!gameId),
      switchMap((gameId: string) =>
        this.gameDetailsQueryPort.getGameDetails(gameId)
      ),
      shareReplay(1)
    );

  ngOnInit(): void {
    this.gameIdSubject.next(this.gameId);
  }

  sendMessage(userId: string): void {
    this.createSingleChatCommandPort
      .create(userId)
      .pipe(
        take(1),
        tap(() => this.modalProvider.dismissAllStoredModals())
      )
      .subscribe();
  }
}
