<div
  class="or-d-flex or-justify-content-between or-align-items-center or-mt-8-px"
>
  <div class="or-head-lg">{{ game.gameName }}</div>

  <div class="or-align-items-center or-d-flex">
    <div class="or-mr-8-px">
      <ng-content></ng-content>
    </div>
    <img
      *ngIf="adminAccess"
      (click)="showAdminDetails()"
      src="assets/icons/eye-icon.svg"
      class="or-cursor-pointer or-mr-8-px"
    />

    <img
      src="assets/icons/share-icon.svg"
      [shareGame]="game"
      class="or-cursor-pointer"
    />
  </div>
</div>

<div class="or-d-flex or-align-items-center or-mt-12-px">
  <img src="assets/icons/clock-icon.svg" class="or-mr-8-px" />

  <div>
    <div class="or-head-xs or-color-secondary-2">Data</div>
    <div class="or-text-md">
      {{ game.formattedDate }}
    </div>
  </div>
</div>

<div class="or-d-flex or-align-items-center or-mt-12-px">
  <img src="assets/icons/map-pin-icon.svg" class="or-mr-8-px" />

  <div>
    <div class="or-head-xs or-color-secondary-2">Adres</div>
    <div class="or-text-md">
      {{ game.location.address }}, {{ game.location.city }}
      <span
        *ngIf="showNavigateLink"
        class="or-text-sm or-color-primary or-text-decoration-underline or-cursor-pointer"
        [navigate]="game.location.coordinates"
        >Nawiguj</span
      >
    </div>
  </div>
</div>

<div class="or-d-flex or-justify-content-space-around">
  <div>
    <div class="or-d-flex or-align-items-center or-mt-12-px">
      <img src="assets/icons/member-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Gracze</div>
        <div class="or-text-md">
          {{ game.occupiedSlots }}/{{ game.totalSlots }}
        </div>
      </div>
    </div>

    <div
      *ngIf="showGameTypeDetails"
      class="or-d-flex or-align-items-center or-mt-12-px"
    >
      <img src="assets/icons/field-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Typ gierki</div>
        <div class="or-text-md">{{ gameType }}</div>
      </div>
    </div>

    <div class="or-d-flex or-align-items-center or-mt-12-px">
      <img src="assets/icons/price-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Cena</div>
        <div *ngIf="game.priceAmount" class="or-text-md">
          {{ game.priceAmount }} zł
        </div>
        <div *ngIf="!game.priceAmount" class="or-text-md">Darmowa</div>
      </div>
    </div>
  </div>
  <div class="or-ml-28-px">
    <div class="or-d-flex or-align-items-center or-mt-12-px">
      <img src="assets/icons/level-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Poziom</div>
        <div class="or-text-md">
          {{ game.level }}
        </div>
      </div>
    </div>

    <div
      *ngIf="showGameTypeDetails"
      class="or-d-flex or-align-items-center or-mt-12-px"
    >
      <img src="assets/icons/map-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Widoczność na mapie</div>
        <div class="or-text-md">
          {{ gameVisibilityLabel }}
        </div>
      </div>
    </div>

    <div class="or-d-flex or-align-items-center or-mt-12-px">
      <ng-container *ngIf="game.priceAmount">
        <img src="assets/icons/payment-3-icon.svg" class="or-mr-8-px" />

        <div>
          <div class="or-head-xs or-color-secondary-2">Płatność</div>
          <div class="or-text-md">
            {{ game.paymentType.join(" / ") }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="or-d-flex or-align-items-center or-mt-16-px">
  <div>
    <div class="or-head-xs or-color-secondary-2">Szczegóły gierki</div>
    <div class="or-text-md">
      {{ game.description }}
    </div>
  </div>
</div>

<div *ngIf="showHostInfo" class="or-d-flex or-align-items-center or-mt-24-px">
  <lib-or-avatar
    [avatarUrl]="game.host?.thumbnail"
    [initials]="game.host?.displayName"
  ></lib-or-avatar>

  <div class="or-d-flex or-justify-content-between or-w-100-pc">
    <div class="or-max-w-60-pc">
      <div class="or-head-xs or-color-secondary-2">Organizator</div>
      <div class="or-text-md">
        {{ game.host?.displayName }}
      </div>
    </div>

    <div class="or-d-flex or-align-items-center">
      <img
        *ngIf="!(isMyGame$ | async)"
        (click)="sendMessage(game.host?.hostId, $event)"
        src="assets/icons/message-host-icon.svg"
        class="or-mr-4-px"
      />
      <div
        *ngIf="showPhoneNumber"
        [copyToClipboard]="game.gameContactPhone"
        class="button-primary-style or-align-items-center or-d-flex or-head-md or-justify-content-between or-px-8-px or-h-32-px or-cursor-pointer"
        [ngClass]="{ 'disabled-button': !game.gameContactPhone }"
      >
        <img src="assets/icons/phone-white.svg" class="or-mr-4-px" />
        <span
          class="or-color-white or-head-xs text-no-wrap"
          [ngClass]="{ 'disabled-button': !game.gameContactPhone }"
          >Skopiuj numer</span
        >
      </div>
    </div>
  </div>
</div>
