import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const DECLINE_INVITATION_COMMAND: InjectionToken<DeclineInvitationCommandPort> =
  new InjectionToken<DeclineInvitationCommandPort>(
    "DECLINE_INVITATION_COMMAND"
  );

export interface DeclineInvitationCommandPort {
  decline(invitaionId: string, gameId: string): Observable<void>;
}
