<lib-title-navbar title="Powiadomienia"></lib-title-navbar>

<div class="or-mb-20-px"></div>

<div
  [ngClass]="
    isiOS
      ? 'full-height-scrollable-container-ios'
      : 'full-height-scrollable-container'
  "
>
  <div *ngIf="isPageLoaded$ | async" class="or-text-md or-mb-8-px or-mx-20-px">
    Nowe
  </div>
  <div
    *ngIf="!(newNotifications$ | async)?.length && (isPageLoaded$ | async)"
    class="or-d-flex or-head-sm or-justify-content-center or-my-32-px"
  >
    Brak nowych powiadomień
  </div>
  <ng-container *ngFor="let notification of newNotifications$ | async">
    <div class="or-d-flex or-align-items-center or-mx-20-px">
      <div>
        <img
          src="assets/icons/unread-icon.svg"
          class="or-h-20-px or-left-40-px or-top-8-px or-position-relative or-w-20-px"
        />

        <lib-or-avatar
          [avatarUrl]="notification.avatar"
          [isNotifications]="true"
          [initials]="notification.displayName"
        ></lib-or-avatar>
      </div>
      <div (click)="redirectToGame(notification)">
        <div class="or-color-secondary-2 or-head-xs or-mb-2-px">
          {{ notification.createdAt }}
        </div>
        <div class="or-text-md or-mb-2-px">{{ notification.title }}</div>
        <div class="or-head-xs">
          {{ notification.description }}
        </div>
        <div *ngIf="notification.formattedDate" class="or-text-sm">
          {{ notification.formattedDate }}
        </div>
      </div>
    </div>
    <div class="custom-divider or-w-90-pc or-my-12-px"></div
  ></ng-container>

  <div
    *ngIf="(pastNotifications$ | async)?.length"
    class="or-text-md or-mb-8-px or-mx-20-px or-mt-28-px"
  >
    Przeszłe
  </div>

  <ng-container
    *ngFor="let notification of pastNotifications$ | async; let i = index"
  >
    <div
      class="or-d-flex or-align-items-center or-mx-20-px opacity-past"
      (click)="redirectToGame(notification)"
    >
      <div>
        <lib-or-avatar
          [avatarUrl]="notification.avatar"
          [isNotifications]="true"
          [initials]="notification.displayName"
        ></lib-or-avatar>
      </div>
      <div>
        <div class="or-color-secondary-2 or-head-xs or-mb-2-px">
          {{ notification.createdAt }}
        </div>
        <div class="or-text-md or-mb-2-px">{{ notification.title }}</div>
        <div class="or-head-xs">
          {{ notification.description }}
        </div>
        <div *ngIf="notification.formattedDate" class="or-text-sm">
          {{ notification.formattedDate }}
        </div>
      </div>
    </div>

    <div
      class="custom-divider or-w-90-pc or-my-12-px"
      [ngClass]="{
        'hide-last-dividier': i + 1 == (pastNotifications$ | async)?.length
      }"
    ></div
  ></ng-container>
</div>
