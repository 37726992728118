import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { BottomNavbarComponent, HomeNavbarComponent } from "@ui-components";
import { map, Observable } from "rxjs";
import {
  NOTIFICATIONS_LIST_QUERY,
  NotificationsListQueryPort,
  NotificationViewModel,
  provideNotificationsListQuery,
} from "@notifications";
import { AsyncPipe, NgIf } from "@angular/common";
import { AppRoutes } from "src/app/app-routes.enum";
import { HostedGamesRoutes } from "@hosted-games";
import { Router } from "@angular/router";
import { DashboardNavbarComponent } from "../dashboard-navbar/dashboard-navbar.component";
import { PLATFORM_TOKEN, PlatformProvider } from "@core";
import { DashboardGamesListComponent } from "../dashboard-games-list/dashboard-games-list.component";
import { DashboardInvitationsComponent } from "../dashboard-invitations/dashboard-invitations.component";
import { DashboardEmptyStateComponent } from "../dashboard-empty-state/dashboard-empty-state.component";
import { AlertBarComponent } from "../alert-bar/alert-bar.component";
import { DashboardTeamsComponent } from "../dashboard-teams/dashboard-teams.component";

@Component({
  selector: "lib-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HomeNavbarComponent,
    AsyncPipe,
    BottomNavbarComponent,
    DashboardNavbarComponent,
    DashboardGamesListComponent,
    NgIf,
    DashboardInvitationsComponent,
    DashboardEmptyStateComponent,
    AlertBarComponent,
    DashboardTeamsComponent,
  ],
  providers: [provideNotificationsListQuery()],
})
export class DashboardComponent {
  private readonly notificationsListQueryPort: NotificationsListQueryPort =
    inject(NOTIFICATIONS_LIST_QUERY);
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  public isGameAvailable: boolean = true;
  public isInvitationsAvailable: boolean = true;
  public isTeamsAvailable: boolean = true;

  get isEmptyStateVisible(): boolean {
    return (
      !this.isGameAvailable &&
      !this.isInvitationsAvailable &&
      !this.isTeamsAvailable
    );
  }

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly isNewNotifications$: Observable<boolean> =
    this.notificationsListQueryPort.getNotificationsList().pipe(
      map((notifications: NotificationViewModel[]) =>
        notifications.filter(
          (notification: NotificationViewModel) => !notification.isRead
        )
      ),
      map((notifications: NotificationViewModel[]) => !!notifications?.length)
    );

  goToGameCreator(): void {
    this.router.navigateByUrl(
      `${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}`
    );
  }

  setIsInvitationsAvailable(event: boolean): void {
    this.isInvitationsAvailable = event;
  }

  setIsGameAvailable(event: boolean): void {
    this.isGameAvailable = event;
  }

  setIsTeamsAvailable(event: boolean): void {
    this.isTeamsAvailable = event;
  }
}
