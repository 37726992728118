import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { AdvancedInvitationsUser } from "../../interfaces";

export const ADVANCED_INVITATIONS_QUERY: InjectionToken<AdvancedInvitationsQueryPort> =
  new InjectionToken<AdvancedInvitationsQueryPort>(
    "ADVANCED_INVITATIONS_QUERY"
  );

export interface AdvancedInvitationsQueryPort {
  getChatUsers(): Observable<AdvancedInvitationsUser[]>;
  getPastUsers(): Observable<AdvancedInvitationsUser[]>;
}
