import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { AlertsSettingsDTO } from "../../interfaces";

export const ALERTS_SETTINGS_QUERY: InjectionToken<AlertsSettingsQueryPort> =
  new InjectionToken<AlertsSettingsQueryPort>("ALERTS_SETTINGS_QUERY");

export interface AlertsSettingsQueryPort {
  getAlertsSettings(): Observable<AlertsSettingsDTO>;
}
