import { UserDTOPort } from "./user.dto-port";
import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import { Observable } from "rxjs";
import { UpdateUserDTO } from "../../../application/interfaces";

export class UserService implements UserDTOPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  updateUser(userData: UpdateUserDTO): Observable<void> {
    return this.httpClient.patch<void>(`${this.env.apiUrl}/users/me`, userData);
  }
}
