<ng-container
  *ngIf="{
    invitationsActive: (invitationsActive$ | async),
    chatUsers: chatUsers$ | async,
    pastUsers: pastUsers$ | async
  } as data"
>
  <div class="or-color-secondary-2 or-text-md">
    <ng-container
      *ngTemplateOutlet="
        data.invitationsActive ? autoInvitationsLabel : autoApprovalLabel
      "
    ></ng-container>
  </div>

  <div
    [ngClass]="
      isiOS
        ? 'full-height-scrollable-container-ios'
        : 'full-height-scrollable-container'
    "
  >
    <div class="or-mb-16-px or-mt-24-px or-head-sm">
      <div class="or-color-secondary-2">Lista graczy z chatu</div>
    </div>

    <ng-container
      *ngTemplateOutlet="
        playerListTemplate;
        context: {
          players: data.chatUsers,
          invitationsActive: data.invitationsActive
        }
      "
    ></ng-container>

    <div class="or-mb-16-px or-mt-24-px or-head-sm">
      <div class="or-color-secondary-2">Lista dawnych graczy</div>
    </div>

    <ng-container
      *ngTemplateOutlet="
        playerListTemplate;
        context: {
          players: data.pastUsers,
          invitationsActive: data.invitationsActive
        }
      "
    ></ng-container>
  </div>
</ng-container>

<ng-template
  #playerListTemplate
  let-players="players"
  let-invitationsActive="invitationsActive"
>
  <div *ngFor="let player of players">
    <div
      class="or-bg-white or-d-flex or-justify-content-between or-p-8-px or-mb-4-px player-box border-radius-8"
      [ngClass]="
        (invitationsActive ? player.autoInvite : player.autoApproval)
          ? 'border-primary or-bg-selected'
          : 'player-box or-bg-white'
      "
    >
      <div class="or-d-flex or-align-items-center or-w-100-pc">
        <ion-checkbox
          [checked]="
            invitationsActive ? player.autoInvite : player.autoApproval
          "
          (ionChange)="selectPlayer(player.userId, $event)"
          class="or-mr-8-px"
        ></ion-checkbox>
        <lib-or-avatar
          [avatarUrl]="player.thumbnail"
          [initials]="player.displayName"
        ></lib-or-avatar>

        <div class="or-head-sm or-d-flex or-align-items-center">
          <span class="or-truncate-at-line-1">{{ player.displayName }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #autoInvitationsLabel>
  Określ, kto powinien dostawać automatyczne zaproszenie do gierki.
</ng-template>
<ng-template #autoApprovalLabel>
  Ulubiony gracz to osoba, która dostanie się na gierkę bez akceptacji hosta.
</ng-template>
