import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import {
  MARK_AS_PAID_COMMAND,
  MarkAsPaidCommandPort,
  REMOVE_PLAYER_COMMAND,
  RemovePlayerCommandPort,
} from "../../application/ports";
import { AsyncPipe, NgIf } from "@angular/common";
import { OrAvatarComponent } from "@ui-components";
import {
  ActionModalComponent,
  ButtonLabels,
  GamePlayersDTO,
  MODAL_TOKEN,
  ModalProvider,
} from "@core";
import {
  provideMarkAsPaidCommand,
  provideRemovePlayerCommand,
} from "../../application/handlers";
import { provideHostedGamesService } from "../../infrastructure/http-service";
import { take } from "rxjs";
import { PaymentConfirmCheckboxComponent } from "../payment-confirm-checkbox/payment-confirm-checkbox.component";
import { HIDE_PAYMENTS_MODAL } from "../../application/utils";

@Component({
  selector: "lib-manage-player",
  templateUrl: "./manage-player.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, OrAvatarComponent, AsyncPipe],
  providers: [
    provideRemovePlayerCommand(),
    provideMarkAsPaidCommand(),
    provideHostedGamesService(),
  ],
})
export class ManagePlayerComponent {
  @Input() player!: GamePlayersDTO;
  @Input() gameId!: string;

  private readonly removePlayerCommandPort: RemovePlayerCommandPort = inject(
    REMOVE_PLAYER_COMMAND
  );
  private readonly markAsPaidCommandPort: MarkAsPaidCommandPort =
    inject(MARK_AS_PAID_COMMAND);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  get paymentStatusText(): ButtonLabels {
    const header: string = this.player.isPaid
      ? "Oznacz jako niezapłacone"
      : "Oznacz jako zapłacone";

    const text: string = this.player.isPaid
      ? "Czy na pewno chcesz oznaczyć jako niezapłacone?"
      : "Czy na pewno chcesz oznaczyć jako zapłacone?";
    return { header, text };
  }

  removePlayer(): void {
    this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: "present-modal",
      componentProps: {
        header: "Usuń gracza z gierki",
        message: "Czy na pewno chcesz usunąć gracza z gierki?",
        btnOk: "Tak, usuń gracza",
        btnCancel: "Anuluj",
        action: () => {
          this.removePlayerCommandPort
            .remove(this.gameId, this.player.gamePlayerId)
            .pipe(take(1))
            .subscribe();
        },
      },
    });
  }

  changePaymentStatus(): void {
    const hideModal: { isHide: boolean } = JSON.parse(
      localStorage.getItem(HIDE_PAYMENTS_MODAL) as string
    );

    if (hideModal?.isHide) {
      this.modalProvider.dismissAllStoredModals();
      return this.markAsPaid();
    }

    this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: "present-modal",
      componentProps: {
        header: this.paymentStatusText.header,
        message: this.paymentStatusText.text,
        btnOk: "Tak, potwierdzam",
        btnCancel: "Anuluj",
        template: PaymentConfirmCheckboxComponent,
        action: () => this.markAsPaid(),
      },
    });
  }

  private markAsPaid(): void {
    this.markAsPaidCommandPort
      .markAsPaid(this.gameId, this.player.gamePlayerId, this.player.isPaid)
      .pipe(take(1))
      .subscribe();
  }
}
