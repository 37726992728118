import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { IsAuthedService } from "@core";
import { inject } from "@angular/core";
import { map, take } from "rxjs";

export const sharedGameResolver: ResolveFn<void> = (
  activatedRoute: ActivatedRouteSnapshot
) => {
  const isAuthed: IsAuthedService = inject(IsAuthedService);
  const sharedGameId: string = activatedRoute.params["id"];
  const itemKey: string = "shared-game";

  isAuthed.isAuthed$
    .pipe(
      take(1),
      map((isAuthed: boolean) => {
        if (isAuthed) return sessionStorage.removeItem(itemKey);

        return sessionStorage.setItem(itemKey, sharedGameId);
      })
    )
    .subscribe();
};
