import { AngularFirestore } from "@angular/fire/compat/firestore";
import { inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { AppVersion, firestoreGetDataUtil } from "@core";

@Injectable({ providedIn: "root" })
export class UpdateService {
  private readonly firestore: AngularFirestore = inject(AngularFirestore);

  isUpdated(): Observable<boolean> {
    return firestoreGetDataUtil<{ versions: string[] }>(
      this.firestore,
      "version",
      "available-versions"
    ).pipe(
      map((data: { versions: string[] }) =>
        data?.versions?.includes(AppVersion)
      )
    );
  }

  newestVersion(): Observable<string> {
    return firestoreGetDataUtil<{ version: string }>(
      this.firestore,
      "version",
      "current-version"
    ).pipe(map((data: { version: string }) => data.version));
  }

  isNewestVersion() {
    return firestoreGetDataUtil<{ version: string }>(
      this.firestore,
      "version",
      "current-version"
    ).pipe(
      map((version: { version: string }) => version.version === AppVersion)
    );
  }
}
