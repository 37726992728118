<div class="or-h-100vh">
  <img src="assets/register-header.svg" class="auth-header" />

  <div
    slot="start"
    class="or-position-absolute or-ml-8-px or-mt-12-px or-top-0"
    (click)="goBack()"
  >
    <img src="assets/icons/back-arrow-icon.svg" />
  </div>

  <auth-form (actionEvent)="register($event)" [isRegister]="true"></auth-form>
</div>
