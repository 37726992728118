export const formattedTimeDifferenceUtil = (date: Date) => {
  const now: Date = new Date();
  const diffMs: number = now.getTime() - date.getTime();
  const diffHours: number = Math.floor(diffMs / (1000 * 60 * 60));

  const daysBetween: number =
    (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);

  if (daysBetween < 1) {
    if (diffHours < 1) {
      return "Mniej niż godzinę temu";
    }
    return `${diffHours} godz. temu`;
  } else if (daysBetween === 1) {
    return "Wczoraj";
  } else if (daysBetween > 1 && daysBetween <= 7) {
    return `${Math.floor(daysBetween)} dni temu`;
  } else {
    return "7+ dni temu";
  }
};
