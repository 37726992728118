import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { InvitePlayersRequestDTO } from "../../interfaces";

export const INVITE_PLAYERS_COMMAND: InjectionToken<InvitePlayersCommandPort> =
  new InjectionToken<InvitePlayersCommandPort>("INVITE_PLAYERS_COMMAND");

export interface InvitePlayersCommandPort {
  sendInvitations(players: InvitePlayersRequestDTO): Observable<void>;
}
