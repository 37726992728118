import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

export class TimezoneInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const addTimezone = req.clone({
      headers: req.headers.set("x-timezone", timezone),
    });

    return next.handle(addTimezone);
  }
}
