import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { FeatureFlag } from "../enums";

export const FEATURE_FLAG_TOKEN: InjectionToken<FeatureFlagProvider> =
  new InjectionToken<FeatureFlagProvider>("FEATURE_FLAG_TOKEN");

export interface FeatureFlagProvider {
  isEnabled$(flag: FeatureFlag): Observable<boolean>;
}
