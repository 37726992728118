import { ArrivalBarDtoPort } from "./arrival-bar.dto-port";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import { ArrivalBarDto } from "../../../application/models";

export class ArrivalBarService implements ArrivalBarDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getArrivalInfo(): Observable<ArrivalBarDto> {
    return this.httpClient.get<ArrivalBarDto>(`${this.env.apiUrl}/arrival`);
  }
}
