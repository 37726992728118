import { Provider } from "@angular/core";
import {
  ADVANCED_INVITATIONS_QUERY,
  DECLINE_INVITATION_COMMAND,
  GET_INVITATIONS_LIST_FOR_HOST_QUERY,
  INVITE_PLAYERS_COMMAND,
  LOAD_ADVANCED_INVITATIONS_COMMAND,
  SWITCH_ADVANCED_INVITATIONS_COMMAND,
} from "../ports";
import { GetInvitationsListForHostQueryHandler } from "./get-invitations-list-for-host.query-handler";
import { InvitePlayersCommandHandler } from "./invite-players.command-handler";
import { DeclineInvitationCommandHandler } from "./decline-invitation.command-handler";
import { AdvancedInvitationsQueryHandler } from "./advanced-invitations.query-handler";
import { SwitchAdvancedInvitationsCommandHandler } from "./switch-advanced-invitations.command-handler";
import { LoadAdvancedInvtitationsCommandHandler } from "./load-advanced-invtitations.command-handler";

export const provideGetInvitationsListForHostQuery = (): Provider => ({
  provide: GET_INVITATIONS_LIST_FOR_HOST_QUERY,
  useClass: GetInvitationsListForHostQueryHandler,
});

export const provideInvitePlayersCommand = (): Provider => ({
  provide: INVITE_PLAYERS_COMMAND,
  useClass: InvitePlayersCommandHandler,
});

export const provideDeclineInvitationCommand = (): Provider => ({
  provide: DECLINE_INVITATION_COMMAND,
  useClass: DeclineInvitationCommandHandler,
});

export const provideAdvancedInvitationsQuery = (): Provider => ({
  provide: ADVANCED_INVITATIONS_QUERY,
  useClass: AdvancedInvitationsQueryHandler,
});

export const provideSwitchAdvancedInvitationsCommand = (): Provider => ({
  provide: SWITCH_ADVANCED_INVITATIONS_COMMAND,
  useClass: SwitchAdvancedInvitationsCommandHandler,
});

export const provideLoadAdvancedInvitationsCommand = (): Provider => ({
  provide: LOAD_ADVANCED_INVITATIONS_COMMAND,
  useClass: LoadAdvancedInvtitationsCommandHandler,
});
