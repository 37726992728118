import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { CustomNotificationDTO } from "../../interfaces";

export const CUSTOM_NOTIFICATION_QUERY: InjectionToken<CustomNotificationQueryPort> =
  new InjectionToken<CustomNotificationQueryPort>("CUSTOM_NOTIFICATION_QUERY");

export interface CustomNotificationQueryPort {
  getData(): Observable<CustomNotificationDTO>;
}
