import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from "@angular/core";
import { OrAvatarComponent, TitileNavbarComponent } from "@ui-components";
import { IonicModule } from "@ionic/angular";
import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import {
  GameDetailsModel,
  GameLevel,
  gameLevelMapper,
  GamePlayersDTO,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PreferredPosition,
  preferredPositionMapper,
} from "@core";
import { ActivatedRoute, Params } from "@angular/router";
import {
  BehaviorSubject,
  combineLatestWith,
  map,
  Observable,
  switchMap,
  take,
  tap,
} from "rxjs";
import {
  GameDetailsQueryHandler,
  SaveLineupsCommandHandler,
} from "../../application/handlers";
import { LineupsService } from "../../infrastructure/http-service";
import { SaveLineupsDTO } from "../../application/interfaces";

@Component({
  selector: "lib-lineups",
  templateUrl: "./lineups.component.html",
  styleUrls: ["./lineups.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    IonicModule,
    NgForOf,
    NgIf,
    OrAvatarComponent,
    AsyncPipe,
    NgClass,
  ],
  providers: [LineupsService, SaveLineupsCommandHandler],
})
export class LineupsComponent implements OnInit {
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly gameDetailsQueryHandler: GameDetailsQueryHandler = inject(
    GameDetailsQueryHandler
  );
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly saveLineupsCommandHandler: SaveLineupsCommandHandler =
    inject(SaveLineupsCommandHandler);

  private readonly playersSubject: BehaviorSubject<GamePlayersDTO[]> =
    new BehaviorSubject<GamePlayersDTO[]>([]);
  private readonly isHostSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public gameLevelMapper: Record<GameLevel, string> = gameLevelMapper;
  public preferredPositionMapper: Record<PreferredPosition, string> =
    preferredPositionMapper;
  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly players$: Observable<GamePlayersDTO[]> =
    this.playersSubject.asObservable();

  readonly isHost$: Observable<boolean> = this.isHostSubject.asObservable();

  readonly gameId$: Observable<string> = this.activatedRoute.params.pipe(
    take(1),
    map((params: Params) => params["id"])
  );

  ngOnInit(): void {
    this.loadData();
  }

  splitTeamSize(data: GamePlayersDTO[]) {
    if (!data) return 0;
    return +(data.length / 2).toFixed();
  }

  doReorder(event: CustomEvent): void {
    const itemMove: GamePlayersDTO = this.playersSubject.value.splice(
      event.detail.from,
      1
    )[0];
    this.playersSubject.value.splice(event.detail.to, 0, itemMove);

    event.detail.complete();
  }

  shufflePlayers(): void {
    for (let i = this.playersSubject.value.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.playersSubject.value[i], this.playersSubject.value[j]] = [
        this.playersSubject.value[j],
        this.playersSubject.value[i],
      ];
    }
  }

  save(): void {
    this.playersSubject
      .asObservable()
      .pipe(
        take(1),
        map((players) => players.map((player) => player.userId)),
        combineLatestWith(this.gameId$),
        switchMap(([ids, gameId]) => {
          const payload: SaveLineupsDTO = {
            gameId: gameId,
            userIds: ids,
          };
          return this.saveLineupsCommandHandler.save(payload);
        })
      )
      .subscribe();
  }

  private loadData(): void {
    this.modalProvider
      .showLoading$()
      .pipe(
        switchMap(() => this.gameId$),
        take(1),
        switchMap((gameId: string) =>
          this.gameDetailsQueryHandler.getGameDetails(gameId)
        ),
        take(1),
        tap((details: GameDetailsModel) =>
          this.isHostSubject.next(details.isHost)
        ),
        tap((details: GameDetailsModel) => {
          console.log(details.players);
          const players: GamePlayersDTO[] = details.players as GamePlayersDTO[];
          this.playersSubject.next(players);
        }),
        tap(() => this.modalProvider.dismissLoading$())
      )
      .subscribe(() => this.shufflePlayers());
  }
}
