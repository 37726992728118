import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from "@angular/core";
import {
  BottomNavbarComponent,
  GameDetailsComponent,
  MobileLandingComponent,
  TitileNavbarComponent,
} from "@ui-components";
import { map, Observable, shareReplay, switchMap, tap } from "rxjs";
import {
  GAME_DETAILS_QUERY,
  GameDetailsQueryPort,
} from "../../application/ports";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MapInitializer } from "../../../../../find-games/src/lib/application/map-initializer";
import { JoinButtonsComponent } from "../join-buttons/join-buttons.component";
import { GameDetailsModel, PLATFORM_TOKEN, PlatformProvider } from "@core";
import { AppRoutes } from "src/app/app-routes.enum";
import { GamesRoutes } from "../../games-routes.enum";
import {
  CREATE_SINGLE_CHAT_COMMAND,
  CreateSingleChatCommandPort,
  provideCreateSingleChatCommand,
} from "@messages";

@Component({
  selector: "lib-shared-game",
  templateUrl: "./shared-game.component.html",
  styleUrls: ["./shared-game.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    CommonModule,
    JoinButtonsComponent,
    GameDetailsComponent,
    MobileLandingComponent,
  ],
  providers: [provideCreateSingleChatCommand()],
})
export class SharedGameComponent {
  @ViewChild("map") map: ElementRef = {} as ElementRef;

  private readonly gameDetailsQueryPort: GameDetailsQueryPort =
    inject(GAME_DETAILS_QUERY);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly mapInitializer: MapInitializer = inject(MapInitializer);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly createSingleChatCommandPort: CreateSingleChatCommandPort =
    inject(CREATE_SINGLE_CHAT_COMMAND);

  readonly isiOS: boolean = this.platformProvider.isiOS;
  readonly redirectPath: string = "games/shared";

  readonly hideScreen$: Observable<boolean> =
    this.activatedRoute.queryParams.pipe(
      map((params: Params) => {
        return (
          (this.platformProvider.isMobileWeb ||
            this.platformProvider.isDesktop) &&
          !this.platformProvider.isNativeRun &&
          params["allow"] !== "true"
        );
      })
    );

  readonly gameDetails$: Observable<GameDetailsModel> =
    this.activatedRoute.params.pipe(
      switchMap((params: Params) =>
        this.gameDetailsQueryPort.getGameDetails(params["id"])
      ),
      tap((game: GameDetailsModel) => {
        this.mapInitializer.initMap({
          mapContainerName: this.map.nativeElement,
          startCoordinates: game.location.coordinates,
          zoom: 15,
        });
        this.mapInitializer.addMarker({
          coordinates: game.location.coordinates,
          showCounter: false,
        });
      }),
      shareReplay(1)
    );

  applicationSent(gameId: string) {
    this.router.navigateByUrl(
      `${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${gameId}`
    );
  }

  sendMessage(userId: string): void {
    this.createSingleChatCommandPort.create(userId).subscribe();
  }
}
