import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { FeatureFlagsRecord } from "../../application/models";

export const FEATURE_FLAGS_DTO: InjectionToken<FeatureFlagsDtoPort> =
  new InjectionToken<FeatureFlagsDtoPort>("FEATURE_FLAGS_DTO");

export interface FeatureFlagsDtoPort {
  getFlags(): Observable<FeatureFlagsRecord>;
}
