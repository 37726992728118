import { inject } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { AdvancedInvitationsDTO } from "../interfaces";
import { LoadAdvancedInvtitationsCommandPort } from "../ports/command";
import {
  ADVANCED_INVITATIONS_CONTEXT,
  AdvancedInvitationsContextPort,
} from "../../infrastructure/storage";
import {
  ADVANCED_INVITATIONS_DTO,
  AdvancedInvitationsDtoPort,
} from "../../infrastructure/http-service";

export class LoadAdvancedInvtitationsCommandHandler
  implements LoadAdvancedInvtitationsCommandPort
{
  private readonly advancedInvitationsContextPort: AdvancedInvitationsContextPort =
    inject(ADVANCED_INVITATIONS_CONTEXT);
  private readonly advancedInvitationsDtoPort: AdvancedInvitationsDtoPort =
    inject(ADVANCED_INVITATIONS_DTO);

  load(gameId: string): Observable<void> {
    return this.advancedInvitationsDtoPort
      .getUsers(gameId)
      .pipe(
        switchMap((data: AdvancedInvitationsDTO) =>
          this.advancedInvitationsContextPort.patchContext(data)
        )
      );
  }
}
