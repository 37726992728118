import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { CreateGameViewService } from "../../../application/view-services";
import { AsyncPipe, NgClass } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { Observable } from "rxjs";

@Component({
  selector: "lib-game-type-modal",
  templateUrl: "./game-type-modal.component.html",
  styleUrls: ["./game-type-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, IonicModule, NgClass],
})
export class GameTypeModalComponent {
  private readonly createGameViewService: CreateGameViewService = inject(
    CreateGameViewService
  );

  constructor() {
    this.createGameViewService.setIsRecurring(false);
  }

  readonly isRecurring$: Observable<boolean> =
    this.createGameViewService.isRecurring$;

  setType(event: CustomEvent) {
    this.createGameViewService.setIsRecurring(event.detail.value);
  }
}
