<ng-container *ngIf="usersSummary$ | async as summary">
  <div
    *ngIf="!showStats"
    (click)="changeVisibility()"
    class="or-color-primary or-text-lg"
  >
    Pokaż statystyki
  </div>
  <div
    *ngIf="showStats"
    (click)="changeVisibility()"
    class="or-color-red or-text-lg or-mt-12-px"
  >
    Zamknij statystyki
  </div>

  <div *ngIf="showStats" class="or-text-lg or-color-red or-mt-12-px">
    <div>
      <span class="or-head-lg">Total users:</span> {{ summary.totalUsers }}
    </div>
    <div>
      <span class="or-head-lg">Android users:</span> {{ summary.androidCount }}
    </div>
    <div><span class="or-head-lg">iOS users:</span> {{ summary.iOsCount }}</div>
    <div>
      <span class="or-head-lg">MobileWeb users:</span>
      {{ summary.mobilewebCount }}
    </div>
    <div><span class="or-head-lg">Web users:</span> {{ summary.webCount }}</div>
    <div><span class="or-head-lg">Hosts:</span> {{ summary.isHost }}</div>
    <div>
      <span class="or-head-lg">Paid hosts:</span> {{ summary.isPaidHost }}
    </div>
    <ul>
      <li
        *ngFor="let version of versionEntries(summary.version) | slice : 0 : 10"
      >
        <span class="or-head-lg">Version {{ version.key }}:</span>
        {{ version.value }}
      </li>
    </ul>
  </div>
</ng-container>
