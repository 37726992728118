import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import {
  NAVIGATION_TOKEN,
  NavigationProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { NgClass, NgIf } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: "lib-title-navbar",
  templateUrl: "./title-navbar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonicModule, NgClass, NgIf],
})
export class TitileNavbarComponent {
  @Input() customBackUrl?: string;
  @Input() showDots?: boolean;
  @Input() showAddGame?: boolean;
  @Input() showBackArrow?: boolean = true;

  @Output() actionOnBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDotsClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() onAddGameOpened: EventEmitter<void> = new EventEmitter<void>();

  private readonly navigationProvider: NavigationProvider =
    inject(NAVIGATION_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  @Input() title: string | null = null;

  isIOs(): boolean {
    return this.platformProvider.isiOS;
  }

  goBack(): void | Promise<boolean> {
    if (this.customBackUrl) return this.router.navigate([this.customBackUrl]);

    this.actionOnBack.emit();

    return this.navigationProvider.back();
  }

  dotsClicked(): void {
    this.onDotsClicked.emit();
  }

  addGame(): void {
    this.onAddGameOpened.emit();
  }
}
