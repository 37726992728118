import { inject } from "@angular/core";
import { map, Observable } from "rxjs";
import { UnreadMessagesDTO } from "../../application/interfaces";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { UnreadMessagesDtoPort } from "./unread-messages.dto-port";

export class UnreadMessagesService implements UnreadMessagesDtoPort {
  private readonly firestore: AngularFirestore = inject(AngularFirestore);

  unreadCount(userId: string): Observable<UnreadMessagesDTO[]> {
    return this.firestore
      .collection("chat-unread-messages")
      .doc(userId)
      .collection("unread-channels")
      .snapshotChanges()
      .pipe(
        map((response) =>
          response.map(
            (data) =>
              ({
                id: data.payload.doc.id,
                ...data.payload.doc.data(),
              } as UnreadMessagesDTO)
          )
        )
      );
  }
}
