import { Injectable } from "@angular/core";
import {
  AlertController,
  AlertOptions,
  LoadingController,
  LoadingOptions,
  ModalController,
  ModalOptions,
  ToastController,
  ToastOptions,
} from "@ionic/angular";
import { from, map, Observable, Subject, switchMap } from "rxjs";

@Injectable({ providedIn: "root" })
export class ModalService {
  public show$: Subject<ModalOptions> = new Subject<ModalOptions>();
  public modalInstances: HTMLIonModalElement[] = [];
  public loadingInstances: HTMLIonLoadingElement[] = [];

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private toastController: ToastController,
    private loadingController: LoadingController
  ) {}

  async showModal(opts: ModalOptions): Promise<HTMLIonModalElement> {
    this.show$.next(opts);
    const modal: HTMLIonModalElement = await this.modalController.create(opts);
    await modal.present();
    this.storeModal(modal);
    return modal;
  }

  dismissAllStoredModals(): void {
    [...this.modalInstances].forEach((modal, index) => {
      modal.dismiss();
      this.modalInstances.splice(index, 1);
    });
  }

  dismissModal$(): Observable<void> {
    return from(this.modalController.dismiss()).pipe(map(() => void 0));
  }

  isModalActive$(): boolean {
    return !!this.modalInstances?.length;
  }

  async presentAlert(opts: AlertOptions) {
    const alert = await this.alertController.create({
      header: opts.header,
      subHeader: opts.subHeader,
      message: opts.message,
      buttons: opts.buttons,
    });

    await alert.present();
  }

  showToast(opts: ToastOptions): Observable<void> {
    return from(this.toastController.create(opts)).pipe(
      switchMap((toast: HTMLIonToastElement) => from(toast.present()))
    );
  }

  async showLoading(opts: LoadingOptions): Promise<void> {
    const loading: HTMLIonLoadingElement = await this.loadingController.create({
      ...opts,
      spinner: opts?.spinner ?? "bubbles",
      duration: opts?.duration ?? 5000,
      cssClass: "ion-loading-bg-none",
    });

    loading.present();
    this.storeLoading(loading);
  }

  async dismissLoading(): Promise<void> {
    [...this.loadingInstances].forEach((loading, index) => {
      loading.dismiss();
      this.loadingInstances.splice(index, 1);
    });
  }

  private storeModal(modal: any): void {
    this.modalInstances.push(modal);
  }
  private storeLoading(loading: any): void {
    this.loadingInstances.push(loading);
  }
}
