import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { PlayerPosition } from "@core";

export const SEND_APPLICATION_COMMAND: InjectionToken<SendApplicationCommandPort> =
  new InjectionToken<SendApplicationCommandPort>("SEND_APPLICATION_COMMAND");

export interface SendApplicationCommandPort {
  sendApplication(gameId: string, position: PlayerPosition): Observable<void>;
}
