<img src="assets/intro/blue-background.svg" class="intro-header" />

<div *ngIf="showElement" [@slideInOutFromRight] class="or-mb-96-px">
  <div
    class="or-d-flex or-justify-content-center or-mt-48-px or-mb-32-px or-w-100-pc"
  >
    <img src="assets/intro/intro-1.svg" class="or-size-24" />
  </div>

  <div class="or-text-align-center or-mx-16-px">
    <div class="or-head-xl or-mb-20-px">
      Wyszukuj gry w łatwy i szybki sposób!
    </div>

    <div class="or-text-lg or-color-secondary-2">
      Dzięki mapie w szybki sposób odnajdziesz najbliższą gierkę, filtrowanie
      pozwoli Ci dobrać gry które pasują do Twoich preferencji.
    </div>
  </div>
</div>

<img
  (click)="goToMap()"
  src="assets/right-arrow-button.svg"
  class="intro-next-button-position"
/>
