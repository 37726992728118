<div
  [class.full-height-scrollable-container]="!isiOS"
  [class.full-height-scrollable-container-ios]="isiOS"
>
  <div *ngFor="let group of games$ | async">
    <div class="or-mt-24-px or-head-md or-color-secondary-2 or-pl-20-px">
      {{ group.date }}
    </div>
    <div *ngFor="let game of group.games" class="or-my-12-px">
      <lib-list-game-card [game]="game"></lib-list-game-card>
    </div>
  </div>
</div>
