import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  shareReplay,
} from "rxjs";
import {
  GameDetailsModel,
  GameStatusEnum,
  IsAuthedService,
  MODAL_TOKEN,
  ModalProvider,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { CommonModule } from "@angular/common";
import { AppRoutes } from "../../../../../../src/app/app-routes.enum";
import { Router } from "@angular/router";
import { provideGameApplicationsService } from "../../infrastructure/http-service";
import { SendApplicationDirective } from "../../application/directives";
import { GamesRoutes } from "../../games-routes.enum";
import { HostedGamesRoutes } from "../../../../../hosted-games/src/lib/hosted-games.routes.enum";
import { SendApplicationCommandHandler } from "../../application/handlers";

@Component({
  selector: "lib-join-buttons",
  templateUrl: "./join-buttons.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SendApplicationDirective],
  providers: [provideGameApplicationsService(), SendApplicationCommandHandler],
})
export class JoinButtonsComponent implements OnInit {
  @Input({ required: true }) game!: GameDetailsModel;
  @Output() applicationSent: EventEmitter<string> = new EventEmitter<string>();

  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly isAuthedService: IsAuthedService = inject(IsAuthedService);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);

  private readonly gameDetailsSubject: BehaviorSubject<GameDetailsModel> =
    new BehaviorSubject<GameDetailsModel>({} as GameDetailsModel);

  readonly game$: Observable<GameDetailsModel> =
    this.gameDetailsSubject.asObservable();

  readonly isAppliedGame$: Observable<boolean> = this.game$.pipe(
    map((game: GameDetailsModel) => game.gameStatus === GameStatusEnum.Applied),
    shareReplay(1)
  );

  readonly isApprovedGame$: Observable<boolean> = this.game$.pipe(
    map(
      (game: GameDetailsModel) => game.gameStatus === GameStatusEnum.Approved
    ),
    shareReplay(1)
  );

  readonly isAuthed$: Observable<boolean> = this.isAuthedService.isAuthed$;

  readonly isMyGame$: Observable<boolean> =
    this.userDataProvider.userData$.pipe(
      map((userData: UserModel) => userData.userId === this.game.host?.hostId),
      shareReplay(1)
    );

  readonly showJoinButton$: Observable<boolean> = combineLatest([
    this.isMyGame$,
    this.isAuthed$,
    this.isAppliedGame$,
    this.isApprovedGame$,
  ]).pipe(
    map(
      ([isMyGame, isAuthed, isApplied, isApproved]) =>
        !isMyGame && isAuthed && !isApplied && !isApproved
    )
  );

  readonly showWaitingButton$: Observable<boolean> = combineLatest([
    this.isMyGame$,
    this.isAuthed$,
    this.isAppliedGame$,
  ]).pipe(
    map(([isMyGame, isAuthed, isApplied]) => !isMyGame && isAuthed && isApplied)
  );

  readonly showJoinedButton$: Observable<boolean> = combineLatest([
    this.isMyGame$,
    this.isAuthed$,
    this.isApprovedGame$,
  ]).pipe(
    map(
      ([isMyGame, isAuthed, isApproved]) => !isMyGame && isAuthed && isApproved
    )
  );

  ngOnInit(): void {
    this.gameDetailsSubject.next(this.game);
  }

  redirectToAuth(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigate([AppRoutes.AUTH]);
  }

  redirectToGameDetails(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigate([
      `${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${this.game.gameId}`,
    ]);
  }

  redirectToHostedGameDetails(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigate([
      `${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${this.game.gameId}`,
    ]);
  }

  applicationSentEvent(gameId: string) {
    this.applicationSent.emit(gameId);
  }
}
