import { SocialMetaTagInfo } from "../interfaces";
import { DynamicLinkType } from "../enums";

export const socialMetaTagInfoMapper: Record<
  DynamicLinkType,
  SocialMetaTagInfo
> = {
  [DynamicLinkType.GAME]: {
    socialTitle: "Orlikfy",
    socialDescription: "Pozwól by gierki znajdowały się same!",
    socialImageLink:
      "https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/assets%2Fsocial-link-image-v5.jpeg?alt=media&token=92205f42-c539-4db1-913d-761bd5aabdfe",
  },
  [DynamicLinkType.CHANNEL]: {
    socialTitle: "Orlikfy",
    socialDescription: "Zapraszam do dołączenia do naszego chatu drużyny!",
    socialImageLink:
      "https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/assets%2Fsocialimagelink_chat.png?alt=media&token=b35a25e0-3ae3-4b7f-8fe4-2685d9a326ee",
  },
  [DynamicLinkType.REFERRAL]: {
    socialTitle: "Orlikfy",
    socialDescription: "Pozwól by gierki znajdowały się same!",
    socialImageLink:
      "https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/assets%2Fsocial-link-image-v5.jpeg?alt=media&token=92205f42-c539-4db1-913d-761bd5aabdfe",
  },
};
