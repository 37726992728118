<div class="or-my-32-px or-mx-24-px">
  <div class="or-d-flex or-justify-content-center">
    <span class="or-head-xl or-mb-16-px">Dodaj nowego gracza</span>
  </div>
  <div
    class="or-d-flex or-justify-content-center or-text-align-center or-mb-24-px"
  >
    <span class="or-text-xl"
      >Jeśli Gracz nie posiada aplikacji, możesz dodać go do gry poprzez ten
      formularz</span
    >
  </div>

  <div class="or-mb-24-px">
    <ng-container [formGroup]="addPLayerForm">
      <span class="or-color-secondary-2 or-head-xs or-mb-4-px">Imię</span>
      <input
        class="input-style or-mb-12-px outline-none or-w-100-pc"
        formControlName="name"
      />
      <span class="or-color-secondary-2 or-head-xs or-mb-4-px">Nazwisko</span>
      <input
        class="input-style or-mb-12-px outline-none or-w-100-pc"
        formControlName="surname"
      />
    </ng-container>
  </div>

  <div class="or-d-flex or-justify-content-center">
    <button
      class="button-secondary-style or-h-48-px or-w-60-pc or-head-md or-mr-8-px"
      (click)="closeModal()"
    >
      Anuluj
    </button>
    <button
      class="button-primary-style or-h-48-px or-w-60-pc or-head-md"
      (click)="addPlayer()"
      [disabled]="!addPLayerForm.valid"
      [ngClass]="{ 'disabled-button': !addPLayerForm.valid }"
    >
      Dodaj
    </button>
  </div>
</div>
