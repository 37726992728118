import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import {
  GET_FUTURE_GAMES_QUERY,
  GetFutureGamesQueryPort,
} from "../../application/ports";
import { CommonModule } from "@angular/common";
import {
  BehaviorSubject,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from "rxjs";
import {
  BottomNavbarComponent,
  GameCardComponent,
  GameDetailsComponent,
  GamesMenuComponent,
  RedirectTypeEnum,
  TitileNavbarComponent,
} from "@ui-components";
import { provideFutureGamesQuery } from "../../application/handlers";
import { AppRoutes } from "src/app/app-routes.enum";
import { GamesRoutes } from "../../games-routes.enum";
import { Router } from "@angular/router";
import { FutureGameDTO } from "../../application/interfaces";
import { GameDetailsModel, PLATFORM_TOKEN, PlatformProvider } from "@core";
import {
  CREATE_SINGLE_CHAT_COMMAND,
  CreateSingleChatCommandPort,
  provideChatService,
  provideCreateSingleChatCommand,
} from "@messages";

interface ChipsValue {
  name: string;
  class: string;
}

@Component({
  selector: "lib-future-games",
  templateUrl: "./future-games.component.html",
  styleUrls: ["./future-games.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TitileNavbarComponent,
    BottomNavbarComponent,
    GamesMenuComponent,
    GameCardComponent,
    GameDetailsComponent,
  ],
  providers: [
    provideFutureGamesQuery(),
    provideCreateSingleChatCommand(),
    provideChatService(),
  ],
})
export class FutureGamesComponent {
  public redirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;

  private readonly gamesTypeSubject: BehaviorSubject<keyof FutureGameDTO> =
    new BehaviorSubject<keyof FutureGameDTO>("approved");

  private showPlaceholderSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isPageLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private readonly getFutureGamesQueryPort: GetFutureGamesQueryPort = inject(
    GET_FUTURE_GAMES_QUERY
  );
  private readonly createSingleChatCommandPort: CreateSingleChatCommandPort =
    inject(CREATE_SINGLE_CHAT_COMMAND);
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  readonly games$: Observable<GameDetailsModel[]> = this.gamesTypeSubject
    .asObservable()
    .pipe(
      switchMap((type: keyof FutureGameDTO) =>
        type === "approved"
          ? this.getFutureGamesQueryPort.getApproved()
          : this.getFutureGamesQueryPort.getWaiting()
      ),
      tap((games: GameDetailsModel[]) =>
        this.showPlaceholderSubject.next(!games.length)
      ),
      tap(() => this.isPageLoaded$.next(true))
    );

  readonly showPlaceholder$: Observable<boolean> = this.showPlaceholderSubject
    .asObservable()
    .pipe(shareReplay(1));

  readonly chipName: Record<keyof FutureGameDTO, ChipsValue> = {
    ["approved"]: { name: "Potwierdzona", class: "approved" },
    ["waiting"]: { name: "Oczekująca", class: "waiting" },
    ["invitations"]: { name: "Zaproszenie", class: "invited" },
  };

  readonly chips$: Observable<ChipsValue> = this.gamesTypeSubject
    .asObservable()
    .pipe(map((type: keyof FutureGameDTO) => this.chipName[type]));

  readonly isiOS: boolean = this.platformProvider.isiOS;

  get isApprovedTabActive() {
    return this.gamesTypeSubject.getValue() === "approved";
  }

  changeDataSource(event: keyof FutureGameDTO): void {
    this.gamesTypeSubject.next(event);
  }

  goToFutureGames(): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.FUTURE}`]);
  }

  goToPastGames(): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.PAST}`]);
  }

  sendMessage(userId: string): void {
    this.createSingleChatCommandPort.create(userId).subscribe();
  }
}
