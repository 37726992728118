import { inject, InjectionToken } from "@angular/core";
import { ModalService } from "../services/modal.service";
import { from, map, Observable, switchMap } from "rxjs";
import {
  AlertOptions,
  LoadingOptions,
  ModalOptions,
  ToastOptions,
} from "@ionic/angular";
import { OverlayEventDetail } from "@ionic/core";

export const MODAL_TOKEN: InjectionToken<ModalProvider> =
  new InjectionToken<ModalProvider>("MODAL_TOKEN", {
    factory: (): ModalProvider => {
      const modalService: ModalService = inject(ModalService);

      return {
        showModal$<T>(opts: ModalOptions): Observable<T> {
          return from(modalService.showModal(opts)).pipe(
            switchMap((modal: HTMLIonModalElement) => modal.onDidDismiss()),
            map(
              (modalResult: OverlayEventDetail<T | undefined>) =>
                modalResult?.data as T
            )
          );
        },

        dismissAllStoredModals(): void {
          modalService.dismissAllStoredModals();
        },

        dismissModal$(): Observable<void> {
          return from(modalService.dismissModal$());
        },

        presentAlert$(opts: AlertOptions): Observable<void> {
          return from(modalService.presentAlert(opts));
        },

        showToast$(opts: ToastOptions): Observable<void> {
          return modalService.showToast(opts);
        },
        showLoading$(opts: LoadingOptions): Observable<void> {
          return from(modalService.showLoading(opts));
        },
        dismissLoading$(): Observable<void> {
          return from(modalService.dismissLoading());
        },
        isModalActive(): boolean {
          return modalService.isModalActive$();
        },
      };
    },
  });

export interface ModalProvider {
  dismissAllStoredModals(): void;
  dismissModal$(): Observable<void>;
  showModal$<T>(opts: ModalOptions): Observable<T>;
  presentAlert$(opts: AlertOptions): Observable<void>;
  showToast$(opts: ToastOptions): Observable<void>;
  showLoading$(opts?: LoadingOptions): Observable<void>;
  dismissLoading$(): Observable<void>;
  isModalActive(): boolean;
}
