import { RemovePlayerCommandPort } from "../ports";
import {
  HOSTED_GAMES_DTO,
  HostedGamesDTOPort,
} from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { Observable } from "rxjs";

export class RemovePlayerCommandHandler implements RemovePlayerCommandPort {
  private readonly hostedGamesDTOPort: HostedGamesDTOPort =
    inject(HOSTED_GAMES_DTO);

  remove(gameId: string, playerId: string): Observable<void> {
    return this.hostedGamesDTOPort.removePlayer(gameId, playerId);
  }
}
