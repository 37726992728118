<lib-title-navbar
  title="Zobacz składy"
  [showRandomize]="(isHost$ | async) || false"
  (onRandomizeClicked)="shufflePlayers()"
></lib-title-navbar>

<div
  *ngIf="players$ | async as players"
  class="or-px-16-px or-pt-20-px"
  [class.full-height-scrollable-container]="!isiOS && !(isHost$ | async)"
  [class.full-height-scrollable-container-ios]="isiOS && !(isHost$ | async)"
  [class.full-height-scrollable-container-host]="!isiOS && (isHost$ | async)"
  [class.full-height-scrollable-container-ios-host]="isiOS && (isHost$ | async)"
>
  <ion-reorder-group
    (ionItemReorder)="doReorder($event)"
    [disabled]="!(isHost$ | async)"
  >
    <ng-container *ngFor="let player of players; let i = index">
      <div>
        <div *ngIf="i === 0" class="or-head-xs or-color-secondary-2">
          Skład 1
        </div>
        <div
          *ngIf="i === this.splitTeamSize(players)"
          class="or-head-xs or-color-secondary-2"
        >
          Skład 2
        </div>
        <div
          class="or-d-flex or-align-items-center or-justify-content-between or-py-8-px lineups-border"
          [class.or-mb-32-px]="i === this.splitTeamSize(players) - 1"
        >
          <div class="or-d-flex or-align-items-center">
            <lib-or-avatar
              [avatarUrl]="player.userThumbnail"
              [initials]="player.userDisplayName"
            ></lib-or-avatar>

            <div>
              <div class="or-head-sm or-truncate-at-line-1">
                {{ player.userDisplayName }}
              </div>
              <div
                *ngIf="player.playerPreferences"
                class="or-note-sm or-color-secondary-2"
              >
                {{
                  preferredPositionMapper[
                    player.playerPreferences.preferredPosition
                  ]
                }}
                /
                {{ gameLevelMapper[player.playerPreferences.preferredLevel] }}
              </div>
            </div>
          </div>

          <ion-reorder slot="end">
            <img src="assets/icons/reorder.svg" />
          </ion-reorder>
        </div>
      </div>
    </ng-container>
  </ion-reorder-group>
</div>

<div
  *ngIf="isHost$ | async"
  class="or-w-100-pc or-d-flex or-justify-content-center"
>
  <button
    (click)="save()"
    class="button-primary-style or-h-48-px or-head-md or-w-100-pc or-mx-32-px or-mt-12-px"
  >
    Zapisz składy!
  </button>
</div>
