<ng-container *ngIf="userDetails$ | async as userDetails">
  <ng-container *ngIf="isPageLoaded$ | async">
    <lib-title-navbar
      [showSettings]="userDetails.isMyProfile"
      (onSettingsClicked)="goToSettings()"
      [title]="userDetails.isMyProfile ? 'Mój profil' : 'Profil'"
    ></lib-title-navbar>

    <div class="top-container"></div>

    <div
      class="or-d-flex or-position-relative or-justify-content-center z-index-999 negative-top-margin-35-px"
    >
      <lib-or-avatar
        [avatarUrl]="userDetails.thumbnail"
        [isProfile]="true"
        [initials]="userDetails.displayName"
      ></lib-or-avatar>
    </div>

    <div class="or-d-flex or-flex-col or-align-items-center or-mt-12-px">
      <div class="or-head-lg">{{ userDetails.displayName }}</div>
      <div class="or-text-sm or-color-secondary-2">
        Aktywny {{ calculatelastActive(userDetails.lastActive) }}
      </div>
    </div>

    <div
      (click)="openNotificationPermissions()"
      *ngIf="!(isNotificationPermissionsEnabled$ | async)"
      class="or-d-flex or-justify-content-center or-mt-12-px"
    >
      <div
        class="or-bg-shade-2 or-p-4-px border-black border-radius-8 or-text-md"
      >
        Włącz powiadomienia w aplikacji
      </div>
    </div>

    <div>
      <div class="or-mt-24-px">
        <lib-user-stats [userStats]="userDetails.userStats"></lib-user-stats>
      </div>

      <div
        *ngIf="userDetails.playerPreferences"
        class="or-mt-32-px or-mx-24-px"
      >
        <lib-player-preferences
          [playerPreferences]="userDetails.playerPreferences"
        ></lib-player-preferences>
      </div>
      <div
        *ngIf="userDetails.penaltyHistory?.length || userDetails.activePenalty"
        class="or-mx-24-px or-mt-24-px"
      >
        <lib-card-history
          [penaltyHistory]="userDetails.penaltyHistory"
          [activePenalty]="userDetails.activePenalty"
        ></lib-card-history>
      </div>
    </div>
  </ng-container>
</ng-container>
<lib-bottom-navbar></lib-bottom-navbar>
