export interface CreateSingleChatResponseDTO {
  readonly justCreated: boolean;
  readonly channelId: string;
  readonly channelName: string;
  readonly channelExternalId: string;
  readonly member: {
    readonly userId: string;
    readonly displayName: string;
    readonly thumbnail: null;
  };
}
