import { GetInvitationsListForHostQueryPort } from "../ports";
import { inject } from "@angular/core";
import {
  INVITATIONS_DTO,
  InvitationsDtoPort,
} from "../../infrastructure/http-service";
import { Observable, switchMap } from "rxjs";
import { ActivatedRoute, Params } from "@angular/router";
import { InvitationsDTO } from "../interfaces";

export class GetInvitationsListForHostQueryHandler
  implements GetInvitationsListForHostQueryPort
{
  private readonly invitationsDtoPort: InvitationsDtoPort =
    inject(INVITATIONS_DTO);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getList(): Observable<InvitationsDTO> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) =>
        this.invitationsDtoPort.getList(params["id"])
      )
    );
  }
}
