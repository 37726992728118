import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { FiltersValues } from "../models";

export const SAVE_QUICK_FILTERS_COMMAND: InjectionToken<SaveQuickFiltersCommandPort> =
  new InjectionToken<SaveQuickFiltersCommandPort>("SAVE_QUICK_FILTERS_COMMAND");

export interface SaveQuickFiltersCommandPort {
  saveQuickFilters(data: FiltersValues): Observable<void>;
}
