import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import {
  GameDTO,
  MODAL_TOKEN,
  ModalProvider,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
  UserRoleEnum,
} from "@core";
import { GameDetailsModalComponent } from "@games";
import { DateFormatPipe } from "@ui-components";
import { AsyncPipe, NgIf } from "@angular/common";
import { map, Observable, switchMap, take } from "rxjs";

@Component({
  selector: "lib-list-game-card",
  templateUrl: "./list-game-card.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DateFormatPipe, NgIf, AsyncPipe],
  providers: [DateFormatPipe],
})
export class ListGameCardComponent {
  @Input({ required: true }) game!: GameDTO;

  private readonly modalToken: ModalProvider = inject(MODAL_TOKEN);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  readonly isAdmin$: Observable<boolean> = this.userDataProvider.userData$.pipe(
    map((data: UserModel) => data.role === UserRoleEnum.ADMIN)
  );

  openDetailsModal(): void {
    this.userDataProvider.userData$
      .pipe(
        take(1),
        switchMap((userData: UserModel) =>
          this.modalToken.showModal$({
            component: GameDetailsModalComponent,
            componentProps: {
              gameId: this.game?.gameId,
              adminAccess: userData.role === UserRoleEnum.ADMIN,
            },
            cssClass: "modal-auto",
            initialBreakpoint: 1,
            breakpoints: [0, 1],
          })
        )
      )
      .subscribe();
  }
}
