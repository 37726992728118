import { NgModule } from "@angular/core";
import { NotificationsRoutingModule } from "./notifications-routing.module";
import {
  provideMarkAsReadNotificationsCommand,
  provideNotificationsListQuery,
} from "./application/handlers";
import { provideNotificationsAlertsService } from "./infrastructure";

@NgModule({
  imports: [NotificationsRoutingModule],
  providers: [
    provideNotificationsListQuery(),
    provideMarkAsReadNotificationsCommand(),
    provideNotificationsAlertsService(),
  ],
})
export class NotificationsModule {}
