import { CustomNotificationQueryPort } from "../ports";
import { CustomNotificationDTO } from "../interfaces";
import { Observable, switchMap } from "rxjs";
import {
  CUSTOM_NOTIFICATION_DTO,
  CustomNotificationDtoPort,
} from "../../infrastructure";
import { inject } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

export class CustomNotificationQueryHandler
  implements CustomNotificationQueryPort
{
  private readonly customNotificationDtoPort: CustomNotificationDtoPort =
    inject(CUSTOM_NOTIFICATION_DTO);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getData(): Observable<CustomNotificationDTO> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) =>
        this.customNotificationDtoPort.getData(params["id"])
      )
    );
  }
}
