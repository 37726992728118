import { LogoutCommandPort } from "../ports";
import { AUTH_DTO, AuthDtoPort } from "../../infrastructure/http-services";
import { inject } from "@angular/core";
import { from, Observable, of, switchMap, tap } from "rxjs";
import { USER_DATA_TOKEN, UserDataProvider } from "@core";

export class LogoutCommandHandler implements LogoutCommandPort {
  private readonly authDtoPort: AuthDtoPort = inject(AUTH_DTO);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  logout(): Observable<void> {
    return from(this.authDtoPort.logout()).pipe(
      tap(() => this.userDataProvider.resetUserData()),
      switchMap(() => of(void 0))
    );
  }
}
