import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { Router } from "@angular/router";
import { CommonModule } from "@angular/common";
import { TabsDataModel } from "./tabs-data.interface";
import { tabsData } from "./tabs-data";
import { PLATFORM_TOKEN, PlatformProvider } from "@core";
import { Observable } from "rxjs";
import {
  UNREAD_MESSAGES_QUERY,
  UnreadMessagesQueryPort,
} from "projects/messages/src/lib/application/ports/unread-messages.query-port";

@Component({
  selector: "lib-bottom-navbar",
  templateUrl: "./bottom-navbar.component.html",
  styleUrls: ["./bottom-navbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class BottomNavbarComponent {
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly unreadMessagesQueryPort: UnreadMessagesQueryPort = inject(
    UNREAD_MESSAGES_QUERY
  );

  public tabsData: TabsDataModel[] = tabsData;

  readonly isUnreadMessage$: Observable<boolean> =
    this.unreadMessagesQueryPort.isUnreadMessage();

  readonly isiOS: boolean = this.platformProvider.isiOS;

  constructor() {
    this.updateTabs();
  }

  private updateTabs(): void {
    this.tabsData = tabsData.map((tab: TabsDataModel) => ({
      ...tab,
      icon: this.isActive(tab.id)
        ? `assets/icons/bottom-navbar/bottom-${tab.id}-filled.svg`
        : `assets/icons/bottom-navbar/bottom-${tab.id}.svg`,
    }));
  }

  isActive(url: string): boolean {
    return this.router.url.includes(url);
  }

  redirect(route: string) {
    return this.router.navigate([`/${route}`]);
  }
}
