import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import { InvitationsDtoPort } from "./invitations.dto-port";
import {
  InvitationsDTO,
  InvitePlayersRequestDTO,
} from "../../../application/interfaces";

export class InvitationsService implements InvitationsDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getList(gameId: string): Observable<InvitationsDTO> {
    return this.httpClient.get<InvitationsDTO>(
      `${this.env.apiUrl}/hosted-games/${gameId}/invitations/candidates`
    );
  }

  invite(gameId: string, payload: InvitePlayersRequestDTO): Observable<void> {
    return this.httpClient.post<void>(
      `${this.env.apiUrl}/hosted-games/${gameId}/invitations`,
      payload
    );
  }

  decline(invitaionId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.env.apiUrl}/game-invitations/${invitaionId}/reject`
    );
  }
}
