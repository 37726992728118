import { Router, UrlTree } from "@angular/router";
import { inject } from "@angular/core";
import { Observable, of, switchMap } from "rxjs";
import { AppRoutes } from "src/app/app-routes.enum";
import { IsAuthedService } from "@core";

export const isAuthedGuard = (): Observable<UrlTree | boolean> => {
  const isAuthedService: IsAuthedService = inject(IsAuthedService);
  const router: Router = inject(Router);

  return isAuthedService.isAuthed$.pipe(
    switchMap((isAuthed: boolean) => {
      if (!isAuthed) return of(router.createUrlTree([AppRoutes.AUTH]));

      return of(true);
    })
  );
};
