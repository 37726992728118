import { Routes } from "@angular/router";
import { AdminPanelRoutes } from "./admin-panel.routes.enum";
import { AdminHomePage, DeveloperSettingsPage, GamesAdminPage } from "./pages";
import { GeneratePostComponent } from "./components/generate-post/generate-post.component";
import { UsersHomeComponent } from "./components";

export const AdminPanelRouting: Routes = [
  {
    path: AdminPanelRoutes.ROOT,
    component: AdminHomePage,
  },
  {
    path: AdminPanelRoutes.DEVELOPER_SETTINGS,
    component: DeveloperSettingsPage,
  },
  {
    path: AdminPanelRoutes.USERS,
    component: UsersHomeComponent,
  },
  {
    path: AdminPanelRoutes.GAMES,
    component: GamesAdminPage,
  },
  {
    path: AdminPanelRoutes.GENERATE_POST,
    component: GeneratePostComponent,
  },
];
