import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { InvitationsDTO } from "../../interfaces";

export const GET_INVITATIONS_LIST_FOR_HOST_QUERY: InjectionToken<GetInvitationsListForHostQueryPort> =
  new InjectionToken<GetInvitationsListForHostQueryPort>(
    "GET_INVITATIONS_LIST_FOR_HOST_QUERY"
  );

export interface GetInvitationsListForHostQueryPort {
  getList(): Observable<InvitationsDTO>;
}
