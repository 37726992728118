import { InjectionToken } from "@angular/core";
import { CredentialsDTO } from "../../application/models";
import { Observable } from "rxjs";

export const AUTH_DTO: InjectionToken<AuthDtoPort> =
  new InjectionToken<AuthDtoPort>("AUTH_DTO");

export interface AuthDtoPort {
  login(credentials: CredentialsDTO): any;
  register(credentials: CredentialsDTO): any;
  logout(): any;
  removeAccount(): any;
  reauthenticate(password: string): any;
  authWithGoogle(): any;
  authWithApple(): any;
  setReferrer(referrerId: string): Observable<void>;
}
