import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { AsyncPipe, NgIf } from "@angular/common";
import { OrAvatarComponent } from "@ui-components";
import { MODAL_TOKEN, ModalProvider } from "@core";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { TeamDetailsDTO } from "../../application/interfaces";
import { TeamsRoutes } from "../../teams-routes.enum";

@Component({
  selector: "lib-manage-team",
  templateUrl: "./manage-team.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, OrAvatarComponent, AsyncPipe],
  providers: [],
})
export class ManageTeamComponent {
  @Input() team!: TeamDetailsDTO;
  @Input() hostId!: string;

  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  editTeam(): void {}

  removeTeam(): void {}

  addMember(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigateByUrl(
      `${AppRoutes.TEAMS}/${this.team.teamId}/${TeamsRoutes.INVITE}`
    );
  }

  shareTeam(): void {}
}
