import { NgModule } from "@angular/core";
import { AuthRoutingModule } from "./auth-routing.module";
import { provideAuthService } from "./infrastructure/http-services";
import { AuthHandlersModule } from "./application/handlers/auth.handlers.module";

@NgModule({
  imports: [AuthRoutingModule, AuthHandlersModule],
  providers: [provideAuthService()],
})
export class AuthModule {}
