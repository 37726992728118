import { inject, Injectable, Injector } from "@angular/core";
import { filter, switchMap, take, tap } from "rxjs";
import { App } from "@capacitor/app";
import { Router } from "@angular/router";
import {
  DeepLinkService,
  GlobalRefreshService,
  IsAuthedService,
  MODAL_TOKEN,
  ModalProvider,
  NAVIGATION_TOKEN,
  NavigationProvider,
  USER_DATA_TOKEN,
  UserDataProvider,
} from "@core";
import {
  NotificationsInitializer,
  PushPermissionsStatusCommandHandler,
  UpdateAlertsLocationCommandHandler,
} from "@notifications";
import { AppRoutes } from "./app-routes.enum";
import { setReferrerIdUtil } from "@auth";
import { LoadFeatureFlagsCommandHandler } from "@feature-flags";

@Injectable({ providedIn: "root" })
export class StartupV1Service {
  private readonly navigationProvider: NavigationProvider =
    inject(NAVIGATION_TOKEN);
  private readonly loadFeatureFlagsCommandHandler: LoadFeatureFlagsCommandHandler =
    inject(LoadFeatureFlagsCommandHandler);
  private readonly notificationInitializer: NotificationsInitializer = inject(
    NotificationsInitializer
  );
  private readonly deepLinkService: DeepLinkService = inject(DeepLinkService);
  private readonly isAuthedService: IsAuthedService = inject(IsAuthedService);
  private readonly globalRefreshService: GlobalRefreshService =
    inject(GlobalRefreshService);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly updateAlertsLocationCommandHandler: UpdateAlertsLocationCommandHandler =
    inject(UpdateAlertsLocationCommandHandler);
  private readonly pushPermissionsStatusCommandHandler: PushPermissionsStatusCommandHandler =
    inject(PushPermissionsStatusCommandHandler);
  private readonly injector: Injector = inject(Injector);

  private backButtonPressedOnce: boolean = false;

  init(): void {
    this.navigationProvider.init();
    this.deepLinkService.initializeDeepLinking();
    this.setupAppResumeListener();
    this.initBackButton();

    this.isAuthedService.isAuthed$
      .pipe(
        filter((isAuthed: boolean) => isAuthed),
        take(1),
        switchMap(() => this.modalProvider.showLoading$()),
        tap(() => this.notificationInitializer.init()),
        tap(() => this.userDataProvider.setUserData()),
        switchMap(() => this.loadFeatureFlagsCommandHandler.loadFLags()),
        tap(() => this.setupAlerts()),
        switchMap(() => setReferrerIdUtil(this.injector)),
        switchMap(() => this.modalProvider.dismissLoading$()),
        take(1)
      )
      .subscribe();
  }

  private setupAlerts(): void {
    this.updateAlertsLocationCommandHandler
      .setLocation()
      .pipe(take(1))
      .subscribe();

    this.pushPermissionsStatusCommandHandler
      .setValue()
      .pipe(take(1))
      .subscribe();
  }

  private setupAppResumeListener(): void {
    App.addListener("appStateChange", (state) => {
      if (state.isActive) {
        this.globalRefreshService.emit();
      }
    });
  }

  private initBackButton(): void {
    App.addListener("backButton", () => {
      if (this.modalProvider.isModalActive()) {
        this.modalProvider.dismissAllStoredModals();
      } else if (this.router.url.includes(AppRoutes.DASHBOARD)) {
        if (this.backButtonPressedOnce) {
          App.exitApp();
        } else {
          this.backButtonPressedOnce = true;
          setTimeout(() => {
            this.backButtonPressedOnce = false;
          }, 500);
        }
      } else {
        this.navigationProvider.back();
      }
    });
  }
}
