import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { GAMES_LIST_QUERY, GamesListQueryPort } from "../../application/ports";
import { Observable, switchMap, tap } from "rxjs";
import { provideGamesListQuery } from "../../application/handlers";
import { provideNearbyGamesService } from "../../infrastructure/http-services";
import {
  MODAL_TOKEN,
  ModalProvider,
  NearbyGamesGame,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { AsyncPipe, NgForOf } from "@angular/common";
import { ListGameCardComponent } from "../list-game-card/list-game-card.component";
import { GamesListModel } from "../../application/interfaces";

@Component({
  selector: "lib-games-list",
  templateUrl: "./games-list.component.html",
  styleUrls: ["./games-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, AsyncPipe, ListGameCardComponent],
  providers: [provideGamesListQuery(), provideNearbyGamesService()],
})
export class GamesListComponent {
  private readonly gamesListQueryPort: GamesListQueryPort =
    inject(GAMES_LIST_QUERY);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly games$: Observable<GamesListModel[]> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.gamesListQueryPort.getGames()),
      tap(() => this.modalProvider.dismissLoading$())
    );
}
