import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { ChatChanelDTO } from "../interfaces";

export const AVAILABLE_CHANNELS_QUERY: InjectionToken<AvailableChannelsQueryPort> =
  new InjectionToken<AvailableChannelsQueryPort>("AVAILABLE_CHANNELS_QUERY");

export interface AvailableChannelsQueryPort {
  availableChannels(): Observable<ChatChanelDTO[]>;
}
