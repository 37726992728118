import { IsFiltersAppliedQueryPort } from "../ports";
import {
  FILTERS_CONTEXT_PORT,
  FiltersContextPort,
} from "../../infrastructure/storages";
import { inject } from "@angular/core";
import { map, Observable } from "rxjs";
import { FiltersValues } from "../models";
import { ShowFrom } from "@core";

export class IsFiltersAppliedQueryHandler implements IsFiltersAppliedQueryPort {
  private filtersContextPort: FiltersContextPort = inject(FILTERS_CONTEXT_PORT);

  isApplied(): Observable<boolean> {
    return this.filtersContextPort.filters$.pipe(
      map((filters: FiltersValues) => {
        return (
          filters.time.max !== 24 ||
          filters.time.min !== 0 ||
          (filters.gameLevel && filters.gameLevel.length > 0) ||
          filters.specificDaysType !== ShowFrom.ALL ||
          (filters.specificWeekdays && filters.specificWeekdays.length > 0) ||
          filters.specificDateFrom !== null ||
          filters.specificDateTo !== null
        );
      })
    );
  }
}
