import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MessagesRoutes } from "./messages-routes.enum";
import {
  ChannelInvitationsComponent,
  ChatComponent,
  ChatsListComponent,
  EditChannelComponent,
  ManageMembersComponent,
} from "./components";
import { isChannelLoadedGuard } from "./application/guards";
import { chatInvitationsResolver } from "./application/resolvers";
import { isAuthedGuard, referrerResolver } from "@auth";

const routes: Routes = [
  {
    path: MessagesRoutes.ROOT,
    resolve: { referrerResolver },
    children: [
      {
        path: MessagesRoutes.ROOT,
        component: ChatsListComponent,
        canActivate: [isAuthedGuard],
      },
      {
        path: MessagesRoutes.CHAT,
        component: ChatComponent,
        canActivate: [isChannelLoadedGuard, isAuthedGuard],
      },
      {
        path: `:id/${MessagesRoutes.EDIT_CHANNEL}`,
        component: EditChannelComponent,
        canActivate: [isAuthedGuard],
      },
      {
        path: `:id/${MessagesRoutes.MANAGE_MEMBERS}`,
        component: ManageMembersComponent,
        canActivate: [isAuthedGuard],
      },
      {
        path: `${MessagesRoutes.CHANNEL_INVITATIONS}/:id`,
        component: ChannelInvitationsComponent,
        resolve: {
          chatInvitationsResolver,
          referrerResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MessagesRoutingModule {}
