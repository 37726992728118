import { DateFormatPipe } from "@ui-components";
import {
  GameDetailsModel,
  GameDTO,
  gameLevelMapper,
  PaymentType,
  paymentTypesMapper,
} from "@core";

export const mapToHostedGameQueryUtil = (
  game: GameDTO,
  dateFormatPipe: DateFormatPipe
): GameDetailsModel => {
  return {
    gameName: game.name,
    gameId: game.gameId,
    formattedDate: dateFormatPipe.transform(
      game.gameDateStart,
      game.gameDateEnd
    ),
    gameDateStart: game.gameDateStart,
    duration: getDuration(game.gameDateStart, game.gameDateEnd),
    location: game.location,
    totalSlots: game.totalSlots,
    occupiedSlots: game.occupiedSlots,
    level: gameLevelMapper[game.level],
    priceAmount: +game.priceAmount,
    paymentType: game.paymentTypes?.map(
      (type: PaymentType) => paymentTypesMapper[type]
    ),
    description: game.description ?? "",
    players: game.gamePlayers,
    gamePlayersLeft: game.gamePlayersLeft,
    playersApplications: game.playerApplications,
    gameContactPhone: game.gameContactPhone,
    visibility: game?.visibility,
    isRecurring: game?.isRecurring,
    channel: game?.channel,
    isHost: game.isHost,
  };
};

const getDuration = (startDate: string, endDate: string) => {
  const start: Date = new Date(startDate);
  const end: Date = new Date(endDate);
  const differenceInMilliseconds: number = end.getTime() - start.getTime();
  return differenceInMilliseconds / (1000 * 60);
};
