import { NgModule } from "@angular/core";
import { RegisterCommandHandler } from "./register.command-handler";
import {
  AUTH_WITH_PROVIDER_COMMAND,
  LOGIN_COMMAND,
  LOGOUT_COMMAND,
  REGISTER_COMMAND,
  REMOVE_ACCOUNT_COMMAND,
} from "../ports";
import { LoginCommandHandler } from "./login.command-handler";
import { LogoutCommandHandler } from "./logout.command-handler";
import { RemoveAccountCommandHandler } from "./remove-account.command-handler";
import { AuthWithProviderCommandHandler } from "./auth-with-provider.command-handler";

@NgModule({
  providers: [
    RegisterCommandHandler,
    LoginCommandHandler,
    {
      provide: REGISTER_COMMAND,
      useClass: RegisterCommandHandler,
    },
    {
      provide: LOGIN_COMMAND,
      useClass: LoginCommandHandler,
    },
    {
      provide: LOGOUT_COMMAND,
      useClass: LogoutCommandHandler,
    },
    {
      provide: REMOVE_ACCOUNT_COMMAND,
      useClass: RemoveAccountCommandHandler,
    },
    {
      provide: AUTH_WITH_PROVIDER_COMMAND,
      useClass: AuthWithProviderCommandHandler,
    },
  ],
})
export class AuthHandlersModule {}
