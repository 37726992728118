import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { combineLatest, combineLatestWith, take, tap } from "rxjs";
import { ActivatedRoute, ParamMap, Params } from "@angular/router";
import { environment } from "src/environment/environment";
import { getNewVersionWebAndroid, getNewVersionWebiOS } from "@update";
import { NgIf } from "@angular/common";

@Component({
  selector: "lib-mobile-landing",
  templateUrl: "./mobile-landing.component.html",
  styleUrls: ["./mobile-landing.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class MobileLandingComponent {
  @Input({ required: true }) showButtons!: boolean;
  @Input({ required: true }) showContinueWithWeb!: boolean;
  @Input({ required: true }) path!: string;

  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  redirectToNativeApp(): void {
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParamMap,
    ])
      .pipe(
        take(1),
        tap(([params, paramMap]: [Params, ParamMap]) => {
          const referrer: string = encodeURIComponent(
            paramMap.get("referrer") || ""
          );
          const url: string = `${environment.customUrl}/${this.path}/${params["id"]}?referrer=${referrer}`;
          window.open(url, "_blank");
        })
      )
      .subscribe();
  }

  downloadAndroid(): void {
    getNewVersionWebAndroid();
  }

  downloadiOS(): void {
    getNewVersionWebiOS();
  }

  redirectToWeb(): void {
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParamMap,
    ])
      .pipe(
        take(1),
        tap(([params, paramMap]: [Params, ParamMap]) =>
          window.open(
            `/games/shared/${params["id"]}?allow=true&referrer=${paramMap.get(
              "referrer"
            )}`,
            "_blank"
          )
        )
      )
      .subscribe();
  }
}
