import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation,
} from "@angular/core";
import { AuthFormComponent } from "../../components";
import { CredentialsDTO } from "../../application/models";
import {
  LOGIN_COMMAND,
  LoginCommandPort,
  REGISTER_COMMAND,
  RegisterCommandPort,
} from "../../application/ports";
import { AppRoutes } from "src/app/app-routes.enum";
import { Router } from "@angular/router";
import { MODAL_TOKEN, ModalProvider, PresentModalComponent } from "@core";
import { ProfileRoutes } from "projects/profile/src/lib/profile.routes.enum";

@Component({
  templateUrl: "./register.page.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [AuthFormComponent],
  standalone: true,
})
export class RegisterPage {
  private readonly registerCommandPort: RegisterCommandPort =
    inject(REGISTER_COMMAND);
  private readonly loginCommandPort: LoginCommandPort = inject(LOGIN_COMMAND);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  register(event: CredentialsDTO) {
    this.registerCommandPort
      .register({
        email: event.email?.trim(),
        password: event.password,
      })
      .subscribe({
        next: () => this.loginAndRedirect(event),
        error: () => this.errorMessage(),
      });
  }

  goBack(): void {
    this.router.navigate([`/${AppRoutes.AUTH}`]);
  }

  private errorMessage(): void {
    this.modalProvider.showModal$({
      component: PresentModalComponent,
      cssClass: "present-modal",
      componentProps: {
        header: "Rejestracja",
        message: "Sprawdź wprowadzone dane",
        btnTxt: "Zamknij",
      },
    });
  }

  private loginAndRedirect(credentials: CredentialsDTO) {
    this.loginCommandPort
      .login({
        email: credentials.email,
        password: credentials.password,
      })
      .subscribe(() =>
        this.router.navigate([`/${AppRoutes.PROFILE}/${ProfileRoutes.EDIT}`])
      );
  }
}
