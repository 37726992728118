import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { AsyncPipe, NgIf } from "@angular/common";
import { OrAvatarComponent } from "@ui-components";
import { ButtonLabels, MODAL_TOKEN, ModalProvider } from "@core";
import {
  provideAdvancedInvitationsContext,
  provideAdvancedInvitationsDTO,
  provideSwitchAdvancedInvitationsCommand,
  SWITCH_ADVANCED_INVITATIONS_COMMAND,
  SwitchAdvancedInvitationsCommandPort,
} from "@invitations";
import { TeamPlayer } from "../../application/interfaces";

@Component({
  selector: "lib-manage-team-member",
  templateUrl: "./manage-team-member.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, OrAvatarComponent, AsyncPipe],
  providers: [
    provideSwitchAdvancedInvitationsCommand(),
    provideAdvancedInvitationsContext(),
    provideAdvancedInvitationsDTO(),
  ],
})
export class ManageTeamMemberComponent {
  @Input() member!: TeamPlayer;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly switchAdvancedInvitationsCommandPort: SwitchAdvancedInvitationsCommandPort =
    inject(SWITCH_ADVANCED_INVITATIONS_COMMAND);

  get autoApproveText(): ButtonLabels {
    const header: string = this.member.isAutoApproved
      ? "Usuń z listy ulubionych graczy"
      : "Ulubiony gracz";

    const text: string = this.member.isAutoApproved
      ? "Wyłącz automatyczną akceptację"
      : "Kliknij, aby gracz byl automatycznie akceptowany";
    return { header, text };
  }

  removePlayer(): void {
    this.modalProvider.dismissAllStoredModals();
  }

  switchAutoAccept(): void {
    this.modalProvider.dismissAllStoredModals();
  }
}
