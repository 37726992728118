<ng-container *ngIf="gameDetails$ | async as game">
  <div class="or-p-16-px">
    <lib-game-details
      [game]="game"
      [showHostInfo]="true"
      [showNavigateLink]="true"
      [showPhoneNumber]="false"
      [adminAccess]="adminAccess"
      (onSendMessage)="sendMessage($event)"
    ></lib-game-details>

    <lib-join-buttons [game]="game"></lib-join-buttons>
  </div>
</ng-container>
