import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { TitileNavbarComponent } from "@ui-components";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { PLATFORM_TOKEN, PlatformProvider } from "@core";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { TeamsRoutes } from "../../teams-routes.enum";

@Component({
  selector: "lib-create-team",
  templateUrl: "./create-team.component.html",
  styleUrls: ["./create-team.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    NgClass,
    AsyncPipe,
  ],
})
export class CreateTeamComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly createTeamForm: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.maxLength(25)]),
  });

  isFieldInvalid(fieldName: string): boolean {
    const control = this.createTeamForm.get(fieldName);
    return control ? control.touched && control.invalid : false;
  }

  get isNameOverAvailableLength() {
    return this.createTeamForm.get("name")?.value?.length > 25;
  }

  createTeam(): void {
    const teamName: string = this.createTeamForm.get("name")?.value;

    this.router.navigateByUrl(
      `${AppRoutes.TEAMS}/123/${TeamsRoutes.CREATE_FINISH}`
    );

    console.log(teamName);
  }
}
