import { InjectionToken } from "@angular/core";
import { CredentialsDTO } from "../models";
import { Observable } from "rxjs";

export const REGISTER_COMMAND: InjectionToken<RegisterCommandPort> =
  new InjectionToken<RegisterCommandPort>("REGISTER_COMMAND");

export interface RegisterCommandPort {
  register(credentials: CredentialsDTO): Observable<void>;
}
