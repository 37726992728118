import {
  Directive,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
} from "@angular/core";
import { SEND_APPLICATION_COMMAND, SendApplicationCommandPort } from "../ports";
import {
  ActionModalComponent,
  MODAL_TOKEN,
  ModalProvider,
  PlayerPosition,
  PresentModalComponent,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { switchMap, take, tap } from "rxjs";
import { ChoosePositionComponent } from "../../components/choose-position/choose-position.component";
import { PlayerPositionViewService } from "../view-services";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { ProfileRoutes } from "@profile";

@Directive({
  standalone: true,
  selector: "[sendApplication]",
})
export class SendApplicationDirective {
  @Input("sendApplication") gameId!: string;
  @Input("showModal") showModal: boolean = true;
  @Output() applicationSent: EventEmitter<string> = new EventEmitter<string>();

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly router: Router = inject(Router);
  private playerPositionViewService: PlayerPositionViewService = inject(
    PlayerPositionViewService
  );
  private readonly sendApplicationCommandPort: SendApplicationCommandPort =
    inject(SEND_APPLICATION_COMMAND);

  @HostListener("click")
  onClick() {
    this.userDataProvider.userData$
      .pipe(
        take(1),
        tap((userData: UserModel) => {
          if (!userData.displayName.trim()) return this.showBlockedModal();

          return this.showPositionModal();
        })
      )
      .subscribe();
  }

  showBlockedModal(): void {
    this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: "present-modal",
      componentProps: {
        header: "Uzupełnij profil",
        message: "Aby dołączyć do gierki musisz uzupełnić profil",
        btnOk: "Przejdź do profilu",
        btnCancel: "Anuluj",
        action: () =>
          this.router.navigateByUrl(
            `${AppRoutes.PROFILE}/${ProfileRoutes.EDIT}`
          ),
      },
    });
  }

  private showPositionModal(): void {
    this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: "present-modal",
      componentProps: {
        header: "Na jakiej pozycji chciałbyś zagrać?",
        btnOk: "Dołącz do gierki!",
        btnCancel: "Anuluj",
        template: ChoosePositionComponent,
        action: () => this.sendApplication(),
      },
    });
  }

  private sendApplication(): void {
    this.playerPositionViewService.playerPosition$
      .pipe(
        take(1),
        switchMap((playerPosition: PlayerPosition) =>
          this.sendApplicationCommandPort.sendApplication(
            this.gameId,
            playerPosition
          )
        ),
        take(1)
      )
      .subscribe(() => {
        this.applicationSent.emit(this.gameId);
        this.showModal ? this.showMessage() : void 0;
      });
  }

  private showMessage(): void {
    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: "Dołączenie do gierki",
        message:
          "Wysłałeś chęć udziału zagrania w gierce. Teraz musisz poczekać, aż organizator Cię zaakceptuje. Czekaj na powiadomienie",
        btnTxt: "Rozumiem, dzięki!",
      },
      cssClass: "present-modal",
    });
  }
}
