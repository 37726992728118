import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalRefreshService {
  private readonly globalRefreshSubject: BehaviorSubject<void> =
    new BehaviorSubject<void>(void 0);

  readonly refresh$: Observable<void> =
    this.globalRefreshSubject.asObservable();

  emit(): void {
    this.globalRefreshSubject.next(void 0);
  }
}
