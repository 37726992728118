import { UserRoleEnum } from "@core";

export interface ChannelMembersResponseDTO {
  readonly members: ChannelMembersDTO[];
}

export interface ChannelMembersDTO {
  readonly userId: string;
  readonly displayName: string;
  readonly thumbnail: string;
  readonly role: UserRoleEnum;
}
