import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { BottomNavbarComponent, DateFormatPipe } from "@ui-components";
import { StreamChatModule } from "stream-chat-angular";
import {
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { BehaviorSubject, Observable, switchMap, take, tap } from "rxjs";
import { ChatNavbarComponent } from "../chat-navbar/chat-navbar.component";
import {
  ActiveChannelModel,
  ChannelDetailsDTO,
} from "../../application/interfaces";
import { ChatInitializer } from "../../application/initializers";
import { ManageChannelComponent } from "../manage-channel/manage-channel.component";
import { provideChannelDetailsQuery } from "../../application/handlers";
import {
  CHANNEL_DETAILS_QUERY,
  ChannelDetailsQueryPort,
} from "../../application/ports";

@Component({
  selector: "lib-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BottomNavbarComponent,
    StreamChatModule,
    NgClass,
    ChatNavbarComponent,
    NgIf,
    AsyncPipe,
    DateFormatPipe,
  ],
  providers: [provideChannelDetailsQuery(), DateFormatPipe],
})
export class ChatComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly chatInitializer: ChatInitializer = inject(ChatInitializer);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly channelDetailsQueryPort: ChannelDetailsQueryPort = inject(
    CHANNEL_DETAILS_QUERY
  );

  readonly isPageLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly isiOs: boolean = this.platformProvider.isiOS;

  readonly channelDetails$: Observable<ChannelDetailsDTO> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.chatInitializer.activeChannelData$),
      take(1),
      switchMap((data: ActiveChannelModel) =>
        this.channelDetailsQueryPort.details(data.channelOrlikfyId)
      ),
      take(1),
      tap(() => this.isPageLoaded$.next(true)),
      tap(() => this.modalProvider.dismissLoading$())
    );

  manageChannel(): void {
    this.channelDetails$
      .pipe(
        take(1),
        switchMap((data: ChannelDetailsDTO) =>
          this.modalProvider.showModal$({
            component: ManageChannelComponent,
            componentProps: {
              channelDetails: data,
            },
            cssClass: "modal-auto",
            initialBreakpoint: 1,
            breakpoints: [0, 1],
          })
        )
      )
      .subscribe();
  }

  stopWatching(): void {
    this.isPageLoaded$.next(false);
    this.chatInitializer.stopWatching();
  }
}
