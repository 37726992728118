<div
  class="or-align-items-center or-d-flex or-mt-24-px or-justify-content-center"
>
  <ion-checkbox
    (ionChange)="change($event)"
    [checked]="false"
    labelPlacement="end"
    justify="start"
    ><span class="or-head-sm">Nie pokazuj ponownie</span></ion-checkbox
  >
</div>
