import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { NgIf } from "@angular/common";
import { OrAvatarComponent } from "@ui-components";
import { switchMap } from "rxjs";
import {
  ActionModalComponent,
  GameDetailsModel,
  GameStatusEnum,
  MODAL_TOKEN,
  ModalProvider,
  NavigateDirective,
} from "@core";
import { AppRoutes } from "src/app/app-routes.enum";
import {
  REMOVE_APPLICATION_COMMAND,
  RemoveApplicationCommandPort,
} from "../../application/ports";
import { Router } from "@angular/router";
import { provideRemoveApplicationCommand } from "../../application/handlers";
import { provideGameApplicationsService } from "../../infrastructure/http-service";

@Component({
  selector: "lib-my-hosted-game",
  templateUrl: "./manage-my-game.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, OrAvatarComponent, NavigateDirective],
  providers: [
    provideRemoveApplicationCommand(),
    provideGameApplicationsService(),
  ],
})
export class ManageMyGameComponent {
  @Input() game!: GameDetailsModel;

  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly removeApplicationCommandPort: RemoveApplicationCommandPort =
    inject(REMOVE_APPLICATION_COMMAND);

  removeApplication(): void {
    this.modalProvider.showModal$({
      component: ActionModalComponent,
      componentProps: {
        header: "Rezygnacja z gierki",
        message: "Czy na pewno chcesz zrezygnować z gierki?",
        btnOk: "Zrezygnuj",
        btnCancel: "Anuluj",
        action: () =>
          this.removeApplicationCommandPort
            .remove(this.game.gameId, this.game.gameStatus as GameStatusEnum)
            .pipe(switchMap(() => this.router.navigate([AppRoutes.GAMES])))
            .subscribe(),
      },
      cssClass: "present-modal",
    });
  }

  editGame(): void {}
}
