import { RateApplicationCommandPort } from "../ports";
import { RATING_DTO, RatingDtoPort } from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { RatingDTO } from "../interfaces";
import { Observable } from "rxjs";

export class RateAppCommandHandler implements RateApplicationCommandPort {
  private readonly ratingDtoPort: RatingDtoPort = inject(RATING_DTO);

  rateApp(rating: RatingDTO): Observable<void> {
    return this.ratingDtoPort.rateApplication(rating);
  }
}
