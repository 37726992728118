<lib-title-navbar
  [showDots]="true"
  (onDotsClicked)="manageTeam()"
  title="Twoja grupka"
></lib-title-navbar>

<div
  class="or-px-16-px or-pt-16-px"
  [ngClass]="
    isiOS
      ? 'full-height-scrollable-container-ios'
      : 'full-height-scrollable-container'
  "
>
  <ng-container *ngIf="teamDetails$ | async as teamDetails">
    <lib-team-card
      [teamDetails]="teamDetails"
      [showInfoLabel]="true"
      [showShareIcon]="false"
    ></lib-team-card>

    <div
      *ngIf="teamDetails.memberRole === 'Member'"
      class="or-d-flex or-align-items-center or-mt-16-px"
    >
      <lib-or-avatar
        [avatarUrl]="teamDetails.host.thumbnail"
        [initials]="teamDetails.host.displayName"
        [redirectToUserProfile]="teamDetails.host.hostId"
      ></lib-or-avatar>
      <div class="or-d-flex or-justify-content-between or-w-100-pc">
        <div
          class="or-max-w-60-pc"
          [redirectToUserProfile]="teamDetails.host.hostId"
        >
          <div class="or-head-xs or-color-secondary-2">Organizator</div>
          <div class="or-head-sm">
            {{ teamDetails.host.displayName }}
          </div>
        </div>

        <div class="or-d-flex or-align-items-center">
          <img
            (click)="sendMessage(teamDetails.host.hostId)"
            src="assets/icons/message-host-icon.svg"
            class="or-mr-4-px"
          />

          <button
            [copyToClipboard]="teamDetails.host.phone"
            class="button-primary-style or-align-items-center or-d-flex or-head-md or-justify-content-between or-px-8-px or-h-32-px or-cursor-pointer"
            [class.disabled-button]="!teamDetails.host.phone"
          >
            <img src="assets/icons/phone-white.svg" class="or-mr-4-px" />
            <span
              class="or-color-white or-head-xs text-no-wrap"
              [class.disabled-button]="!teamDetails.host.phone"
              >Skopiuj numer
            </span>
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="teamDetails.members.length"
      class="or-head-xs or-color-secondary-2 or-mt-24-px or-mb-12-px"
    >
      Lista graczy w grupce
    </div>

    <div *ngFor="let member of teamDetails.members; let i = index">
      <div class="or-d-flex or-justify-content-between">
        <div
          class="or-d-flex or-align-items-center"
          [redirectToUserProfile]="member.userId"
        >
          <lib-or-avatar
            [avatarUrl]="member.thumbnail"
            [initials]="member.displayName"
          ></lib-or-avatar>

          <div
            class="or-head-sm or-truncate-at-line-1 or-mr-32-px or-d-flex or-align-items-center"
          >
            {{ i + 1 }}. {{ member.displayName }}

            <img
              *ngIf="member.memberRole !== 'Member'"
              src="assets/icons/team-capitan.svg"
              class="or-ml-4-px or-size-24-px"
            />

            <img
              *ngIf="
                member.isAutoApproved &&
                member.memberRole === 'Member' &&
                teamDetails.memberRole === 'Host'
              "
              src="assets/icons/autoapprove.svg"
              class="or-ml-4-px or-size-16-px"
            />
          </div>
        </div>
        <div
          *ngIf="member.userId !== (userId$ | async)"
          class="or-d-flex or-justify-content-end or-align-items-center"
        >
          <img
            (click)="sendMessage(member.userId)"
            src="assets/icons/message-icon.svg"
          />

          <img
            *ngIf="teamDetails.memberRole !== 'Member'"
            class="or-m-12-px"
            (click)="managePlayer(member, teamDetails.teamId)"
            src="assets/icons/multi-dots-icon.svg"
          />
        </div>
      </div>
      <div class="or-d-flex or-justify-content-center or-my-4-px">
        <div class="custom-divider-team-details or-my-4-px"></div>
      </div>
    </div>

    <div *ngIf="teamDetails.members.length < 2">
      <div
        (click)="inviteViaLink()"
        class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
      >
        <img
          src="assets/icons/share-shade-2.svg"
          class="or-mr-8-px or-size-32-px"
        />

        <div>
          <div class="or-text-md">Zaproś za pomocą linku</div>
          <div class="or-text-sm or-color-secondary-2">
            Osoba z linkiem zostanie dodana do grupki
          </div>
        </div>
      </div>

      <div
        (click)="addMember()"
        class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px"
      >
        <img
          src="assets/add-button-shade.svg"
          class="or-mr-8-px or-size-32-px"
        />

        <div>
          <div class="or-text-md">Dodaj graczy do grupki</div>
          <div class="or-text-sm or-color-secondary-2">
            Dodaj graczy z poprzednich gierek
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<lib-bottom-navbar></lib-bottom-navbar>
