import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { UpdateUserDTO } from "../interfaces";

export const UPDATE_USER_COMMAND: InjectionToken<UpdateUserCommandPort> =
  new InjectionToken<UpdateUserCommandPort>("UPDATE_USER");

export interface UpdateUserCommandPort {
  update(userData: UpdateUserDTO): Observable<void>;
}
