import { PatchAdminUsersFiltersCommandPort } from "../ports";
import { inject } from "@angular/core";
import { AdminUsersFiltersModel } from "../models";
import { Observable } from "rxjs";
import {
  AdminUsersFiltersContextPort,
  AMIN_USERS_CONTEXT,
} from "../../infrastructure/storages";

export class PatchAdminUsersFiltersCommandHandler
  implements PatchAdminUsersFiltersCommandPort
{
  private readonly adminUsersFiltersContextPort: AdminUsersFiltersContextPort =
    inject(AMIN_USERS_CONTEXT);

  patch(data: Partial<AdminUsersFiltersModel>): Observable<void> {
    return this.adminUsersFiltersContextPort.patchContext(data);
  }

  reset(): Observable<void> {
    return this.adminUsersFiltersContextPort.patchContext({
      search: "",
      platform: [],
    });
  }
}
