import { BehaviorSubject, map, Observable, take } from "rxjs";
import { AdvancedInvitationsContextPort } from "./advanced-invitations.context-port";
import { AdvancedInvitationsDTO } from "../../../application/interfaces";

const MOCK: AdvancedInvitationsDTO = {
  chatUsers: [
    {
      userId: "userewqe1",
      displayName: "PlayerOne",
      thumbnail: "",
      autoInvite: true,
      autoApproval: false,
    },
    {
      userId: "usewr2",
      displayName: "PlayerTwo",
      thumbnail: "",
      autoInvite: false,
      autoApproval: true,
    },
    {
      userId: "usewr3",
      displayName: "PlayerThree",
      thumbnail: "",
      autoInvite: true,
      autoApproval: true,
    },
    {
      userId: "usesadr4",
      displayName: "PlayerFour",
      thumbnail: "",
      autoInvite: false,
      autoApproval: false,
    },
    {
      userId: "uswqeer5",
      displayName: "PlayerFive",
      thumbnail: "",
      autoInvite: true,
      autoApproval: false,
    },
    {
      userId: "uqweser4",
      displayName: "PlayerFour",
      thumbnail: "",
      autoInvite: false,
      autoApproval: false,
    },
    {
      userId: "userasf5",
      displayName: "PlayerFive",
      thumbnail: "",
      autoInvite: true,
      autoApproval: false,
    },
    {
      userId: "usesr4",
      displayName: "PlayerFour",
      thumbnail: "",
      autoInvite: false,
      autoApproval: false,
    },
    {
      userId: "usqweer5",
      displayName: "PlayerFive",
      thumbnail: "",
      autoInvite: true,
      autoApproval: false,
    },
  ],
  pastGames: [
    {
      userId: "usesar6",
      displayName: "PlayerSix",
      thumbnail: "",
      autoInvite: false,
      autoApproval: true,
    },
    {
      userId: "usqweer7",
      displayName: "PlayerSeven",
      thumbnail: "",
      autoInvite: true,
      autoApproval: false,
    },
    {
      userId: "usersa8",
      displayName: "PlayerEight",
      thumbnail: "",
      autoInvite: false,
      autoApproval: true,
    },
    {
      userId: "useqwer9",
      displayName: "PlayerNine",
      thumbnail: "",
      autoInvite: true,
      autoApproval: true,
    },
    {
      userId: "useqwer8",
      displayName: "PlayerEight",
      thumbnail: "",
      autoInvite: false,
      autoApproval: true,
    },
    {
      userId: "usqer9",
      displayName: "PlayerNine",
      thumbnail: "",
      autoInvite: true,
      autoApproval: true,
    },
    {
      userId: "userad10",
      displayName: "PlayerTen",
      thumbnail: "",
      autoInvite: false,
      autoApproval: false,
    },
  ],
};

export class AdvancedInvitationsStorage
  implements AdvancedInvitationsContextPort
{
  private readonly stateSubject: BehaviorSubject<AdvancedInvitationsDTO> =
    new BehaviorSubject<AdvancedInvitationsDTO>(MOCK);

  state$: Observable<AdvancedInvitationsDTO> = this.stateSubject.asObservable();

  patchContext(state: Partial<AdvancedInvitationsDTO>): Observable<void> {
    return this.stateSubject.asObservable().pipe(
      take(1),
      map((context: AdvancedInvitationsDTO) =>
        this.stateSubject.next({ ...context, ...state })
      )
    );
  }
}
