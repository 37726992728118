import { Component } from "@angular/core";

@Component({
  standalone: true,
  selector: "lib-app-launcher",
  template: `<img
    src="assets/launch.svg"
    style="position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; 
  object-fit: cover;
  z-index: -1"
  />`,
})
export class LaunchScreenComponent {}
