import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from "@angular/core";
import { AppRoutes } from "src/app/app-routes.enum";
import { HostedGamesRoutes } from "../../hosted-games.routes.enum";
import { Router } from "@angular/router";
import { GameDetailsModel, MODAL_TOKEN, ModalProvider } from "@core";
import { AddCustomPlayerComponent } from "../add-custom-player/add-custom-player.component";
import { ShareGameDirective } from "@ui-components";
import { take, tap } from "rxjs";

@Component({
  selector: "lib-invite-options",
  templateUrl: "./invite-options.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ShareGameDirective],
})
export class InviteOptionsComponent {
  @Input({ required: true }) game!: GameDetailsModel;
  @Output() onActionPerformed: EventEmitter<void> = new EventEmitter<void>();

  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  addPlayer(): void {
    this.modalProvider
      .showModal$({
        component: AddCustomPlayerComponent,
        cssClass: "present-modal",
        componentProps: {
          gameId: this.game.gameId,
        },
      })
      .pipe(
        take(1),
        tap(() => this.onActionPerformed.emit()),
        tap(() => this.modalProvider.dismissAllStoredModals())
      )
      .subscribe();
  }

  goToInvitations(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigate([
      `${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${this.game.gameId}/${HostedGamesRoutes.INVITE}`,
    ]);
  }
}
