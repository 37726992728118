<lib-title-navbar title="Mój profil"></lib-title-navbar>

<ng-container *ngIf="userData$ | async as userData">
  <div class="or-d-flex or-justify-content-center or-mt-24-px or-flex-col">
    <div class="or-d-flex or-justify-content-center">
      <img
        *ngIf="!isiOS"
        src="assets/icons/photo-icon.svg"
        class="edit-profile-icon or-position-relative or-cursor-pointer"
        (click)="fileInput.click()"
      />

      <ng-container *ngIf="base64image$ | async as base64">
        <lib-or-avatar
          class="or-mr-24-px"
          *ngIf="!base64.isAvailable"
          [avatarUrl]="userData.avatarUrl"
          [isProfile]="true"
          [initials]="userData.displayName"
        >
        </lib-or-avatar>

        <lib-or-avatar
          class="or-mr-24-px"
          *ngIf="base64.isAvailable"
          [base64]="base64.base64"
          [isProfile]="true"
        ></lib-or-avatar>
      </ng-container>
    </div>

    <div class="or-d-flex or-flex-col or-align-items-center or-mt-12-px">
      <div class="or-head-lg">{{ userData.name }} {{ userData.surname }}</div>
      <div class="or-text-sm or-color-secondary-2">{{ userData.email }}</div>
    </div>
  </div>

  <form
    [formGroup]="profileForm"
    class="or-d-flex or-flex-col or-justify-content-center or-mx-24-px or-mt-24-px"
  >
    <div class="or-head-xs or-color-secondary-2 or-mb-4-px">Imie</div>
    <input
      class="outline-none input-style or-mb-12-px"
      formControlName="name"
      [ngClass]="{ 'error-field': isFieldInvalid('name') }"
    />
    <div
      *ngIf="isNameOverAvailableLength"
      class="or-mb-12-px or-text-sm or-color-red or-mt-2-px"
    >
      Maksymalna długość to 15 znaków
    </div>

    <div class="or-head-xs or-color-secondary-2 or-mb-4-px">Nazwisko</div>
    <input
      class="outline-none input-style or-mb-12-px"
      formControlName="lastName"
    />
    <div
      *ngIf="isLastNameOverAvailableLength"
      class="or-mb-12-px or-text-sm or-color-red or-mt-2-px"
    >
      Maksymalna długość to 15 znaków
    </div>

    <div class="or-head-xs or-color-secondary-2 or-mb-4-px">Telefon</div>
    <div class="or-d-flex or-align-items-center">
      <div
        class="phone-input-prefix or-align-content-center or-align-items-center"
      >
        +48
      </div>
      <input
        class="outline-none phone-input or-w-100-pc"
        formControlName="phone"
        type="text"
        inputmode="numeric"
      />
      <input
        type="file"
        #fileInput
        (change)="changeAvatar($event)"
        hidden
        formControlName="avatar"
        [accept]="fileExtensions"
      />
    </div>

    <div class="or-head-xs or-mt-32-px or-mb-16-px">Informacje dodatkowe</div>

    <div class="or-d-flex or-justify-content-center">
      <div class="or-d-flex or-flex-col or-w-100-pc or-mr-12-px">
        <div class="or-head-xs or-color-secondary-2 text-no-wrap or-mb-2-px">
          Preferowana pozycja
        </div>

        <ion-select
          formControlName="preferredPosition"
          interface="alert"
          class="outline-none custom-select"
          [okText]="'Wybierz'"
          [cancelText]="'Zamknij'"
          [class.error-field]="isFieldInvalid('level')"
        >
          <ion-select-option
            *ngFor="let position of preferredPosition"
            [value]="position"
          >
            {{ preferredPositionMapper[position] }}
          </ion-select-option>
        </ion-select>
      </div>

      <div class="or-d-flex or-flex-col or-w-100-pc">
        <div class="or-head-xs or-color-secondary-2 or-mb-2-px">
          Twój poziom
        </div>
        <ion-select
          formControlName="preferredLevel"
          interface="alert"
          class="outline-none custom-select"
          [okText]="'Wybierz'"
          [cancelText]="'Zamknij'"
          [class.error-field]="isFieldInvalid('level')"
        >
          <ion-select-option
            *ngFor="let level of preferredLevel"
            [value]="level"
          >
            {{ gameLevelMapper[level] }}
          </ion-select-option>
        </ion-select>
      </div>
    </div>
  </form>

  <div class="or-mx-24-px or-mt-72-px">
    <div class="or-d-flex or-justify-content-center">
      <button
        (click)="goToSettings()"
        class="button-secondary-style or-h-48-px or-w-100-pc or-head-md or-mr-12-px"
      >
        Anuluj
      </button>

      <button
        [disabled]="!profileForm.valid && isRemoveAccountMode"
        (click)="saveData()"
        class="button-primary-style or-h-48-px or-w-100-pc or-head-md"
        [ngClass]="{
          'disabled-button': !profileForm.valid || isRemoveAccountMode
        }"
      >
        Zapisz zmiany
      </button>
    </div>
  </div>

  <div
    *ngIf="!isRemoveAccountMode"
    (click)="removeAcoountMode()"
    class="or-d-flex or-justify-content-center or-mt-28-px or-text-decoration-underline or-text-md or-w-100-pc"
  >
    Usuń konto
  </div>

  <div *ngIf="isRemoveAccountMode">
    <form
      [formGroup]="removeAccountForm"
      class="or-d-flex or-flex-col or-justify-content-center or-mx-24-px or-mt-48-px"
    >
      <div class="or-head-xs or-color-secondary-2 or-mb-2-px">
        Podaj hasło aby usunąć konto
      </div>
      <input
        class="outline-none input-style or-mb-12-px"
        formControlName="password"
        type="password"
      />
    </form>
    <div
      (click)="deleteAccount()"
      class="or-d-flex or-justify-content-center or-mt-12-px or-text-decoration-underline or-text-md or-w-100-pc"
    >
      Usuń konto
    </div>
  </div>
</ng-container>
