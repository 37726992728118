import { GameApplicationsDTOPort } from "./game-applications.dto-port";
import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig, PlayerPosition } from "@core";
import { Observable } from "rxjs";
import { SendApplicationResponseDTO } from "../../../application/interfaces";

export class GameApplicationsService implements GameApplicationsDTOPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  sendApplication(
    gameId: string,
    position: PlayerPosition
  ): Observable<SendApplicationResponseDTO> {
    return this.httpClient.post<SendApplicationResponseDTO>(
      `${this.env.apiUrl}/games/${gameId}/player-applications`,
      { position }
    );
  }

  removeFromGame(gameId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.env.apiUrl}/player-games/${gameId}`
    );
  }

  removeApplication(gameId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.env.apiUrl}/games/${gameId}/player-applications`
    );
  }
}
