<div class="or-d-flex or-justify-content-evenly">
  <div class="or-d-flex or-flex-col">
    <div class="or-head-sm or-color-secondary-2">Preferowana pozycja</div>
  </div>
  <div class="or-d-flex or-flex-col">
    <div class="or-head-sm or-color-secondary-2">Poziom umiejętności</div>
  </div>
</div>

<div class="or-mt-8-px or-d-flex">
  <div
    class="border-shade border-radius-4 or-p-8-px or-mr-8-px or-w-100-pc or-bg-white or-d-flex or-justify-content-center"
  >
    <img src="assets/icons/preferred-position.svg" class="or-mr-8-px" />
    <div class="or-text-lg or-my-4-px">
      {{ preferredPositionMapper[playerPreferences.preferredPosition] }}
    </div>
  </div>

  <div
    class="border-shade border-radius-4 or-p-8-px or-mr-8-px or-w-100-pc or-bg-white or-d-flex or-justify-content-center"
  >
    <img src="assets/icons/preferred-level.svg" class="or-mr-8-px" />
    <div class="or-text-lg or-my-4-px">
      {{ gameLevelMapper[playerPreferences.preferredLevel] }}
    </div>
  </div>
</div>
