import { SendApplicationCommandPort } from "../ports";
import { Observable } from "rxjs";
import {
  GAME_APPLICATIONS_DTO,
  GameApplicationsDTOPort,
} from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { PlayerPosition } from "@core";

export class SendApplicationCommandHandler
  implements SendApplicationCommandPort
{
  private readonly gameApplicationsDTOPort: GameApplicationsDTOPort = inject(
    GAME_APPLICATIONS_DTO
  );

  sendApplication(gameId: string, position: PlayerPosition): Observable<void> {
    return this.gameApplicationsDTOPort.sendApplication(gameId, position);
  }
}
