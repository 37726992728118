import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from "@angular/core";
import {
  OrAvatarComponent,
  OrLoadingComponent,
  OrLoadingService,
  TitileNavbarComponent,
} from "@ui-components";
import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from "rxjs";

import {
  LocalRefreshService,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { ActivatedRoute, Params } from "@angular/router";
import { ChannelMembersDTO } from "../../application/interfaces";
import {
  CHANNEL_MEMBERS_QUERY,
  ChannelMembersQueryPort,
  REMOVE_MEMBERS_FROM_CHANNEL_COMMAND,
  RemoveMembersFromChannelCommandPort,
} from "../../application/ports";
import {
  provideChannelMembersQuery,
  provideRemoveMembersFromChannelCommand,
} from "../../application/handlers";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

type ChannelMembersDTOViewModel = ChannelMembersDTO & { isSelected: boolean };

@Component({
  selector: "lib-manage-members",
  templateUrl: "./manage-members.component.html",
  styleUrls: ["./manage-members.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    AsyncPipe,
    IonicModule,
    NgClass,
    OrAvatarComponent,
    NgForOf,
    NgIf,
    OrLoadingComponent,
  ],
  providers: [
    LocalRefreshService,
    provideChannelMembersQuery(),
    provideRemoveMembersFromChannelCommand(),
    OrLoadingService,
  ],
})
export class ManageMembersComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly localRefreshService: LocalRefreshService =
    inject(LocalRefreshService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly channelMembersQueryPort: ChannelMembersQueryPort = inject(
    CHANNEL_MEMBERS_QUERY
  );
  private readonly removeMembersFromChannelCommandPort: RemoveMembersFromChannelCommandPort =
    inject(REMOVE_MEMBERS_FROM_CHANNEL_COMMAND);
  private readonly orLoadingService: OrLoadingService =
    inject(OrLoadingService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  private readonly selectedMembers: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([]);

  readonly channelId$: Observable<string> = this.activatedRoute.params.pipe(
    take(1),
    map((params: Params) => params["id"])
  );

  readonly selectedMembers$: Observable<string[]> =
    this.selectedMembers.asObservable();

  readonly members$: Observable<ChannelMembersDTOViewModel[]> =
    this.localRefreshService.refresh$.pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(() => this.modalProvider.showLoading$()),
      switchMap(() => this.loadMembers()),
      combineLatestWith(this.selectedMembers$),
      map(([members, selectedMembers]: [ChannelMembersDTO[], string[]]) => {
        return members.map((member: ChannelMembersDTO) => {
          return {
            ...member,
            isSelected: selectedMembers.includes(member.userId),
          };
        });
      }),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1)
    );

  readonly isiOS: boolean = this.platformProvider.isiOS;

  loadMembers(): Observable<ChannelMembersDTO[]> {
    return this.channelId$.pipe(
      take(1),
      switchMap((channelId: string) =>
        this.channelMembersQueryPort.getMembers(channelId)
      )
    );
  }

  selectMember(userId: string): void {
    const selectedMembers: string[] = this.selectedMembers.value;

    if (selectedMembers.includes(userId))
      return this.selectedMembers.next(
        selectedMembers.filter((id: string) => id !== userId)
      );
    return this.selectedMembers.next([...selectedMembers, userId]);
  }

  removeUsers(): void {
    this.orLoadingService.show();

    combineLatest([this.selectedMembers$, this.channelId$])
      .pipe(
        take(1),
        switchMap(([selectedUsers, channelId]: [string[], string]) =>
          this.removeMembersFromChannelCommandPort.remove(
            channelId,
            selectedUsers
          )
        ),
        take(1)
      )
      .subscribe({
        next: () => {
          this.localRefreshService.emit();
          this.orLoadingService.hide();
        },
        error: () => this.orLoadingService.hide(),
      });
  }
}
