import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TeamsRoutes } from "./teams-routes.enum";
import {
  CreateTeamComponent,
  CreateTeamFinishComponent,
  TeamDetailsComponent,
  TeamInvitePlayersComponent,
} from "./components";

const routes: Routes = [
  {
    path: TeamsRoutes.CREATE,
    component: CreateTeamComponent,
  },
  {
    path: `:id/${TeamsRoutes.CREATE_FINISH}`,
    component: CreateTeamFinishComponent,
  },
  {
    path: `:id/${TeamsRoutes.TEAM_DETAILS}`,
    component: TeamDetailsComponent,
  },
  {
    path: `:id/${TeamsRoutes.INVITE}`,
    component: TeamInvitePlayersComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeamsRoutingModule {}
