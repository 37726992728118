import { map, Observable } from "rxjs";
import { inject } from "@angular/core";
import { CreateGameCommandPort } from "../ports";
import {
  HOSTED_GAMES_DTO,
  HostedGamesDTOPort,
} from "../../infrastructure/http-service";
import { VisibilityEnum } from "../enums";
import {
  CreateGameDTO,
  CreateGameResponseDTO,
  CreateGameResponseViewModel,
} from "../interfaces";
import { DateFormatPipe } from "@ui-components";
import { mapToCreateGameViewModelUtil } from "./utils/map-to-create-game-view-model.util";

export class CreateGameCommandHandler implements CreateGameCommandPort {
  private readonly hostedGamesDTOPort: HostedGamesDTOPort =
    inject(HOSTED_GAMES_DTO);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  createGame(game: CreateGameDTO): Observable<CreateGameResponseViewModel> {
    return this.hostedGamesDTOPort
      .createGame({
        ...game,
        gameContactPhone: game.gameContactPhone
          ? `+48${game.gameContactPhone}`
          : null,
        priceAmount: game.priceAmount.toString(),
        visibility: game.visibility
          ? VisibilityEnum.PRIVATE
          : VisibilityEnum.PUBLIC,
        channelId: game.channelId || "create-new",
        paymentTypes: !game.priceAmount ? [] : game.paymentTypes,
      })
      .pipe(
        map((response: CreateGameResponseDTO) =>
          mapToCreateGameViewModelUtil(response, this.dateFormatPipe)
        )
      );
  }
}
