import { NgModule } from "@angular/core";
import { GamesRouting } from "./games.routing";
import { RouterModule } from "@angular/router";
import { provideGamesService } from "./infrastructure/http-service";
import { provideGameDetailsQuery } from "./application/handlers";

@NgModule({
  imports: [RouterModule.forChild(GamesRouting)],
  providers: [provideGamesService(), provideGameDetailsQuery()],
})
export class GamesModule {}
