<lib-title-navbar title="News"></lib-title-navbar>

<div
  [class.full-height-scrollable-container]="!isiOS"
  [class.full-height-scrollable-container-ios]="isiOS"
>
  <div *ngIf="data$ | async as data" class="or-px-16-px or-pt-24-px">
    <div class="or-head-xl or-mb-20-px">
      {{ data.title }}
    </div>
    <div class="or-text-md or-mb-20-px">{{ data.body }}</div>
    <div
      [routerLink]="data.url"
      class="or-text-md or-mb-20-px or-color-primary or-text-decoration-underline"
    >
      {{ data.urlText }}
    </div>
    <img class="or-mb-20-px custom-image-size" [src]="data.image" />
    <div class="or-head-lg or-mb-20-px">{{ data.smallTitle }}</div>
    <div *ngFor="let item of data.list" class="or-text-md or-mb-8-px">
      ⚽ {{ item }}
    </div>
    <div class="or-head-lg or-my-20-px">
      Dzięki za uwagę,<br />
      Team Orlikfy!
    </div>
  </div>
</div>

<lib-bottom-navbar></lib-bottom-navbar>
