import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const SWITCH_ADVANCED_INVITATIONS_COMMAND: InjectionToken<SwitchAdvancedInvitationsCommandPort> =
  new InjectionToken<SwitchAdvancedInvitationsCommandPort>(
    "SWITCH_ADVANCED_INVITATIONS_COMMAND"
  );

export interface SwitchAdvancedInvitationsCommandPort {
  switchAutoInvite(
    userId: string,
    value: boolean,
    gameId?: string
  ): Observable<void>;
  switchAutoApproval(
    userId: string,
    value: boolean,
    gameId?: string
  ): Observable<void>;
}
