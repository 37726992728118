import { QuickFiltersEnum } from "../enums";
import { GameLevel, ShowFrom } from "@core";

const getNextMonday = () => {
  const today: Date = new Date();
  const todayDayOfWeek: number = today.getDay();
  const daysUntilNextMonday: number =
    todayDayOfWeek === 0 ? 1 : 8 - todayDayOfWeek;
  const nextMonday: Date = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilNextMonday);
  nextMonday.setHours(0, 0, 0, 0);
  return nextMonday;
};

const getFollowingSunday = () => {
  const nextMonday: Date = getNextMonday();
  const followingSunday: Date = new Date(nextMonday);
  followingSunday.setDate(nextMonday.getDate() + 6);
  followingSunday.setHours(23, 59, 59);
  return followingSunday;
};

export const quickFiltersMapper: Record<QuickFiltersEnum, any> = {
  [QuickFiltersEnum.TODAY]: {
    id: QuickFiltersEnum.TODAY,
    text: "Na dziś",
    filters: {
      time: { min: 0, max: 24 },
      gameLevel: [],
      specificDaysType: ShowFrom.TODAY,
      specificWeekdays: [],
    },
  },
  [QuickFiltersEnum.TOMORROW]: {
    id: QuickFiltersEnum.TOMORROW,
    text: "Na jutro",
    filters: {
      time: { min: 0, max: 24 },
      gameLevel: [],
      specificDaysType: ShowFrom.TOMORROW,
      specificWeekdays: [],
    },
  },
  [QuickFiltersEnum.BEGINNER]: {
    id: QuickFiltersEnum.BEGINNER,
    text: "Rekreacyjny",
    filters: {
      time: { min: 0, max: 24 },
      gameLevel: [GameLevel.BEGINNER],
      specificDaysType: ShowFrom.ALL,
      specificWeekdays: [],
    },
  },
  [QuickFiltersEnum.NEXT_WEEK]: {
    id: QuickFiltersEnum.NEXT_WEEK,
    text: "Przyszły tydzień",
    filters: {
      time: { min: 0, max: 24 },
      gameLevel: [],
      specificDaysType: ShowFrom.BY_DATE,
      specificWeekdays: [],
      specificDateFrom: getNextMonday().toISOString(),
      specificDateTo: getFollowingSunday().toISOString(),
    },
  },
  [QuickFiltersEnum.INTERMEDIATE]: {
    id: QuickFiltersEnum.INTERMEDIATE,
    text: "Średni",
    filters: {
      time: { min: 0, max: 24 },
      gameLevel: [GameLevel.INTERMEDIATE],
      specificDaysType: ShowFrom.ALL,
      specificWeekdays: [],
    },
  },
};
