<div class="or-h-100vh">
  <img src="assets/login-header.svg" class="auth-header" />

  <div
    slot="start"
    class="or-position-absolute or-ml-8-px or-mt-12-px or-top-0"
    [ngClass]="isiOS ? 'or-mt-36-px' : 'or-mt-12-px'"
    (click)="goBack()"
  >
    <img src="assets/icons/back-arrow-icon.svg" />
  </div>

  <auth-form (actionEvent)="login($event)" [isRegister]="false"></auth-form>
</div>
