import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  switchMap,
  take,
} from "rxjs";
import {
  GameLevel,
  gameLevelMapper,
  MODAL_TOKEN,
  ModalProvider,
  ShowFrom,
  showFromMapper,
} from "@core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { SpecificDaysModalComponent } from "@ui-components";
import { FiltersValues } from "../../application/models";
import { AppRoutes } from "src/app/app-routes.enum";
import {
  GetCurrentFiltersQueryHandler,
  SaveFiltersCommandHandler,
} from "../../application/handlers";

@Component({
  selector: "lib-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, CommonModule],
  providers: [SaveFiltersCommandHandler, GetCurrentFiltersQueryHandler],
})
export class FiltersComponent implements OnInit {
  private readonly modalToken: ModalProvider = inject(MODAL_TOKEN);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly saveFiltersCommandHandler: SaveFiltersCommandHandler =
    inject(SaveFiltersCommandHandler);
  private readonly getCurrentFiltersQueryHandler: GetCurrentFiltersQueryHandler =
    inject(GetCurrentFiltersQueryHandler);
  private readonly router: Router = inject(Router);

  public availableShowFromValues: ShowFrom[] = [
    ShowFrom.TODAY,
    ShowFrom.TOMORROW,
    ShowFrom.ALL,
    ShowFrom.WEEKDAYS,
  ];

  public gameLevels: GameLevel[] = Object.values(GameLevel);
  public gameLevelMapper: Record<GameLevel, string> = gameLevelMapper;
  public viewFromValuesMapper: Record<ShowFrom, string> = showFromMapper;

  private filtersValuesSubject: BehaviorSubject<FiltersValues> =
    new BehaviorSubject<FiltersValues>({} as FiltersValues);

  readonly filtersValues$: Observable<FiltersValues> =
    this.filtersValuesSubject.asObservable();

  ngOnInit(): void {
    this.loadFilters().subscribe();
  }

  loadFilters(): Observable<void> {
    return this.getCurrentFiltersQueryHandler.getFilters().pipe(
      take(1),
      map((filters: FiltersValues) => this.filtersValuesSubject.next(filters))
    );
  }

  saveSettings(): void {
    combineLatest([this.activatedRoute.queryParamMap, this.filtersValues$])
      .pipe(
        take(1),
        map(([queryParams, values]: [ParamMap, FiltersValues]) => {
          const days: string[] = queryParams.getAll("day");
          return {
            ...values,
            specificWeekdays: days,
          };
        }),
        switchMap((result: FiltersValues) =>
          this.saveFiltersCommandHandler.saveFilters(result)
        ),
        take(1)
      )
      .subscribe(() => {
        this.modalToken.dismissAllStoredModals();
        this.router.navigate([AppRoutes.FIND_GAMES]);
      });
  }

  resetFilters(): void {
    this.saveFiltersCommandHandler
      .defaultValue()
      .pipe(
        switchMap(() => this.loadFilters()),
        take(1)
      )
      .subscribe(() => this.modalToken.dismissAllStoredModals());
  }

  onTimeChange(event: CustomEvent): void {
    this.filtersValues$
      .pipe(
        take(1),
        map((values: FiltersValues) =>
          this.filtersValuesSubject.next({
            ...values,
            time: {
              min: event.detail.value.lower,
              max: event.detail.value.upper,
            },
          })
        )
      )
      .subscribe();
  }

  levelChips(level: GameLevel): boolean {
    return this.filtersValuesSubject.value.gameLevel.some(
      (gameLevel: GameLevel) => gameLevel === level
    );
  }

  setLevelChips(value: GameLevel): void {
    const currentValues: FiltersValues = this.filtersValuesSubject.value;
    const currentSpecificValues: GameLevel[] =
      this.filtersValuesSubject.value.gameLevel;

    if (currentSpecificValues.some((v: GameLevel) => v === value)) {
      const updatedGameLevel = currentSpecificValues.filter(
        (v: GameLevel) => v !== value
      );
      this.filtersValuesSubject.next({
        ...currentValues,
        gameLevel: updatedGameLevel,
      });
    } else {
      const updatedGameLevel: GameLevel[] = [...currentSpecificValues, value];
      this.filtersValuesSubject.next({
        ...currentValues,
        gameLevel: updatedGameLevel,
      });
    }
  }

  viewFrom(from: ShowFrom): boolean {
    return this.filtersValuesSubject.value.specificDaysType === from;
  }

  setViewFromChips(value: ShowFrom): void {
    if (value === ShowFrom.WEEKDAYS) {
      this.openSpecifcDaysModal();
    }
    const currentValues: FiltersValues = this.filtersValuesSubject.value;

    this.filtersValuesSubject.next({
      ...currentValues,
      specificDaysType: value,
    });
  }

  private openSpecifcDaysModal(): void {
    this.filtersValues$
      .pipe(
        take(1),
        switchMap((values: FiltersValues) =>
          this.modalToken.showModal$({
            component: SpecificDaysModalComponent,
            componentProps: {
              isNotifications: false,
              selectedDays: values.specificWeekdays ?? [],
            },
            cssClass: "present-modal",
          })
        )
      )
      .subscribe();
  }
}
