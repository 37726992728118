import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const REMOVE_APPLICATION_COMMAND: InjectionToken<RemoveApplicationCommandPort> =
  new InjectionToken<RemoveApplicationCommandPort>(
    "REMOVE_APPLICATION_COMMAND"
  );

export interface RemoveApplicationCommandPort {
  remove(gameId: string, gameStatus: string): Observable<void>;
}
