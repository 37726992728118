import { inject, InjectionToken } from "@angular/core";
import { from, map, Observable, of } from "rxjs";
import { PushNotifications } from "@capacitor/push-notifications";
import { PLATFORM_TOKEN, PlatformProvider } from "./platform.token";
import { PermissionStates } from "../enums";

export const NOTIFICATION_TOKEN: InjectionToken<NotificationPermissionProvider> =
  new InjectionToken<NotificationPermissionProvider>("NOTIFICATION_TOKEN", {
    factory: (): NotificationPermissionProvider => {
      const platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

      return {
        isEnabled(): Observable<boolean> {
          if (!platformProvider.isNativeRun) return of(true);

          return from(PushNotifications.checkPermissions()).pipe(
            map((data) => data.receive === PermissionStates.GRANTED)
          );
        },
      };
    },
  });

export interface NotificationPermissionProvider {
  isEnabled(): Observable<boolean>;
}
