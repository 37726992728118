import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { FiltersValues } from "../models";

export const GET_CURRENT_FILTERS_QUERY: InjectionToken<GetCurrentFiltersQueryPort> =
  new InjectionToken<GetCurrentFiltersQueryPort>("GET_CURRENT_FILTERS_QUERY");

export interface GetCurrentFiltersQueryPort {
  getFilters(): Observable<FiltersValues>;
}
