<lib-title-navbar title="Edytuj chat"></lib-title-navbar>
<div class="top-container"></div>

<ng-container *ngIf="channelDetails$ | async as channelDetails">
  <div
    class="or-d-flex or-justify-content-center or-flex-col or-position-relative z-index-999 negative-margin-top-24-px"
  >
    <div class="or-d-flex or-justify-content-center">
      <lib-or-avatar
        [avatarUrl]="channelDetails.channelThumbnail"
        [isProfile]="true"
        [initials]="channelDetails.channelName"
      >
      </lib-or-avatar>
    </div>
  </div>

  <div
    [ngClass]="
      isiOS
        ? 'full-height-scrollable-container-ios'
        : 'full-height-scrollable-container'
    "
  >
    <form
      [formGroup]="channelDataForm"
      class="or-d-flex or-flex-col or-justify-content-center or-mx-24-px or-mt-24-px"
    >
      <div class="or-head-xs or-color-secondary-2 or-mb-4-px">Nazwa chatu</div>
      <input
        class="outline-none input-style or-mb-12-px"
        formControlName="name"
        [ngClass]="{ 'error-field': isFieldInvalid('name') }"
      />
      <div
        *ngIf="isNameOverAvailableLength"
        class="or-mb-12-px or-text-sm or-color-red or-mt-2-px"
      >
        Maksymalna długość to 15 znaków
      </div>
    </form>
  </div>

  <div class="or-mx-24-px">
    <div class="or-d-flex or-justify-content-center">
      <button
        (click)="backToChannel()"
        class="button-secondary-style or-h-48-px or-w-100-pc or-head-md or-mr-12-px"
      >
        Anuluj
      </button>

      <button
        [disabled]="!channelDataForm.valid"
        (click)="saveData()"
        class="button-primary-style or-h-48-px or-w-100-pc or-head-md"
        [ngClass]="{
          'disabled-button': !channelDataForm.valid
        }"
      >
        Zapisz zmiany
      </button>
    </div>
  </div>
</ng-container>
