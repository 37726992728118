import { Routes } from "@angular/router";
import { ProfileRoutes } from "./profile.routes.enum";
import {
  ContactComponent,
  EditProfileComponent,
  MyProfileComponent,
  ReferralComponent,
} from "./components";

export const ProfileRouting: Routes = [
  {
    path: ProfileRoutes.ROOT,
    component: MyProfileComponent,
  },
  {
    path: ProfileRoutes.EDIT,
    component: EditProfileComponent,
  },
  {
    path: ProfileRoutes.CONTACT,
    component: ContactComponent,
  },
  {
    path: ProfileRoutes.REFERRAL,
    component: ReferralComponent,
  },
];
