import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { LocationDetailsModel } from "../../interfaces/models/location-details.model";

export const LOCATION_DETAILS_QUERY: InjectionToken<GetLocationDetailsQueryPort> =
  new InjectionToken<GetLocationDetailsQueryPort>("LOCATION_DETAILS_QUERY");

export interface GetLocationDetailsQueryPort {
  getDetails(): Observable<LocationDetailsModel>;
}
