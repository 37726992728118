import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  BottomNavbarComponent,
  GameCardComponent,
  GamesMenuComponent,
  RedirectTypeEnum,
  TitileNavbarComponent,
} from "@ui-components";
import { AppRoutes } from "src/app/app-routes.enum";
import { HostedGamesRoutes } from "../../hosted-games.routes.enum";
import {
  GET_HOSTED_PAST_GAMES_QUERY,
  GetHostedPastGamesQueryPort,
} from "../../application/ports";
import { BehaviorSubject, Observable, shareReplay, switchMap, tap } from "rxjs";
import { provideHostedPastGamesQuery } from "../../application/handlers";
import { provideHostedGamesService } from "../../infrastructure/http-service";
import { Router } from "@angular/router";
import {
  GameDetailsModel,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { CreateGameViewService } from "../../application/view-services";

@Component({
  selector: "lib-past-future-games",
  templateUrl: "./hosted-past-games.component.html",
  styleUrls: ["./hosted-past-games.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BottomNavbarComponent,
    GamesMenuComponent,
    TitileNavbarComponent,
    GameCardComponent,
  ],
  providers: [provideHostedPastGamesQuery(), provideHostedGamesService()],
})
export class HostedPastGamesComponent {
  public redirectType: RedirectTypeEnum = RedirectTypeEnum.HOSTED;

  private readonly getHostedPastGamesQueryPort: GetHostedPastGamesQueryPort =
    inject(GET_HOSTED_PAST_GAMES_QUERY);
  private readonly router: Router = inject(Router);
  private readonly createGameViewService: CreateGameViewService = inject(
    CreateGameViewService
  );
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  private showPlaceholderSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isPageLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly games$: Observable<Map<string, GameDetailsModel[]>> =
    this.modalProvider.showLoading$().pipe(
      switchMap(() => this.getHostedPastGamesQueryPort.getGames()),
      tap((games: Map<string, GameDetailsModel[]>) =>
        this.showPlaceholderSubject.next(games?.size === 0)
      ),
      tap(() => this.isPageLoaded$.next(true)),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1)
    );

  readonly showPlaceholder$: Observable<boolean> =
    this.showPlaceholderSubject.asObservable();

  readonly isiOS: boolean = this.platformProvider.isiOS;

  goToFutureGames(): void {
    this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}`]);
  }

  goToPastGames(): void {
    this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.PAST}`]);
  }

  addSimilarGame(game: GameDetailsModel): void {
    this.createGameViewService.setGame(game);
    this.router.navigateByUrl(
      `${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}`
    );
  }
}
