import { Component, inject, OnInit } from "@angular/core";
import { PLATFORM_TOKEN, PlatformProvider } from "@core";
import { StartupV1Service } from "./startup-v1.service";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private readonly startupService: StartupV1Service = inject(StartupV1Service);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  ngOnInit(): void {
    this.startupService.init();
  }

  get showDesktopView(): boolean {
    return (
      !this.platformProvider.isNativeRun && !this.platformProvider.isMobileWeb
    );
  }
}
