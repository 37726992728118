import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { AdminUsersFiltersModel } from "../../models";

export const PATCH_ADMIN_USERS_FILTERS_COMMAND: InjectionToken<PatchAdminUsersFiltersCommandPort> =
  new InjectionToken<PatchAdminUsersFiltersCommandPort>(
    "PATCH_ADMIN_USERS_FILTERS_COMMAND"
  );

export interface PatchAdminUsersFiltersCommandPort {
  patch(data: Partial<AdminUsersFiltersModel>): Observable<void>;
  reset(): Observable<void>;
}
