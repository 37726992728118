<ion-chip
  *ngIf="game?.playersApplications?.length"
  class="or-ml-32-px z-index-2 or-head-xs waiting-players chips-margin-bottom--20-px or-mt-0-px"
  >Ilość oczekujących graczy: {{ game.playersApplications?.length }}</ion-chip
>

<ion-chip
  *ngIf="!game?.isRecurring"
  [class.or-ml-32-px]="!game.playersApplications?.length"
  class="or-ml-32-px single z-index-2 or-head-xs single chips-margin-bottom--20-px or-mt-0-px"
  >Pojedyncza</ion-chip
>

<ion-chip
  *ngIf="game?.isRecurring"
  [class.or-ml-32-px]="!game.playersApplications?.length"
  class="or-ml-32-px single z-index-2 or-head-xs multi chips-margin-bottom--20-px or-mt-0-px"
  >Powtarzalna</ion-chip
>

<lib-game-card
  [game]="game"
  [showMoreDetails]="true"
  [showShareButton]="true"
  [redirectType]="redirectType"
  [adjustToChip]="true"
  [showVisibility]="true"
></lib-game-card>
