import { Days } from "./days.interface";

export const days: Days[] = [
  {
    name: "Poniedziałek",
    id: "MONDAY",
    checked: false,
  },
  {
    name: "Wtorek",
    id: "TUESDAY",
    checked: false,
  },
  {
    name: "Sroda",
    id: "WEDNESDAY",
    checked: false,
  },
  {
    name: "Czwartek",
    id: "THURSDAY",
    checked: false,
  },
  {
    name: "Piątek",
    id: "FRIDAY",
    checked: false,
  },
  {
    name: "Sobota",
    id: "SATURDAY",
    checked: false,
  },
  {
    name: "Niedziela",
    id: "SUNDAY",
    checked: false,
  },
];
