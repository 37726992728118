import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import { Observable } from "rxjs";
import { GeneratePostDtoPort } from "./generate-post.dto-port";
import { PostGameResponse } from "../../../application/models/post-game.dto";

export class GeneratePostService implements GeneratePostDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  generateFutureGames(endDays: number): Observable<PostGameResponse> {
    return this.httpClient.get<PostGameResponse>(
      `${this.env.apiUrl}/admin/games-for-post?gameStartDaysOffset=1&gameEndDaysOffset=${endDays}`
    );
  }
}
