import { CoordinatesDTO } from "./coordinates.dto";

export interface LocationDTO {
  readonly locationId: string;
  readonly name: string;
  readonly coordinates: CoordinatesDTO;
  readonly address: string;
  readonly city: string;
  readonly hasRelationWithSomeGame: boolean;
}
