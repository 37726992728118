import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const LOAD_ADVANCED_INVITATIONS_COMMAND: InjectionToken<LoadAdvancedInvtitationsCommandPort> =
  new InjectionToken<LoadAdvancedInvtitationsCommandPort>(
    "LOAD_ADVANCED_INVITATIONS_COMMAND"
  );

export interface LoadAdvancedInvtitationsCommandPort {
  load(gameId: string): Observable<void>;
}
