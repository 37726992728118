import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import {
  CreateGameDTO,
  CreateGameResponseDTO,
  CustomPlayerModel,
  HostedGameDTO,
} from "../../../application/interfaces";
import { GameDTO } from "@core";

export const HOSTED_GAMES_DTO: InjectionToken<HostedGamesDTOPort> =
  new InjectionToken<HostedGamesDTOPort>("HOSTED_GAMES_DTO");

export interface HostedGamesDTOPort {
  createGame(game: CreateGameDTO): Observable<CreateGameResponseDTO>;
  updateGame(
    gameId: string,
    game: Partial<CreateGameDTO>
  ): Observable<CreateGameResponseDTO>;
  getFutureGames(): Observable<HostedGameDTO>;
  getPastGames(): Observable<HostedGameDTO>;
  getOneGame(gameId: string): Observable<GameDTO>;
  removeGame(gameId: string): Observable<void>;
  removePlayer(gameId: string, playerId: string): Observable<void>;
  markAsPaid(gameId: string, playerId: string): Observable<void>;
  markAsNotPaid(gameId: string, playerId: string): Observable<void>;
  addPlayer(gameId: string, player: CustomPlayerModel): Observable<void>;
}
