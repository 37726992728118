import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from "@angular/core";
import {
  BottomNavbarComponent,
  OrAvatarComponent,
  TitileNavbarComponent,
} from "@ui-components";
import { BehaviorSubject, Observable, switchMap, tap } from "rxjs";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { AppRoutes } from "src/app/app-routes.enum";
import { Router } from "@angular/router";
import { ProfileRoutes } from "../../profile.routes.enum";
import {
  formattedTimeDifferenceUtil,
  GlobalRefreshService,
  MODAL_TOKEN,
  ModalProvider,
  NOTIFICATION_TOKEN,
  NotificationPermissionProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";
import { UserDetailsQueryHandler } from "../../application/handlers";
import { UserDetailsDTO } from "../../application/interfaces";
import { CardHistoryComponent } from "../card-history/card-history.component";
import { UserStatsComponent } from "../user-stats/user-stats.component";
import { user } from "@angular/fire/auth";
import { PlayerPreferencesComponent } from "../player-preferences/player-preferences.component";

@Component({
  selector: "lib-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TitileNavbarComponent,
    BottomNavbarComponent,
    IonicModule,
    OrAvatarComponent,
    CardHistoryComponent,
    UserStatsComponent,
    PlayerPreferencesComponent,
  ],
  providers: [UserDetailsQueryHandler],
})
export class ProfileComponent {
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private notificationPermissionProvider: NotificationPermissionProvider =
    inject(NOTIFICATION_TOKEN);
  private globalRefreshService: GlobalRefreshService =
    inject(GlobalRefreshService);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly userDetailsQueryHandler: UserDetailsQueryHandler = inject(
    UserDetailsQueryHandler
  );
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly userDetails$: Observable<UserDetailsDTO> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.userDetailsQueryHandler.details()),
      tap(() => this.isPageLoaded$.next(true)),
      tap(() => this.modalProvider.dismissLoading$())
    );

  readonly isNotificationPermissionsEnabled$: Observable<boolean> =
    this.globalRefreshService.refresh$.pipe(
      tap(() => this.cdr.markForCheck()),
      switchMap(() => this.notificationPermissionProvider.isEnabled()),
      tap(() => this.cdr.detectChanges())
    );

  readonly isiOS: boolean = this.platformProvider.isiOS;
  readonly isNativeRun: boolean = this.platformProvider.isNativeRun;

  openNotificationPermissions() {
    if (this.platformProvider.isAndroid)
      return NativeSettings.openAndroid({
        option: AndroidSettings.AppNotification,
      });

    return NativeSettings.openIOS({
      option: IOSSettings.Notifications,
    });
  }

  goToSettings(): void {
    this.router.navigate([`${AppRoutes.PROFILE}/${ProfileRoutes.SETTINGS}`]);
  }

  calculatelastActive(date: string): string {
    return formattedTimeDifferenceUtil(new Date(date)).toLowerCase();
  }

  protected readonly user = user;
}
