import { LineupsService } from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { SaveLineupsDTO } from "../interfaces";
import { Observable } from "rxjs";

export class SaveLineupsCommandHandler {
  private readonly lineupsService: LineupsService = inject(LineupsService);

  save(payload: SaveLineupsDTO): Observable<void> {
    return this.lineupsService.save(payload);
  }
}
