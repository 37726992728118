export enum AppRoutes {
  ROOT = "",
  AUTH = "auth",
  FIND_GAMES = "find-games",
  GAMES = "games",
  HOST = "host",
  MESSAGES = "messages",
  PROFILE = "profile",
  NOTIFICATIONS = "notifications",
  BLOCKED_VERSION = "blocked-version",
  POLICY_PRIVACY = "policy-privacy",
  ADMIN_PANEL = "admin",
  INTRODUCTION = "introduction",
  INVITATIONS = "invitations",
  DASHBOARD = "dashboard",
  TEAMS = "teams",
}
