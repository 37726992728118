import { GameStatusEnum } from "../enums";
import { LocationDTO } from "./location.dto";

export type NerabyGamesDTO = LocationDTO & {
  readonly games: NearbyGamesGame[];
};

export interface NearbyGamesGame {
  readonly gameId: string;
  readonly name: string;
  readonly gameDate: string;
  readonly gameDateEnd: string;
  readonly currentUserParticipationStatus: GameStatusEnum;
}
