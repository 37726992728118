import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { IonicModule } from "@ionic/angular";
import { AngularFireModule } from "@angular/fire/compat";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  AppInfoInterceptor,
  ENV_CONFIG,
  ErrorHandlerInterceptor,
  IsAuthedService,
  TimezoneInterceptor,
  TraceRequestInterceptor,
  TraceSessionInterceptor,
} from "@core";
import { environment } from "../environment/environment";
import { AuthInterceptor, AuthModule } from "@auth";
import {
  provideFeatureFlagsService,
  provideFeatureFlagsStorage,
  provideFeatureFlagsToken,
  provideLoadFeatureFlagsCommand,
} from "@feature-flags";
import {
  NotificationsInitializer,
  provideNotificationsAlertsService,
  provideNotificationsService,
  providePushNotificationsStatusCommand,
  provideUpdateAlertsLocationCommand,
} from "@notifications";
import { PlayerPositionViewService } from "@games";
import { provideFiltersStorage } from "@find-games";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
import { NgxSpinnerModule } from "ngx-spinner";
import { CreateGameViewService } from "../../projects/hosted-games/src/lib/application/view-services";
import { StartupV1Service } from "./startup-v1.service";
import {
  MessagesModule,
  provideUnreadMessagesQuery,
  provideUnreadMessagesService,
} from "@messages";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    IonicModule.forRoot({ mode: "ios" }),
    AngularFireModule.initializeApp(environment.firebase),
    HttpClientModule,
    AuthModule,
    NgxSpinnerModule,
    MessagesModule,
  ],
  declarations: [AppComponent],
  providers: [
    StartupV1Service,
    NotificationsInitializer,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    IsAuthedService,
    {
      provide: ENV_CONFIG,
      useValue: environment,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppInfoInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceSessionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    provideFeatureFlagsService(),
    provideFeatureFlagsStorage(),
    provideLoadFeatureFlagsCommand(),
    provideFeatureFlagsToken(),
    provideNotificationsService(),
    PlayerPositionViewService,
    CreateGameViewService,
    provideFiltersStorage(),
    provideUnreadMessagesService(),
    provideUnreadMessagesQuery(),
    provideUpdateAlertsLocationCommand(),
    provideNotificationsAlertsService(),
    providePushNotificationsStatusCommand(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
