import { CreateSingleChatCommandPort } from "../ports";
import { inject } from "@angular/core";
import { map, Observable, switchMap, take } from "rxjs";
import {
  CHAT_CHANEL_DTO,
  ChatDtoPort,
} from "../../infrastructure/http-service";
import { ChannelTypeEnum } from "../enums";
import { ChatInitializer } from "../initializers";
import { CreateSingleChatResponseDTO } from "../interfaces";

export class CreateSingleChatCommandHandler
  implements CreateSingleChatCommandPort
{
  private readonly chatDtoPort: ChatDtoPort = inject(CHAT_CHANEL_DTO);
  private readonly chatInitializer: ChatInitializer = inject(ChatInitializer);

  create(userId: string): Observable<void> {
    return this.chatInitializer.init(ChannelTypeEnum.SINGLE).pipe(
      take(1),
      switchMap(() => this.chatDtoPort.createSingleChat(userId)),
      switchMap((data: CreateSingleChatResponseDTO) =>
        this.chatInitializer.openSingleChannel(data.channelExternalId)
      ),
      map(() => void 0)
    );
  }
}
