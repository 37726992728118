import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { ChannelMembersDTO } from "../interfaces";

export const CHANNEL_MEMBERS_QUERY: InjectionToken<ChannelMembersQueryPort> =
  new InjectionToken<ChannelMembersQueryPort>("CHANNEL_MEMBERS_QUERY");

export interface ChannelMembersQueryPort {
  getMembers(channelId: string): Observable<ChannelMembersDTO[]>;
}
