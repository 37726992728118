import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { Clipboard } from "@capacitor/clipboard";
import { MODAL_TOKEN, ModalProvider } from "../tokens/modal.token";

export class CopyService {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  copyToClipboard(value: string): Observable<void> {
    Clipboard.write({ string: value });
    return this.modalProvider.showToast$({
      message: "Skopiowano do schowka",
      position: "bottom",
      duration: 2000,
    });
  }
}
