import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Output,
} from "@angular/core";
import {
  GET_FUTURE_GAMES_QUERY,
  GetFutureGamesQueryPort,
  provideFutureGamesQuery,
  provideGameApplicationsService,
  provideGamesService,
  provideSendApplicationCommand,
  SendApplicationDirective,
} from "@games";
import { GameCardComponent, RedirectTypeEnum } from "@ui-components";
import { Observable, switchMap, tap } from "rxjs";
import {
  GameDetailsModel,
  LocalRefreshService,
  MODAL_TOKEN,
  ModalProvider,
} from "@core";
import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import {
  DECLINE_INVITATION_COMMAND,
  DeclineInvitationCommandPort,
  provideDeclineInvitationCommand,
  provideInvitationsService,
} from "@invitations";

@Component({
  selector: "lib-dashboard-invitations",
  templateUrl: "./dashboard-invitations.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgForOf,
    IonicModule,
    GameCardComponent,
    SendApplicationDirective,
    NgTemplateOutlet,
  ],
  providers: [
    provideGamesService(),
    provideFutureGamesQuery(),
    provideSendApplicationCommand(),
    provideGameApplicationsService(),
    LocalRefreshService,
    provideDeclineInvitationCommand(),
    provideInvitationsService(),
  ],
})
export class DashboardInvitationsComponent {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getFutureGamesQueryPort: GetFutureGamesQueryPort = inject(
    GET_FUTURE_GAMES_QUERY
  );
  private readonly declineInvitationCommandPort: DeclineInvitationCommandPort =
    inject(DECLINE_INVITATION_COMMAND);
  private readonly localRefreshService: LocalRefreshService =
    inject(LocalRefreshService);

  @Output() isInvitationsAvailable: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public isInvitationsExpanded: boolean = true;
  public gameRedirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;

  readonly invitations$: Observable<GameDetailsModel[]> =
    this.localRefreshService.refresh$.pipe(
      switchMap(() => this.modalProvider.showLoading$()),
      switchMap(() => this.getFutureGamesQueryPort.getInvitations()),
      tap((result: GameDetailsModel[]) =>
        this.isInvitationsAvailable.emit(!!result?.length)
      ),
      tap(() => this.modalProvider.dismissLoading$())
    );

  refresh(): void {
    this.localRefreshService.emit();
  }

  stopEventPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  changeInvitationsVisibility(): void {
    this.isInvitationsExpanded = !this.isInvitationsExpanded;
  }

  decline(invitaionId: string, gameId: string): void {
    this.declineInvitationCommandPort
      .decline(invitaionId, gameId)
      .subscribe(() => this.refresh());
  }
}
