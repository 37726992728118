import { SaveQuickFiltersCommandPort } from "../ports";
import { Observable } from "rxjs";
import {
  FILTERS_CONTEXT_PORT,
  FiltersContextPort,
} from "../../infrastructure/storages";
import { inject } from "@angular/core";
import { FiltersValues } from "../models";

export class SaveQuickFiltersCommandHandler
  implements SaveQuickFiltersCommandPort
{
  private filtersContextPort: FiltersContextPort = inject(FILTERS_CONTEXT_PORT);

  saveQuickFilters(data: FiltersValues): Observable<void> {
    return this.filtersContextPort.setFilters(data);
  }
}
