export interface DeepLinkDTO {
  readonly payload: {
    readonly dynamicLinkInfo: {
      readonly domainUriPrefix: string;
      readonly link: string;
      readonly androidInfo: {
        readonly androidPackageName: string;
        readonly androidFallbackLink: string;
      };
      readonly socialMetaTagInfo: SocialMetaTagInfo;
    };
    readonly suffix: {
      readonly option: string;
    };
  };
  readonly apiKey: string;
}

export interface SocialMetaTagInfo {
  readonly socialTitle: string;
  readonly socialDescription: string;
  readonly socialImageLink: string;
}

export interface DeepLinkResponseInterface {
  shortLink: string;
}
