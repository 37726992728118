import { Routes } from "@angular/router";
import { GamesRoutes } from "./games-routes.enum";
import {
  FutureGamesComponent,
  GameFinishedComponent,
  LineupsComponent,
  LocationDetailsComponent,
  MyGameDetailsComponent,
  PastGamesComponent,
  SharedGameComponent,
} from "./components";
import { sharedGameResolver } from "./application/resolvers";
import { provideFutureGamesStorage } from "./infrastructure/storage";
import { isAuthedGuard, referrerResolver } from "@auth";
import { blockWebVersionGuard } from "@core";

export const GamesRouting: Routes = [
  {
    path: GamesRoutes.ROOT,
    redirectTo: GamesRoutes.FUTURE,
    pathMatch: "full",
  },
  {
    path: `${GamesRoutes.DETAILS}/:id`,
    component: MyGameDetailsComponent,
    canActivate: [blockWebVersionGuard],
  },
  {
    path: GamesRoutes.FUTURE,
    component: FutureGamesComponent,
    providers: [provideFutureGamesStorage()],
    canActivate: [blockWebVersionGuard, isAuthedGuard],
  },
  {
    path: GamesRoutes.PAST,
    component: PastGamesComponent,
    providers: [provideFutureGamesStorage()],
    canActivate: [blockWebVersionGuard, isAuthedGuard],
  },
  {
    path: `${GamesRoutes.SHARED}/:id`,
    component: SharedGameComponent,
    resolve: { sharedGameResolver, referrerResolver },
  },
  {
    path: `${GamesRoutes.LOCATION}/:id`,
    component: LocationDetailsComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard],
  },
  {
    path: `${GamesRoutes.GAME_FINISHED}/:id`,
    component: GameFinishedComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard],
  },
  {
    path: `${GamesRoutes.LINEUPS}/:id`,
    component: LineupsComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard],
  },
];
