import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const APPROVE_APPLICATIONS_COMMAND: InjectionToken<ApproveApplicationsCommandPort> =
  new InjectionToken<ApproveApplicationsCommandPort>(
    "APPROVE_APPLICATIONS_COMMAND"
  );

export interface ApproveApplicationsCommandPort {
  approve(gameId: string, payload: string[]): Observable<void>;
}
