import { Provider } from "@angular/core";
import {
  GAMES_LIST_QUERY,
  GET_ARRIVAL_BAR_INFO_QUERY,
  GET_CURRENT_FILTERS_QUERY,
  GET_MAP_MARKERS,
  IS_FILTERS_APPLIED,
  SAVE_FILTERS_COMMAND,
  SAVE_QUICK_FILTERS_COMMAND,
} from "../ports";
import { GetMapMarkersQueryHandler } from "./get-map-markers.query-handler";
import { SaveFiltersCommandHandler } from "./save-filters.command-handler";
import { GetCurrentFiltersQueryHandler } from "./get-current-filters.query-handler";
import { SaveQuickFiltersCommandHandler } from "./save-quick-filters.command-handler";
import { GetArrivalBarInfoQueryHandler } from "./get-arrival-bar-info.query-handler";
import { IsFiltersAppliedQueryHandler } from "./is-filters-applied.query-handler";
import { GamesListQueryHandler } from "./games-list.query-handler";

export const provideMapMarkerQuery = (): Provider => ({
  provide: GET_MAP_MARKERS,
  useClass: GetMapMarkersQueryHandler,
});

export const provideSaveFiltersCommand = (): Provider => ({
  provide: SAVE_FILTERS_COMMAND,
  useClass: SaveFiltersCommandHandler,
});

export const provideGetCurrentFiltersQuery = (): Provider => ({
  provide: GET_CURRENT_FILTERS_QUERY,
  useClass: GetCurrentFiltersQueryHandler,
});

export const provideSaveQuickFiltersCommand = (): Provider => ({
  provide: SAVE_QUICK_FILTERS_COMMAND,
  useClass: SaveQuickFiltersCommandHandler,
});

export const provideArrivalBarQuery = (): Provider => ({
  provide: GET_ARRIVAL_BAR_INFO_QUERY,
  useClass: GetArrivalBarInfoQueryHandler,
});

export const provideIsFiltersAppliedQuery = (): Provider => ({
  provide: IS_FILTERS_APPLIED,
  useClass: IsFiltersAppliedQueryHandler,
});

export const provideGamesListQuery = (): Provider => ({
  provide: GAMES_LIST_QUERY,
  useClass: GamesListQueryHandler,
});
