import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IntroductionRouting } from "./introduction.routing";
import { provideIntroductionService } from "./lib/infrastructure";

@NgModule({
  imports: [RouterModule.forChild(IntroductionRouting)],
  providers: [provideIntroductionService()],
})
export class IntroductionModule {}
