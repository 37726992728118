import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { AdminGamesDTO } from "../../../application/models";

export const ADMIN_GAMES_DTO: InjectionToken<AdminGamesDtoPort> =
  new InjectionToken<AdminGamesDtoPort>("ADMIN_GAMES_DTO");

export interface AdminGamesDtoPort {
  getAllGames(daysBefore: number): Observable<AdminGamesDTO>;
}
