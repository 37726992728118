import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { combineLatest, take, tap } from "rxjs";
import { ActivatedRoute, ParamMap, Params } from "@angular/router";
import { environment } from "src/environment/environment";
import { getNewVersionWebAndroid, getNewVersionWebiOS } from "@update";

@Component({
  selector: "lib-blocked-web-view",
  templateUrl: "./blocked-web-view.component.html",
  styleUrls: ["./blocked-web-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BlockedWebViewComponent {
  @Input({ required: true }) path: string = "";

  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  redirectToNativeApp(): void {
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParamMap,
    ])
      .pipe(
        take(1),
        tap(([params, paramMap]: [Params, ParamMap]) => {
          const referrer: string = encodeURIComponent(
            paramMap.get("referrer") || ""
          );
          const referrerPath = referrer ? `?referrer=${referrer}` : "";
          const sharedUrl: string = `${environment.customUrl}/${this.path}${referrerPath}`;
          const defaultUrl: string = `${environment.customUrl}`;

          const url = this.path ? sharedUrl : defaultUrl;
          window.open(url, "_blank");
        })
      )
      .subscribe();
  }

  downloadAndroid(): void {
    getNewVersionWebAndroid();
  }

  downloadiOS(): void {
    getNewVersionWebiOS();
  }
}
