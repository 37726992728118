import { inject, Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, take, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MODAL_TOKEN, ModalProvider } from "../tokens";
import { PresentModalComponent } from "../modals";
import { ErrorModel } from "../interfaces";
import { getErrorMessage } from "../mappers/error.mapper";
import { ErrorEnum } from "../enums/error.enum";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.info(error.error);
        const knownException: ErrorEnum = error?.error?.knownException;
        const messageForClient: ErrorModel = error?.error?.messageForClient;

        if (error.status === 401) {
          return throwError(() => error);
        }

        this.openModal(getErrorMessage(knownException, messageForClient));

        return throwError(() => error);
      })
    );
  }

  private openModal(error: ErrorModel): void {
    this.modalProvider.dismissLoading$().pipe(take(1)).subscribe();

    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: error.title,
        message: error.message,
        btnTxt: "Zamknij",
      },
      cssClass: "present-modal",
    });
  }
}
