import { Observable } from "rxjs";
import {
  GAME_APPLICATIONS_DTO,
  GameApplicationsDTOPort,
} from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { PlayerPosition } from "@core";
import { SendApplicationResponseDTO } from "../interfaces";

export class SendApplicationCommandHandler {
  private readonly gameApplicationsDTOPort: GameApplicationsDTOPort = inject(
    GAME_APPLICATIONS_DTO
  );

  sendApplication(
    gameId: string,
    position: PlayerPosition
  ): Observable<SendApplicationResponseDTO> {
    return this.gameApplicationsDTOPort.sendApplication(gameId, position);
  }
}
