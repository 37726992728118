import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { AsyncPipe, NgClass } from "@angular/common";
import { BottomNavbarComponent, HomeNavbarComponent } from "@ui-components";
import { FiltersComponent } from "../filters/filters.component";
import { IsAuthedService, MODAL_TOKEN, ModalProvider } from "@core";
import { AppRoutes } from "src/app/app-routes.enum";
import { HostedGamesRoutes } from "@hosted-games";
import { filter, map, Observable, switchMap } from "rxjs";
import {
  NOTIFICATIONS_LIST_QUERY,
  NotificationsListQueryPort,
  NotificationViewModel,
  provideNotificationsListQuery,
} from "@notifications";
import {
  IS_FILTERS_APPLIED,
  IsFiltersAppliedQueryPort,
} from "../../application/ports";
import { provideIsFiltersAppliedQuery } from "../../application/handlers";
import { FindGamesRoutes } from "../../find-games.routes.enum";

@Component({
  selector: "lib-find-games-home",
  templateUrl: "./find-games-home.component.html",
  styleUrls: ["./find-games-home.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    HomeNavbarComponent,
    BottomNavbarComponent,
    NgClass,
  ],
  providers: [provideNotificationsListQuery(), provideIsFiltersAppliedQuery()],
})
export class FindGamesHomeComponent {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly notificationsListQueryPort: NotificationsListQueryPort =
    inject(NOTIFICATIONS_LIST_QUERY);
  private readonly isAuthedService: IsAuthedService = inject(IsAuthedService);
  private readonly isFiltersAppliedQueryPort: IsFiltersAppliedQueryPort =
    inject(IS_FILTERS_APPLIED);

  readonly isAuthed$: Observable<boolean> = this.isAuthedService.isAuthed$;

  readonly isNewNotifications$: Observable<boolean> = this.isAuthed$.pipe(
    filter((isAuthed: boolean) => isAuthed),
    switchMap(() => this.notificationsListQueryPort.getNotificationsList()),
    map((notifications: NotificationViewModel[]) =>
      notifications.filter(
        (notification: NotificationViewModel) => !notification.isRead
      )
    ),
    map((notifications: NotificationViewModel[]) => !!notifications?.length)
  );

  get isMapActive(): boolean {
    return this.router.url.includes(FindGamesRoutes.MAP);
  }

  readonly isFiltersApplied$: Observable<boolean> =
    this.isFiltersAppliedQueryPort.isApplied();

  openFilters(): void {
    this.modalProvider.showModal$({
      component: FiltersComponent,
      cssClass: "filters-modal",
      initialBreakpoint: 1,
      breakpoints: [0, 1],
    });
  }

  goToGameCreator(): void {
    this.router.navigateByUrl(
      `${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}`
    );
  }

  goToList(): void {
    this.router.navigateByUrl(AppRoutes.FIND_GAMES);
  }

  goToMap(): void {
    this.router.navigateByUrl(`${AppRoutes.FIND_GAMES}/${FindGamesRoutes.MAP}`);
  }
}
