import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import {
  PLATFORM_TOKEN,
  PlatformProvider,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
  UserRoleEnum,
} from "@core";
import { AppRoutes } from "src/app/app-routes.enum";
import { HostedGamesRoutes } from "../../hosted-games.routes.enum";
import { map, Observable } from "rxjs";

export const blockWebVersionGuard = (
  route: ActivatedRouteSnapshot
): Observable<true | UrlTree> => {
  const router: Router = inject(Router);
  const platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  const userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  const isNativeRun: boolean = platformProvider.isNativeRun;

  return userDataProvider.userData$.pipe(
    map((userData: UserModel) => {
      if (userData.role === UserRoleEnum.ADMIN || isNativeRun) return true;
      return router.createUrlTree([
        AppRoutes.HOST,
        HostedGamesRoutes.BLOCK_WEB,
      ]);
    })
  );
};
