import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { CreateGameDTO, CreateGameResponseViewModel } from "../../interfaces";

export const CREATE_GAME_COMMAND: InjectionToken<CreateGameCommandPort> =
  new InjectionToken<CreateGameCommandPort>("CREATE_GAME_COMMAND");

export interface CreateGameCommandPort {
  createGame(game: CreateGameDTO): Observable<CreateGameResponseViewModel>;
}
