<img src="assets/login-header.svg" class="auth-header" />

<div
  class="auth-home-contaier-height margin-top--20-px or-align-items-center or-d-flex or-flex-col"
>
  <div class="or-d-flex or-flex-col or-w-80-pc or-h-100-pc">
    <button
      (click)="googleAuth()"
      class="or-bg-white border-radius-4 border-shade or-h-48-px or-head-xs"
    >
      <div class="or-d-flex or-justify-content-center or-align-items-center">
        <img src="assets/icons/google-icon.svg" class="or-mr-8-px" /> Zaloguj
        się przez Google
      </div>
    </button>

    <button
      *ngIf="!isAndroid"
      (click)="appleAuth()"
      class="or-bg-white border-radius-4 border-shade or-h-48-px or-head-xs or-mt-16-px"
    >
      <div class="or-d-flex or-justify-content-center or-align-items-center">
        <img src="assets/icons/apple-icon.svg" class="or-mr-8-px" />
        Zaloguj się przez Apple
      </div>
    </button>

    <div class="or-my-20-px or-text-align-center or-text-sm">lub</div>
    <button
      class="or-bg-white border-radius-4 border-shade or-h-48-px or-head-xs"
      (click)="goToLogin()"
    >
      Zaloguj się e-mailem
    </button>

    <div class="or-head-xs or-my-24-px or-text-align-center">
      Nie masz konta?
    </div>

    <button
      class="or-color-white border-radius-4 or-bg-primary or-h-48-px or-head-xs button-primary-style"
      (click)="goToRegister()"
    >
      Zarejestruj sie
    </button>
  </div>

  <div
    class="or-d-flex or-flex-col or-w-80-pc or-h-100-pc or-justify-content-end"
  >
    <button
      class="border-radius-4 border-shade or-bg-white or-h-48-px or-head-xs"
      (click)="goToMap()"
    >
      Przejdź do mapy
    </button>

    <div class="or-text-md or-text-align-center or-mt-24-px">
      Logując sie akceptujesz
      <span
        [routerLink]="'/policy-privacy'"
        class="or-text-decoration-underline or-color-primary or-cursor-pointer"
        >Politykę prywatności</span
      >
    </div>
  </div>
</div>

<div class="or-text-sm or-text-align-end or-mt-8-px or-mx-8-px">
  {{appVersion}} ({{buildVersion}})
</div>
