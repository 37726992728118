import { inject } from "@angular/core";
import { environment } from "src/environment/environment";
import { USER_DATA_TOKEN, UserDataProvider, UserModel } from "@core";
import { BehaviorSubject, map, Observable, of, switchMap, tap } from "rxjs";
import {
  ChannelService,
  ChatClientService,
  StreamI18nService,
} from "stream-chat-angular";
import { chatTranslation } from "src/assets/i18n/chat-translation";
import { ActiveChannelModel } from "../interfaces/active-channel.model";
import { ChannelTypeEnum } from "../enums";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { MessagesRoutes } from "../../messages-routes.enum";

export class ChatInitializer {
  private userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private chatService: ChatClientService = inject(ChatClientService);
  private channelService: ChannelService = inject(ChannelService);
  private streamI18nService: StreamI18nService = inject(StreamI18nService);
  private router: Router = inject(Router);

  private readonly isChatAvailableSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  readonly isChatAvailable$: Observable<boolean> =
    this.isChatAvailableSubject.asObservable();

  readonly activeChannelData$: Observable<ActiveChannelModel> =
    this.channelService.activeChannel$.pipe(
      map((channel) => channel?.data?.["orlikfyData"] as ActiveChannelModel)
    );

  readonly userData$: Observable<UserModel> = this.userDataProvider.userData$;

  init(type: ChannelTypeEnum): Observable<void> {
    return this.userData$.pipe(
      tap(() => this.stopWatching()),
      tap(() => this.channelService.reset()),
      tap((userData: UserModel) => this.initChatService(userData)),
      tap((userData: UserModel) =>
        this.initChannels(userData?.chatSettings?.chatUserExternalId, type)
      ),
      tap(() => this.streamI18nService.setTranslation("pl", chatTranslation)),
      map(() => void 0)
    );
  }

  stopWatching(): void {
    this.channelService.activeChannel?.stopWatching();
    this.channelService.deselectActiveChannel();
  }

  openSingleChannel(channelExternalId: string): Observable<void> {
    return this.userData$.pipe(
      switchMap(async (userData: UserModel) => {
        const availableChannels =
          await this.chatService.chatClient.queryChannels(
            {
              type: ChannelTypeEnum.SINGLE,
              members: {
                $in: [userData.chatSettings.chatUserExternalId],
              },
            },
            { last_message_at: -1 },
            { watch: true, limit: 10000 }
          );

        const specificChannel = availableChannels.find(
          (channel) => channel.id === channelExternalId
        );

        if (specificChannel) {
          this.channelService.setAsActiveChannel(specificChannel);
        }

        return of(void 0);
      }),
      switchMap(() =>
        this.router.navigateByUrl(
          `${AppRoutes.MESSAGES}/${MessagesRoutes.CHAT}`
        )
      ),
      map(() => void 0)
    );
  }

  private initChatService(userData: UserModel): void {
    this.chatService.init(
      environment.chatToken,
      userData?.chatSettings?.chatUserExternalId,
      userData?.chatSettings?.token
    );
  }

  private async initChannels(
    chatUserExternalId: string,
    type: ChannelTypeEnum
  ) {
    const availableChats = await this.channelService.init(
      {
        type: type,
        members: {
          $in: [chatUserExternalId],
        },
      },
      { last_message_at: -1 },
      { watch: true, limit: 10000 },
      false
    );

    this.isChatAvailableSubject.next(!!availableChats?.length);
  }
}
