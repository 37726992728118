import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, switchMap, take } from "rxjs";
import { AppRoutes } from "src/app/app-routes.enum";
import { IonicModule } from "@ionic/angular";
import { AsyncPipe, NgIf } from "@angular/common";
import {
  ALERTS_SETTINGS_QUERY,
  AlertsSettingsDTO,
  AlertsSettingsQueryPort,
  NotificationsRoutes,
  provideGetAlertsSettingsQuery,
  provideNotificationsAlertsService,
  provideUpdateAlertsSettingsCommand,
  UPDATE_ALERTS_SETTINGS_COMMAND,
  UpdateAlertsSettingsCommandPort,
} from "@notifications";
import {
  ActionModalComponent,
  MODAL_TOKEN,
  ModalProvider,
  NOTIFICATION_TOKEN,
  NotificationPermissionProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";

@Component({
  selector: "lib-alert-bar",
  templateUrl: "./alert-bar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, AsyncPipe, NgIf],
  providers: [
    provideGetAlertsSettingsQuery(),
    provideUpdateAlertsSettingsCommand(),
    provideNotificationsAlertsService(),
  ],
})
export class AlertBarComponent {
  private readonly router: Router = inject(Router);
  private readonly getAlertsSettingsQueryPort: AlertsSettingsQueryPort = inject(
    ALERTS_SETTINGS_QUERY
  );
  private readonly updateAlertsSettingsCommandPort: UpdateAlertsSettingsCommandPort =
    inject(UPDATE_ALERTS_SETTINGS_COMMAND);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private notificationPermissionProvider: NotificationPermissionProvider =
    inject(NOTIFICATION_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  readonly alerts$: Observable<AlertsSettingsDTO> =
    this.getAlertsSettingsQueryPort.getAlertsSettings();

  readonly isPermissionsEnabled$: Observable<boolean> =
    this.notificationPermissionProvider.isEnabled();

  goToAlerts(): void {
    this.router.navigateByUrl(
      `${AppRoutes.NOTIFICATIONS}/${NotificationsRoutes.ALERTS}`
    );
  }

  toggleNotifications(event: CustomEvent): void {
    const value: boolean = event.detail.checked;

    this.notificationPermissionProvider
      .isEnabled()
      .pipe(
        take(1),
        switchMap((isEnabled: boolean) => {
          if (!isEnabled)
            this.modalProvider.showModal$({
              component: ActionModalComponent,
              componentProps: {
                header: "Brak uprawnień do powiadomień w aplikacji",
                message:
                  "Nadaj uprawnienia do powiadomień aby otrzymywać powiadomienia",
                btnOk: "Włącz",
                btnCancel: "Anuluj",
                action: () => this.openNotificationPermissions(),
              },
              cssClass: "present-modal",
            });

          return this.changeAlerts(value);
        })
      )
      .subscribe();
  }

  private changeAlerts(value: boolean) {
    return this.updateAlertsSettingsCommandPort.switchAlerts(value);
  }

  private openNotificationPermissions() {
    if (this.platformProvider.isAndroid)
      return NativeSettings.openAndroid({
        option: AndroidSettings.AppNotification,
      });

    return NativeSettings.openIOS({
      option: IOSSettings.Notifications,
    });
  }
}
