import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { UserStatsDTO } from "../../../application/interfaces";

export const USER_STATS_DTO: InjectionToken<UserStatsDtoPort> =
  new InjectionToken<UserStatsDtoPort>("USER_STATS_DTO");

export interface UserStatsDtoPort {
  getStats(): Observable<UserStatsDTO>;
}
