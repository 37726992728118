import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { GameDetailsModel } from "@core";

export const GET_HOSTED_FUTURE_GAMES_QUERY: InjectionToken<GetHostedFutureGamesQueryPort> =
  new InjectionToken<GetHostedFutureGamesQueryPort>(
    "GET_HOSTED_FUTURE_GAMES_QUERY"
  );

export interface GetHostedFutureGamesQueryPort {
  getGames(): Observable<GameDetailsModel[]>;
}
