import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const PUSH_PERMISSIONS_STATUS_COMMAND: InjectionToken<PushPermissionsStatusCommandPort> =
  new InjectionToken<PushPermissionsStatusCommandPort>(
    "PUSH_PERMISSIONS_STATUS_COMMAND"
  );

export interface PushPermissionsStatusCommandPort {
  setValue(): Observable<void>;
}
