import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import {
  BottomNavbarComponent,
  OrAvatarComponent,
  TitileNavbarComponent,
} from "@ui-components";
import {
  CopyService,
  CopyValueToClipboardDirective,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { Router } from "@angular/router";
import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { TeamCardComponent } from "../team-card/team-card.component";
import { Observable, of, switchMap, take } from "rxjs";
import { TeamDetailsDTO, TeamPlayer } from "../../application/interfaces";
import { mockTeamDetails } from "../../application/data-mock";
import { ManageTeamMemberComponent } from "../manage-team-member/manage-team-member.component";
import {
  CREATE_SINGLE_CHAT_COMMAND,
  CreateSingleChatCommandPort,
  provideCreateSingleChatCommand,
} from "@messages";
import { ManageTeamComponent } from "../manage-team/manage-team.component";

@Component({
  selector: "lib-team-details",
  templateUrl: "./team-details.component.html",
  styleUrls: ["./team-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    NgClass,
    TeamCardComponent,
    AsyncPipe,
    NgIf,
    NgForOf,
    OrAvatarComponent,
    CopyValueToClipboardDirective,
  ],
  providers: [CopyService, provideCreateSingleChatCommand()],
})
export class TeamDetailsComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly createSingleChatCommandPort: CreateSingleChatCommandPort =
    inject(CREATE_SINGLE_CHAT_COMMAND);

  teamDetails$: Observable<TeamDetailsDTO> = of(mockTeamDetails);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  manageTeam(): void {
    this.teamDetails$
      .pipe(
        take(1),
        switchMap((team: TeamDetailsDTO) =>
          this.modalProvider.showModal$({
            component: ManageTeamComponent,
            cssClass: "modal-auto",
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            componentProps: { team: team },
          })
        )
      )
      .subscribe();
  }

  sendMessage(userId: string, event: Event) {
    this.createSingleChatCommandPort.create(userId).subscribe();
  }

  managePlayer(member: TeamPlayer) {
    this.modalProvider.showModal$({
      component: ManageTeamMemberComponent,
      cssClass: "modal-auto",
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      componentProps: { member: member },
    });
  }
}
