import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { PlayerPosition } from "@core";

export const GAME_APPLICATIONS_DTO: InjectionToken<GameApplicationsDTOPort> =
  new InjectionToken<GameApplicationsDTOPort>("GAME_APPLICATIONS_DTO");

export interface GameApplicationsDTOPort {
  sendApplication(gameId: string, position: PlayerPosition): Observable<void>;
  removeFromGame(gameId: string): Observable<void>;
  removeApplication(gameId: string): Observable<void>;
}
