import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TitileNavbarComponent } from "@ui-components";
import { HostedInvitationsListComponent } from "@invitations";

@Component({
  templateUrl: "./invite-players.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, HostedInvitationsListComponent],
})
export class InvitePlayersComponent {}
