<img src="assets/intro/blue-background.svg" class="intro-header" />

<div *ngIf="showElement" [@slideInOutFromRight] class="or-mb-96-px">
  <div
    class="or-d-flex or-justify-content-center or-mt-48-px or-mb-32-px or-w-100-pc"
  >
    <img src="assets/intro/intro-4.svg" class="or-size-24" />
  </div>

  <div class="or-text-align-center or-mx-16-px">
    <div class="or-head-xl or-mb-20-px">
      Ustaw swoje powiadomienia, a gierka sama znajdzie Ciebie!
    </div>

    <div class="or-text-lg or-color-secondary-2">
      Zamiast przeszukiwania różnych grup facebookowych z nadzieją znalezienia
      gierki, ustaw swoje preferencje a aplikacja sama poinformuje Cię o gierce,
      która Cię interesuje!
    </div>
  </div>
</div>
<img
  (click)="goToMap()"
  src="assets/right-arrow-button.svg"
  class="intro-next-button-position"
/>
