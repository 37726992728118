<div class="or-p-16-px or-pt-40-px or-bg-shade">
  <div class="or-head-xs or-color-secondary-2 or-mb-12-px">
    Szczegóły gracza
  </div>

  <div
    class="or-d-flex or-align-items-center or-mb-20-px or-justify-content-between"
  >
    <div class="or-d-flex or-align-items-center">
      <lib-or-avatar
        [avatarUrl]="player.userThumbnail"
        [initials]="player.userDisplayName"
      ></lib-or-avatar>

      <div class="or-head-sm or-truncate-at-line-1">
        {{ player.userDisplayName }}
      </div>

      <img
        *ngIf="player.position === 'GOALKEEPER'"
        class="or-ml-8-px"
        src="assets/icons/goalkeeper.svg"
      />
    </div>

    <img *ngIf="player.isPaid" src="assets/icons/payment-icon.svg" />
  </div>

  <div
    (click)="changePaymentStatus()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px"
  >
    <img src="assets/icons/payment-2-icon.svg" class="or-mr-8-px" />
    <div *ngIf="!player.isPaid" class="or-text-md">Oznacz jako opłacone</div>
    <div *ngIf="player.isPaid" class="or-text-md">Oznacz jako nieopłacone</div>
  </div>

  <div
    (click)="removePlayer()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-d-flex or-p-8-px"
  >
    <img src="assets/icons/trash-icon.svg" class="or-mr-8-px" />
    <div class="or-text-md or-color-red">Usuń gracza</div>
  </div>
</div>
