<lib-title-navbar
  [showDots]="true"
  (onDotsClicked)="manageTeam()"
  title="Twoja grupka"
></lib-title-navbar>

<div
  class="or-px-16-px or-pt-16-px"
  [ngClass]="
    isiOS
      ? 'full-height-scrollable-container-ios'
      : 'full-height-scrollable-container'
  "
>
  <ng-container *ngIf="teamDetails$ | async as teamDetails">
    <lib-team-card [teamDetails]="teamDetails"></lib-team-card>

    <div
      *ngIf="!teamDetails.isOwner"
      class="or-d-flex or-align-items-center or-mt-16-px"
    >
      <lib-or-avatar
        [avatarUrl]="teamDetails.owner.userThumbnail"
        [initials]="teamDetails.owner.userDisplayName"
      ></lib-or-avatar>
      <div class="or-d-flex or-justify-content-between or-w-100-pc">
        <div class="or-max-w-60-pc">
          <div class="or-head-xs or-color-secondary-2">Organizator</div>
          <div class="or-head-sm">
            {{ teamDetails.owner.userDisplayName }}
          </div>
        </div>

        <div class="or-d-flex or-align-items-center">
          <img
            (click)="sendMessage(teamDetails.owner.userId, $event)"
            src="assets/icons/message-host-icon.svg"
            class="or-mr-4-px"
          />

          <button
            [copyToClipboard]="teamDetails.owner.phone"
            class="button-primary-style or-align-items-center or-d-flex or-head-md or-justify-content-between or-px-8-px or-h-32-px or-cursor-pointer"
            [class.disabled-button]="!teamDetails.owner.phone"
          >
            <img src="assets/icons/phone-white.svg" class="or-mr-4-px" />
            <span
              class="or-color-white or-head-xs text-no-wrap"
              [class.disabled-button]="!teamDetails.owner.phone"
              >Skopiuj numer
            </span>
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="teamDetails.members.length"
      class="or-head-xs or-color-secondary-2 or-mt-24-px or-mb-12-px"
    >
      Lista graczy w grupce
    </div>

    <div *ngFor="let member of teamDetails.members; let i = index">
      <div class="or-d-flex or-justify-content-between">
        <div class="or-d-flex or-align-items-center">
          <lib-or-avatar
            [avatarUrl]="member.userThumbnail"
            [initials]="member.userDisplayName"
          ></lib-or-avatar>

          <div class="or-head-sm or-truncate-at-line-1 or-mr-32-px">
            {{ i + 1 }}. {{ member.userDisplayName }}
            <span
              *ngIf="member.isOwner"
              class="border-radius-20 or-bg-black-2 or-color-white or-ml-8-px or-p-4-px"
              >C</span
            >
          </div>
        </div>
        <div class="or-d-flex or-justify-content-end or-align-items-center">
          <img
            (click)="sendMessage(member.userId, $event)"
            src="assets/icons/message-icon.svg"
          />

          <img
            class="or-m-12-px"
            (click)="managePlayer(member)"
            src="assets/icons/multi-dots-icon.svg"
          />
        </div>
      </div>
      <div class="or-d-flex or-justify-content-center or-my-4-px">
        <div class="custom-divider-team-details or-my-4-px"></div>
      </div>
    </div>
  </ng-container>
</div>

<lib-bottom-navbar></lib-bottom-navbar>
