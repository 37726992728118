import {
  FILTERS_CONTEXT_PORT,
  FiltersContextPort,
} from "../../infrastructure/storages";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { FiltersValues } from "../models";

export class GetCurrentFiltersQueryHandler {
  private filtersContextPort: FiltersContextPort = inject(FILTERS_CONTEXT_PORT);

  getFilters(): Observable<FiltersValues> {
    return this.filtersContextPort.filters$;
  }
}
