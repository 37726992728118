<div class="or-my-32-px or-mx-24-px">
  <div class="or-d-flex or-justify-content-center">
    <span class="or-head-xl or-mb-16-px or-text-align-center">{{
      header
    }}</span>
  </div>
  <div
    class="or-d-flex or-justify-content-center or-text-align-center or-mb-20-px"
  >
    <span class="or-text-xl">{{ message }}</span>
  </div>

  <div class="or-mb-24-px">
    <ng-template #componentHost></ng-template>
  </div>

  <div class="or-d-flex">
    <button
      class="button-secondary-style or-h-48-px or-w-60-pc or-head-md or-mr-8-px"
      (click)="closeModal()"
    >
      {{ btnCancel }}
    </button>
    <button
      class="button-primary-style or-h-48-px or-w-60-pc or-head-md"
      (click)="handle()"
    >
      {{ btnOk }}
    </button>
  </div>
</div>
