import { UserRoleEnum } from "../../enums";

export interface UserDTO {
  readonly userId: string;
  readonly userAuthId: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly email: string;
  readonly phone: string;
  readonly name: string;
  readonly surname: string;
  readonly role: UserRoleEnum;
  readonly thumbnailUrl: string;
  readonly isHost: boolean;
  readonly isPaidHost: boolean;
}

export interface ChatUserDataDTO {
  readonly token: string;
  readonly chatUserExternalId: string;
}
